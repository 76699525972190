import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AUTH, AUTHH, BASE_URL, IMAGE_URL } from '../../config/axios';
import { toast, ToastContainer } from 'react-toastify';
import { formatDate, formattedDateLong } from '../Date';
import { Modal } from 'react-bootstrap';
import Dashboardnavbar from '../../layout/Dashboardnavbar';
import { useNavigate } from 'react-router-dom';
import { handleErrorToast } from '../CustomFunctions';
import { AccountBExpenseFinalize } from './ExpenseSheet/ExpenseSlipComponents';
import usePermission from '../../config/permissions';


const RequestImprestApproved = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const approved_other_details = i.other_details.map((other) => ({
                other_name: other.other_name,
                amount: other.amount,
                approved_other: other.amount,
                remaining_other: other.remaining_other,
            }));
            let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                rh_leave_status: "approved",
                admin_leave_status: "pending",
                approved_transportation: i.transportation,
                approved_accomodation: i.accomodation,
                approved_fooding: i.fooding,
                // approved_other: i.other,
                other_details: approved_other_details,
                amount_change_reason: "Confirmed",
            });

            if (res.status === 200) {
                await getImprestname();
                // setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            if (err.response) {
                toast.dismiss(loadingToastId);
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-edit-button" title="TL Imprest Approved" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15 4.5L6.75 12.75L3 9"
                        stroke="#145650"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Approval of Imprest "{i.project_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.project_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Total Expense -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {
                                        Number(i.transportation) +
                                        Number(i.accomodation) +
                                        Number(i.fooding) +
                                        // Number(i.other)
                                        (i.other_details?.length > 0
                                            ? i.other_details.reduce((total, other) => total + Number(other.amount), 0)
                                            : 0)
                                    }
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="transportation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Transportation -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.transportation}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="accomodation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Accommodation -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.accomodation}
                                </div>

                            </div>

                            <div className="flex-row">
                                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Food -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.fooding}
                                </div>
                            </div>

                            {/* <div className="flex-row">
                  <label htmlFor="other" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    {i.other_name ? `${i.other_name} Expenses - ` : 'Other Expenses - '}
  
                  </label>
                  <div className="form-labels announce-date font-size-heading">
                    {i.other}
                  </div>
                </div> */}

                            <div className="flex-column">
                                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                    Other Expenses:
                                </label>
                                {i.other_details?.length > 0 ? (
                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                        {i.other_details.map((other, index) => (
                                            <li key={index} className="form-labels announce-date font-size-heading">
                                                <span className="form-text-trasformation-uppercase">
                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.amount}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <span>No Other Expense <br /></span>
                                )}
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="no_of_days"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Number's of days :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.no_of_days}
                                </div>
                            </div>


                            <div className="flex-row">
                                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Number's of Person :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.multipleperson?.length}
                                </div>

                            </div>
                            <div className="flex-column">
                                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Name of Person :
                                </label>
                                {i.multiple_person_details.map((is) => {
                                    return (
                                        <div className="form-labels announce-date font-size-heading">
                                            {is.emp_code} - {is.name}
                                            <br />
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Remarks :
                                </label>
                                {i.remarks}
                                <div className="form-labels announce-date font-size-heading">
                                </div>
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

const RequestImprestPartialApprove = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        approved_transportation: i.transportation,
        approved_accomodation: i.accomodation,
        approved_fooding: i.fooding,
        // approved_other: i.other,
        other_details: i.other_details.map((other) => ({
            other_name: other.other_name,
            amount: other.amount,
            approved_other: other.amount,
            remaining_other: other.remaining_other,
        })),
        amount_change_reason: "",
    });

    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["amount_change_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // const handleInputChange = (e) => {
    //   const { name, value } = e.target;

    //   setInputState({
    //     ...inputState,
    //     [name]: value.trim() ? "green" : "",
    //   });

    //   setFormData({
    //     ...formData,
    //     [name]: value,
    //   });
    // };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('approved_')) {

            // For individual expenses like transportation, accommodation, fooding, etc.
            if (!name.startsWith('approved_other_')) {
                const itemAmountKey = name.replace('approved_', '');
                const currentValue = i[itemAmountKey] !== undefined && i[itemAmountKey] !== null ? i[itemAmountKey] : 0;
                const maxLength = getNumberLength(currentValue);

                // Ensure the input value does not exceed the allowed length
                if (value.length <= maxLength) {
                    const numericValue = value.trim() ? parseInt(value, 10) : 0;

                    // Check if the numeric value is less than or equal to the current value
                    if (numericValue <= currentValue) {
                        setInputState({
                            ...inputState,
                            [name]: value.trim() ? "success" : "",
                        });

                        setFormData({
                            ...formData,
                            [name]: value,
                        });

                        // Clear any previous error
                        setErrors({
                            ...errors,
                            [name]: "",
                        });
                    } else {
                        // Handle case where the numeric value exceeds the current value
                        setErrors({
                            ...errors,
                            [name]: `Value must be less than or equal to ${currentValue}`,
                        });
                    }
                } else {
                    // Handle case where input length exceeds the allowed length
                    setErrors({
                        ...errors,
                        [name]: `Maximum allowed length is ${maxLength} digits`,
                    });
                }

            } else {
                // For entries in approved_other_details
                const index = parseInt(name.replace('approved_other_', ''), 10);
                const currentOther = i.other_details[index];
                const currentValue = currentOther.amount !== undefined && currentOther.amount !== null ? currentOther.amount : 0;
                const maxLength = getNumberLength(currentValue);

                // Ensure the input value does not exceed the allowed length
                if (value.length <= maxLength) {
                    const numericValue = value.trim() ? parseInt(value, 10) : 0;

                    // Check if the numeric value is less than or equal to the current value
                    if (numericValue <= currentValue) {
                        const newOtherDetails = [...formData.other_details];
                        newOtherDetails[index].approved_other = value;

                        setInputState({
                            ...inputState,
                            [name]: value.trim() ? "success" : "",
                        });

                        setFormData({
                            ...formData,
                            other_details: newOtherDetails,
                        });

                        // Clear any previous error
                        setErrors({
                            ...errors,
                            [name]: "",
                        });
                    } else {
                        // Handle case where the numeric value exceeds the current value
                        setErrors({
                            ...errors,
                            [name]: `Value must be less than or equal to ${currentValue}`,
                        });
                    }
                } else {
                    // Handle case where input length exceeds the allowed length
                    setErrors({
                        ...errors,
                        [name]: `Maximum allowed length is ${maxLength} digits`,
                    });
                }
            }

        } else {
            // For textarea or other fields without length validation
            setInputState({
                ...inputState,
                [name]: value.trim() ? "success" : "",
            });

            setFormData({
                ...formData,
                [name]: value,
            });
            setErrors({
                ...errors,
                [name]: "",
            });
        }
    };

    // Helper function to get the length of a number
    function getNumberLength(value) {
        return value.toString().length;
    }
    console.log("formData")
    console.log(formData)

    // const handleInputChange = (e) => {
    //   const { name, value } = e.target;

    //   if (name.startsWith('approved_')) {

    //     const itemAmountKey = name.replace('approved_', '');
    //     const currentValue = i[itemAmountKey] !== undefined && i[itemAmountKey] !== null ? i[itemAmountKey] : 0;
    //     const maxLength = getNumberLength(currentValue);

    //     // Ensure the input value does not exceed the allowed length
    //     if (value.length <= maxLength) {
    //       const numericValue = value.trim() ? parseInt(value, 10) : 0;

    //       // Check if the numeric value is less than the current value
    //       if (numericValue <= currentValue) {
    //         setInputState({
    //           ...inputState,
    //           [name]: value.trim() ? "success" : "",
    //         });

    //         setFormData({
    //           ...formData,
    //           [name]: value,
    //         });

    //         // Clear any previous error
    //         setErrors({
    //           ...errors,
    //           [name]: "",
    //         });
    //       } else {
    //         // Handle case where the numeric value exceeds the current value
    //         setErrors({
    //           ...errors,
    //           [name]: `Value must be less than or equal to ${currentValue}`,
    //         });
    //       }
    //     } else {
    //       // Handle case where input length exceeds the allowed length
    //       setErrors({
    //         ...errors,
    //         [name]: `Maximum allowed length is ${maxLength} digits`,
    //       });
    //     }
    //   } else {
    //     // For `textarea` or other fields without length validation
    //     setInputState({
    //       ...inputState,
    //       [name]: value.trim() ? "success" : "",
    //     });

    //     setFormData({
    //       ...formData,
    //       [name]: value,
    //     });
    //     setErrors({
    //       ...errors,
    //       [name]: "",
    //     });
    //   }
    // };



    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                    rh_leave_status: "approved",
                    admin_leave_status: "pending",
                    amount_change_reason: formData.amount_change_reason,
                    approved_transportation: formData.approved_transportation,
                    approved_accomodation: formData.approved_accomodation,
                    approved_fooding: formData.approved_fooding,
                    approved_other: formData.approved_other,
                    other_details: formData.other_details,

                });

                if (res.status === 200) {
                    await getImprestname();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    function getNumberLength(value) {
        return value.toString().length;
    }

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-partial-button" title="TL Imprest Partial Approved" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 48 48" id="exclamation">
                    <path fill="#F7941E" fill-rule="evenodd" d="M24 10C25.1046 10 26 10.8954 26 12L26 30C26 31.1046 25.1046 32 24 32 22.8954 32 22 31.1046 22 30L22 12C22 10.8954 22.8954 10 24 10zM24 38C25.1046 38 26 37.1046 26 36 26 34.8954 25.1046 34 24 34 22.8954 34 22 34.8954 22 36 22 37.1046 22.8954 38 24 38z" clip-rule="evenodd"></path></svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center font-weight500 font-size-heading">Give Approvable Amount for Imprest : "{i.project_name}" </h1>
                            <div className="flex-column">
                                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Total Expense
                                </label>
                                <input
                                    id="total"
                                    type="number"
                                    placeholder="0"
                                    value={
                                        Number(formData.approved_transportation) +
                                        Number(formData.approved_accomodation) +
                                        Number(formData.approved_fooding) +
                                        // Number(formData.approved_other)
                                        (formData.other_details?.length > 0
                                            ? formData.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                            : 0)
                                    }
                                    readOnly
                                    className="form-input   font-weight500   font-size-subheading"
                                />
                            </div>
                            <div className="flex-column">
                                <label
                                    htmlFor="approved_transportation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Transportation
                                </label>
                                <input
                                    id="approved_transportation"
                                    type="number"
                                    name="approved_transportation"
                                    placeholder="0"
                                    min={0}
                                    maxLength={getNumberLength(i.transportation)}
                                    max={i.transportation}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.approved_transportation}
                                    className={`form-input ${errors.approved_transportation
                                        ? "error"
                                        : inputState.approved_transportation
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.approved_transportation && (
                                    <span className="error-message font-size-text ">{errors.approved_transportation}</span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label
                                    htmlFor="approved_accomodation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Accommodation
                                </label>
                                <input
                                    id="approved_accomodation"
                                    type="number"
                                    name="approved_accomodation"
                                    placeholder="0"
                                    min={0}
                                    max={i.accomodation}
                                    maxLength={getNumberLength(i.accomodation)}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.approved_accomodation}
                                    className={`form-input ${errors.approved_accomodation
                                        ? "error"
                                        : inputState.approved_accomodation
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.approved_accomodation && (
                                    <span className="error-message font-size-text ">{errors.approved_accomodation}</span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="approved_fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Food
                                </label>
                                <input
                                    id="approved_fooding"
                                    type="number"
                                    name="approved_fooding"
                                    placeholder="0"
                                    min={0}
                                    max={i.fooding}
                                    maxLength={getNumberLength(i.fooding)}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.approved_fooding}
                                    className={`form-input ${errors.approved_fooding
                                        ? "error"
                                        : inputState.approved_fooding
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.approved_fooding && (
                                    <span className="error-message font-size-text ">{errors.approved_fooding}</span>
                                )}
                            </div>

                            {/* <div className="flex-column">
                  <label htmlFor="approved_other" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    {i.other_name ? `${i.other_name} Expenses` : 'Other Expenses'}
                  </label>
                  <input
                    id="approved_other"
                    type="number"
                    name="approved_other"
                    placeholder="0"
                    min={0}
                    max={i.other}
                    maxLength={getNumberLength(i.other)}
                    onChange={handleInputChange}
                    value={formData.approved_other}
                    className={`form-input ${errors.approved_other ? "error" : inputState.approved_other ? "success" : ""
                      }`}
                  />
                  {errors.approved_other && (
                    <span className="error-message font-size-text ">{errors.approved_other}</span>
                  )}
                </div> */}

                            <div className="flex-column">
                                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                    Other Expenses
                                </label>
                                {formData.other_details?.map((other, index) => (
                                    <div key={index} className="flex-column">
                                        <label htmlFor={`approved_other_${index}`} className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                            {other.other_name ? other.other_name : `Other-${index + 1}`} :
                                        </label>
                                        <input
                                            id={`approved_other_${index}`}
                                            type="number"
                                            name={`approved_other_${index}`}
                                            placeholder="0"
                                            min={0}
                                            max={other.amount}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={handleInputChange}
                                            value={other.approved_other}
                                            className={`form-input ${errors[`approved_other_${index}`] ? "error" : inputState[`approved_other_${index}`] ? "success" : ""}`}
                                        />
                                        {errors[`approved_other_${index}`] && <span className="error-message font-size-text">{errors[`approved_other_${index}`]}</span>}
                                    </div>
                                ))}
                            </div>


                            <div className="flex-column">
                                <label htmlFor="amount_change_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Amount Change Reason<span className="required">*</span>
                                </label>
                                <textarea
                                    id="amount_change_reason"
                                    type="text"
                                    name="amount_change_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.amount_change_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.amount_change_reason
                                        ? "error"
                                        : inputState.amount_change_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.amount_change_reason && (
                                    <span className="error-message font-size-text ">{errors.amount_change_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                </Modal.Body>
            </Modal>
        </>
    );
};

const RequestImprestReject = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        rejection_reason: "",
    });

    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                    rh_leave_status: "rejected",
                    rejection_reason: formData.rejection_reason,
                });

                if (res.status === 200) {
                    await getImprestname();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="TL Imprest Reject" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M13.5 4.5L4.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M4.5 4.5L13.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                {/* <Modal.Header closeButton>
            <Modal.Title>Rejection Reason</Modal.Title>
          </Modal.Header> */}
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">

                            <div className="flex-column">
                                <label htmlFor="rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Reporting Head Rejection Reason
                                </label>
                                <textarea
                                    id="rejection_reason"
                                    type="text"
                                    name="rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.rejection_reason
                                        ? "error"
                                        : inputState.rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                </Modal.Body>
            </Modal>
        </>
    );
};


const AdminapprovedHR = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                admin_leave_status: "approved",
            }, AUTH);

            if (res.status === 200) {
                await getImprestname();
                // setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-edit-button" title="Admin Imprest Approved" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15 4.5L6.75 12.75L3 9"
                        stroke="#145650"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Admin Approval of Imprest "{i.project_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.project_name}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Total Expense -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {
                                        Number(i.approved_transportation) +
                                        Number(i.approved_accomodation) +
                                        Number(i.approved_fooding) +
                                        (i.other_details?.length > 0
                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
                                            : 0)
                                    }
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="transportation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Transportation -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.approved_transportation}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="accomodation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Accommodation -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.approved_accomodation}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Food -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.approved_fooding}
                                </div>
                            </div>

                            <div className="flex-column">
                                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                    Other Expenses:
                                </label>
                                {i.other_details?.length > 0 ? (
                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                        {i.other_details.map((other, index) => (
                                            <li key={index} className="form-labels announce-date font-size-heading">
                                                <span className="form-text-trasformation-uppercase">
                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <span>No Other Expense <br /></span>
                                )}
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="no_of_days"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Number's of days :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.no_of_days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Number's of Person :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.multipleperson?.length}
                                </div>

                            </div>
                            <div className="flex-column">
                                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Name of Person :
                                </label>
                                {i.multiple_person_details.map((is) => {
                                    return (
                                        <div className="form-labels announce-date font-size-heading">
                                            {is.emp_code} - {is.name}
                                            <br />
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Remarks :
                                </label>
                                {i.remarks}
                                <div className="form-labels announce-date font-size-heading">
                                </div>
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const AdminDeleteHR = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        admin_rejection_reason: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["admin_rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                    admin_leave_status: "rejected",
                    rh_leave_status: "pending",
                    admin_rejection_reason: formData.admin_rejection_reason,
                });

                if (res.status === 200) {
                    await getImprestname();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Admin Imprest Reject" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M13.5 4.5L4.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M4.5 4.5L13.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-column">
                                <label htmlFor="admin_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Admin Rejection Reason
                                </label>
                                <textarea
                                    id="admin_rejection_reason"
                                    type="text"
                                    name="admin_rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.admin_rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.admin_rejection_reason
                                        ? "error"
                                        : inputState.admin_rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.admin_rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.admin_rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};


const ImprestApprovedAccount = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [fileName, setFileName] = useState("");
    const [formData, setFormData] = useState({
        account_leave_status: "approved",
        account_approved_final_details: "",
        account_transaction_proof: null,
    });

    const totalApproved =
        (Number(i.approved_transportation) +
            Number(i.approved_accomodation) +
            Number(i.approved_fooding) +
            // Number(i.approved_other)
            (i.other_details?.length > 0
                ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
                : 0));

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields =
            totalApproved === 0 ? [] :
                [
                    "account_approved_final_details",
                    "account_transaction_proof"
                ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            setFormData({ ...formData, [name]: file, });
            setFileName(file.name);
        } else {
            setFileName("");
        }
    };

    const handleClear = () => {
        setFormData(prevState => ({
            ...prevState,
            account_transaction_proof: null
        }));
        setFileName("");

    }

    const handleFormSubmitPut = async (e) => {

        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const formDataa = new FormData();

            const symbols = '!@#$%^&*()_-+=';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const numbers = '0123456789';
            const now = new Date();
            const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
            const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = '';
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            if (formData.account_transaction_proof && formData.account_approved_final_details) {
                const originalFile = formData?.account_transaction_proof;
                const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile?.name}`;
                const customFile = new File([originalFile], customFileName, { type: originalFile.type });

                formDataa.append("account_approved_final_details", formData.account_approved_final_details);
                formDataa.append("account_transaction_proof", customFile);
            }
            formDataa.append("account_leave_status", formData.account_leave_status);
            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, formDataa, AUTHH, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (res.status === 200) {
                    await getImprestname();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-edit-button" title="Account Imprest Approved" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15 4.5L6.75 12.75L3 9"
                        stroke="#145650"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Account Approval of Imprest "{i.project_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.project_name}
                                </div>
                            </div>


                            <div className="flex-row">
                                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Total Expense -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {
                                        Number(i.approved_transportation) +
                                        Number(i.approved_accomodation) +
                                        Number(i.approved_fooding) +
                                        // Number(i.approved_other)
                                        (i.other_details?.length > 0
                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
                                            : 0)
                                    }
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="transportation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Transportation -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.approved_transportation}
                                </div>

                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="accomodation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Accommodation -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.approved_accomodation}
                                </div>

                            </div>

                            <div className="flex-row">
                                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Food -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.approved_fooding}
                                </div>
                            </div>

                            <div className="flex-column">
                                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                    Other Expenses:
                                </label>
                                {i.other_details?.length > 0 ? (
                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                        {i.other_details.map((other, index) => (
                                            <li key={index} className="form-labels announce-date font-size-heading">
                                                <span className="form-text-trasformation-uppercase">
                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <span>No Other Expense <br /></span>
                                )}
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="no_of_days"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Number's of days :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.no_of_days}
                                </div>
                            </div>


                            <div className="flex-row">
                                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Number's of Person :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.multipleperson?.length}
                                </div>

                            </div>
                            <div className="flex-column">
                                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Name of Person :
                                </label>
                                {i.multiple_person_details.map((is) => {
                                    return (
                                        <div className="form-labels announce-date font-size-heading">
                                            {is.emp_code} - {is.name}
                                            <br />
                                        </div>
                                    );
                                })}

                            </div>


                            <div className="flex-column">
                                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Remarks :
                                </label>
                                {i.remarks}
                                <div className="form-labels announce-date font-size-heading">
                                </div>
                            </div>


                            {totalApproved === 0 ? null :
                                <>
                                    <div className="flex-column">
                                        <label
                                            htmlFor="account_approved_final_details"
                                            className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                        >
                                            Final Approval Details (Transaction / Cheque no.)
                                        </label>
                                        <input
                                            id="account_approved_final_details"
                                            name="account_approved_final_details"
                                            placeholder="Final Approval Details (Transaction / Cheque no.)"
                                            onChange={handleInputChange}
                                            value={formData.account_approved_final_details}
                                            className={`form-input ${errors.account_approved_final_details
                                                ? "error"
                                                : inputState.account_approved_final_details
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.account_approved_final_details && (
                                            <span className="error-message font-size-text ">{errors.account_approved_final_details}</span>
                                        )}
                                    </div>

                                    <div className="flex-row">
                                        <label htmlFor="" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                            Account Transaction Proof :
                                        </label>
                                        <div className="flex-column" >
                                            <label
                                                htmlFor="account_transaction_proof"
                                                className={`align-center  form-inputss custom-file-upload 
                      ${errors.account_transaction_proof
                                                        ? "error"
                                                        : inputState.account_transaction_proof
                                                            ? "success"
                                                            : ""
                                                    }
                         `}
                                            >
                                                <svg
                                                    className="pdf-input"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15"
                                                    height="15"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                                        stroke="#707070"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </label>
                                            <input
                                                id="account_transaction_proof"
                                                name="account_transaction_proof"
                                                type="file"
                                                onChange={handleFileChange}
                                                onFocus={handleClear}
                                                className="file-inputs align-center"
                                                style={{ display: "none", position: "relative" }}
                                            />
                                            <br />
                                            <div className="flex-row justify-evenly">
                                                <div>
                                                    {fileName && <p style={{ margin: "0px" }}>{fileName}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        {formData.account_transaction_proof ?
                                            <div>
                                                <button className="model-button" onClick={handleClear}>Clear</button>
                                            </div>
                                            : null
                                        }
                                        {errors.account_transaction_proof && (
                                            <span className="error-message font-size-text ">{errors.account_transaction_proof}</span>
                                        )}
                                    </div>
                                </>
                            }
                            <br />

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const ImprestDeleteAccount = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        account_rejection_reason: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["account_rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                    account_leave_status: "rejected",
                    account_rejection_reason: formData.account_rejection_reason,
                });

                if (res.status === 200) {
                    await getImprestname();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Accounts Imprest Reject" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M13.5 4.5L4.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M4.5 4.5L13.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                {/* <Modal.Header closeButton>
            <Modal.Title>Rejection Reason</Modal.Title>
          </Modal.Header> */}
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">

                            <div className="flex-column">
                                <label htmlFor="account_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Account Rejection Reason
                                </label>
                                <textarea
                                    id="account_rejection_reason"
                                    type="text"
                                    name="account_rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.account_rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.account_rejection_reason
                                        ? "error"
                                        : inputState.account_rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.account_rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.account_rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                </Modal.Body>
            </Modal>
        </>
    );
};


const RequestImpresttable = () => {
    const [checkedItems, setCheckedItems] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const getimprestname = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/imprestbyrh/${sessionStorage.getItem(
                    "emp_code"
                )}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
            );
            setOutdutyName(res.data);
            console.log(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getimprestname();
    }, [fromdate, todate, selectedStatus, employeeGet]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/ourcompanysRH/${sessionStorage.getItem("email")}/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    // *********** Employee Details Api End ***********


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 17 17"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3351)">
                                    <mask
                                        id="mask0_650_3351"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="17"
                                        height="17"
                                    >
                                        <path d="M17 0H0V17H17V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_650_3351)">
                                        <path
                                            d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                            fill="#707070"
                                        />
                                        <path
                                            d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                            fill="#707070"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3351">
                                        <rect width="17" height="17" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={employeeGet}
                                onChange={(e) => setEmployeeGet(e.target.value)}
                            >
                                <option value="null">Select Employee Code </option>
                                {allemployee
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <option value={i.emp_code}>
                                            {i.emp_code} - {i.name}
                                        </option>
                                    ))}
                                ;
                            </select>
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Imprest </div>
                        {/* <div className="flex-row">
                <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
                <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
              </div> */}
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">S. No.</th>
                                <th className="align-center">Employee</th>
                                <th className="align-center">Date</th>
                                <th className="align-leftt">Project Name</th>
                                <th className="align-leftt">Total Amount</th>
                                <th className="align-center">No. of Days</th>
                                <th className="align-center">No. of People</th>
                                <th className="align-leftt">Person Name</th>
                                <th className="align-center">Remarks</th>
                                <th className="align-center">Status</th>
                                <th className="align-center width-5vw">Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td" title={`Team Leader Status : ${i.rh_leave_status}`}>
                                            <td className="align-left">{index + 1}</td>

                                            <td className="align-center">
                                                <img
                                                    className="profile-img"
                                                    src={
                                                        `${i.profilepic}`
                                                            ? `${IMAGE_URL}${i.profilepic}`
                                                            : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                    }
                                                    alt="profilepic"
                                                />
                                                <p>{i.imprest_name}</p>
                                            </td>

                                            <td className="align-center">{formatDate(i.date)}</td>
                                            <td className="align-leftt">{i.project_name}</td>

                                            <td className="align-leftt">
                                                {i.rh_leave_status === "approved" ?
                                                    <>
                                                        <b>
                                                            {Number(i.approved_transportation) +
                                                                Number(i.approved_accomodation) +
                                                                Number(i.approved_fooding) +
                                                                (i.other_details?.length > 0
                                                                    ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                                    : 0)}{" "}
                                                            Rs
                                                        </b>{" "}
                                                        <br />
                                                        Transportation: {i.approved_transportation} <br />
                                                        Accomodation: {i.approved_accomodation} <br />
                                                        Food: {i.approved_fooding} <br />
                                                        Others : <br />
                                                        {i.other_details?.length > 0 ? (
                                                            <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                                                {i.other_details.map((other, index) => (
                                                                    <li key={index}>
                                                                        <span className="form-text-trasformation-uppercase">
                                                                            {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other || 0}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <span>No Other Expense <br /></span>
                                                        )}
                                                    </> :
                                                    <>
                                                        <b>
                                                            {Number(i.transportation) +
                                                                Number(i.accomodation) +
                                                                Number(i.fooding) +
                                                                // Number(i.other)}{" "}
                                                                (i.other_details?.length > 0
                                                                    ? i.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                                                    : 0)}{" "}
                                                            Rs
                                                        </b>{" "}
                                                        <br />
                                                        Transportation: {i.transportation} <br />
                                                        Accomodation: {i.accomodation} <br />
                                                        Food: {i.fooding} <br />
                                                        Others : <br />
                                                        {i.other_details?.length > 0 ? (
                                                            <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                                                {i.other_details.map((other, index) => (
                                                                    <li key={index}>
                                                                        <span className="form-text-trasformation-uppercase">
                                                                            {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.amount || 0}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <span>No Other Expense <br /></span>
                                                        )}
                                                    </>}
                                            </td>
                                            <td className="align-center">{i.no_of_days}</td>
                                            <td className="align-center">{i.person}</td>
                                            <td className="align-leftt">
                                                {i.multiple_person_details.map((is) => {
                                                    return (
                                                        <>
                                                            {is.emp_code} - {is.name}
                                                            <br />
                                                        </>
                                                    );
                                                })}
                                            </td>
                                            <td className="align-center">{i.remarks}</td>
                                            <td className="align-center"  >
                                                <span
                                                    request-status={i.rh_leave_status}
                                                    className="request-status align-center"
                                                    title={`${i.rh_leave_status === "rejected" ? `TL Reason For Rejection : \n${i.rejection_reason}` : ''}`}
                                                >
                                                    {i.rh_leave_status === "pending"
                                                        ? "Pending For Rh Approval ⌛"
                                                        : i.rh_leave_status === "rejected"
                                                            ? "Rh ✖, "
                                                            : "Rh ✔, "}
                                                </span>
                                                {/* <span request-status={i.rh_leave_status} className="request-status align-center" > {i.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : "Rh ✔, "}</span> */}
                                                <br />
                                                <span
                                                    request-status={i.admin_leave_status}
                                                    className="request-status align-center"
                                                    title={`${i.admin_leave_status === "rejected" ? `Admin Reason: ${i.admin_rejection_reason}` : ''}`}
                                                >
                                                    {i.rh_leave_status === "rejected" &&
                                                        i.admin_leave_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.rh_leave_status === "approved" &&
                                                            i.admin_leave_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.rh_leave_status === "approved" &&
                                                                i.admin_leave_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.rh_leave_status === "approved" &&
                                                                    i.admin_leave_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.admin_leave_status}
                                                </span>
                                                {/* <span request-status={i.admin_leave_status} className="request-status align-center" > {i.rh_leave_status === "approved" && i.admin_leave_status === "pending" ? "Pending For Admin Approval ⌛," : "Admin ✔, "}</span> */}
                                                <br />
                                                <span
                                                    request-status={i.account_leave_status}
                                                    className="request-status align-center"
                                                    title={`${i.account_leave_status === "rejected" ? `Account Reason: ${i.account_rejection_reason}` : ''}`}
                                                >
                                                    {i.rh_leave_status === "rejected" &&
                                                        i.admin_leave_status === "rejected" &&
                                                        i.account_leave_status === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_leave_status === "approved" &&
                                                            i.admin_leave_status === "approved" &&
                                                            i.account_leave_status === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_leave_status === "approved" &&
                                                                i.admin_leave_status === "approved" &&
                                                                i.account_leave_status === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_leave_status === "approved" &&
                                                                    i.admin_leave_status === "approved" &&
                                                                    i.account_leave_status === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Status: " + i.account_leave_status}
                                                </span>
                                                {/* <span request-status={i.account_leave_status} className="request-status align-center" >{(i.rh_leave_status === "approved" && i.admin_leave_status === "approved" && i.account_leave_status === "pending") ? "Pending For Account Approval ⌛" : "Accounts ✔"}</span> */}
                                            </td>
                                            <td className="align-center" style={{ display: 'flex' }}>
                                                {i.rh_leave_status === 'approved' ? null :
                                                    <div>
                                                        <RequestImprestApproved
                                                            i={i}
                                                            getImprestname={getimprestname}
                                                        />
                                                        <RequestImprestPartialApprove
                                                            i={i}
                                                            getImprestname={getimprestname}
                                                        />
                                                    </div>}
                                                {i.rh_leave_status === 'rejected' ? null :
                                                    <div>
                                                        <RequestImprestReject
                                                            i={i}
                                                            getImprestname={getimprestname}
                                                        />
                                                    </div>}
                                                {/* <RequestOutdutyapproved i={i} getimprestname={getimprestname} /> */}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div >
        </>
    );
};

const RequestExpensetable = () => {
    const [checkedItems, setCheckedItems] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const getexpensename = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/expensebyrh/${sessionStorage.getItem("emp_code")}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
            );
            setOutdutyName(res.data);
            console.log(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getexpensename();
    }, [fromdate, todate, selectedStatus, employeeGet]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/ourcompanysRH/${sessionStorage.getItem("email")}/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    // *********** Employee Details Api End ***********

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
    const opener = "tl"
    const openExenseSlip = (i) => {
        navigate(baseUrl + i.id + '/', { state: { i, opener } });
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">

                            <div className="  date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 17 17"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3351)">
                                    <mask
                                        id="mask0_650_3351"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="17"
                                        height="17"
                                    >
                                        <path d="M17 0H0V17H17V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_650_3351)">
                                        <path
                                            d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                            fill="#707070"
                                        />
                                        <path
                                            d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                            fill="#707070"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3351">
                                        <rect width="17" height="17" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={employeeGet}
                                onChange={(e) => setEmployeeGet(e.target.value)}
                            >
                                <option value="">Select Employee Code </option>
                                {allemployee
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <option value={i.emp_code}>
                                            {i.emp_code} - {i.name}
                                        </option>
                                    ))}
                                ;
                            </select>
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500 font-size-heading">Expense </div>
                        {/* <div className="flex-row">
                <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
                <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
              </div> */}
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left width-5vw">S. No.</th>
                                <th className="align-center width-5vw">Date</th>
                                <th className="align-center width-10vw">Expense <b>Against</b> Imprest</th>
                                <th className="align-center width-10vw">Imprest Amount</th>
                                <th className="align-center width-5vw">Utilized Money</th>
                                <th className="align-center width-5vw">Remaining Money</th>
                                <th className="align-center width-10vw">Status</th>
                                <th className="align-center width-15vw">Remarks</th>
                                <th className="align-center width-5vw">View/Action</th>
                                {/* <th className="align-right  ">Action</th> */}
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>

                                            <td className="align-center">{formatDate(i.date)}</td>
                                            <td className="align-center"><span>{i.name}</span>
                                                <br />
                                                <b> Against </b>
                                                <br />
                                                <span>{i.project_name}</span></td>
                                            <td className="align-leftt">
                                                <b>
                                                    {Number(i.approved_transportation) +
                                                        Number(i.approved_accomodation) +
                                                        Number(i.approved_fooding) +
                                                        (i.other_details?.length > 0
                                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                            : 0)}{" "}
                                                    Rs
                                                </b>{" "}
                                                <br />
                                                Transportation: {i.approved_transportation} <br />
                                                Accomodation: {i.approved_accomodation} <br />
                                                Food: {i.approved_fooding} <br />
                                                Others : <br />
                                                {i.other_details?.length > 0 ? (
                                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                                        {i.other_details.map((other, index) => (
                                                            <li key={index}>
                                                                <span className="form-text-trasformation-uppercase">
                                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other || 0}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span>No Other Expense <br /></span>
                                                )}
                                            </td>
                                            <td className="align-center">{i.utilized_amount ? i.utilized_amount : '-'} Rs</td>
                                            <td className="align-center">{i.remaining_amount ? i.remaining_amount : '-'} Rs</td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_status}
                                                    className="request-status align-center"
                                                    title={i.rh_status === "rejected" ? `Rh Reject Reason : ${i.rh_rejection_reason}` : ""}
                                                >
                                                    {i.rh_status === "pending"
                                                        ? "Pending For Rh Approval ⌛"
                                                        : i.rh_status === "rejected"
                                                            ? "Rh ✖, "
                                                            : "Rh ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status align-center"
                                                    title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected" &&
                                                        i.account_status_a === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "approved" &&
                                                            i.account_status_a === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "approved" &&
                                                                i.account_status_a === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved" &&
                                                                    i.account_status_a === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 1: " + i.account_status_a}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.admin_status}
                                                    className="request-status align-center"
                                                    title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.admin_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_b}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected" &&
                                                        i.account_status_b === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "approved" &&
                                                            i.account_status_b === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "approved" &&
                                                                i.account_status_b === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved" &&
                                                                    i.account_status_b === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 2: " + i.account_status_b}
                                                </span>
                                            </td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "pending"
                                                        ? "-"
                                                        : i.rh_status === "rejected"
                                                            ? `Rh Reject Reason : ${i.rh_rejection_reason}`
                                                            : "Rh: Verified Amounts for Bills."}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status align-center"
                                                >
                                                    {i.account_status_a === "pending"
                                                        ? "-"
                                                        : i.account_status_a === "rejected"
                                                            ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                                                            : "Accounts : Bills Verified."}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.admin_status}
                                                    className="request-status align-center"
                                                    title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.admin_status === "pending"
                                                        ? "-"
                                                        : i.admin_status === "rejected"
                                                            ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                                                            : "Admin: Approved After Final Verification."}
                                                </span>
                                            </td>
                                            <td className="align-center">
                                                <button
                                                    className="allproject-dashboard"
                                                    title="View using Navigate"
                                                    onClick={(e) => openExenseSlip(i)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};


const RequestImpresttableAdmin = () => {
    // *********** Employee Details Api Start ***********

    const [buffer, setBuffering] = useState(true); //buffering logic

    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("company_id")}`
    );

    const [active, setActive] = useState("active");

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getimprestname = async () => {
        try {
            setBuffering(true); //buffering logic // Start Buffering
            const res = await axios.get(
                `${BASE_URL}/siteproject/imprestbyadmin/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/${selectedDivision}/${active}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getSubCompany();
        getimprestname();
    }, [fromdate, todate, selectedStatus, employeeGet, selectedDivision]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/ourcompanyempdetails/${active}/${selectedDivision}/`
            );
            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });
            setAllemployee(sortedList);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, [active, selectedDivision]);
    //! *********** Employee Details Api End ***********

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 17 17"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3351)">
                                    <mask
                                        id="mask0_650_3351"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="17"
                                        height="17"
                                    >
                                        <path d="M17 0H0V17H17V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_650_3351)">
                                        <path
                                            d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                            fill="#707070"
                                        />
                                        <path
                                            d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                            fill="#707070"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3351">
                                        <rect width="17" height="17" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={employeeGet}
                                onChange={(e) => setEmployeeGet(e.target.value)}
                            >
                                <option value="null">Select Employee Code </option>
                                {allemployee
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <option value={i.emp_code}>
                                            {i.emp_code} - {i.name}
                                        </option>
                                    ))}
                                ;
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null"> All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-10vw  "
                                type="text"
                                value={active}
                                onChange={(e) => setActive(e.target.value)}
                            >
                                <option value="null">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">In Active</option>
                            </select>
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Imprest </div>
                        {/* <div className="flex-row">
                <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
                <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
              </div> */}
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">S. No.</th>
                                <th className="align-center">Employee</th>
                                <th className="align-center">Date</th>
                                <th className="align-leftt">Project Name</th>
                                <th className="align-leftt">Total Amount</th>
                                <th className="align-center">No. of Days</th>
                                <th className="align-center">No. of People</th>
                                <th className="align-leftt">Person Name</th>
                                <th className="align-center">Remarks</th>
                                <th className="align-center">Status</th>
                                <th className="align-right ">Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>

                                            <td className="align-center">
                                                <img
                                                    className="profile-img"
                                                    src={
                                                        `${i.profilepic}`
                                                            ? `${IMAGE_URL}${i.profilepic}`
                                                            : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                    }
                                                    alt="profilepic"
                                                />
                                                <p>{i.imprest_name}</p>
                                            </td>

                                            <td className="align-center">{formatDate(i.date)}</td>
                                            <td className="align-leftt">{i.project_name}</td>

                                            <td className="align-leftt">
                                                <>
                                                    <b>
                                                        {Number(i.approved_transportation) +
                                                            Number(i.approved_accomodation) +
                                                            Number(i.approved_fooding) +
                                                            (i.other_details?.length > 0
                                                                ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                                : 0)}{" "}
                                                        Rs
                                                    </b>{" "}
                                                    <br />
                                                    Transportation: {i.approved_transportation} <br />
                                                    Accomodation: {i.approved_accomodation} <br />
                                                    Food: {i.approved_fooding} <br />
                                                    Others : <br />
                                                    {i.other_details?.length > 0 ? (
                                                        <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                                            {i.other_details.map((other, index) => (
                                                                <li key={index}>
                                                                    <span className="form-text-trasformation-uppercase">
                                                                        {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other || 0}
                                                                    </span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <span>No Other Expense <br /></span>
                                                    )}
                                                </>
                                            </td>
                                            <td className="align-center">{i.no_of_days}</td>
                                            <td className="align-center">{i.person}</td>
                                            <td className="align-leftt">
                                                {i.multiple_person_details.map((is) => {
                                                    return (
                                                        <>
                                                            {is.emp_code} - {is.name}
                                                            <br />
                                                        </>
                                                    );
                                                })}
                                            </td>
                                            <td className="align-center">{i.remarks}</td>
                                            <td className="align-center"  >
                                                <span
                                                    request-status={i.rh_leave_status}
                                                    className="request-status align-center"
                                                    title={`${i.rh_leave_status === "rejected" ? `TL Reason For Rejection : \n${i.rejection_reason}` : ''}`}
                                                >
                                                    {i.rh_leave_status === "pending"
                                                        ? "Pending For Rh Approval ⌛"
                                                        : i.rh_leave_status === "rejected"
                                                            ? "Rh ✖, "
                                                            : "Rh ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.admin_leave_status}
                                                    className="request-status align-center"
                                                    title={`${i.admin_leave_status === "rejected" ? `Admin Reason: ${i.admin_rejection_reason}` : ''}`}
                                                >
                                                    {i.rh_leave_status === "rejected" &&
                                                        i.admin_leave_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.rh_leave_status === "approved" &&
                                                            i.admin_leave_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.rh_leave_status === "approved" &&
                                                                i.admin_leave_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.rh_leave_status === "approved" &&
                                                                    i.admin_leave_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.admin_leave_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_leave_status}
                                                    className="request-status align-center"
                                                    title={`${i.account_leave_status === "rejected" ? `Account Reason: ${i.account_rejection_reason}` : ''}`}
                                                >
                                                    {i.rh_leave_status === "rejected" &&
                                                        i.admin_leave_status === "rejected" &&
                                                        i.account_leave_status === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_leave_status === "approved" &&
                                                            i.admin_leave_status === "approved" &&
                                                            i.account_leave_status === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_leave_status === "approved" &&
                                                                i.admin_leave_status === "approved" &&
                                                                i.account_leave_status === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_leave_status === "approved" &&
                                                                    i.admin_leave_status === "approved" &&
                                                                    i.account_leave_status === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Status: " + i.account_leave_status}
                                                </span>
                                            </td>

                                            <td className="align-right">
                                                <div className="flex-row">
                                                    <>
                                                        {sessionStorage.getItem("role") === "Admin" ? (
                                                            i.admin_leave_status === "approved" ? (
                                                                <></>
                                                            ) : (
                                                                <AdminapprovedHR
                                                                    i={i}
                                                                    getImprestname={getimprestname}
                                                                />
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                    {/* <RequestOutdutyapproved i={i} getimprestname={getimprestname} /> */}
                                                    <>
                                                        {sessionStorage.getItem("role") === "Admin" ? (
                                                            i.admin_leave_status === "rejected" ? (
                                                                <></>
                                                            ) : (
                                                                <AdminDeleteHR
                                                                    i={i}
                                                                    getImprestname={getimprestname}
                                                                />
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestExpensetableAdmin = () => {
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("company_id")}`
    );

    const [active, setActive] = useState("active");

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getexpansename = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const res = await axios.get(`${BASE_URL}/siteproject/expense/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            const res = await axios.get(
                `${BASE_URL}/siteproject/expensebyadmin/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/${selectedDivision}/${active}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getexpansename();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, employeeGet, selectedDivision]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/ourcompanyempdetails/${active}/${selectedDivision}/`
            );
            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });
            setAllemployee(sortedList);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, [active, selectedDivision]);
    //! *********** Employee Details Api End ***********

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
    const opener = "admin"
    const openExenseSlip = (i) => {
        navigate(baseUrl + i.id + '/', { state: { i, opener } });
    };
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 17 17"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3351)">
                                    <mask
                                        id="mask0_650_3351"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="17"
                                        height="17"
                                    >
                                        <path d="M17 0H0V17H17V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_650_3351)">
                                        <path
                                            d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                            fill="#707070"
                                        />
                                        <path
                                            d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                            fill="#707070"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3351">
                                        <rect width="17" height="17" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-15vw   date-field"
                                type="text"
                                value={employeeGet}
                                onChange={(e) => setEmployeeGet(e.target.value)}
                            >
                                <option value="null">Select Employee Code </option>
                                {allemployee
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <option value={i.emp_code}>
                                            {i.emp_code} - {i.name}
                                        </option>
                                    ))}
                                ;
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-10vw date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null">All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-10vw  "
                                type="text"
                                value={active}
                                onChange={(e) => setActive(e.target.value)}
                            >
                                <option value="null">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">In Active</option>
                            </select>
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left width-5vw">S. No.</th>
                                <th className="align-center width-5vw">Date</th>
                                <th className="align-center width-10vw">Expense <b>Against</b> Imprest</th>
                                <th className="align-center width-10vw">Imprest Amount</th>
                                <th className="align-center width-5vw">Utilized Money</th>
                                <th className="align-center width-5vw">Remaining Money</th>
                                <th className="align-center width-10vw">Status</th>
                                <th className="align-center width-15vw">Remarks</th>
                                <th className="align-center width-5vw">View/Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>

                                            <td className="align-center">{formatDate(i.date)}</td>
                                            <td className="align-center"><span>{i.name}</span>
                                                <br />
                                                <b> Against </b>
                                                <br />
                                                <span>{i.project_name}</span></td>
                                            <td className="align-leftt">
                                                <b>
                                                    {Number(i.approved_transportation) +
                                                        Number(i.approved_accomodation) +
                                                        Number(i.approved_fooding) +
                                                        Number(i.other_details?.length > 0
                                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                            : 0)}{" "}
                                                    Rs
                                                </b>{" "}
                                                <br />
                                                Transportation: {i.approved_transportation} <br />
                                                Accomodation: {i.approved_accomodation} <br />
                                                Food: {i.approved_fooding} <br />
                                                Others : <br />
                                                {i.other_details?.length > 0 ? (
                                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                                        {i.other_details.map((other, index) => (
                                                            <li key={index}>
                                                                <span className="form-text-trasformation-uppercase">
                                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other || 0}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span>No Other Expense <br /></span>
                                                )}
                                            </td>
                                            <td className="align-center">{i.utilized_amount ? i.utilized_amount : '-'} Rs</td>
                                            <td className="align-center">{i.remaining_amount ? i.remaining_amount : '-'} Rs</td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_status}
                                                    className="request-status align-center"
                                                    title={i.rh_status === "rejected" ? `Rh Reject Reason : ${i.rh_rejection_reason}` : ""}
                                                >
                                                    {i.rh_status === "pending"
                                                        ? "Pending For Rh Approval ⌛"
                                                        : i.rh_status === "rejected"
                                                            ? "Rh ✖, "
                                                            : "Rh ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status align-center"
                                                    title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected" &&
                                                        i.account_status_a === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "approved" &&
                                                            i.account_status_a === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "approved" &&
                                                                i.account_status_a === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved" &&
                                                                    i.account_status_a === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 1: " + i.account_status_a}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.admin_status}
                                                    className="request-status align-center"
                                                    title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.admin_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_b}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected" &&
                                                        i.account_status_b === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "approved" &&
                                                            i.account_status_b === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "approved" &&
                                                                i.account_status_b === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved" &&
                                                                    i.account_status_b === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 2: " + i.account_status_b}
                                                </span>
                                            </td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "pending"
                                                        ? "-"
                                                        : i.rh_status === "rejected"
                                                            ? `Rh Reject Reason : ${i.rh_rejection_reason}`
                                                            : "Rh: Verified Amounts for Bills."}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status align-center"
                                                >
                                                    {i.account_status_a === "pending"
                                                        ? "-"
                                                        : i.account_status_a === "rejected"
                                                            ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                                                            : "Accounts : Bills Verified."}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.admin_status}
                                                    className="request-status align-center"
                                                    title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.admin_status === "pending"
                                                        ? "-"
                                                        : i.admin_status === "rejected"
                                                            ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                                                            : "Admin: Approved After Final Verification."}
                                                </span>
                                            </td>
                                            <td className="align-center">
                                                <button
                                                    className="allproject-dashboard"
                                                    title="View using Navigate"
                                                    onClick={(e) => openExenseSlip(i)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestImpresttableAcc = () => {
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    //! ******************************API start***************************//
    const getimprestname = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/imprestbyaccount/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getimprestname();
    }, [fromdate, todate, selectedStatus, employeeGet]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/ourcompanyempdetails/active/${sessionStorage.getItem('company_id')}/`
                // `${BASE_URL}/siteproject/ourcompanysRH/${sessionStorage.getItem("email")}/`
            );


            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });

            setAllemployee(sortedList);
            // setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 17 17"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3351)">
                                    <mask
                                        id="mask0_650_3351"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="17"
                                        height="17"
                                    >
                                        <path d="M17 0H0V17H17V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_650_3351)">
                                        <path
                                            d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                            fill="#707070"
                                        />
                                        <path
                                            d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                            fill="#707070"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3351">
                                        <rect width="17" height="17" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={employeeGet}
                                onChange={(e) => setEmployeeGet(e.target.value)}
                            >
                                <option value="null">Select Employee Code </option>
                                {allemployee
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <option value={i.emp_code}>
                                            {i.emp_code} - {i.name}
                                        </option>
                                    ))}
                                ;
                            </select>
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Imprest </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">S. No.</th>
                                <th className="align-center">Employee</th>
                                <th className="align-center">Date</th>
                                <th className="align-leftt">Project Name</th>
                                <th className="align-leftt">Total Amount</th>
                                <th className="align-center">No. of Days</th>
                                <th className="align-center">No. of People</th>
                                <th className="align-leftt">Person Name</th>
                                <th className="align-center">Remarks</th>
                                <th className="align-center">Status</th>
                                <th className="align-right">Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>

                                            <td className="align-center">
                                                <img
                                                    className="profile-img"
                                                    src={
                                                        `${i.profilepic}`
                                                            ? `${IMAGE_URL}${i.profilepic}`
                                                            : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                    }
                                                    alt="profilepic"
                                                />
                                                <p>{i.imprest_name}</p>
                                            </td>

                                            <td className="align-center">{formatDate(i.date)}</td>
                                            <td className="align-leftt">{i.project_name}</td>

                                            <td className="align-leftt">
                                                <b>
                                                    {Number(i.approved_transportation) +
                                                        Number(i.approved_accomodation) +
                                                        Number(i.approved_fooding) +
                                                        (i.other_details?.length > 0
                                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                            : 0)}{" "}
                                                    Rs
                                                </b>{" "}
                                                <br />
                                                Transportation: {i.approved_transportation} <br />
                                                Accomodation: {i.approved_accomodation} <br />
                                                Food: {i.approved_fooding} <br />
                                                Others : <br />
                                                {i.other_details?.length > 0 ? (
                                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                                        {i.other_details.map((other, index) => (
                                                            <li key={index}>
                                                                <span className="form-text-trasformation-uppercase">
                                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other || 0}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span>No Other Expense <br /></span>
                                                )}
                                            </td>
                                            <td className="align-center">{i.no_of_days}</td>
                                            <td className="align-center">{i.person}</td>
                                            <td className="align-leftt">
                                                {i.multiple_person_details.map((is) => {
                                                    return (
                                                        <>
                                                            {is.emp_code} - {is.name}
                                                            <br />
                                                        </>
                                                    );
                                                })}
                                            </td>
                                            <td className="align-center">{i.remarks}</td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_leave_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_leave_status === "pending"
                                                        ? "Pending For Rh Approval ⌛"
                                                        : i.rh_leave_status === "rejected"
                                                            ? "Rh ✖, "
                                                            : "Rh ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.admin_leave_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_leave_status === "rejected" &&
                                                        i.admin_leave_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.rh_leave_status === "approved" &&
                                                            i.admin_leave_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.rh_leave_status === "approved" &&
                                                                i.admin_leave_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.rh_leave_status === "approved" &&
                                                                    i.admin_leave_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.admin_leave_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_leave_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_leave_status === "rejected" &&
                                                        i.admin_leave_status === "rejected" &&
                                                        i.account_leave_status === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_leave_status === "approved" &&
                                                            i.admin_leave_status === "approved" &&
                                                            i.account_leave_status === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_leave_status === "approved" &&
                                                                i.admin_leave_status === "approved" &&
                                                                i.account_leave_status === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_leave_status === "approved" &&
                                                                    i.admin_leave_status === "approved" &&
                                                                    i.account_leave_status === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Status: " + i.account_leave_status}
                                                </span>
                                            </td>

                                            <td className="align-right ">
                                                <div className="flex-row ">
                                                    <>
                                                        {
                                                            i.account_leave_status === "approved" ? (
                                                                <></>
                                                            ) : (
                                                                <ImprestApprovedAccount
                                                                    i={i}
                                                                    getImprestname={getimprestname}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                    <>
                                                        {
                                                            i.account_leave_status === "rejected" ? (
                                                                <></>
                                                            ) : (
                                                                // <ImprestDeleteAccount
                                                                //   i={i}
                                                                //   getImprestname={getimprestname}
                                                                // />
                                                                <></>
                                                            )
                                                        }
                                                    </>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestExpensetableAccStage1 = () => {
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    //! ******************************API start***************************//
    const getexpansename = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const res = await axios.get(`${BASE_URL}/siteproject/expense/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            const res = await axios.get(
                `${BASE_URL}/siteproject/expensebyaccount_a/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getexpansename();
    }, [fromdate, todate, selectedStatus, employeeGet]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/ourcompanyempdetails/active/${sessionStorage.getItem('company_id')}/`
                // `${BASE_URL}/siteproject/ourcompanysRH/${sessionStorage.getItem("email")}/`
            );


            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });

            setAllemployee(sortedList);
            // setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
    const opener = "acc"
    const openExenseSlip = (i) => {
        navigate(baseUrl + i.id + '/', { state: { i, opener } });
    };
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 17 17"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3351)">
                                    <mask
                                        id="mask0_650_3351"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="17"
                                        height="17"
                                    >
                                        <path d="M17 0H0V17H17V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_650_3351)">
                                        <path
                                            d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                            fill="#707070"
                                        />
                                        <path
                                            d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                            fill="#707070"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3351">
                                        <rect width="17" height="17" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-15vw   date-field"
                                type="text"
                                value={employeeGet}
                                onChange={(e) => setEmployeeGet(e.target.value)}
                            >
                                <option value="null">Select Employee Code </option>
                                {allemployee
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <option value={i.emp_code}>
                                            {i.emp_code} - {i.name}
                                        </option>
                                    ))}
                                ;
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500 font-size-heading">Expense Requests</div>
                        <div className="flex-row">
                        </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left width-5vw">S. No.</th>
                                <th className="align-center width-5vw">Date</th>
                                <th className="align-center width-10vw">Expense <b>Against</b> Imprest</th>
                                <th className="align-center width-10vw">Imprest Amount</th>
                                <th className="align-center width-5vw">Utilized Money</th>
                                <th className="align-center width-5vw">Remaining Money</th>
                                <th className="align-center width-10vw">Status</th>
                                <th className="align-center width-15vw">Remarks</th>
                                <th className="align-center width-5vw">View/Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="9"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>

                                            <td className="align-center">{formatDate(i.date)}</td>
                                            <td className="align-center"><span>{i.name}</span>
                                                <br />
                                                <b> Against </b>
                                                <br />
                                                <span>{i.project_name}</span></td>
                                            <td className="align-leftt">
                                                <b>
                                                    {Number(i.approved_transportation) +
                                                        Number(i.approved_accomodation) +
                                                        Number(i.approved_fooding) +
                                                        (i.other_details?.length > 0
                                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                            : 0)}{" "}
                                                    Rs
                                                </b>{" "}
                                                <br />
                                                Transportation: {i.approved_transportation} <br />
                                                Accomodation: {i.approved_accomodation} <br />
                                                Food: {i.approved_fooding} <br />
                                                Others : <br />
                                                {i.other_details?.length > 0 ? (
                                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                                        {i.other_details.map((other, index) => (
                                                            <li key={index}>
                                                                <span className="form-text-trasformation-uppercase">
                                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other || 0}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span>No Other Expense <br /></span>
                                                )}
                                            </td>

                                            <td className="align-center">{i.utilized_amount ? i.utilized_amount : '-'} Rs</td>
                                            <td className="align-center">{i.remaining_amount ? i.remaining_amount : '-'} Rs</td>

                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "pending"
                                                        ? "Pending For Rh Approval ⌛"
                                                        : i.rh_status === "rejected"
                                                            ? "Rh ✖, "
                                                            : "Rh ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected" &&
                                                        i.account_status_a === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "approved" &&
                                                            i.account_status_a === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "approved" &&
                                                                i.account_status_a === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved" &&
                                                                    i.account_status_a === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 1: " + i.account_status_a}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.admin_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.admin_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_b}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected" &&
                                                        i.account_status_b === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "approved" &&
                                                            i.account_status_b === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "approved" &&
                                                                i.account_status_b === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved" &&
                                                                    i.account_status_b === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 2: " + i.account_status_b}
                                                </span>
                                            </td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "pending"
                                                        ? "-"
                                                        : i.rh_status === "rejected"
                                                            ? `Rh Reject Reason : ${i.rh_rejection_reason}`
                                                            : "Rh: Verified Amounts for Bills."}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status align-center"
                                                >
                                                    {i.account_status_a === "pending"
                                                        ? "-"
                                                        : i.account_status_a === "rejected"
                                                            ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                                                            : "Accounts : Bills Verified."}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.admin_status}
                                                    className="request-status align-center"
                                                    title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.admin_status === "pending"
                                                        ? "-"
                                                        : i.admin_status === "rejected"
                                                            ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                                                            : "Admin: Approved After Final Verification."}
                                                </span>
                                            </td>
                                            <td className="align-center">
                                                <button
                                                    className="allproject-dashboard"
                                                    title="View using Navigate"
                                                    onClick={(e) => openExenseSlip(i)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestExpensetableAccStage2 = () => {
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    //! ******************************API start***************************//
    const getexpansename = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/expensebyaccount_b/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getexpansename();
    }, [fromdate, todate, selectedStatus, employeeGet]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/ourcompanyempdetails/active/${sessionStorage.getItem('company_id')}/`);
            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });

            setAllemployee(sortedList);
            // setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
    const opener = "accb"
    const openExenseSlip = (i) => {
        navigate(baseUrl + i.id + '/', { state: { i, opener } });
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 17 17"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3351)">
                                    <mask
                                        id="mask0_650_3351"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="17"
                                        height="17"
                                    >
                                        <path d="M17 0H0V17H17V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_650_3351)">
                                        <path
                                            d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                            fill="#707070"
                                        />
                                        <path
                                            d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                            fill="#707070"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3351">
                                        <rect width="17" height="17" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_650_3324)">
                                    <path
                                        d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                                        fill="#707070"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_650_3324">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <select
                                className="attendance-input-field width-15vw   date-field"
                                type="text"
                                value={employeeGet}
                                onChange={(e) => setEmployeeGet(e.target.value)}
                            >
                                <option value="null">Select Employee Code </option>
                                {allemployee
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <option value={i.emp_code}>
                                            {i.emp_code} - {i.name}
                                        </option>
                                    ))}
                                ;
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense Settlement</div>
                        <div className="flex-row">
                        </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left width-5vw">S. No.</th>
                                <th className="align-center width-5vw">Date</th>
                                <th className="align-center width-10vw">Expense <b>Against</b> Imprest</th>
                                <th className="align-center width-10vw">Imprest Amount</th>
                                <th className="align-center width-5vw">Utilized Money</th>
                                <th className="align-center width-5vw">Remaining Money</th>
                                <th className="align-center width-5vw">View</th>
                                <th className="align-center width-10vw">Status</th>
                                <th className="align-center width-15vw">Remarks</th>
                                <th className="align-center width-5vw">View/Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="align-left">{index + 1}</td>

                                            <td className="align-center">{formatDate(i.date)}</td>
                                            <td className="align-center"><span>{i.name}</span>
                                                <br />
                                                <b> Against </b>
                                                <br />
                                                <span>{i.project_name}</span></td>
                                            <td className="align-leftt">
                                                <b>
                                                    {Number(i.approved_transportation) +
                                                        Number(i.approved_accomodation) +
                                                        Number(i.approved_fooding) +
                                                        (i.other_details?.length > 0
                                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                            : 0)}{" "}
                                                    Rs
                                                </b>{" "}
                                                <br />
                                                Transportation: {i.approved_transportation} <br />
                                                Accomodation: {i.approved_accomodation} <br />
                                                Food: {i.approved_fooding} <br />
                                                Others : <br />
                                                {i.other_details?.length > 0 ? (
                                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                                        {i.other_details.map((other, index) => (
                                                            <li key={index}>
                                                                <span className="form-text-trasformation-uppercase">
                                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other || 0}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span>No Other Expense <br /></span>
                                                )}
                                            </td>

                                            <td className="align-center">{i.utilized_amount ? i.utilized_amount : '-'} Rs</td>
                                            <td className="align-center">{i.remaining_amount ? i.remaining_amount : '-'} Rs</td>
                                            <td className="align-center">
                                                <button
                                                    className="allproject-dashboard"
                                                    title="View using Navigate"
                                                    onClick={(e) => openExenseSlip(i)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="40"
                                                        height="40"
                                                        viewBox="0 0 30 30"
                                                        fill="none"
                                                    >
                                                        <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                                        <path
                                                            d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                            fill="#2576BC"
                                                        />
                                                    </svg>
                                                </button>
                                            </td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "pending"
                                                        ? "Pending For Rh Approval ⌛"
                                                        : i.rh_status === "rejected"
                                                            ? "Rh ✖, "
                                                            : "Rh ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected" &&
                                                        i.account_status_a === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "approved" &&
                                                            i.account_status_a === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "approved" &&
                                                                i.account_status_a === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved" &&
                                                                    i.account_status_a === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 1: " + i.account_status_a}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.admin_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.admin_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_b}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "rejected" &&
                                                        i.admin_status === "rejected" &&
                                                        i.account_status_b === "rejected"
                                                        ? "Account ✖, "
                                                        : i.rh_status === "approved" &&
                                                            i.admin_status === "approved" &&
                                                            i.account_status_b === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.rh_status === "approved" &&
                                                                i.admin_status === "approved" &&
                                                                i.account_status_b === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.rh_status === "approved" &&
                                                                    i.admin_status === "approved" &&
                                                                    i.account_status_b === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 2: " + i.account_status_b}
                                                </span>
                                            </td>
                                            <td className="align-center">
                                                <span
                                                    request-status={i.rh_status}
                                                    className="request-status align-center"
                                                >
                                                    {i.rh_status === "pending"
                                                        ? "-"
                                                        : i.rh_status === "rejected"
                                                            ? `Rh Reject Reason : ${i.rh_rejection_reason}`
                                                            : "Rh: Verified Amounts for Bills."}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status align-center"
                                                >
                                                    {i.account_status_a === "pending"
                                                        ? "-"
                                                        : i.account_status_a === "rejected"
                                                            ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                                                            : "Accounts : Bills Verified."}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.admin_status}
                                                    className="request-status align-center"
                                                    title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.admin_status === "pending"
                                                        ? "-"
                                                        : i.admin_status === "rejected"
                                                            ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                                                            : "Admin: Approved After Final Verification."}
                                                </span>
                                            </td>
                                            <td className="align-right">
                                                <AccountBExpenseFinalize
                                                    i={i}
                                                    getExpanseData={getexpansename}
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};


export {
    RequestImpresttable,
    RequestExpensetable,

    RequestImpresttableAdmin,
    RequestExpensetableAdmin,

    RequestImpresttableAcc,
    RequestExpensetableAccStage1,
    RequestExpensetableAccStage2,
}