import React, { useEffect, useRef, useState } from 'react';
import { BASE_URL } from '../../../config/axios';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { AddTransportationInvoice, DutyTravelInvoice, OfficeRentInvoice, OfficeSuppliesInvoice, OfficeFurnitureInvoice, DeleteDutyTravelInvoice, UpdateTransportationInvoice, UpdateDutyTravelInvoice, DeleteTransportationInvoice, ReportsPrinttInvoice, RoadSurveyEquipmentInvoice, UpdateOfficeRentInvoice, DeleteOfficeRent, UpdateOfficeSuppliesInvoice, DeleteOfficeSuppliesInvoice, UpdateFurnitureInvoice, DeleteFurnitureInvoice, UpdateReportsPrinttInvoice, DeleteReportsPrinttInvoice, UpdateRoadSurveyEquipmentInvoice, DeleteRoadSurveyEquipmentInvoice, AddEmployeeInvoice } from './ConsultancyInvoiceComponents';
import ReactToPrint from 'react-to-print';
import { calculateTotal, formatCurrencyIndian, getMonthName } from '../../CustomFunctions';
import { PaymentReceipt } from './PaymentReceipts';

const consultant = [
    {
        "position": "Office Manger",
        "name": "Manoj Kumar",
        "rate": "35000",
        "const": "18.0",
        "maint": "-",
        "staff_month": "18.0",
        "total_amount": "630000",
        "month": "1",
        "amount": "335877",
        "description": "Innova/ Scropio",
        "descriptions": "Office rent",
        "descriptionss": "Monthly report",
        "nos_ofvehicle": "2",
        "trip": "5",
        "trips": "Site to NHAIHQ",
        "copies": "52",
        "copie_rate": "1500",
        "km": "20"
    },
]

// const ConsultantInvoiceTable = ({ p_id, invoice_id, month, year, invoice_num }) => {
//     const [consultantInvoiceData, setConsultantInvoiceData] = useState(null);

//     const getConsultantInvoice = async () => {
//         try {
//             const res = await axios.get(
//                 `${BASE_URL}/siteproject/invoice-summary-site/${invoice_id}/`
//             );
//             setConsultantInvoiceData(res.data);
//         } catch (err) {
//             console.error("Error fetching consultant invoice data:", err);
//         }
//     };

//     useEffect(() => {
//         getConsultantInvoice();
//     }, [invoice_id, month, year]);

//     if (!consultantInvoiceData) {
//         return <div>Loading...</div>;
//     }

//     const renderInvoiceRows = (data, description) => {
//         return data.details.map((item, index) => (
//             <tr key={item.id}>
//                 <td>{index + 1}</td>
//                 <td>{description}</td>
//                 <td>{item.contract_amount.toLocaleString()}</td>
//                 <td>{item.previous_invoice_total.toLocaleString()}</td>
//                 <td>{item.current_invoice_amount.toLocaleString()}</td>
//                 <td>{item.cumulative_invoice_total.toLocaleString()}</td>
//                 <td>{item.remaining_amount.toLocaleString()}</td>
//             </tr>
//         ));
//     };

//     return (
//         <>
//             <br />
//             <div className='table-css-backgrounds'>
//                 <div className='heading-conslt'>
//                     <div className='consultant-heading'>
//                         <h5>Client Name:</h5>
//                         <h5>Consultant (AE):</h5>
//                         <h5>GSTIN No.:</h5>
//                         <h5>PAN No.:</h5>
//                         <h5>Ref. No.:</h5>
//                         <h5>Approval Date:</h5>
//                     </div>
//                     <div className='consultant-heading'>
//                         <h5>Last date of bid submission:</h5>
//                         <h5>Date: {getMonthName(month)}-{year}</h5>
//                         <h5>Invoice Number: {invoice_num}</h5>
//                     </div>
//                 </div>
//                 <div className="table-css-background">
//                     <div className='invoice-heading'>
//                         <h6>Final Summary Of Consultant Invoice</h6>
//                     </div>
//                     <table className="nested-table-lightblue">
//                         <thead>
//                             <tr>
//                                 <th>S.No.</th>
//                                 <th>Description</th>
//                                 <th>Contract Amount</th>
//                                 <th>Cumulated Total of Preceding Months</th>
//                                 <th>This Period</th>
//                                 <th>Cumulated Total including current Invoice</th>
//                                 <th>Remaining amount against contract value</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {renderInvoiceRows(consultantInvoiceData.employee_data, "Remuneration for Key Professional Staff")}
//                             {renderInvoiceRows(consultantInvoiceData.transportation_data, "Transportation")}
//                             {renderInvoiceRows(consultantInvoiceData.duty_travel_data, "Duty Travel to Site")}
//                             {renderInvoiceRows(consultantInvoiceData.office_rent_data, "Office Rent")}
//                             {renderInvoiceRows(consultantInvoiceData.office_supplies_data, "Office Supplies, Utilities and Communication")}
//                             {renderInvoiceRows(consultantInvoiceData.furniture_data, "Office Furniture and Equipment")}
//                             {renderInvoiceRows(consultantInvoiceData.report_data, "Report and Document Printing")}
//                             {renderInvoiceRows(consultantInvoiceData.survey_data, "Road Survey Equipment")}

//                             <tr className='total-amount'>
//                                 <td></td>
//                                 <td>Total Amount</td>
//                                 <td>{consultantInvoiceData.total_contract_amount.toLocaleString()}</td>
//                                 <td>{consultantInvoiceData.total_cumulative_amount.toLocaleString()}</td>
//                                 <td></td>
//                                 <td></td>
//                                 <td>{consultantInvoiceData.total_remaining_amount.toLocaleString()}</td>
//                             </tr>
//                         </tbody>
//                     </table>
//                     <br />
//                     <div>
//                         <h6>Account Details:-</h6>
//                     </div>
//                     <table className="nested-table-lightblue">
//                         <tbody>
//                             <tr>
//                                 <td>Name of Account Holder</td>
//                                 <td>Dhruv Consultancy</td>
//                             </tr>
//                             <tr>
//                                 <td>Account No.</td>
//                                 <td>50200000212287</td>
//                             </tr>
//                             <tr>
//                                 <td>Type of Account</td>
//                                 <td>Current Account</td>
//                             </tr>
//                             <tr>
//                                 <td>Name of Bank</td>
//                                 <td>HDFC Bank LTD.</td>
//                             </tr>
//                             <tr>
//                                 <td>IFSC Code</td>
//                                 <td>HDFC0000830</td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </>
//     );
// };

const ProfessionalStafff = ({ p_id, invoice_id, year, month, invoice_num }) => {
    const monthOptions = [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" }
    ];
    const getMonthName = (monthNumber) => {
        const month = monthOptions.find(m => m.id.toString() === monthNumber);
        return month ? month.name : '';
    };



    const [employees, setEmployees] = useState([]);

    const getEmployeeInvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceemployeebyprojectstatus/${p_id}/${month}/${year}/`);
            setEmployees(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getEmployeeInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <>
            <div className='table-css-backgrounds'>
                <div className="table-css-background">
                    <div className='heading-conslt'>
                        <div className='consultant-heading'>
                            <h5>Client Name:</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-heading'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>

                    <div className='heads-div'>
                        <h6>I- Remuneration for Local Professional Staff</h6>
                        <div className="hide-on-print">
                            <AddEmployeeInvoice getEmployeeInvoice={getEmployeeInvoice} invoice_id={invoice_id} month={month} year={year} p_id={p_id} />
                        </div>
                    </div>
                    <table className="nested-table-lightblue">
                        <thead>
                            <tr>
                                <th rowSpan="2">S.No.</th>
                                <th rowSpan="2">Position</th>
                                <th rowSpan="2">Name</th>
                                <th colSpan="6">Contract Amount</th>
                                <th colSpan="2">Cumultative upto <br /> Previuos Invoice</th>
                                <th colSpan="2">Current Invoice</th>
                                <th colSpan="2">Cumulative Amount upto <br /> This Invoice</th>
                                <th colSpan="2">Remaining Amount</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td>Rate</td>
                                <td></td>
                                <td>Const</td>
                                <td>Maint</td>
                                <td>Nos. of MM</td>
                                <td>Total Amount</td>

                                <td>Month</td>
                                <td>Amount</td>
                                <td>Month</td>
                                <td>Amount</td>
                                <td>Month</td>
                                <td>Amount</td>
                                <td>Month</td>
                                <td>Amount</td>
                            </tr>
                        </thead>
                        <thead className='Key-pro'>
                            <td>1</td>
                            <td>Key Professional Staff (A)</td>
                            <td></td>
                            <td>Replacement <br /> Under Clause 1.10</td>
                            <td>Revised rate</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>TBN</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Arun Kumar</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>R.P. Singh</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Parmod</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Rakesh Kumar</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Himmat</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr className='total-amount'>
                                <td></td>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr className='Key-pro total-amount'>
                                <td>2</td>
                                <td>Sub-Professional Staff (B)</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Survey Engineer-1</td>
                                <td>Naresh</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Survey Engineer-2</td>
                                <td>Naveen</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Assiatant Highway Engineer-1</td>
                                <td>Harendra</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Assiatant Highway Engineer-2</td>
                                <td>Vikas Dhakad</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>CAD Expert</td>
                                <td>TBN</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Enviromental Engineer</td>
                                <td>TBN</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Assistant Bridge Engineer-1</td>
                                <td>Manmohan</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Assistant Bridge Engineer-2</td>
                                <td>Sachin</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Assistant Quality Cum Material Engineer-1</td>
                                <td>Chetan</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Assistant Quality Cum Material Engineer-2</td>
                                <td>Vishnu</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Himmat</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Himmat</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Himmat</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Himmat</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Himmat</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Himmat</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Remuneration for Key Professional Staff</td>
                                <td>Himmat</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>1,183,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                                <td>2,000,000</td>
                            </tr>
                        </tbody>

                        <tr className='total-amount'>
                            <td></td>
                            <td>Total B</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>

                        <tfoot>
                            <tr className='total-amount'>
                                <td></td>
                                <td>Total(A+B)</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                        </tfoot>
                    </table>

                </div>
            </div>
        </>
    )
}

const SupportStafff = ({ p_id, invoice_id, year, month, invoice_num }) => {





    useEffect(() => {

        // getdocument();
        // getTransportation();
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='invoice-heading'>
                    <h5>2-Support Staff <br /></h5>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No</th>
                            <th rowSpan="2">Position</th>
                            <th rowSpan="2">Name</th>
                            <th colSpan="5" className='align-center'>Contract Amount</th>
                            <th colSpan="2" className='align-center'>Pervices upto Previous Invoice</th>
                            <th colSpan="2" className='align-center'>Current Invoice</th>
                            <th colSpan="2" className='align-center'>Cumulative Amount upto This</th>
                            <th colSpan="2" className='align-center'>Remaining Amount</th>
                        </tr>
                        <tr>
                            <th>Rate</th>
                            <th>Cost</th>
                            <th>Maint</th>
                            <th>Staff <br /> Month</th>
                            <th>Total Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>


                        </tr>
                    </thead>
                    <tbody>
                        {consultant.map((item, index) => (

                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.position}</td>
                                <td>{item.name}</td>
                                <td>{item.rate}</td>
                                <td>{item.const}</td>
                                <td>{item.maint}</td>
                                <td>{item.staff_month}</td>
                                <td>{item.total_amount}</td>
                                <td>{item.month}</td>
                                <td>{item.amount}</td>
                                <td>{item.month}</td>
                                <td>{item.amount}</td>
                                <td>{item.month}</td>
                                <td>{item.amount}</td>
                                <td>{item.month}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td colSpan="14" className="total-label">Total:</td>
                            {/* <td>{supportStaff.reduce(
                                (total, item) => total + (item.during_construction_period + item.during_dlp_OM_period) * item.rate,
                                0
                            )}</td> */}
                            <td>335877</td>

                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
};


const ProfessionalStaff = ({ p_id, invoice_id, year, month, invoice_num }) => {
    const [employees, setEmployees] = useState([]);

    const getEmployeeInvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceemployeebyprojectstatus/${p_id}/${month}/${year}/`
            );
            setEmployees(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getEmployeeInvoice();
    }, [p_id, month, year]);

    const renderEmployeeRows = (type) => {
        return employees
            .filter(employee => employee.employee_details.professional_type === type)
            .map((employee, index) => {
                const { designation_name, rate, MM_construction_period, MM_OM_period } = employee.employee_details;
                const totalMM = MM_construction_period + MM_OM_period;
                const totalAmount = rate * totalMM;
                const prevInvoiceTotal = employee.previous_invoice_total || 0;
                const currentInvoiceAmount = employee.current_invoice_amount || 0;
                const cumulativeTotal = prevInvoiceTotal + currentInvoiceAmount;
                const remainingAmount = employee.remaining_amount || 0;

                return (
                    <tr key={employee.id}>
                        <td>{index + 1}</td>
                        <td>{designation_name}</td>
                        <td>{employee.employee_details.employee_name_details.map(ed => ed.name).join(", ")}</td>
                        <td>{rate.toLocaleString()}</td>
                        <td>{MM_construction_period}</td>
                        <td>{MM_OM_period}</td>
                        <td>{totalMM}</td>
                        <td>{totalAmount.toLocaleString()}</td>
                        <td>{employee.actual_month_total_previous}</td>
                        <td>{prevInvoiceTotal.toLocaleString()}</td>
                        <td>{employee.actual_month}</td>
                        <td>{currentInvoiceAmount.toLocaleString()}</td>
                        <td>{employee.actual_month_cumulative_total}</td>
                        <td>{cumulativeTotal.toLocaleString()}</td>
                        <td>{employee.actual_month_remaining}</td>
                        <td>{remainingAmount.toLocaleString()}</td>
                    </tr>
                );
            });
    };

    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-heading'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-heading'>
                        <h5>Date: {getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                        <h6>I- Remuneration for Local Professional Staff</h6>
                        <div className="hide-on-print">
                            <AddEmployeeInvoice getEmployeeInvoice={getEmployeeInvoice} invoice_id={invoice_id} month={month} year={year} p_id={p_id} />
                        </div>
                    </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No.</th>
                            <th rowSpan="2">Position</th>
                            <th rowSpan="2">Name</th>
                            <th colSpan="5">Contract Amount</th>
                            <th colSpan="2">Services up to Previous Invoice</th>
                            <th colSpan="2">Current Invoice</th>
                            <th colSpan="2">Cumulative Amount up to This</th>
                            <th colSpan="2">Remaining Amount</th>
                        </tr>
                        <tr>
                            <th>Rate</th>
                            <th>Const</th>
                            <th>Maint</th>
                            <th>Const & Maint</th>
                            <th>Total Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="16" className="section-header">Key Professional Staff (A)</td>
                        </tr>
                        {renderEmployeeRows("Key Professional")}
                        <tr>
                            <td colSpan="16" className="section-header">Sub Professional Staff (B)</td>
                        </tr>
                        {renderEmployeeRows("Sub Professional")}
                        <tr>
                            <td colSpan="16" className="section-header">Support Staff (C)</td>
                        </tr>
                        {renderEmployeeRows("Support Staff")}
                    </tbody>
                </table>
            </div>
        </div>
    );
};



const Transportation = ({ p_id, invoice_id, invoice_num, month, year }) => {


    const [transportations, setTransportations] = useState([]);

    const getTransportationInvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicetransportbyprojectstatus/${p_id}/${month}/${year}/`);
            setTransportations(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getTransportationInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                    <h2>3- Transportation(Fixed rate on rental basis) <br /></h2>
                    <div className="hide-on-print">
                        <AddTransportationInvoice p_id={p_id} invoice_id={invoice_id} getTransportationInvoice={getTransportationInvoice} month={month} year={year} />
                    </div>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No</th>
                            <th rowSpan="2">Description</th>
                            <th colSpan="5" className='align-center'>Contract Amount</th>
                            <th colSpan="2" className='align-center'>Pervices upto Previous Invoice</th>
                            <th colSpan="2" className='align-center'>Current Invoice</th>
                            <th colSpan="2" className='align-center'>Cumulative Amount upto This</th>
                            <th colSpan="2" className='align-center'>Remaining Amount</th>
                            <th rowSpan="2" className="hide-on-print">Action</th>
                        </tr>
                        <tr>
                            {/* <th>No. of Vehicle</th> */}
                            <th>Rate</th>
                            <th>Const</th>
                            <th>Maint</th>
                            <th>Const & <br />Maint</th>
                            <th>Total Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>


                        </tr>
                    </thead>
                    <tbody>
                        {transportations.map((item, index) => (

                            <tr key={item.id}>
                                <td className='align-right'>{index + 1}</td>
                                <td className='align-left'>{item.transport_details.vehicle_description}</td>
                                {/* <td className='align-right'>1</td> */}
                                <td className='align-right'>{formatCurrencyIndian(Number(item.transport_details.rate))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.during_construction_period_sum))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.during_dlp_OM_period_sum))}</td>
                                <td className='align-right'>{Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.transport_details.rate) * (Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)))}</td>
                                <td className='align-right'>{item.actual_month_total_previous}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                <td className='align-right'>{Number(item.actual_month)}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                <td className='align-right'>{item.actual_month_cumulative_total}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                <td className='align-right'>{item.actual_month_remaining}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                <td>
                                    <UpdateTransportationInvoice i={item} p_id={p_id} invoice_id={invoice_id} getTransportationInvoice={getTransportationInvoice} />
                                    <DeleteTransportationInvoice i={item} getTransportationInvoice={getTransportationInvoice} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td colSpan="" className="total-label">Total:</td>
                            {/* <td>{transportation.reduce(
                                (total, item) => total + (item.during_construction_period + item.during_dlp_OM_period) * item.rate,
                                0
                            )}</td> */}
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.transport_details.rate))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.during_construction_period_sum))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.during_dlp_OM_period_sum))}</td>
                            <td className='align-right'>
                                {formatCurrencyIndian(calculateTotal(transportations, (item) => Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)))}
                            </td>
                            <td className='align-right'>
                                {formatCurrencyIndian(
                                    transportations.reduce((total, item) => total + Number(item.transport_details.rate) * (Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)), 0)
                                )}
                            </td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.actual_month_total_previous))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.previous_invoice_total))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.actual_month))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.current_invoice_amount))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.actual_month_cumulative_total))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.cumulative_invoice_total))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.actual_month_remaining))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.remaining_amount))}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
};

const EmployeeInvoice = ({ p_id, invoice_id, invoice_num, month, year }) => {


    const [employee, setEmployee] = useState([]);

    const getEmployeeInvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicetransportbyprojectstatus/${p_id}/${month}/${year}/`);
            setEmployee(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getEmployeeInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                    <h2>4. Employee Invoice <br /></h2>
                    <div className="hide-on-print">
                        <AddEmployeeInvoice p_id={p_id} invoice_id={invoice_id} getEmployeeInvoice={getEmployeeInvoice} month={month} year={year} />
                    </div>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No</th>
                            <th rowSpan="2">Description</th>
                            <th colSpan="5" className='align-center'>Contract Amount</th>
                            <th colSpan="2" className='align-center'>Pervices upto Previous Invoice</th>
                            <th colSpan="2" className='align-center'>Current Invoice</th>
                            <th colSpan="2" className='align-center'>Cumulative Amount upto This</th>
                            <th colSpan="2" className='align-center'>Remaining Amount</th>
                            <th rowSpan="2" className="hide-on-print">Action</th>
                        </tr>
                        <tr>
                            {/* <th>No. of Vehicle</th> */}
                            <th>Rate</th>
                            <th>Const</th>
                            <th>Maint</th>
                            <th>Const & <br />Maint</th>
                            <th>Total Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>


                        </tr>
                    </thead>
                    <tbody>
                        {employee.map((item, index) => (

                            <tr key={item.id}>
                                <td className='align-right'>{index + 1}</td>
                                <td className='align-left'>{item.transport_details.vehicle_description}</td>
                                {/* <td className='align-right'>1</td> */}
                                <td className='align-right'>{formatCurrencyIndian(Number(item.transport_details.rate))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.during_construction_period_sum))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.during_dlp_OM_period_sum))}</td>
                                <td className='align-right'>{Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.transport_details.rate) * (Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)))}</td>
                                <td className='align-right'>{item.actual_month_total_previous}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                <td className='align-right'>{Number(item.actual_month)}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                <td className='align-right'>{item.actual_month_cumulative_total}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                <td className='align-right'>{item.actual_month_remaining}</td>

                                <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                <td></td>
                                {/* <td>
                                    <UpdateTransportationInvoice i={item} p_id={p_id} invoice_id={invoice_id} getTransportationInvoice={getTransportationInvoice} />
                                    <DeleteTransportationInvoice i={item} getTransportationInvoice={getTransportationInvoice} />
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td colSpan="" className="total-label">Total:</td>
                            {/* <td>{transportation.reduce(
                                (total, item) => total + (item.during_construction_period + item.during_dlp_OM_period) * item.rate,
                                0
                            )}</td> */}
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.transport_details.rate))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.during_construction_period_sum))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.during_dlp_OM_period_sum))}</td>
                            <td className='align-right'>
                                {formatCurrencyIndian(calculateTotal(employee, (item) => Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)))}
                            </td>
                            <td className='align-right'>
                                {formatCurrencyIndian(
                                    employee.reduce((total, item) => total + Number(item.transport_details.rate) * (Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)), 0)
                                )}
                            </td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.actual_month_total_previous))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.previous_invoice_total))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.actual_month))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.current_invoice_amount))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.actual_month_cumulative_total))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.cumulative_invoice_total))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.actual_month_remaining))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.remaining_amount))}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
};


const DutyTravel = ({ p_id, invoice_id, i, invoice_num, year, month }) => {

    // let { id } = useParams();

    const [dutyTravelinvoice, setDutyTravelinvoice] = useState([]);

    const getDutyTravelinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicedutybyprojectstatus/${p_id}/${month}/${year}/`);
            setDutyTravelinvoice(res.data);
        } catch (err) { }
    };



    useEffect(() => {

        // getdocument();
        getDutyTravelinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    console.log(dutyTravelinvoice)


    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                    <h5>4-Duty Travel to Site(Fixed Costs)(For all lienght of project):<br />
                        Professional and sub-professional Staff
                    </h5>

                    <div>
                        <DutyTravelInvoice p_id={p_id} invoice_id={invoice_id} getDutyTravelinvoice={getDutyTravelinvoice} month={month} year={year} />
                    </div>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No</th>
                            <th rowSpan="2">Trips</th>
                            <th colSpan="3" className='align-center'>Contract Amount</th>
                            <th colSpan="2" className='align-center'>Pervices upto Previous Invoice</th>
                            <th colSpan="2" className='align-center'>Current Invoice</th>
                            <th colSpan="2" className='align-center'>Cumulative Amount upto This</th>
                            <th colSpan="2" className='align-center'>Remaining Amount</th>
                            <th rowSpan="2" className='align-center'>Action</th>
                        </tr>
                        <tr>
                            <th>Rate</th>
                            <th>Trips</th>
                            <th>Total Amount</th>
                            <th>Trip</th>
                            <th>Amount</th>
                            <th>Trip</th>
                            <th>Amount</th>
                            <th>Trip</th>
                            <th>Amount</th>
                            <th>Trip</th>
                            <th>Amount</th>


                        </tr>
                    </thead>
                    <tbody>
                        {dutyTravelinvoice.map((item, index) => (

                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.duty_travel_details.trips}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.duty_travel_details.rate_per_trip))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.duty_travel_details.num_of_min_trip))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.duty_travel_details.rate_per_trip) * Number(item.duty_travel_details.num_of_min_trip))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_trip))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>

                                <td>
                                    {/* <UpdateDutyTravelInvoice i={item} p_id={p_id} invoice_id={invoice_id} getDutyTravelinvoice={getDutyTravelinvoice} /> */}
                                    <DeleteDutyTravelInvoice i={item} getDutyTravelinvoice={getDutyTravelinvoice} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>

                            <td></td>
                            <td colSpan="" className="total-label">Total:</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.duty_travel_details.rate_per_trip)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.duty_travel_details.num_of_min_trip)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.duty_travel_details.rate_per_trip) * Number(item.duty_travel_details.num_of_min_trip)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_trip)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.remaining_amount)))}</td>
                            <td></td>

                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
};




// const DutyTravel = ({ p_id, invoice_id, invoice_num, year, month }) => {
//     const [dutyTravelinvoice, setDutyTravelinvoice] = useState([]);

//     const getDutyTravelinvoice = async () => {
//         try {
//             const res = await axios.get(
//                 `${BASE_URL}/siteproject/invoicedutybyprojectstatus/${p_id}/${month}/${year}/`
//             );
//             console.log("Fetched data:", res.data); // Debugging: Log fetched data
//             setDutyTravelinvoice(res.data || []); // Ensure empty array if no data
//         } catch (err) {
//             console.error("Error fetching duty travel invoice data:", err);
//             setDutyTravelinvoice([]); // Set to empty array in case of error
//         }
//     };

//     useEffect(() => {
//         getDutyTravelinvoice();
//     }, []);

//     const formatCurrencyIndian = (value) => (value ? Number(value).toLocaleString(undefined, { minimumFractionDigits: 2 }) : '0.00');

//     const calculateTotal = (data, selector) => {
//         return data.reduce((total, item) => total + (selector(item) || 0), 0);
//     };

//     return (
//         <div className='table-css-backgrounds'>
//             <div className="table-css-background">
//                 <div className='heading-conslt'>
//                     <div className='consultant-headings'>
//                         <h5>Client Name:</h5>
//                         <h5>Consultant (AE):</h5>
//                         <h5>PAN No.:</h5>
//                     </div>
//                     <div className='consultant-headings'>
//                         <h5>Date: {getMonthName(month)}-{year}</h5>
//                         <h5>Invoice Number: {invoice_num}</h5>
//                     </div>
//                 </div>
//                 <div className='heads-div'>
//                     <h5>4-Duty Travel to Site (Fixed Costs) (For all length of project):<br />
//                         Professional and sub-professional Staff
//                     </h5>
//                     <DutyTravelInvoice 
//                         p_id={p_id} 
//                         invoice_id={invoice_id} 
//                         getDutyTravelinvoice={getDutyTravelinvoice} 
//                         month={month} 
//                         year={year} 
//                     />
//                 </div>
//                 <table className="nested-table-lightblue">
//                     <thead>
//                         <tr>
//                             <th rowSpan="2">S.No</th>
//                             <th rowSpan="2">Trips</th>
//                             <th colSpan="3" className='align-center'>Contract Amount</th>
//                             <th colSpan="2" className='align-center'>Services up to Previous Invoice</th>
//                             <th colSpan="2" className='align-center'>Current Invoice</th>
//                             <th colSpan="2" className='align-center'>Cumulative Amount up to This</th>
//                             <th colSpan="2" className='align-center'>Remaining Amount</th>
//                             <th rowSpan="2" className='align-center'>Action</th>
//                         </tr>
//                         <tr>
//                             <th>Rate</th>
//                             <th>Trips</th>
//                             <th>Total Amount</th>
//                             <th>Month</th>
//                             <th>Amount</th>
//                             <th>Month</th>
//                             <th>Amount</th>
//                             <th>Month</th>
//                             <th>Amount</th>
//                             <th>Month</th>
//                             <th>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {dutyTravelinvoice.length > 0 ? (
//                             dutyTravelinvoice.map((item, index) => (
//                                 <tr key={item.id}>
//                                     <td>{index + 1}</td>
//                                     <td>{item.duty_travel_details?.trips ?? 'N/A'}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.duty_travel_details?.rate_per_trip)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.duty_travel_details?.num_of_min_trip)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian((item.duty_travel_details?.rate_per_trip || 0) * (item.duty_travel_details?.num_of_min_trip || 0))}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.actual_month_total_previous ?? 0)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.previous_invoice_total ?? 0)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.actual_month_remaining ?? 0)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.current_invoice_amount ?? 0)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.actual_month_cumulative_total ?? 0)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.cumulative_invoice_total ?? 0)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.month ?? 0)}</td>
//                                     <td className='align-right'>{formatCurrencyIndian(item.remaining_amount ?? 0)}</td>
//                                     <td>
//                                         {/* <UpdateDutyTravelInvoice i={item} p_id={p_id} invoice_id={invoice_id} getDutyTravelinvoice={getDutyTravelinvoice} /> */}
//                                         <DeleteDutyTravelInvoice i={item} getDutyTravelinvoice={getDutyTravelinvoice} />
//                                     </td>
//                                 </tr>
//                             ))
//                         ) : (
//                             <tr><td colSpan="14">No data available</td></tr>
//                         )}
//                     </tbody>
//                     <tfoot>
//                         <tr>
//                             <td></td>
//                             <td className="total-label">Total:</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.duty_travel_details?.rate_per_trip || 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.duty_travel_details?.num_of_min_trip || 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => (item.duty_travel_details?.rate_per_trip || 0) * (item.duty_travel_details?.num_of_min_trip || 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_total_previous ?? 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.previous_invoice_total ?? 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_remaining ?? 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.current_invoice_amount ?? 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_cumulative_total ?? 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.cumulative_invoice_total ?? 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.month ?? 0)))}</td>
//                             <td className='align-right'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.remaining_amount ?? 0)))}</td>
//                             <td></td>
//                         </tr>
//                     </tfoot>
//                 </table>
//             </div>
//         </div>
//     );
// };





const OfficeRent = ({ p_id, invoice_id, invoice_num, month, year }) => {


    const [officeRentinvoice, setOfficeRentinvoice] = useState([]);

    const getOfficeRentinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceofficerentbyprojectstatus/${p_id}/${month}/${year}/`);
            setOfficeRentinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getOfficeRentinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-heading'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>

                    </div>
                </div>
                <div className="nested-table-lightblue">
                    <div className='heads-div'>
                        <h3>5- Office Rent(Fixed Cost)-(Minimum 200 sqm area of offic shall be rented. )
                        </h3>
                        <div>
                            <OfficeRentInvoice p_id={p_id} invoice_id={invoice_id} getOfficeRentinvoice={getOfficeRentinvoice} month={month} year={year} />
                        </div>
                    </div>
                    <table className="nested-table-lightblue">
                        <thead>
                            <tr>
                                <th rowSpan="2">S.No</th>

                                <th colSpan="3" className='align-center'>Contract Amount</th>
                                <th colSpan="2" className='align-center'>Pervices upto Previous Invoice</th>
                                <th colSpan="2" className='align-center'>Current Invoice</th>
                                <th colSpan="2" className='align-center'>Cumulative Amount upto This</th>
                                <th colSpan="2" className='align-center'>Remaining Amount</th>
                                <th rowSpan="4" className='align-center '>Action</th>
                            </tr>
                            <tr>
                                <th>Months</th>
                                <th>Rate</th>
                                {/* <th>Const</th>
                                <th>Mint</th>
                                <th>Total MM</th> */}
                                <th>Total Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>


                            </tr>
                        </thead>
                        <tbody>
                            {officeRentinvoice.map((item, index) => (

                                <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.officerent_details.num_of_months))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.officerent_details.rate_per_month))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.officerent_details.rate_per_month) * Number(item.officerent_details.num_of_months))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                    <td colSpan={4} className='flex-row'>
                                        {/* <UpdateOfficeRentInvoice i={item} p_id={p_id} invoice_id={invoice_id} getOfficeRentinvoice={getOfficeRentinvoice} /> */}
                                        <DeleteOfficeRent i={item} getOfficeRentinvoice={getOfficeRentinvoice} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="total-label">Total:</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.officerent_details.num_of_months)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.officerent_details.rate_per_month)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.officerent_details.rate_per_month) * Number(item.officerent_details.num_of_months)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.actual_month)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.remaining_amount)))}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        </div>
    );
};

const OfficeSupplies = ({ p_id, invoice_id, month, year, invoice_num }) => {

    // let { id } = useParams();

    const [officeSuppliesinvoice, setOfficeSuppliesinvoice] = useState([]);

    const getOfficeSuppliesinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceofficesuppliesbyprojectstatus/${p_id}/${month}/${year}/`);
            setOfficeSuppliesinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getOfficeSuppliesinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                    <h5>6- Office Supplies, Utilities and Communication(Fixed Cost)<br /></h5>

                    <div className=''>
                        <OfficeSuppliesInvoice p_id={p_id} invoice_id={invoice_id} getOfficeSuppliesinvoice={getOfficeSuppliesinvoice} month={month} year={year} />
                    </div>

                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No</th>
                            <th rowSpan="2">Description</th>
                            <th colSpan="3" className='align-center'>Contract Amount</th>
                            <th colSpan="2" className='align-center'> Previous Invoice</th>
                            <th colSpan="2" className='align-center'>Current Invoice</th>
                            <th colSpan="2" className='align-center'>Cumulative Amount</th>
                            <th colSpan="2" className='align-center'>Remaining Amount</th>
                            <th rowSpan="2" className='align-center'>Action</th>
                        </tr>
                        <tr>
                            <th>Period <br />(Month)</th>
                            <th>Rate</th>
                            <th>Total Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>


                        </tr>
                    </thead>
                    <tbody>
                        {officeSuppliesinvoice.map((item, index) => (

                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.officesupplies_details.item}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.officesupplies_details.months))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.officesupplies_details.monthly_rate))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.officesupplies_details.monthly_rate) * Number(item.officesupplies_details.months))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                <td>
                                    {/* <UpdateOfficeSuppliesInvoice i={item} p_id={p_id} invoice_id={invoice_id} getOfficeSuppliesinvoice={getOfficeSuppliesinvoice} /> */}
                                    <DeleteOfficeSuppliesInvoice i={item} getOfficeSuppliesinvoice={getOfficeSuppliesinvoice} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td className="total-label">Total:</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.officesupplies_details.months)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.officesupplies_details.monthly_rate)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.officesupplies_details.monthly_rate) * Number(item.officesupplies_details.months)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.actual_month)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.remaining_amount)))}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
};

const OfficeFurniture = ({ p_id, invoice_id, month, year, invoice_num }) => {

    // let { id } = useParams();

    const [officeFurnitureinvoice, setOfficeFurnitureinvoice] = useState([]);

    const getOfficeFurnitureinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceofficefurnitureandequipmentstatusbyproject/${p_id}/${month}/${year}/`);
            setOfficeFurnitureinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getOfficeFurnitureinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                    <h5>Office Furniture and Equipment(Rental)<br /></h5>
                    <div>
                        <OfficeFurnitureInvoice p_id={p_id} invoice_id={invoice_id} getOfficeFurnitureinvoice={getOfficeFurnitureinvoice} month={month} year={year} />
                    </div>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No</th>
                            <th rowSpan="2">Description</th>
                            <th colSpan="3" className='align-center'>As per Contract</th>
                            <th colSpan="2" className='align-center'>Previous Invoice</th>
                            <th colSpan="2" className='align-center'>Current Invoice</th>
                            <th colSpan="2" className='align-center'>Cumulative Amount</th>
                            <th colSpan="2" className='align-center'>Remaining Amount</th>
                            <th rowSpan="2" className='align-center'>Action</th>
                        </tr>
                        <tr>
                            <th>Period <br />(Month)</th>
                            <th>Rate</th>
                            <th>Total Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>


                        </tr>
                    </thead>
                    <tbody>
                        {officeFurnitureinvoice.map((item, index) => (

                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.officefurniture_details.description}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.officefurniture_details.num_of_months))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.officefurniture_details.rate_per_month))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.officefurniture_details.rate_per_month) * Number(item.officefurniture_details.num_of_months))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.month))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                <td>
                                    {/* <UpdateFurnitureInvoice i={item} p_id={p_id} invoice_id={invoice_id} getOfficeFurnitureinvoice={getOfficeFurnitureinvoice} /> */}
                                    <DeleteFurnitureInvoice i={item} getOfficeFurnitureinvoice={getOfficeFurnitureinvoice} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>

                            <td></td>
                            <td className="total-label">Total:</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.officefurniture_details.num_of_months)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.officefurniture_details.rate_per_month)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.officefurniture_details.rate_per_month) * Number(item.officefurniture_details.num_of_months)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.actual_month)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.month)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.remaining_amount)))}</td>
                            <td></td>

                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
};

const ReportsPrintt = ({ p_id, invoice_id, month, year, invoice_num }) => {


    const [reportsPrinttinvoice, setReportsPrinttinvoice] = useState([]);

    const getReportsPrinttinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicereportsanddocprintstatusbyproject/${p_id}/${month}/${year}/`);
            setReportsPrinttinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getReportsPrinttinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                    <h5>8-Report and Document Printing<br /></h5>
                    <div>
                        <ReportsPrinttInvoice p_id={p_id} invoice_id={invoice_id} getReportsPrinttinvoice={getReportsPrinttinvoice} month={month} year={year} />
                    </div>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No</th>
                            <th rowSpan="2">Description</th>
                            <th colSpan="5" className='align-center'>Contract Amount</th>
                            <th colSpan="2" className='align-center'> Previous Invoice</th>
                            <th colSpan="2" className='align-center'>Current Invoice</th>
                            <th colSpan="2" className='align-center'>Cumulative Amount</th>
                            <th colSpan="2" className='align-center'>Remaining Amount</th>
                            <th rowSpan="2" className='align-center'>Action</th>
                        </tr>
                        <tr>
                            <th>No. of <br /> Reports</th>
                            <th>No. of Copies <br /> per Report</th>
                            <th>Copies</th>
                            <th>Rate per <br /> Copy(Rs)</th>
                            <th>Total Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>
                            <th>Month</th>
                            <th>Amount</th>


                        </tr>
                    </thead>
                    <tbody>
                        {reportsPrinttinvoice.map((item, index) => (

                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.reportanddoc_details.description}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.reportanddoc_details.num_of_reports))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.reportanddoc_details.num_of_copies_per_report))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.reportanddoc_details.num_of_reports) * Number(item.reportanddoc_details.num_of_copies_per_report))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.reportanddoc_details.rate_per_copy))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.reportanddoc_details.rate_per_copy) * Number(item.reportanddoc_details.num_of_reports) * Number(item.reportanddoc_details.num_of_copies_per_report))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                <td className='flex-row'>
                                    {/* <UpdateReportsPrinttInvoice i={item} p_id={p_id} invoice_id={invoice_id} getReportsPrinttinvoice={getReportsPrinttinvoice} /> */}
                                    <DeleteReportsPrinttInvoice i={item} getReportsPrinttinvoice={getReportsPrinttinvoice} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td className="total-label">Total:</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.num_of_reports)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.num_of_copies_per_report)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.num_of_reports) * Number(item.reportanddoc_details.num_of_copies_per_report)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.rate_per_copy)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.rate_per_copy) * Number(item.reportanddoc_details.num_of_reports) * Number(item.reportanddoc_details.num_of_copies_per_report)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.actual_month)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.remaining_amount)))}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
};

const RoadSurvey = ({ p_id, invoice_id, year, month, invoice_num }) => {

    const [roadSurveyinvoice, setRoadSurveyinvoice] = useState([]);

    const getRoadSurveyinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceroadsurveyequipmentStatusbyproject/${p_id}/${month}/${year}/`);
            setRoadSurveyinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getRoadSurveyinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className="table-css-background">
                <div className='heading-conslt'>
                    <div className='consultant-headings'>
                        <h5>Client Name:</h5>
                        <h5>Consultant (AE):</h5>
                        <h5>PAN No.:</h5>
                    </div>
                    <div className='consultant-headings'>
                        <h5>Date:{getMonthName(month)}-{year}</h5>
                        <h5>Invoice Number: {invoice_num}</h5>
                    </div>
                </div>
                <div className='heads-div'>
                    <h5>9- Road Survey Equipment <br /></h5>
                    <div>
                        <RoadSurveyEquipmentInvoice p_id={p_id} invoice_id={invoice_id} getRoadSurveyinvoice={getRoadSurveyinvoice} month={month} year={year} />
                    </div>
                </div>
                <table className="nested-table-lightblue">
                    <thead>
                        <tr>
                            <th rowSpan="2">S.No</th>
                            <th rowSpan="2">Item</th>
                            <th colSpan="" className='align-center'>Contract Amount</th>
                            <th colSpan="2" className='align-center'>Previous Invoice</th>
                            <th colSpan="2" className='align-center'>Current Invoice</th>
                            <th colSpan="2" className='align-center'>Cumulative Amount</th>
                            <th colSpan="2" className='align-center'>Remaining Amount</th>
                            <th rowSpan="2" className='align-center'>Action</th>
                        </tr>

                    </thead>
                    <tbody>
                        {roadSurveyinvoice.map((item, index) => (

                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.roadsurvey_details.description}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.roadsurvey_details.KM))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.roadsurvey_details.rate_per_km_times))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.roadsurvey_details.num_of_times_survey_concluded))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.roadsurvey_details.KM) * Number(item.roadsurvey_details.rate_per_km_times) * Number(item.roadsurvey_details.num_of_times_survey_concluded))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>

                                <td className='flex-row'>
                                    {/* <UpdateRoadSurveyEquipmentInvoice i={item} p_id={p_id} invoice_id={invoice_id} getRoadSurveyinvoice={getRoadSurveyinvoice} /> */}
                                    <DeleteRoadSurveyEquipmentInvoice i={item} getRoadSurveyinvoice={getRoadSurveyinvoice} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>

                            <td></td>
                            <td className="total-label">Total:</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.roadsurvey_details.KM)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.roadsurvey_details.rate_per_km_times)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.roadsurvey_details.num_of_times_survey_concluded)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.roadsurvey_details.KM) * Number(item.roadsurvey_details.rate_per_km_times) * Number(item.roadsurvey_details.num_of_times_survey_concluded)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.actual_month)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                            <td className='align-right'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.remaining_amount)))}</td>
                            <td></td>

                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
};


const PrintPaymentInvoiceFormat = React.forwardRef((props, ref) => {

    const location = useLocation();
    const p_id = location.state ? location.state.p_id : "";
    const invoice_id = location.state ? location.state.invoice_id : "";
    const month = location.state ? location.state.month : "";
    const year = location.state ? location.state.year : "";
    const invoice_num = location.state ? location.state.invoice_number : "";

    return (
        <div className='printSlip-container' ref={ref} >

            <PaymentReceipt p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />

        </div>
    )
})
const ConsultancyInvoiceFormat = React.forwardRef((props, ref) => {

    const location = useLocation();
    const p_id = location.state ? location.state.p_id : "";
    const invoice_id = location.state ? location.state.invoice_id : "";
    const month = location.state ? location.state.month : "";
    const year = location.state ? location.state.year : "";
    const invoice_num = location.state ? location.state.invoice_number : "";

    return (
        <div className='printSlip-container' ref={ref} >
            {/* <ConsultantInvoiceTable p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} /> */}
            {/* <Invoice p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} /> */}
            <ProfessionalStaff p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            {/* <SupportStaff p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} /> */}

            <Transportation p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            {/* <EmployeeInvoice p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num}/> */}
            <DutyTravel p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <OfficeRent p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <OfficeSupplies p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <OfficeFurniture p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <ReportsPrintt p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <RoadSurvey p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
        </div>
    )
})


const ConsultancyInvoice = (i) => {
    const ref = useRef();
    const [isPrinting, setIsPrinting] = useState(false);

    const handleBeforePrint = () => {
        setIsPrinting(true);
    };

    const handleAfterPrint = () => {
        setIsPrinting(false);
    };
    console.log("isPrinting")
    console.log(isPrinting)

    return (
        // <div className='flex-column flex-center  slip-container'>
        // <div className='printSlip-width flex-row justify-evenly button-margin'>
        <div>
            <div>
                {/* <button className="model-button  font-weight500    model-button-cancel font-size-heading" onClick={(e) => { backroute(firsttoggle, secondtoggle, accfirsttoggle) }}>Back</button> */}
                <ReactToPrint
                    bodyClass="print-agreement"
                    content={() => ref.current}
                    onBeforeGetContent={handleBeforePrint}
                    onAfterPrint={handleAfterPrint}
                    trigger={() => (
                        <button className="model-button model-button-black">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                                <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                            </svg> Print
                        </button>
                    )}
                />
            </div>
            <ConsultancyInvoiceFormat ref={ref} isPrinting={isPrinting} />
        </div>
    );
}
const PrintPayment = (i) => {
    const ref = useRef();
    const [isPrinting, setIsPrinting] = useState(false);

    const handleBeforePrint = () => {
        setIsPrinting(true);
    };

    const handleAfterPrint = () => {
        setIsPrinting(false);
    };


    return (
        // <div className='flex-column flex-center  slip-container'>
        // <div className='printSlip-width flex-row justify-evenly button-margin'>
        <div>
            <div>
                {/* <button className="model-button  font-weight500    model-button-cancel font-size-heading" onClick={(e) => { backroute(firsttoggle, secondtoggle, accfirsttoggle) }}>Back</button> */}
                <ReactToPrint
                    bodyClass="print-agreement"
                    content={() => ref.current}
                    onBeforeGetContent={handleBeforePrint}
                    onAfterPrint={handleAfterPrint}
                    trigger={() => (
                        <button className="model-button model-button-black">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                                <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                            </svg> Print
                        </button>
                    )}
                />
            </div>
            <PrintPaymentInvoiceFormat ref={ref} isPrinting={isPrinting} />
        </div>
    );
}




export { ConsultancyInvoice, PrintPayment }
