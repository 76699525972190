import { CircularGrid } from "./AllSvg"


const LetterStatus = ({ selectedLetterStatus, setLetterStatus }) => {
    return (
        <div title="Status Filter" className="field-cont-div">
            <CircularGrid />
            <select
                id="status"
                name="status"
                placeholder="Status"
                value={selectedLetterStatus}
                onChange={(e) => setLetterStatus(e.target.value)}
                className={`attendance-input-field width-10vw `}
            >



                <option value="null">Select a Status</option>
                <option value="pending">Pending</option>
                <option value="in-progress">InProgress</option>
                <option value="partially-close">Partially Close</option>
                <option value="close">Close</option>
         


            </select>
            <hr className="field-cont-hr" />
        </div>
    )
}


const LetterType = ({ setLetterType, lettertype }) => {
    return (
        <>
            <div title="Letter Type" className="field-cont-div">
                <CircularGrid />
                <select
                    onChange={(e) => setLetterType(e.target.value)}
                    value={lettertype}
                    className="attendance-input-field width-10vw   date-field"
                >

                    <option value="null">Select Letter Type</option>

                    <option value="eot">EOT</option>
                    <option value="cos">COS</option>
                    <option value="ncr">NCR</option>

                    <option value="billing">Billing</option>
                    <option value="design">Design</option>
                    <option value="informative">Informative</option>
                    <option value="show-cause">Show Cause</option>

                    <option value="other">Others</option>


                </select>

                <hr className="field-cont-hr" />
            </div>
        </>
    )
}


export { LetterStatus, LetterType }