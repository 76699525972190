import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";
import { ToastContainer } from "react-toastify";
import { handleErrorToast } from "../../CustomFunctions";


const AdminInventoryitemlist = ({ i }) => {
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    getSubCompanyEmployees();
  };

  const [subcompanyEmployees, setSubCompanyEmployees] = useState([]);

  const getSubCompanyEmployees = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/filterbyproj/null/${i.id}/`
      );
      setSubCompanyEmployees(res.data)

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: "", numericPart: 0 };
        }
      };

      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } =
          getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } =
          getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setSubCompanyEmployees(sortedList);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    getSubCompanyEmployees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const navigate = useNavigate();
  // const Viewdetails = (emp_code, user_detail) => {
  //   navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`);
  // };
  return (
    <>
      <button
        className=""
        title="View"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
                  <div className="attendance-heading  font-weight500    font-size-heading"></div>
                </div> */}
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                  List Item : {i.item_name}


                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">Sr.no</th>
                    <th className="align-right">Emp Code</th>
                    <th className="align-right">Project Name </th>
                    {/* <th className="align-right"> Designation </th> */}
                  </tr>
                </thead>
                {buffer ? (
                  <div className="spinner"></div> //buffering logic
                ) : (
                  <tbody>
                    {subcompanyEmployees
                      ?.filter((e) =>
                        e.project_short_name.toLowerCase().includes(searchQuery.toLowerCase())
                        ||
                        e.emp_code.toString().includes(searchQuery.toLowerCase())
                      )
                      .sort((a, b) => a.emp_code - b.emp_code)
                      .map((i, index) => (
                        <React.Fragment key={index}>
                          {/* <tr className="tr-border-bottom">
                              <td colSpan="6"></td>
                            </tr> */}
                          <tr className="custom-table-head-td">
                            <td className="align-left">{index + 1}</td>
                            <td className="align-right">{i.emp_code}</td>
                            <td className="align-right">{i.project_short_name}</td>
                            {/* <td className="align-center">
                                {i.designation_name}
                              </td> */}
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};



export default AdminInventoryitemlist;