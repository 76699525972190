import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { BASE_URL } from '../../../config/axios';

const months = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' }
];

const years = Array.from(new Array(30), (val, index) => new Date().getFullYear() - index);

const BulkAttendanceUpload = () => {
  const [fileData, setFileData] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the sheet with attendance data is named "Attendance"
        const worksheet = workbook.Sheets['Attendance'];
        const rawData = XLSX.utils.sheet_to_json(worksheet);

        // Transform rawData into the required format for uploading
        const transformedData = transformAttendanceData(rawData);
        setJsonData(transformedData);

        console.log(transformedData)
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const transformAttendanceData = (rawData) => {
    let transformedData = [];

    rawData.forEach((entry) => {
      const emp_code = entry.emp_code;


      // Loop through days of the month (assuming 1 to 31)
      for (let day = 1; day <= 31; day++) {
        if (entry[day]) {
          transformedData.push({
            emp_code: emp_code,
            date: `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
            status: entry[day],  // 'P', 'A', 'L', etc.
            in_time: null,  // Add if available in the data
            out_time: null  // Add if available in the data
          });
        }
      }
    });

    return transformedData;
  };

  const handleSubmit = async () => {
    if (!jsonData) {
      alert("Please upload an Excel file first!");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/siteproject/attendancepostbulk/`, { data: jsonData });
      alert('Attendance uploaded successfully!');
    } catch (error) {
      console.error('Error uploading attendance:', error);
      alert('Error uploading attendance.');
    }
  };

  return (
    <div>
      <h2>Bulk Attendance Upload</h2>

      {/* Dropdown for Year */}
      <label>
        Select Year:
        <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
          <option value="" disabled>Select Year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
            
          ))}
        </select>
      </label>

      {/* Dropdown for Month */}
      <label>
        Select Month:
        <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
          <option value="" disabled>Select Month</option>
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
      </label>

      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      <button onClick={handleSubmit}>Upload</button>
    </div>
  );
};

export default BulkAttendanceUpload;
