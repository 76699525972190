import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AUTH, AUTHH, BASE_URL, IMAGE_URL } from '../../config/axios';
import { toast, ToastContainer } from 'react-toastify';
import { formatDate, formattedDateLong } from '../Date';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleErrorToast } from '../CustomFunctions';
import { RequestExpensetable, RequestExpensetableAccStage1, RequestExpensetableAccStage2, RequestExpensetableAdmin, RequestImpresttable, RequestImpresttableAcc, RequestImpresttableAdmin } from './ImprestExpenseRequestComponents';
import Dashboardnavbar from '../../layout/Dashboardnavbar';


const HORequestsManagementTab = () => {

    // const [toggleState, setToggleState] = useState(1);
    const location = useLocation();
    const firsttoggle = location.state !== null ? location.state?.firsttoggle : 1;
    const secondtoggle = location.state !== null ? location.state?.secondtoggle : 4;
    const [toggleState, setToggleState] = useState(secondtoggle);

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 0:
                return 'My Site Clockins';
            case 1:
                return 'My Site OutDuties';
            case 2:
                return 'My Site Leaves';
            case 3:
                return 'My Site Compensatory Requests';
            case 4:
                return 'My Site Imprests';
            case 5:
                return 'My Site Expenses';
            case 6:
                return 'My Site Requisition Requests';
            default:
                return 'My Site OutDuties';
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Dashboardnavbar name={getSelectedTitle()} url="Site Request Management" />
            <div className="content-tabs">
                {/* <TeamRequestManagment secondtoggle={secondtoggle} /> */}
                <>
                    <div>
                        <div className="bloc-tabs-with-white-bg">

                            <button
                                className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(4)}
                            >
                                Imprest Requests
                            </button>
                            <button
                                className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                                onClick={() => setToggleState(5)}
                            >
                                Expense Requests
                            </button>

                        </div>

                        <div className="content-tabs-without-margin">

                            {toggleState === 4 ? <RequestImpresttable /> : null}
                            {toggleState === 5 ? <RequestExpensetable /> : null}

                        </div>
                    </div>
                </>
            </div >
        </>
    );
};

const AdminRequestsManagementTab = () => {

    // const [toggleState, setToggleState] = useState(1);
    const location = useLocation();
    const firsttoggle = location.state !== null ? location.state?.firsttoggle : 1;
    const secondtoggle = location.state !== null ? location.state?.secondtoggle : 1;
    const [toggleState, setToggleState] = useState(secondtoggle);

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 0:
                return 'Clock-In Logs';
            case 1:
                return 'Imprest Requests';
            case 2:
                return 'Expense Requests';
            case 3:
                return 'Compensatory Logs';
            case 4:
                return 'Requisition Requests';
            default:
                return 'Imprest Requests';
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Dashboardnavbar name={getSelectedTitle()} url="Admin Requests Management" />
            <div className="content-tabs">
                {/* <TeamRequestManagment secondtoggle={secondtoggle} /> */}
                <>
                    <div>
                        <div className="bloc-tabs-with-white-bg">
                            <button
                                className={
                                    toggleState === 1
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(1)}
                            >
                                Imprest
                            </button>
                            <button
                                className={
                                    toggleState === 2
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(2)}
                            >
                                Expense
                            </button>


                        </div>

                        <div className="content-tabs-without-margin">
                            {toggleState === 1 ? <RequestImpresttableAdmin /> : null}
                            {toggleState === 2 ? <RequestExpensetableAdmin /> : null}
                        </div>
                    </div>
                </>
            </div >
        </>
    );
};



const AccountsImprestExpenseRequestMangement = () => {
    const location = useLocation();
    const accfirsttoggle = location.state !== null ? location.state?.accfirsttoggle : 1;
    const [toggleState, setToggleState] = useState(accfirsttoggle ? accfirsttoggle : 1);

    const getSelectedTitle = () => {

        switch (toggleState) {
            case 1:
                return "Imprest Requests";
            case 2:
                return "Expense Requests";
            case 3:
                return "Expense Settelment";
            default:
                return "Imprest Requests";
        }
    };

    return (
        <>
            <div>
                <Dashboardnavbar name={getSelectedTitle()} url="Imprest & Expense Request Management" />
                <div className="bloc-tabs-with-white-bg content-tabs">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggleState(1)}
                    >
                        Imprest
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggleState(2)}
                    >
                        Expense Requests
                    </button>
                    <button
                        className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggleState(3)}
                    >
                        Expense Finalization
                    </button>
                </div>
            </div>
            <div className="content-tabs">
                {toggleState === 1 ? <RequestImpresttableAcc /> : null}
                {toggleState === 2 ? <RequestExpensetableAccStage1 /> : null}
                {toggleState === 3 ? <RequestExpensetableAccStage2 /> : null}
            </div>
        </>
    )
}

const ImprestRequest = () => {
    const [toggle, setToggle] = useState(1);

    const gettitles = () => {
        switch (toggle) {
            case 1:
                return "Team Imprest";
            case 2:
                return "Team Expense";
            case 3:
                return "Imprest";
            case 4:
                return "Expense";
            case 5:
                return "Imprest Request Account";
            case 6:
                return "Expense Request Account";
            case 7:
                return "Expense Finalize Account";
            default:
                return "Imprest"
        }
    }


    return (
        <>
            <div>
                <Dashboardnavbar name={gettitles()} url={gettitles()} />
                <div className="bloc-tabs-with-white-bg content-tabs">
                    <button
                        className={toggle === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggle(1)}
                    >
                        Team Imprest
                    </button>
                    <button
                        className={toggle === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggle(2)}
                    >
                        Team Expense
                    </button>
                    <button
                        className={toggle === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggle(3)}
                    >
                        Imprest
                    </button>
                    <button
                        className={toggle === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggle(4)}
                    >
                        Expense
                    </button>
                    <button
                        className={toggle === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggle(5)}
                    >
                        Imprest Request Account
                    </button>
                    <button
                        className={toggle === 6 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggle(6)}
                    >
                        Expense Request Account
                    </button>
                    <button
                        className={toggle === 7 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"}
                        onClick={() => setToggle(7)}
                    >
                        Expense Finalize Account
                    </button>
                </div>

                <div className="content-tabs-without-margin">
                    {toggle === 1 ? <RequestImpresttable /> : null}
                    {toggle === 2 ? <RequestExpensetable /> : null}
                    {toggle === 3 ? <RequestImpresttableAdmin /> : null}
                    {toggle === 4 ? <RequestExpensetableAdmin /> : null}
                    {toggle === 5 ? <RequestImpresttableAcc /> : null}
                    {toggle === 6 ? <RequestExpensetableAccStage1 /> : null}
                    {toggle === 7 ? <RequestExpensetableAccStage2 /> : null}
                </div>
            </div>
        </>
    )
}

export {
    ImprestRequest,
    HORequestsManagementTab,
    AdminRequestsManagementTab,
    AccountsImprestExpenseRequestMangement

}