
import React, { useState } from "react";

import Dashboardnavbar from "../../layout/Dashboardnavbar.jsx";
import usePermission from "../../config/permissions.js";
import Personaldetails from "./Personaldetails";
import Userdetailsmain from "./Userdetailsmain";



const UserDetails = () => {
  const { PERMISSION_ROLE, PERMISSION_AUTHORITY, SUPER_ADMIN } = usePermission(); // Use the custom hook

  const [toggleState, setToggleState] = useState(1);

  const email = sessionStorage.getItem("email")

  const emp_code = sessionStorage.getItem("emp_code")

  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'About';
      case 2:
        return 'Expenses';
      default:
        return 'About';
    }
  };


  return (
    <div>
      <Dashboardnavbar name={getSelectedTitle()} url="Profile" />
      <div style={{ background: "#F7F8FA" }}>
        <Userdetailsmain email={email} emp_code={emp_code} />
        <Personaldetails email={email} emp_code={emp_code} />
      </div>
    </div>
  );
};

export default UserDetails;