import { Outlet } from 'react-router-dom';
import DashboardSidebar from "../../layout/DashboardSidebar";
import { sidebarData } from './Hr_Sidebar';
import React, { useState } from 'react'
import { Modal } from "react-bootstrap";

const HrDashboard = () => {

  const [isexpand, setExpanded] = useState(false);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);



  return (
    <>
      <div className="dashboard-main-section">
        <DashboardSidebar sidebarData={sidebarData} isexpand={isexpand} setExpanded={setExpanded} />
        <div className={isexpand ? "dashboard-main-content-sectionn" : "dashboard-main-content-sectionnn"}>
          <Outlet />


        </div>

        <Modal show={show} onHide={handleClose} dialogClassName="model-annoucee">
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body >
            <img src="https://civilmantra.s3.ap-south-1.amazonaws.com/Ashish+Dhaiya.png" alt="demo" className="model-annoucee-pic" />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};


export default HrDashboard
