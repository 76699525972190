import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from 'react-toastify';
import usePermission from "../../config/permissions";
import * as XLSX from 'xlsx';
import { handleErrorToast } from "../CustomFunctions";
import { AddwithWhiteCircle, DeleteDustbin, Tripledot, UpdatePencil } from "../AllSvg";
import { useNavigate } from "react-router-dom";





// ! *************** Designation Crud Models Start ***************

const AddSiteDepartment = ({ getSiteDepartment, siteDepartmentList, siteSubCompanyList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setFormData({
            title: "",
            sub_company: ""
        })
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        title: "",
        sub_company: ""
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["title", "sub_company"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });


    };

    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm() && formData.title != null) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL}/siteproject/designation/`, formData);

                if (res.status === 200) {
                    await getSiteDepartment();
                    handleClose()
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button title="Add Site Designation" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Site Designation
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
                    <Modal.Title>Add Site Designation</Modal.Title>
                </Modal.Header>
                {/* <Modal.Header closeButton>
                <Modal.Title>Request Leave</Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
                            <div className="flex-column">
                                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                                    Title<span className="required">*</span>
                                </label>
                                <input
                                    maxLength={50}
                                    type="text"
                                    id="title"
                                    name="title"
                                    placeholder="Site Designation Name"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                                        }`}
                                />
                                {errors.title && (
                                    <span className="error-message font-size-text ">{errors.title}</span>
                                )}
                                {/* {filteredList.length > 0 && (
                    <div className="list-group">
                      {filteredList.map((item, index) => (
                        <div key={index} className="list-group-item">
                          {item.title}
                        </div>
                      ))}
                    </div>
                  )} */}

                            </div>

                            <div className="flex-column form-group-selectt">
                                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                                    Sub Company<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="sub_company"
                                    name="sub_company"
                                    value={formData.sub_company}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                                        }`}
                                >
                                    <option value="">Select Site Company  </option>
                                    {siteSubCompanyList.map(company => (<option value={company.id}>{company.title}</option>))};
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        class="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.sub_company && (
                                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button   font-weight500  model-button-cancel " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    //   type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

const UpdateSiteDepartment = ({ i, getSiteDepartment, siteDepartmentList, siteSubCompanyList }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i);
        setShow(true);
    };

    const [filteredList, setFilteredList] = useState([]);

    const [formData, setFormData] = useState({
        title: "",
        sub_company: ""
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["title", "sub_company"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        // Filter logic based on the input
        if (name === 'title') {
            const filtered = siteDepartmentList.filter(item =>
                item.title.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredList(filtered);
        }
    };

    // ?  ************** Validation End **************  ! //

    const handleFormSubmitPut = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/designation/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getSiteDepartment();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {

                //toast Logic
                handleErrorToast(err)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button title="Update Site Designation" className="model-edit-button" onClick={handleShow}>
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Update Designation {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
                            <div className="flex-column">
                                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                                    Title<span className="required">*</span>
                                </label>
                                <input
                                    maxLength={50}
                                    type="text"
                                    id="title"
                                    name="title"
                                    placeholder="Designation Name"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.title && (
                                    <span className="error-message font-size-text ">{errors.title}</span>
                                )}
                                {filteredList.length > 0 && (
                                    <div className="list-group">
                                        {filteredList.map((item, index) => (
                                            <div key={index} className="list-group-item">
                                                {item.title}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>



                            <div className="flex-column form-group-selectt">
                                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                                    Sub Company<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="sub_company"
                                    name="sub_company"
                                    value={formData.sub_company}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                                        }`}
                                >
                                    <option value="">Select Site Company  </option>
                                    {siteSubCompanyList.map(company => (<option value={company.id}>{company.title}</option>))};
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        class="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.sub_company && (
                                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                                )}
                            </div>


                            <div className="button-models">
                                <button className="model-button   font-weight500    " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    onClick={handleFormSubmitPut}
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteSiteDepartment = ({ i, getSiteDepartment }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.delete(`${BASE_URL}/siteproject/designation/${i.id}/`);

            if (res.status === 200) {
                await getSiteDepartment();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button title="Delete Site Designation" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Designation {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete designation {i.title}
                        <div className="button-models">
                            <button className="model-button   font-weight500   model-button-cancel " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ? *************** Designation Crud Models End ***************


// ! *************** Sub Company Crud Models Start ***************
const AddSiteSubcompanys = ({ getSiteSubcompanies }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setFormData({
            title: ""
        })
    };

    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        title: "",
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["title"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL}/siteproject/subcompany/`, formData);

                if (res.status === 200) {
                    handleClose();
                    await getSiteSubcompanies();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button title="Add Sub Company" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "}Sub Company
            </button>


            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
                    <Modal.Title>Add Sub Company</Modal.Title>
                </Modal.Header>
                {/* <Modal.Header closeButton>
                <Modal.Title>Request Leave</Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
                            <div className="flex-column">
                                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                                    Title<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={50}
                                    id="title"
                                    name="title"
                                    placeholder="Company Name"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                                        }`}
                                />
                                {errors.title && (
                                    <span className="error-message font-size-text ">{errors.title}</span>
                                )}
                                <div className="button-models">
                                    <button className="model-button   font-weight500   model-button-cancel" onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <button
                                        //   type="submit"
                                        className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                        onClick={handleFormSubmit}
                                        disabled={loading}
                                    >
                                        Submit
                                    </button>
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateSiteSubcompanyupdate = ({ i, getSiteSubcompanies }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i);
        setShow(true);
    };

    const [formData, setFormData] = useState({
        title: "",
    });


    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["title"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // ?  ************** Validation End **************  ! //

    const handleFormSubmitPut = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/subcompany/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    setShow(false);
                    await getSiteSubcompanies();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <button className="model-edit-button" onClick={handleShow}>
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Update SubCompany {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Birthday </h6> */}
                            <div className="flex-column">
                                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                                    Title<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    maxLength={50}
                                    id="title"
                                    name="title"
                                    placeholder="SubCompany Name"
                                    onChange={handleInputChange}
                                    value={formData.title}
                                    className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                                        }`}
                                />
                                {errors.title && (
                                    <span className="error-message font-size-text ">{errors.title}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button   font-weight500   model-button-cancel " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    onClick={handleFormSubmitPut}
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteSiteSubcompany = ({ i, getSiteSubcompanies }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();

        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(`${BASE_URL}/siteproject/subcompany/${i.id}/`);

            if (res.status === 200) {
                setShow(false);
                await getSiteSubcompanies();
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete SubCompany {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete SubCompany {i.title}
                        <div className="button-models">
                            <button className="model-button   font-weight500    model-button-cancel" onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ? *************** SubComany Crud Models End ***************


// ! *************** Employee Crud Models Start ***************


const UploadSiteEmployee = ({ id, getSiteEmployee }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Employee Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Employee Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            // Function to convert Excel serial number to a date
            const convertExcelDate = (serial) => {
                const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                const daysOffset = serial - 1; // Subtract 1 because Excel's date system includes a leap year bug
                return new Date(excelEpoch.getTime() + daysOffset * 86400000).toISOString().slice(0, 10); // Format as YYYY-MM-DD
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    // Check if the column is one of the date fields and convert it if necessary
                    // if (["dob", "joining_date", "intimation_date"].includes(header) && row[index] && typeof row[index] === "number") {
                    if (["dob", "joining_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertExcelDate(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };


    // Function to handle the upload button click
    const handleUpload = async () => {
        if (excelJson) {
            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/employeebulk/`, excelJson);
                if (res.status === 200) {
                    // await getSiteEmployee();
                    handleClose();
                }
            } catch (err) {
                handleError(err, loadingToastId);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };

    // Error handling function for API errors
    const handleError = (err, loadingToastId) => {
        toast.dismiss(loadingToastId);
        handleErrorToast(err);
    };


    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">
                {/* <Modal.Header closeButton>
            <Modal.Title>Update Site Employee</Modal.Title>
          </Modal.Header> */}
                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Employee Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">emp_code</th>
                                    <th className="align-center">site_prcode</th>
                                    <th className="align-center">name</th>
                                    <th className="align-center">dob</th>
                                    <th className="align-center">gender</th>
                                    <th className="align-center">married_status</th>
                                    <th className="align-center">qualification</th>
                                    <th className="align-center">designation</th>
                                    <th className="align-center">professional_type</th>
                                    <th className="align-center">current_address</th>
                                    <th className="align-center">mobile</th>
                                    <th className="align-center">email</th>
                                    <th className="align-center">joining_date</th>
                                    <th className="align-center">employee_status</th>
                                    <th className="align-center">emergency_person_name</th>
                                    <th className="align-center">emergency_phone_number</th>
                                    <th className="align-center">emergency_relation</th>
                                    <th className="align-center">emergency_address</th>
                                    <th className="align-center">sub_company</th>
                                    <th className="align-center">actual_salary</th>
                                    <th className="align-center">boq_salary</th>
                                    <th className="align-center">account_number</th>
                                    <th className="align-center">ifsc_code</th>
                                    <th className="align-center">bank_name</th>
                                    <th className="align-center">branch_address</th>
                                    <th className="align-center">uan</th>
                                    <th className="align-center">esi</th>
                                    <th className="align-center">aadhaar</th>
                                    <th className="align-right">pan</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-left">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-right">-</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background">
                        <table className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">emp_code</th>
                                    <th className="align-center">site_prcode</th>
                                    <th className="align-center">name</th>
                                    <th className="align-center width-5vw">dob</th>
                                    <th className="align-center">gender</th>
                                    <th className="align-center">married_status</th>
                                    <th className="align-center">qualification</th>
                                    <th className="align-center">designation</th>
                                    <th className="align-center">professional_type</th>
                                    <th className="align-center">current_address</th>
                                    <th className="align-center">mobile</th>
                                    <th className="align-center">email</th>
                                    <th className="align-center width-5vw">joining_date</th>
                                    <th className="align-center">employee_status</th>
                                    <th className="align-center">emergency_person_name</th>
                                    <th className="align-center">emergency_phone_number</th>
                                    <th className="align-center">emergency_relation</th>
                                    <th className="align-center">emergency_address</th>
                                    <th className="align-center">sub_company</th>
                                    <th className="align-center">actual_salary</th>
                                    <th className="align-center">boq_salary</th>
                                    <th className="align-center">account_number</th>
                                    <th className="align-center">ifsc_code</th>
                                    <th className="align-center">bank_name</th>
                                    <th className="align-center">branch_address</th>
                                    <th className="align-center">uan</th>
                                    <th className="align-center">esi</th>
                                    <th className="align-center">aadhaar</th>
                                    <th className="align-right">pan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {excelJson?.map((employee, index) => (
                                    <tr key={index}>
                                        <td className="align-center">{employee.emp_code}</td>
                                        <td className="align-center">{employee.site_prcode}</td>
                                        <td className="align-center">{employee.name}</td>
                                        <td className="align-center">{employee.dob}</td>
                                        <td className="align-center">{employee.gender}</td>
                                        <td className="align-center">{employee.married_status}</td>
                                        <td className="align-center">{employee.qualification}</td>
                                        <td className="align-center">{employee.designation}</td>
                                        <td className="align-center">{employee.professional_type}</td>
                                        <td className="align-center">{employee.current_address}</td>
                                        <td className="align-center">{employee.mobile}</td>
                                        <td className="align-center">{employee.email}</td>
                                        <td className="align-center">{employee.joining_date}</td>
                                        <td className="align-center">{employee.employee_status}</td>
                                        <td className="align-center">{employee.emergency_person_name}</td>
                                        <td className="align-center">{employee.emergency_phone_number}</td>
                                        <td className="align-center">{employee.emergency_relation}</td>
                                        <td className="align-center">{employee.emergency_address}</td>
                                        <td className="align-center">{employee.sub_company}</td>
                                        <td className="align-center">{employee.actual_salary}</td>
                                        <td className="align-center">{employee.boq_salary}</td>
                                        {/* <td className="align-center">{employee.intimation_date}</td> */}
                                        <td className="align-center">{employee.account_number}</td>
                                        <td className="align-center">{employee.ifsc_code}</td>
                                        <td className="align-center">{employee.bank_name}</td>
                                        <td className="align-center">{employee.branch_address}</td>
                                        <td className="align-center">{employee.uan}</td>
                                        <td className="align-center">{employee.esi}</td>
                                        <td className="align-center">{employee.aadhaar}</td>
                                        <td className="align-center">{employee.pan}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>

                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};


const AddSiteEmployee = ({ companyId, projectId, getSiteEmployee, siteDepartmentList, siteProjectList, siteSubCompanyList }) => {

    const [show, setShow] = useState(false);
    const { siteRole } = usePermission();
    const handleClose = () => {
        setShow(false);
        setFormData({
            emp_role: "null",
            emp_code: "",
            name: "",
            email: "",
            mobile: 0,
            designation: "",
            professional_type: "",
            current_address: "",
            joining_date: null,
            employee_status: "",
            sub_company: companyId ? companyId : "",
            // intimation_date: null,
            project: projectId ? projectId : "",
            dob: "",
            gender: "",
            married_status: "",
            qualification: "",
            emergency_person_name: "",
            emergency_phone_number: "",
            emergency_relation: "",
            emergency_address: "",
            account_number: "",
            ifsc_code: "",
            bank_name: "",
            branch_address: "",
            aadhaar: "",
            uan: "",
        });
    };


    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        emp_code: "",
        emp_role: null,
        name: "",
        email: "",
        mobile: 0,
        designation: "",
        professional_type: "",
        current_address: "",
        joining_date: null,
        employee_status: "active",
        sub_company: companyId ? companyId : "",

        // category: "",
        // intimation_date: null,
        project: projectId ? projectId : null,

        dob: null,
        gender: "",
        married_status: "",
        qualification: "",
        emergency_person_name: "",
        emergency_phone_number: "",
        emergency_relation: "",
        emergency_address: "",
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        branch_address: "",
        aadhaar: "",
        uan: "",
        pan: "",
        replacement_deduction: "",

    });

    // !  ************** Validation start **************  ! //
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        let requiredFields = [
            'emp_code',
            'name',
            // 'email',
            'mobile',
            'designation',
            'professional_type',
            'joining_date',
            'gender',
            'married_status',
            'emergency_person_name',
            'emergency_phone_number',
            'emergency_relation',
            'account_number',
            'ifsc_code',
            'bank_name',
            'aadhaar',
            'pan',
            'project',
            'emp_role',
            'sub_company'
        ];

        // Remove 'project' and 'emp_role' from requiredFields if siteRole is "SiteManagement"
        if (siteRole === "SiteManagement") {
            requiredFields = requiredFields.filter(field => field !== 'project' && field !== 'sub_company' && field !== 'emp_role');
        }

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);
        return Object.keys(newErrors).length === 0;
    };


    console.log(errors);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (value) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // const updatedFormData = {
        //     ...formData,
        //     employee: formData.email,
        // };

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(`${BASE_URL}/siteproject/employee/`, formData);

                if (res.status === 200) {
                    // window.location.reload();
                    await getSiteEmployee();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Submitted Successfully")
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };


    return (
        <>

            <button title="Add Site Employee" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Site Employee
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
                    <Modal.Title>Add Site Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/*  ************** timeline Seaction  ************** */}

                    <>
                        <form className="register-form">
                            <div className="form-flex-wrap">
                                <div title="Emp Code" className="flex-column">
                                    <label htmlFor="emp_code" className="form-labels  font-weight500    font-size-subheading">
                                        Emp Code<span className="required">*</span>
                                    </label>
                                    <input
                                        maxLength={20}
                                        type="text"
                                        id="emp_code"
                                        name="emp_code"
                                        placeholder="Emp Code"
                                        onChange={handleInputChange}
                                        value={formData.emp_code}
                                        className={`form-input ${errors.emp_code
                                            ? "error"
                                            : inputState.emp_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emp_code && (
                                        <span className="error-message font-size-text ">{errors.emp_code}</span>
                                    )}
                                </div>

                                <div title="Name" className="flex-column">
                                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                                        Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="name"
                                        name="name"
                                        placeholder="Name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.name
                                            ? "error"
                                            : inputState.name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.name && (
                                        <span className="error-message font-size-text ">{errors.name}</span>
                                    )}
                                </div>

                                <div title="Email" className="flex-column">
                                    <label htmlFor="email" className="form-labels  font-weight500    font-size-subheading">
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Demo@gmail.com"
                                        onChange={handleInputChange}
                                        value={formData.email}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                                        }}
                                        className={`form-input ${errors.email
                                            ? "error"
                                            : inputState.email
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.email && (
                                        <span className="error-message font-size-text ">{errors.email}</span>
                                    )}
                                </div>

                                <div title="Mobile No." className="flex-column">
                                    <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                                        Mobile No.<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        maxLength={10}
                                        id="mobile"
                                        name="mobile"
                                        placeholder="Mobile Number."
                                        onChange={handleInputChange}
                                        value={formData.mobile}
                                        className={`form-input ${errors.mobile
                                            ? "error"
                                            : inputState.mobile
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.mobile && (
                                        <span className="error-message font-size-text ">{errors.mobile}</span>
                                    )}
                                </div>
                                {siteRole === "SiteManageent" ? "" :
                                    <div title="Replacement Deduction " className="flex-column">
                                        <label htmlFor="replacement_deduction" className="form-labels  font-weight500    font-size-subheading">
                                            Replacement Deduction
                                        </label>
                                        <input
                                            type="text"
                                            min={0}
                                            maxLength={10}
                                            id="replacement_deduction"
                                            name="replacement_deduction"
                                            placeholder=" Replacement Deduction"
                                            onChange={handleInputChange}
                                            value={formData.replacement_deduction}
                                            className={`form-input ${errors.replacement_deduction
                                                ? "error"
                                                : inputState.replacement_deduction
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.replacement_deduction && (
                                            <span className="error-message font-size-text ">{errors.replacement_deduction}</span>
                                        )}
                                    </div>
                                }

                                <div title="Site Designation" className="flex-column form-group-selectt">
                                    <label htmlFor="designation" className="form-labels  font-weight500    font-size-subheading">
                                        Site Designation<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="designation"
                                        name="designation"
                                        value={formData.designation}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Designation</option>
                                        {siteDepartmentList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }
                                    </select>

                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.designation && (
                                            <span className="error-message font-size-text ">{errors.designation}</span>
                                        )}
                                    </div>
                                </div>
                                {siteRole === "SiteManagement" ? "" :
                                    <div title="Employee Role" className="flex-column form-group-selectt">
                                        <label htmlFor="emp_role" className="form-labels  font-weight500    font-size-subheading">
                                            Employee Role
                                        </label>
                                        <br />
                                        <select
                                            id="emp_role"
                                            name="emp_role"
                                            value={formData.emp_role}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.emp_role ? "error" : inputState.emp_role ? "success" : ""
                                                }`}
                                        >
                                            <option value="null">Select Employee Role </option>
                                            <option value="SiteManagement">Manager</option>
                                            <option value="DataEntryOperator">Data Entry Operator</option>
                                            <option value="AuthorityEngineer ">Authority Engineer</option>
                                            <option value="Account">Account</option>
                                            <option value="Hr">HR</option>
                                            <option value="Admin">Admin</option>
                                            <option value="BD">BD</option>
                                        </select>
                                        <div className="form-group-selection-arrow">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="25"
                                                fill="currentColor"
                                                class="bi bi-caret-down-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg>
                                        </div>
                                        <div>
                                            {errors.emp_role && (
                                                <span className="error-message font-size-text ">{errors.emp_role}</span>
                                            )}
                                        </div>
                                    </div>
                                }


                                <div title="Professional Type" className="flex-column form-group-selectt">
                                    <label htmlFor="professional_type" className="form-labels  font-weight500    font-size-subheading">
                                        Employee Type<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="professional_type"
                                        name="professional_type"
                                        value={formData.professional_type}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.professional_type ? "error" : inputState.professional_type ? "success" : ""
                                            }`}
                                    >
                                        <option value="null">Select a Professional </option>
                                        <option value="Key Professional">Key Professional</option>
                                        <option value="Sub Professional">Sub Professional</option>
                                        <option value="Support Staff">Support Staff</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.professional_type && (
                                            <span className="error-message font-size-text ">{errors.professional_type}</span>
                                        )}
                                    </div>
                                </div>

                                {companyId === null &&
                                    <>
                                        <div title="Site Sub Company" className="flex-column form-group-selectt">
                                            <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                                                Site Sub Company
                                            </label>
                                            <br />
                                            <select
                                                id="sub_company"
                                                name="sub_company"
                                                value={formData.sub_company}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Site SubCompany</option>
                                                {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}</option>
                                                </>))
                                                }
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="25"
                                                    fill="currentColor"
                                                    class="bi bi-caret-down-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            <div>
                                                {errors.sub_company && (
                                                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                                                )}
                                            </div>
                                        </div>


                                    </>

                                }

                                {siteRole === "SiteManagement" ? "" :
                                    <div title="Employee Status" className="flex-column form-group-selectt">
                                        <label htmlFor="employee_status" className="form-labels  font-weight500    font-size-subheading">
                                            Employee Status<span className="required">*</span>
                                        </label>
                                        <br />
                                        <select
                                            id="employee_status"
                                            name="employee_status"
                                            value={formData.employee_status}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.employee_status ? "error" : inputState.employee_status ? "success" : ""
                                                }`}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">In Active</option>
                                        </select>
                                        <div className="form-group-selection-arrow">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="25"
                                                fill="currentColor"
                                                class="bi bi-caret-down-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg>
                                        </div>
                                        <div>
                                            {errors.employee_status && (
                                                <span className="error-message font-size-text ">{errors.employee_status}</span>
                                            )}
                                        </div>
                                    </div>
                                }
                                <div title="Joining Date" className="flex-column">
                                    <label htmlFor="joining_date" className="form-labels  font-weight500    font-size-subheading">
                                        Joining Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="joining_date"
                                        name="joining_date"
                                        onChange={handleInputChange}
                                        value={formData.joining_date}
                                        className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.joining_date && <span className="error-message font-size-text ">{errors.joining_date}</span>}
                                </div>
                                {projectId === null &&
                                    <div title="Site Projects" className="flex-column form-group-selectt">
                                        <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                                            Site Projects<span className="required">*</span>
                                        </label>
                                        <br />
                                        <select
                                            id="project"
                                            name="project"
                                            value={formData.project}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                                                }`}
                                        >
                                            <option value="">Select a Project</option>
                                            {siteProjectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                                            </>))
                                            }
                                        </select>
                                        {/* {formData.project !== "" ? "" :  */}
                                        <div className="form-group-selection-arrow">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="25"
                                                fill="currentColor"
                                                class="bi bi-caret-down-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg>
                                        </div>
                                        {/* } */}
                                        <div>
                                            {errors.project && (
                                                <span className="error-message font-size-text ">{errors.project}</span>
                                            )}
                                        </div>
                                    </div>
                                }


                                <div title="dob" className="flex-column">
                                    <label htmlFor="dob" className="form-labels  font-weight500    font-size-subheading">
                                        Date of Birth
                                    </label>
                                    <input
                                        type="date"
                                        id="dob"
                                        name="dob"
                                        onChange={handleInputChange}
                                        value={formData.dob}
                                        className={`form-input ${errors.dob ? "error" : inputState.dob ? "success" : ""
                                            }`}
                                    />
                                    {errors.dob && <span className="error-message font-size-text ">{errors.dob}</span>}
                                </div>

                                <div title="Gender" className="flex-column form-group-select">
                                    <label htmlFor="gender" className="form-labels font-weight500    font-size-subheading">
                                        Gender<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="gender"
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.gender ? "error" : inputState.gender ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.gender && (
                                            <span className="error-message font-size-text ">{errors.gender}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="qualification" className="flex-column">
                                    <label htmlFor="qualification" className="form-labels  font-weight500    font-size-subheading">
                                        Qualification
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="qualification"
                                        name="qualification"
                                        placeholder="qualification"
                                        onChange={handleInputChange}
                                        value={formData.qualification}
                                        className={`form-input ${errors.qualification
                                            ? "error"
                                            : inputState.qualification
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.qualification && (
                                        <span className="error-message font-size-text ">{errors.qualification}</span>
                                    )}
                                </div>

                                <div title="Marital Status" className="flex-column form-group-select">
                                    <label htmlFor="married_status" className="form-labels font-weight500    font-size-subheading">
                                        Marital Status<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="married_status"
                                        name="married_status"
                                        value={formData.married_status}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.married_status ? "error" : inputState.married_status ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Divorced">Divorced</option>
                                        <option value="Widowed">Widowed</option>
                                        <option value="Separated">Separated</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.married_status && (
                                            <span className="error-message font-size-text ">{errors.married_status}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="emergency_person_name" className="flex-column">
                                    <label htmlFor="emergency_person_name" className="form-labels  font-weight500    font-size-subheading">
                                        Emergency person name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="emergency_person_name"
                                        name="emergency_person_name"
                                        placeholder="Emergency person name"
                                        onChange={handleInputChange}
                                        value={formData.emergency_person_name}
                                        className={`form-input ${errors.emergency_person_name
                                            ? "error"
                                            : inputState.emergency_person_name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emergency_person_name && (
                                        <span className="error-message font-size-text ">{errors.emergency_person_name}</span>
                                    )}
                                </div>

                                <div title="emergency_phone_number" className="flex-column">
                                    <label htmlFor="emergency_phone_number" className="form-labels  font-weight500    font-size-subheading">
                                        Emergency Phone Number<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        maxLength={10}
                                        min={0}
                                        id="emergency_phone_number"
                                        name="emergency_phone_number"
                                        placeholder="Emergency Phone Number"
                                        onChange={handleInputChange}
                                        value={formData.emergency_phone_number}
                                        className={`form-input ${errors.emergency_phone_number
                                            ? "error"
                                            : inputState.emergency_phone_number
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emergency_phone_number && (
                                        <span className="error-message font-size-text ">{errors.emergency_phone_number}</span>
                                    )}
                                </div>

                                <div title="emergency_relation" className="flex-column">
                                    <label htmlFor="emergency_relation" className="form-labels  font-weight500    font-size-subheading">
                                        Relative<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="emergency_relation"
                                        name="emergency_relation"
                                        placeholder=" Relative Name"
                                        onChange={handleInputChange}
                                        value={formData.emergency_relation}
                                        className={`form-input ${errors.emergency_relation
                                            ? "error"
                                            : inputState.emergency_relation
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emergency_relation && (
                                        <span className="error-message font-size-text ">{errors.emergency_relation}</span>
                                    )}
                                </div>

                                <div title="account number" className="flex-column">
                                    <label htmlFor="account_number" className="form-labels  font-weight500    font-size-subheading">
                                        Account Number<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        // maxLength={100}
                                        min={0}
                                        id="account_number"
                                        name="account_number"
                                        placeholder="Account number"
                                        onChange={handleInputChange}
                                        value={formData.account_number}
                                        className={`form-input ${errors.account_number
                                            ? "error"
                                            : inputState.account_number
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.account_number && (
                                        <span className="error-message font-size-text ">{errors.account_number}</span>
                                    )}
                                </div>

                                <div title="IFSC code" className="flex-column">
                                    <label htmlFor="ifsc_code" className="form-labels  font-weight500    font-size-subheading">
                                        IFSC code<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={15}
                                        id="ifsc_code"
                                        name="ifsc_code"
                                        placeholder="ifsc code"
                                        onChange={handleInputChange}
                                        value={formData.ifsc_code}
                                        className={`form-input ${errors.ifsc_code
                                            ? "error"
                                            : inputState.ifsc_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.ifsc_code && (
                                        <span className="error-message font-size-text ">{errors.ifsc_code}</span>
                                    )}
                                </div>

                                <div title="Bank Name" className="flex-column">
                                    <label htmlFor="bank_name" className="form-labels  font-weight500    font-size-subheading">
                                        Bank Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={60}
                                        id="bank_name"
                                        name="bank_name"
                                        placeholder="Bank Name"
                                        onChange={handleInputChange}
                                        value={formData.bank_name}
                                        className={`form-input ${errors.bank_name
                                            ? "error"
                                            : inputState.bank_name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.bank_name && (
                                        <span className="error-message font-size-text ">{errors.bank_name}</span>
                                    )}
                                </div>

                                <div title="Bank address" className="flex-column">
                                    <label htmlFor="branch_address" className="form-labels  font-weight500    font-size-subheading">
                                        Bank Address
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="branch_address"
                                        name="branch_address"
                                        placeholder="Bank address"
                                        onChange={handleInputChange}
                                        value={formData.branch_address}
                                        className={`form-input ${errors.branch_address
                                            ? "error"
                                            : inputState.branch_address
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.branch_address && (
                                        <span className="error-message font-size-text ">{errors.branch_address}</span>
                                    )}
                                </div>

                                <div title="Aadhaar card" className="flex-column">
                                    <label htmlFor="aadhaar" className="form-labels  font-weight500    font-size-subheading">
                                        Aadhaar Card No.<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        maxLength={100}
                                        id="aadhaar"
                                        min={0}

                                        name="aadhaar"
                                        placeholder="Aadhaar card No."
                                        onChange={handleInputChange}
                                        value={formData.aadhaar}
                                        className={`form-input ${errors.aadhaar
                                            ? "error"
                                            : inputState.aadhaar
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.aadhaar && (
                                        <span className="error-message font-size-text ">{errors.aadhaar}</span>
                                    )}
                                </div>

                                <div title="uan no." className="flex-column">
                                    <label htmlFor="uan" className="form-labels  font-weight500    font-size-subheading">
                                        UAN No.
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="uan"
                                        name="uan"
                                        placeholder="uan"
                                        onChange={handleInputChange}
                                        value={formData.uan}
                                        className={`form-input ${errors.uan
                                            ? "error"
                                            : inputState.uan
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.uan && (
                                        <span className="error-message font-size-text ">{errors.uan}</span>
                                    )}
                                </div>

                                <div title="pan card" className="flex-column">
                                    <label htmlFor="pan" className="form-labels  font-weight500    font-size-subheading">
                                        PAN No.<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="pan"
                                        name="pan"
                                        placeholder="pan card"
                                        onChange={handleInputChange}
                                        value={formData.pan}
                                        className={`form-input ${errors.pan
                                            ? "error"
                                            : inputState.pan
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.pan && (
                                        <span className="error-message font-size-text ">{errors.pan}</span>
                                    )}
                                </div>

                                <div title="Current Address" className="flex-column">
                                    <label htmlFor="current_address" className="form-labels  font-weight500    font-size-subheading">
                                        Current Address
                                    </label>
                                    <textarea
                                        type="text"
                                        id="current_address"
                                        name="current_address"
                                        maxLength={300}
                                        placeholder="Current Address"
                                        onChange={handleInputChange}
                                        value={formData.current_address}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.current_address
                                            ? "error"
                                            : inputState.current_address
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.current_address && (
                                        <span className="error-message font-size-text ">{errors.current_address}</span>
                                    )}
                                </div>

                                <div title="Emergency Address" className="flex-column">
                                    <label htmlFor="emergency_address" className="form-labels  font-weight500    font-size-subheading">
                                        Emergency Address
                                    </label>
                                    <textarea
                                        type="text"
                                        id="emergency_address"
                                        name="emergency_address"
                                        maxLength={300}
                                        placeholder="Emergency Address"
                                        onChange={handleInputChange}
                                        value={formData.emergency_address}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.emergency_address
                                            ? "error"
                                            : inputState.emergency_address
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emergency_address && (
                                        <span className="error-message font-size-text ">{errors.emergency_address}</span>
                                    )}
                                </div>




                            </div>


                            <div className="button-models">

                                <button className="model-button-cancel model-button   font-weight500   " onClick={(e) => handleClose()}>
                                    Cancel
                                </button>
                                <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                                    Submit
                                </button>




                            </div>
                            <p className="error-message font-size-text">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 5 ? (
                                        <h5 className="text-center">Please fill all mandatory fields!</h5>
                                    ) : (
                                        Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'
                                    )
                                )}
                            </p>

                        </form>
                    </>

                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </>
    );
};

const UpdateSiteEmployee = ({ i, getSiteEmployee, siteDepartmentList, siteProjectList, siteSubCompanyList, companyID, projectId }) => {

    const [show, setShow] = useState(false)
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { siteRole } = usePermission();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = async () => {
        // setFormData(i);
        getUserDetails()
        setShow(true);
    };


    const getUserDetails = async () => {
        setBuffering(true); // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/employeebyempcode/${i.emp_code}/`);
            setFormData(res.data);

        } catch (err) {
            handleErrorToast(err)
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [formData, setFormData] = useState({
        emp_code: "",
        name: "",
        email: "",
        mobile: 0,
        designation: "",
        professional_type: "",
        current_address: "",
        joining_date: "",
        employee_status: "",
        sub_company: companyID ? companyID : "",
        emp_role: null,
        dob: "",
        gender: "",
        married_status: "",
        qualification: "",
        emergency_person_name: "",
        emergency_phone_number: "",
        emergency_relation: "",
        emergency_address: "",
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        branch_address: "",
        aadhaar: "",
        uan: "",
        replacement_deduction: "",

    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [

        ];

        // if (siteRole !== "SiteManagement") {
        //     requiredFields.push("project", 'employee_status', "sub_company", 'boq_salary', 'actual_salary', 'emp_role');
        // }
        // requiredFields.forEach((field) => {
        //     if (!formData[field]) {
        //         newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
        //             } is required !`;
        //     }
        // });


        setErrors(newErrors);
        setIsSubmitted(true);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/employee/${i.id}/`, formData)

                if (res.status === 200) {
                    await getSiteEmployee()
                    handleClose()
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err)
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };



    return (
        <>

            <button className="model-edit-button" onClick={handleShow}>
                <UpdatePencil />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title>Update Site Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/*  ************** timeline Seaction  ************** */}

                    <>
                        <form className="register-form">
                            <div className="form-flex-wrap">
                                <div title="Emp Code" className="flex-column">
                                    <label htmlFor="emp_code" className="form-labels  font-weight500    font-size-subheading">
                                        Emp Code<span className="required">*</span>
                                    </label>
                                    <input
                                        maxLength={20}
                                        type="text"
                                        id="emp_code"
                                        name="emp_code"
                                        placeholder="Emp Code"
                                        onChange={handleInputChange}
                                        value={formData.emp_code}
                                        className={`form-input ${errors.emp_code
                                            ? "error"
                                            : inputState.emp_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emp_code && (
                                        <span className="error-message font-size-text ">{errors.emp_code}</span>
                                    )}
                                </div>

                                <div title="Name" className="flex-column">
                                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                                        Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="name"
                                        name="name"
                                        placeholder="Name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.name
                                            ? "error"
                                            : inputState.name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.name && (
                                        <span className="error-message font-size-text ">{errors.name}</span>
                                    )}
                                </div>

                                <div title="Email" className="flex-column">
                                    <label htmlFor="email" className="form-labels  font-weight500    font-size-subheading">
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Demo@gmail.com"
                                        onChange={handleInputChange}
                                        value={formData.email}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                                        }}
                                        className={`form-input ${errors.email
                                            ? "error"
                                            : inputState.email
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.email && (
                                        <span className="error-message font-size-text ">{errors.email}</span>
                                    )}
                                </div>

                                <div title="Mobile No." className="flex-column">
                                    <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                                        Mobile No.<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        maxLength={10}
                                        id="mobile"
                                        name="mobile"
                                        placeholder="Mobile Number"
                                        onChange={handleInputChange}
                                        value={formData.mobile}
                                        className={`form-input ${errors.mobile
                                            ? "error"
                                            : inputState.mobile
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.mobile && (
                                        <span className="error-message font-size-text ">{errors.mobile}</span>
                                    )}
                                </div>

                                {siteRole === "SiteManageent" ? "" :
                                    <div title="Replacement Deduction " className="flex-column">
                                        <label htmlFor="replacement_deduction" className="form-labels  font-weight500    font-size-subheading">
                                            Replacement Deduction
                                        </label>
                                        <input
                                            type="text"
                                            min={0}
                                            maxLength={10}
                                            id="replacement_deduction"
                                            name="replacement_deduction"
                                            placeholder=" Replacement Deduction"
                                            onChange={handleInputChange}
                                            value={formData.replacement_deduction}
                                            className={`form-input ${errors.replacement_deduction
                                                ? "error"
                                                : inputState.replacement_deduction
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.replacement_deduction && (
                                            <span className="error-message font-size-text ">{errors.replacement_deduction}</span>
                                        )}
                                    </div>
                                }

                                <div title="Site Designation" className="flex-column form-group-selectt">
                                    <label htmlFor="designation" className="form-labels  font-weight500    font-size-subheading">
                                        Site Designation<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="designation"
                                        name="designation"
                                        value={formData.designation}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Designation</option>
                                        {siteDepartmentList?.sort((a, b) => a.title?.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }
                                    </select>

                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.designation && (
                                            <span className="error-message font-size-text ">{errors.designation}</span>
                                        )}
                                    </div>
                                </div>
                                {siteRole === "SiteManagement" ? "" :
                                    <div title="Employee Role" className="flex-column form-group-selectt">
                                        <label htmlFor="emp_role" className="form-labels  font-weight500    font-size-subheading">
                                            Employee Role<span className="required">*</span>
                                        </label>
                                        <br />
                                        <select
                                            id="emp_role"
                                            name="emp_role"
                                            value={formData.emp_role}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.emp_role ? "error" : inputState.emp_role ? "success" : ""
                                                }`}
                                        >
                                            <option value="null">Select Employee Role </option>
                                            <option value="SiteManagement">Manager</option>
                                            <option value="DataEntryOperator">Data Entry Operator</option>
                                            <option value="AuthoritySupervisor">Authority Supervisor</option>
                                            <option value="Account">Account</option>
                                            <option value="Hr">HR</option>
                                        </select>
                                        <div className="form-group-selection-arrow">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="25"
                                                fill="currentColor"
                                                class="bi bi-caret-down-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg>
                                        </div>
                                        <div>
                                            {errors.emp_role && (
                                                <span className="error-message font-size-text ">{errors.emp_role}</span>
                                            )}
                                        </div>
                                    </div>
                                }

                                <div title="Professional Type" className="flex-column form-group-selectt">
                                    <label htmlFor="professional_type" className="form-labels  font-weight500    font-size-subheading">
                                        Employee Type<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="professional_type"
                                        name="professional_type"
                                        value={formData.professional_type}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.professional_type ? "error" : inputState.professional_type ? "success" : ""
                                            }`}
                                    >
                                        <option value="null">Select a Professional </option>
                                        <option value="Key Professional">Key Professional</option>
                                        <option value="Sub Professional">Sub Professional</option>
                                        <option value="Support Staff">Support Staff</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.professional_type && (
                                            <span className="error-message font-size-text ">{errors.professional_type}</span>
                                        )}
                                    </div>
                                </div>

                                {siteRole === "SiteManagement" ? "" :
                                    <>
                                        <div title="Site Sub Company" className="flex-column form-group-selectt">
                                            <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                                                Site Sub Company<span className="required">*</span>
                                            </label>
                                            <br />
                                            <select
                                                id="sub_company"
                                                name="sub_company"
                                                value={formData.sub_company}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select a Site SubCompany</option>
                                                {siteSubCompanyList?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                    <option value={i.id}>{i.title}</option>
                                                </>))
                                                }
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="25"
                                                    fill="currentColor"
                                                    class="bi bi-caret-down-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            <div>
                                                {errors.sub_company && (
                                                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                                                )}
                                            </div>
                                        </div>



                                        <div title="Employee Status" className="flex-column form-group-selectt">
                                            <label htmlFor="employee_status" className="form-labels  font-weight500    font-size-subheading">
                                                Employee Status<span className="required">*</span>
                                            </label>
                                            <br />
                                            <select
                                                id="employee_status"
                                                name="employee_status"
                                                value={formData.employee_status}
                                                onChange={handleInputChange}
                                                className={`form-input form-group-selection ${errors.employee_status ? "error" : inputState.employee_status ? "success" : ""
                                                    }`}
                                            >
                                                <option value="">Select Option</option>
                                                <option value="active">Active</option>
                                                <option value="inactive">In Active</option>
                                            </select>
                                            <div className="form-group-selection-arrow">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="25"
                                                    fill="currentColor"
                                                    class="bi bi-caret-down-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </div>
                                            <div>
                                                {errors.employee_status && (
                                                    <span className="error-message font-size-text ">{errors.employee_status}</span>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                }
                                <div title="Joining Date" className="flex-column">
                                    <label htmlFor="joining_date" className="form-labels  font-weight500    font-size-subheading">
                                        Joining Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="joining_date"
                                        name="joining_date"
                                        onChange={handleInputChange}
                                        value={formData.joining_date}
                                        className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.joining_date && <span className="error-message font-size-text ">{errors.joining_date}</span>}
                                </div>
                                {siteRole === "SiteManagement" ? "" :
                                    <div title="Site Projects" className="flex-column form-group-selectt">
                                        <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                                            Site Projects<span className="required">*</span>
                                        </label>
                                        <br />
                                        <select
                                            id="project"
                                            name="project"
                                            value={formData.project}
                                            onChange={handleInputChange}
                                            className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                                                }`}
                                        // disabled={formData.project === "" ? false : true}
                                        >
                                            <option value="">Select a Project</option>
                                            {siteProjectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                                            </>))
                                            }
                                        </select>
                                        {formData.project !== "" ? "" : <div className="form-group-selection-arrow">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="25"
                                                fill="currentColor"
                                                class="bi bi-caret-down-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg>
                                        </div>}
                                        <div>
                                            {errors.project && (
                                                <span className="error-message font-size-text ">{errors.project}</span>
                                            )}
                                        </div>
                                    </div>
                                }


                                <div title="dob" className="flex-column">
                                    <label htmlFor="dob" className="form-labels  font-weight500    font-size-subheading">
                                        DOB
                                    </label>
                                    <input
                                        type="date"
                                        id="dob"
                                        name="dob"
                                        onChange={handleInputChange}
                                        value={formData.dob}
                                        className={`form-input ${errors.dob ? "error" : inputState.dob ? "success" : ""
                                            }`}
                                    />
                                    {errors.dob && <span className="error-message font-size-text ">{errors.dob}</span>}
                                </div>

                                <div title="Gender" className="flex-column form-group-select">
                                    <label htmlFor="gender" className="form-labels font-weight500    font-size-subheading">
                                        Gender<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="gender"
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.gender ? "error" : inputState.gender ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.gender && (
                                            <span className="error-message font-size-text ">{errors.gender}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="qualification" className="flex-column">
                                    <label htmlFor="qualification" className="form-labels  font-weight500    font-size-subheading">
                                        Qualification
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="qualification"
                                        name="qualification"
                                        placeholder="qualification"
                                        onChange={handleInputChange}
                                        value={formData.qualification}
                                        className={`form-input ${errors.qualification
                                            ? "error"
                                            : inputState.qualification
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.qualification && (
                                        <span className="error-message font-size-text ">{errors.qualification}</span>
                                    )}
                                </div>

                                <div className="flex-column form-group-select">
                                    <label htmlFor="married_status" className="form-labels font-weight500    font-size-subheading">
                                        Marital Status
                                    </label>
                                    <br />
                                    <select
                                        id="married_status"
                                        name="married_status"
                                        value={formData.married_status}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.married_status ? "error" : inputState.married_status ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Divorced">Divorced</option>
                                        <option value="Widowed">Widowed</option>
                                        <option value="Separated">Separated</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.married_status && (
                                            <span className="error-message font-size-text ">{errors.married_status}</span>
                                        )}
                                    </div>
                                </div>

                                <div title="emergency_person_name" className="flex-column">
                                    <label htmlFor="emergency_person_name" className="form-labels  font-weight500    font-size-subheading">
                                        Emergency person name
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="emergency_person_name"
                                        name="emergency_person_name"
                                        placeholder="Emergency person name"
                                        onChange={handleInputChange}
                                        value={formData.emergency_person_name}
                                        className={`form-input ${errors.emergency_person_name
                                            ? "error"
                                            : inputState.emergency_person_name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emergency_person_name && (
                                        <span className="error-message font-size-text ">{errors.emergency_person_name}</span>
                                    )}
                                </div>

                                <div title="emergency_phone_number" className="flex-column">
                                    <label htmlFor="emergency_phone_number" className="form-labels  font-weight500    font-size-subheading">
                                        Emergency Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="emergency_phone_number"
                                        name="emergency_phone_number"
                                        placeholder="Emergency Phone Number"
                                        onChange={handleInputChange}
                                        value={formData.emergency_phone_number}
                                        className={`form-input ${errors.emergency_phone_number
                                            ? "error"
                                            : inputState.emergency_phone_number
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emergency_phone_number && (
                                        <span className="error-message font-size-text ">{errors.emergency_phone_number}</span>
                                    )}
                                </div>

                                <div title="emergency_relation" className="flex-column">
                                    <label htmlFor="emergency_relation" className="form-labels  font-weight500    font-size-subheading">
                                        Relative
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="emergency_relation"
                                        name="emergency_relation"
                                        placeholder=" Relative Name"
                                        onChange={handleInputChange}
                                        value={formData.emergency_relation}
                                        className={`form-input ${errors.emergency_relation
                                            ? "error"
                                            : inputState.emergency_relation
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emergency_relation && (
                                        <span className="error-message font-size-text ">{errors.emergency_relation}</span>
                                    )}
                                </div>

                                <div title="account number" className="flex-column">
                                    <label htmlFor="account_number" className="form-labels  font-weight500    font-size-subheading">
                                        Account Number
                                    </label>
                                    <input
                                        type="text"
                                        // maxLength={100}
                                        id="account_number"
                                        name="account_number"
                                        placeholder="Account number"
                                        onChange={handleInputChange}
                                        value={formData.account_number}
                                        className={`form-input ${errors.account_number
                                            ? "error"
                                            : inputState.account_number
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.account_number && (
                                        <span className="error-message font-size-text ">{errors.account_number}</span>
                                    )}
                                </div>

                                <div title="IFSC code" className="flex-column">
                                    <label htmlFor="ifsc_code" className="form-labels  font-weight500    font-size-subheading">
                                        IFSC code
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={15}
                                        id="ifsc_code"
                                        name="ifsc_code"
                                        placeholder="ifsc code"
                                        onChange={handleInputChange}
                                        value={formData.ifsc_code}
                                        className={`form-input ${errors.ifsc_code
                                            ? "error"
                                            : inputState.ifsc_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.ifsc_code && (
                                        <span className="error-message font-size-text ">{errors.ifsc_code}</span>
                                    )}
                                </div>

                                <div title="Bank Name" className="flex-column">
                                    <label htmlFor="bank_name" className="form-labels  font-weight500    font-size-subheading">
                                        Bank Name
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={60}
                                        id="bank_name"
                                        name="bank_name"
                                        placeholder="Bank Name"
                                        onChange={handleInputChange}
                                        value={formData.bank_name}
                                        className={`form-input ${errors.bank_name
                                            ? "error"
                                            : inputState.bank_name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.bank_name && (
                                        <span className="error-message font-size-text ">{errors.bank_name}</span>
                                    )}
                                </div>

                                <div title="Bank address" className="flex-column">
                                    <label htmlFor="branch_address" className="form-labels  font-weight500    font-size-subheading">
                                        Bank Address
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="branch_address"
                                        name="branch_address"
                                        placeholder="Bank address"
                                        onChange={handleInputChange}
                                        value={formData.branch_address}
                                        className={`form-input ${errors.branch_address
                                            ? "error"
                                            : inputState.branch_address
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.branch_address && (
                                        <span className="error-message font-size-text ">{errors.branch_address}</span>
                                    )}
                                </div>

                                <div title="Addhar card" className="flex-column">
                                    <label htmlFor="aadhaar" className="form-labels  font-weight500    font-size-subheading">
                                        Aadhaar Number
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="aadhaar"
                                        name="aadhaar"
                                        placeholder="Aadhaar card"
                                        onChange={handleInputChange}
                                        value={formData.aadhaar}
                                        className={`form-input ${errors.aadhaar
                                            ? "error"
                                            : inputState.aadhaar
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.aadhaar && (
                                        <span className="error-message font-size-text ">{errors.aadhaar}</span>
                                    )}
                                </div>

                                <div title="uan" className="flex-column">
                                    <label htmlFor="uan" className="form-labels  font-weight500    font-size-subheading">
                                        UAN
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="uan"
                                        name="uan"
                                        placeholder="uan"
                                        onChange={handleInputChange}
                                        value={formData.uan}
                                        className={`form-input ${errors.uan
                                            ? "error"
                                            : inputState.uan
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.uan && (
                                        <span className="error-message font-size-text ">{errors.uan}</span>
                                    )}
                                </div>

                                <div title="pan card" className="flex-column">
                                    <label htmlFor="pan" className="form-labels  font-weight500    font-size-subheading">
                                        PAN Number
                                    </label>
                                    <input
                                        type="text"
                                        maxLength={100}
                                        id="pan"
                                        name="pan"
                                        placeholder="pan card"
                                        onChange={handleInputChange}
                                        value={formData.pan}
                                        className={`form-input ${errors.pan
                                            ? "error"
                                            : inputState.pan
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.pan && (
                                        <span className="error-message font-size-text ">{errors.pan}</span>
                                    )}
                                </div>

                                <div title="Current Address" className="flex-column">
                                    <label htmlFor="current_address" className="form-labels  font-weight500    font-size-subheading">
                                        Current Address<span className="required">*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        id="current_address"
                                        name="current_address"
                                        maxLength={300}
                                        placeholder="Current Address"
                                        onChange={handleInputChange}
                                        value={formData.current_address}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.current_address
                                            ? "error"
                                            : inputState.current_address
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.current_address && (
                                        <span className="error-message font-size-text ">{errors.current_address}</span>
                                    )}
                                </div>

                                <div title="Emergency Address" className="flex-column">
                                    <label htmlFor="emergency_address" className="form-labels  font-weight500    font-size-subheading">
                                        Emergency Address<span className="required">*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        id="emergency_address"
                                        name="emergency_address"
                                        maxLength={300}
                                        placeholder="Emergency Address"
                                        onChange={handleInputChange}
                                        value={formData.emergency_address}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.emergency_address
                                            ? "error"
                                            : inputState.emergency_address
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emergency_address && (
                                        <span className="error-message font-size-text ">{errors.emergency_address}</span>
                                    )}
                                </div>




                            </div>

                            <div className="button-models">

                                <button className="model-button-cancel model-button   font-weight500   " onClick={(e) => handleClose()}>
                                    Cancel
                                </button>
                                <button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >{/* //loading logic */}
                                    Update
                                </button>
                            </div>

                            <p className="error-message font-size-text">
                                {isSubmitted && (
                                    Object.keys(errors).length > 5 ? (
                                        <h5 className="text-center"> 'Please fill all mandatory fields!'</h5>

                                    ) : (
                                        Object.keys(errors).map((field) =>
                                            field.charAt(0).toUpperCase() + field.slice(1)
                                        ).join(', ') + ' are required!'
                                    )
                                )}
                            </p>
                        </form>
                    </>

                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>

        </>
    );
};

const DeleteSiteEmployee = ({ i, getSiteEmployee }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.delete(`${BASE_URL}/siteproject/employee/${i.id}/`);

            if (res.status === 200) {
                await getSiteEmployee();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button title="Delete Site Designation" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Employee {i.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete {i.name}'s data?
                        <div className="button-models">
                            <button className="model-button   font-weight500   model-button-cancel " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};


const AddBOQEmployee = ({ projectId, getEmployee, professional_type, designation }) => {

};


// ? *************** Employee Crud Models End ***************



const EmployeeDetailCard = ({ data, getSiteEmployee, siteDepartmentList, siteProjectList, siteSubCompanyList }) => {
    const { siteRole } = usePermission();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const navigate = useNavigate()
    const Viewdetails = (emp_code) => {
        navigate(`/${urlParts[3]}/siteemployeedetail/${emp_code}/`)
    }

    return (
        <div className='employee-cards height-73vh table-box' >

            {data?.sort((a, b) => a.emp_code - b.emp_code).map((e, index) => {
                return (
                    <div className='employee-card'>
                        <div className='employee-card-a'>



                            <div className='employee-card-image'>
                                {/* <div className='employee-card-image-background'>
                    <img src={e.profilepic != "" ? `${IMAGE_URL}${e.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="card-pic" className="employee-card-imageee" />
                  </div> */}
                                <div className='employee-card-icons'>
                                    <div className='employee-card-name font-weight500   font-size-subheading'>{e.name}</div>
                                    <div className='employee-card-designation font-weight400   font-size-subheading'>{e.designation_name}</div>
                                    <div className="employee-card-name font-weight500   font-size-subheading">Emp Code: {e.emp_code}</div>


                                </div>

                            </div>

                            <div title="Edit" className="flex-row">
                                {/* {siteRole==="SiteManagement"?"":
                          
                                <UpdateSiteEmployee
                                    i={e}
                                    getSiteEmployee={getSiteEmployee}
                                    siteProjectList={siteProjectList}
                                    siteDepartmentList={siteDepartmentList}
                                    siteSubCompanyList={siteSubCompanyList}
                                />
                          }      */}

                                <UpdateSiteEmployee
                                    i={e}
                                    getSiteEmployee={getSiteEmployee}
                                    siteProjectList={siteProjectList}
                                    siteDepartmentList={siteDepartmentList}
                                    siteSubCompanyList={siteSubCompanyList}
                                />
                                <button className='employee-edit' onClick={() => Viewdetails(e.emp_code)} title="View Profile">
                                    <Tripledot />
                                </button>

                                {siteRole === "Admin" ? <DeleteSiteEmployee
                                    i={e}
                                    getSiteEmployee={getSiteEmployee}
                                /> : ""}
                            </div>
                        </div>
                        <div className='employee-card-b'>
                            <div className='employee-card-b-flex'>
                                <div className="cart-subdiv-flex" ><span className='employee-card-b-heading  font-weight400  font-size-label'>Project  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>{e.project_short_name}</span></div>
                                <div className="cart-subdiv-flex" ><span className='employee-card-b-heading  font-weight400  font-size-label'>Email  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>{e.email}</span></div>

                            </div>
                            <div className='employee-card-b-flex'>
                                <div className="cart-subdiv-flex" ><span className='employee-card-b-heading  font-weight400  font-size-label'>Profession  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>{e.professional_type}</span></div>
                                <div className="cart-subdiv-flex" ><span className='employee-card-b-heading  font-weight400  font-size-label'>Mobile No :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>{e.mobile}</span></div>
                            </div>

                        </div>


                    </div >

                )
            })}




        </div >)
}




export {
    AddSiteDepartment,
    UpdateSiteDepartment,
    DeleteSiteDepartment,

    AddSiteSubcompanys,
    UpdateSiteSubcompanyupdate,
    DeleteSiteSubcompany,

    UploadSiteEmployee,
    AddSiteEmployee,

    AddBOQEmployee,
    UpdateSiteEmployee,
    DeleteSiteEmployee,


    EmployeeDetailCard



};
