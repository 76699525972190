// axiosInstance.js

import axios from 'axios';
import { BASE_URL } from './axios';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

export default axiosInstance;
