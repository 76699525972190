import React, { useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { BASE_URL } from "../../../config/axios";
// import usePermission from "../../../config/permissions";
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import { handleErrorToast } from "../../CustomFunctions";
import { AddwithWhiteCircle, DeleteDustbin, UpdatePencil } from "../../AllSvg";
import { AddBOQEmployee } from "../../Wfm/WorkforceCrudButton";



const AddBreakdownOfCosts = ({ id, getBreakdowncost }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false); // loading state\
    const [showCreateEmployeeModal, setShowCreateEmployeeModal] = useState(false);

    // Initial form data state
    const [formData, setFormData] = useState({
        rate: "",
        MM_construction_period: "",
        MM_OM_period: "",
        project: id,
        professional_type: null,
        designation: "",
        empcode: [],
    });

    // Error and input state
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    // Open modal and fetch necessary data
    const handleShow = () => {
        setShow(true);
        getEmployee("null");
        getDesignation();
    };

    // Close modal
    const handleClose = () => setShow(false);

    // Validate form fields
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["rate", "designation"]; // Add other required fields if needed

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Check if the field is numeric and update accordingly
        let parsedValue = value;

        if (name === "rate" || name === "MM_construction_period" || name === "MM_OM_period") {
            parsedValue = value !== "" ? parseInt(value, 10) : "";
            if (isNaN(parsedValue) || parsedValue < 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Please enter a valid positive number.",
                }));
                parsedValue = "";
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
            }
        }

        // Update form data state
        setFormData((prevState) => ({
            ...prevState,
            [name]: parsedValue,
        }));

        // Conditionally call getEmployee() if the name is "professional_type"
        if (name === "professional_type") {
            getEmployee(value);
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); // Start loading
            const loadingToastId = toast.loading("Loading: Please wait..."); // Display loading toast

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/boqemployee/`, formData);

                if (res.status === 200) {
                    await getBreakdowncost(); // Refresh data
                    setShow(false); // Close modal
                    window.location.reload(); // Reload page if needed
                } else {
                    alert(res); // Handle non-200 status codes
                }
            } catch (err) {
                handleErrorToast(err); // Handle errors
            } finally {
                setLoading(false); // Stop loading
                toast.dismiss(loadingToastId); // Dismiss loading toast
            }
        }
    };


    //! **************************** Get API  ***********************!//
    const [employeeList, setEmployeeList] = useState([]);
    // const [ setBuffering] = useState(true);

    const [designation, setDesignation] = useState([]);


    const getEmployee = async (value) => {
        // setBuffering(true); //buffering logic // Start Buffering
        try {
            const pro = await axios.get(
                `${BASE_URL}/siteproject/employeeboqbyproject/${id}/${value}/`
            );
            setEmployeeList(pro.data);
            console.log(pro.data)
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            // setBuffering(false); // End Buffering
        }
    };

    const getDesignation = async () => {
        // setBuffering(true); //buffering logic // Start Buffering
        try {
            const pro = await axios.get(
                `${BASE_URL}/siteproject/designation/`
            );
            setDesignation(pro.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            // setBuffering(false); // End Buffering
        }
    };
    //! **************************** Get API  End ***********************!//



    const options = [
        {
            value: 'create-new-employee',
            label: 'Create New Employee',
        },
        ...employeeList
            .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
            .map((e) => ({
                value: e.emp_code,
                label: `${e.emp_code} - ${e.name}`,
            })),
    ];



    const handleChangecc = (selected) => {
        const empcode = selected.length > 0 ? selected.map((item) => item.value) : "";

        if (empcode.includes('create-new-employee')) {
            setShowCreateEmployeeModal(true);
        } else {
            setFormData((prevState) => ({
                ...prevState,
                empcode,
            }));
        }
    };



    return (
        <>
            <button title="Designation" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Designation
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Designation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div
                                    title="emp_code"
                                    className="flex-column form-group-selectt"
                                >
                                    <label
                                        htmlFor="designation"
                                        className="form-labels  font-weight500    font-size-subheading"
                                    >
                                        Designation<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="designation"
                                        name="designation"
                                        placeholder="designation"
                                        value={formData.designation}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.designation
                                            ? "error"
                                            : inputState.designation
                                                ? "success"
                                                : ""
                                            }`}
                                    >
                                        <option value="">Select Designation</option>
                                        {designation
                                            ?.sort((a, b) =>
                                                a.title.localeCompare(b.title, "en", {
                                                    ignorePunctuation: true,
                                                })
                                            )
                                            .map((i, index) => (
                                                <option key={index} value={i.id}>
                                                    {i.title}
                                                    {i.name}
                                                </option>
                                            ))}
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.project && (
                                        <span className="error-message font-size-text ">
                                            {errors.project}
                                        </span>
                                    )}
                                </div>

                                <div title="Professional Type" className="flex-column form-group-selectt">
                                    <label htmlFor="professional_type" className="form-labels  font-weight500    font-size-subheading">
                                        Professional Type<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="professional_type"
                                        name="professional_type"
                                        value={formData.professional_type}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.professional_type ? "error" : inputState.professional_type ? "success" : ""
                                            }`}
                                    >
                                        <option value="null">Select a Professional </option>
                                        <option value="Key Professional">Key Professional</option>
                                        <option value="Sub Professional">Sub Professional</option>
                                        <option value="Support Staff">Support Staff</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.professional_type && (
                                            <span className="error-message font-size-text ">{errors.professional_type}</span>
                                        )}
                                    </div>
                                </div>


                                <div className="flex-column">
                                    <label
                                        htmlFor="names"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Name
                                    </label>
                                    <Select
                                        options={options}
                                        isMulti
                                        placeholder="Select Name"
                                        onChange={handleChangecc}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                minHeight: '50px',
                                                borderRadius: '10px',
                                                border: '1px solid #e3e3e3',
                                                margin: '10px 0px 20px 0px',
                                                color: '#4a4a4a',
                                                backgroundColor: '#f7f8fa',
                                                padding: '0px 10px',
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                width: '33vw',
                                                borderRadius: '10px',
                                                marginTop: '0',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#4a4a4a',
                                            }),
                                        }}
                                    />

                                </div>


                                {showCreateEmployeeModal && <AddBOQEmployee projectId={id} getEmployee={getEmployee} professional_type={formData.professional_type} designation={formData.designation} />}



                                <div className="flex-column">
                                    <label
                                        htmlFor="rate"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate (Rs.)
                                    </label>
                                    <input
                                        id="rate"
                                        type="number"
                                        min={0}
                                        name="rate"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate"
                                        onChange={handleInputChange}
                                        value={formData.rate}
                                        className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="MM_construction_period"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        MM Construction Period
                                    </label>
                                    <input
                                        id="MM_construction_period"
                                        type="number"
                                        name="MM_construction_period"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="MM Construction Period"
                                        onChange={handleInputChange}
                                        value={formData.MM_construction_period}
                                        className={`form-input ${errors.MM_construction_period
                                            ? "error"
                                            : inputState.MM_construction_period
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.MM_construction_period && (
                                        <span className="error-message font-size-text ">
                                            {errors.MM_construction_period}
                                        </span>
                                    )}
                                </div>



                                <div className="flex-column">
                                    <label
                                        htmlFor="MM_OM_period"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        MM OM Period
                                    </label>
                                    <input
                                        id="MM_OM_period"
                                        type="number"
                                        name="MM_OM_period"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="MM OM Period"
                                        onChange={handleInputChange}
                                        value={formData.MM_OM_period}
                                        className={`form-input ${errors.MM_OM_period
                                            ? "error"
                                            : inputState.MM_OM_period
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.MM_OM_period && (
                                        <span className="error-message font-size-text ">
                                            {errors.MM_OM_period}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="No._of_Man_Months"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of Man-Months
                                    </label>
                                    <input
                                        id="No._of_Man_Months"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        min={0}
                                        placeholder="MM OM Period"
                                        className="form-input"
                                        value={(formData.MM_OM_period || 0) + (formData.MM_construction_period || 0)} // Calculate and display the sum
                                        readOnly // Make the input read-only

                                    />
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};



const AddSubprofessionalStaff = ({ getSubprofesstion }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        keyPersonnel: "",
        names: "",
        rate: "",
        num_of_man_month: "",
        amount: "",

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["names", "rate", "num_of_man_month", "amount"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqemployee/`,
                    formData
                );

                if (res.status === 200) {
                    await getSubprofesstion();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Add Item"
                className="models-button model-add"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="keyPersonnel"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Position
                                    </label>
                                    <input
                                        id="keyPersonnel"
                                        type="keyPersonnel"
                                        name="keyPersonnel"

                                        placeholder="Professional type"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.keyPersonnel
                                            ? "error"
                                            : inputState.keyPersonnel
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.keyPersonnel && (
                                        <span className="error-message font-size-text ">
                                            {errors.keyPersonnel}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="names"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Name
                                    </label>
                                    <input
                                        id="names"
                                        type="names"
                                        name="names"

                                        placeholder="Name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.names ? "error" : inputState.names ? "success" : ""
                                            }`}
                                    />
                                    {errors.names && (
                                        <span className="error-message font-size-text ">
                                            {errors.names}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Staff Months
                                    </label>
                                    <input
                                        id="rate"
                                        type="number"
                                        min={0}
                                        name="rate"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_man_month"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Billing Rate(Rs.)
                                    </label>
                                    <input
                                        id="num_of_man_month"
                                        type="number"
                                        min={0}
                                        name="num_of_man_month"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Number of man month"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.num_of_man_month
                                            ? "error"
                                            : inputState.num_of_man_month
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.num_of_man_month && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_man_month}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="amount"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Amount (Rs.)
                                    </label>
                                    <input
                                        id="amount"
                                        type="number"
                                        min={0}
                                        name="amount"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Amount"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.amount
                                            ? "error"
                                            : inputState.amount
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.amount && (
                                        <span className="error-message font-size-text ">
                                            {errors.amount}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const AddSupportStaff = ({ getSupportStaff }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        keyPersonnel: "",
        names: "",
        rate: "",
        num_of_man_month: "",
        amount: "",

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["names", "rate", "num_of_man_month", "amount"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqemployee/`,
                    formData
                );

                if (res.status === 200) {
                    await getSupportStaff();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Add Item"
                className="models-button model-add"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="keyPersonnel"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Position
                                    </label>
                                    <input
                                        id="keyPersonnel"
                                        type="keyPersonnel"
                                        name="keyPersonnel"

                                        placeholder="Professional type"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.keyPersonnel
                                            ? "error"
                                            : inputState.keyPersonnel
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.keyPersonnel && (
                                        <span className="error-message font-size-text ">
                                            {errors.keyPersonnel}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="names"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Name
                                    </label>
                                    <input
                                        id="names"
                                        type="names"
                                        name="names"

                                        placeholder="Name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.names ? "error" : inputState.names ? "success" : ""
                                            }`}
                                    />
                                    {errors.names && (
                                        <span className="error-message font-size-text ">
                                            {errors.names}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Staff Months
                                    </label>
                                    <input
                                        id="rate"
                                        type="number"
                                        min={0}
                                        name="rate"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_man_month"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Billing Rate(Rs.)
                                    </label>
                                    <input
                                        id="num_of_man_month"
                                        type="number"
                                        min={0}
                                        name="num_of_man_month"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Number of man month"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.num_of_man_month
                                            ? "error"
                                            : inputState.num_of_man_month
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.num_of_man_month && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_man_month}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="amount"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Amount (Rs.)
                                    </label>
                                    <input
                                        id="amount"
                                        type="number"
                                        min={0}
                                        name="amount"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Amount"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.amount
                                            ? "error"
                                            : inputState.amount
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.amount && (
                                        <span className="error-message font-size-text ">
                                            {errors.amount}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UpdateBreakdownOfCosts = ({ i, id, getBreakdowncost }) => {
    const [show, setShow] = useState(false);

    console.log("i")
    console.log(i)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getEmployee(i.professional_type);
        getDesignation();
        setFormData(i)
    };

    const [formData, setFormData] = useState({
        rate: "",
        num_of_man_month: "",
        project: id,
        professional_type: null,
        designation: "",
        empcode: []


        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    console.log(errors)

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqemployeeupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    // await getBreakdowncost();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;

    //     // Update input state based on the trimmed value
    //     setInputState({
    //         ...inputState,
    //         [name]: value.trim() ? "green" : "",
    //     });

    //     // Update form data state
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });

    //     // Conditionally call getEmployee() if name is "professional_type"
    //     if (name === "professional_type") {
    //         getEmployee(value);
    //     }
    // };
    //! **************************** Get API  ***********************!//
    const [employeeList, setEmployeeList] = useState([]);
    const [buffer, setBuffering] = useState(true);

    const [designation, setDesignation] = useState([]);


    const getEmployee = async (value) => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const pro = await axios.get(
                `${BASE_URL}/siteproject/employeeboqbyproject/${id}/${value}/`
            );
            setEmployeeList(pro.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    const getDesignation = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const pro = await axios.get(
                `${BASE_URL}/siteproject/designation/`
            );
            setDesignation(pro.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };
    //! **************************** Get API  End ***********************!//

    const options = employeeList
        .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
        .map((e) => ({
            value: e.emp_code,
            label: `${e.emp_code} - ${e.name}`,
        }));

    const handleChangecc = (selected) => {
        const empcode = selected.length > 0 ? selected.map((item) => item.value) : "";
        setFormData((prevState) => ({
            ...prevState,
            empcode,

        }));
    };

    return (
        <>
            <button
                title="Update Designation"
                className="models-button model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Employees for : {i.professional_type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-column">
                                <label
                                    htmlFor="names"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Name
                                </label>
                                <Select
                                    options={options}
                                    isMulti
                                    onChange={handleChangecc}
                                    placeholder="Select Name"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '33vw',
                                            minHeight: '50px',
                                            borderRadius: '10px',
                                            border: '1px solid #e3e3e3',
                                            margin: '10px 0px 20px 0px',
                                            color: '#4a4a4a',
                                            backgroundColor: '#f7f8fa',
                                            padding: '0px 10px',
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: '33vw',
                                            borderRadius: '10px',
                                            marginTop: '0',
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                        input: (provided) => ({
                                            ...provided,
                                            color: '#4a4a4a',
                                        }),
                                    }}
                                />
                                {errors.remarks && (
                                    <span className="error-message font-size-text ">{errors.remarks}</span>
                                )}
                            </div>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};







const AddOfficeFurniture = ({ id, getOfficeFurniture }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        num_of_months: "",
        rate_per_month: "",
        project: id,

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["num_of_months", "rate_per_month"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqofficefurnitureandequipment/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeFurniture();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Office Furniture" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Office Furniture
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Office Furniture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_months"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of Months
                                    </label>
                                    <input
                                        id="num_of_months"
                                        type="number"
                                        min={0}
                                        name="num_of_months"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Months"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.num_of_months
                                            ? "error"
                                            : inputState.num_of_months
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.num_of_months && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_months}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_month"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate per Month
                                    </label>
                                    <input
                                        id="rate_per_month"
                                        type="numnber"
                                        name="rate_per_month"

                                        placeholder="Rate Per Month"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.rate_per_month ? "error" : inputState.rate_per_month ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_month && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_month}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const AddReportsPrinting = ({ id, getReportsPrinting }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        description: "",
        num_of_reports: "",
        num_of_copies_per_report: "",
        rate_per_copy: "",
        project: id

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["description", "num_of_reports", "num_of_copies_per_report", "rate_per_copy"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqreportsanddocprint/`,
                    formData
                );

                if (res.status === 200) {
                    await getReportsPrinting();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Report & Document" className="upload-svg flex-row" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Report & Doc
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Report & Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="description"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Description<span className="required">*</span>
                                    </label>
                                    <input
                                        id="description"
                                        type="text"
                                        name="description"
                                        maxLength={300}
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                        value={formData.description}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.description
                                            ? "error"
                                            : inputState.description
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.description && (
                                        <span className="error-message font-size-text ">
                                            {errors.description}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_reports"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of Reports<span className="required">*</span>
                                    </label>
                                    <input
                                        id="num_of_reports"
                                        type="number"
                                        name="num_of_reports"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Reports"
                                        onChange={handleInputChange}
                                        value={formData.num_of_reports}
                                        className={`form-input ${errors.num_of_reports ? "error" : inputState.num_of_reports ? "success" : ""
                                            }`}
                                    />
                                    {errors.num_of_reports && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_reports}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_copies_per_report"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of Copies per Report<span className="required">*</span>
                                    </label>
                                    <input
                                        id="num_of_copies_per_report"
                                        type="number"
                                        min={0}
                                        name="num_of_copies_per_report"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Copies per Report"
                                        onChange={handleInputChange}
                                        value={formData.num_of_copies_per_report}
                                        className={`form-input ${errors.num_of_copies_per_report ? "error" : inputState.num_of_copies_per_report ? "success" : ""
                                            }`}
                                    />
                                    {errors.num_of_copies_per_report && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_copies_per_report}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_copy"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate per Copy<span className="required">*</span>
                                    </label>
                                    <input
                                        id="rate_per_copy"
                                        type="number"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                        name="rate_per_copy"

                                        placeholder="Rate per Copy"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_copy}
                                        className={`form-input ${errors.rate_per_copy
                                            ? "error"
                                            : inputState.rate_per_copy
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.rate_per_copy && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_copy}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const AddRoadSurveyEquipment = ({ id, getRoadSurveyEquipment }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        KM: "",
        description: "",
        num_of_times_survey_concluded: "",
        rate_per_km_times: "",
        project: id
        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["description", "num_of_times_survey_concluded", "rate_per_km_times"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqroadsurveyequipment/`,
                    formData
                );

                if (res.status === 200) {
                    await getRoadSurveyEquipment();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Road Survey" className="upload-svg flex-row" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Road Survey
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Road Survey</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="description"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Description
                                    </label>
                                    <input
                                        id="description"
                                        type="text"
                                        name="description"
                                        maxLength={300}
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                        value={formData.description}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.description
                                            ? "error"
                                            : inputState.description
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.description && (
                                        <span className="error-message font-size-text ">
                                            {errors.description}
                                        </span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label
                                        htmlFor="KM"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        KM
                                    </label>
                                    <input
                                        id="KM"
                                        type="number"
                                        min={0}
                                        name="KM"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="KM"
                                        onChange={handleInputChange}
                                        value={formData.KM}
                                        className={`form-input ${errors.KM ? "error" : inputState.KM ? "success" : ""
                                            }`}
                                    />
                                    {errors.KM && (
                                        <span className="error-message font-size-text ">
                                            {errors.KM}
                                        </span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_times_survey_concluded"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of times Survey Concluded
                                    </label>
                                    <input
                                        id="num_of_times_survey_concluded"
                                        type="number"
                                        min={0}
                                        name="num_of_times_survey_concluded"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of times Survey Concluded"
                                        onChange={handleInputChange}
                                        value={formData.num_of_times_survey_concluded}
                                        className={`form-input ${errors.num_of_times_survey_concluded ? "error" : inputState.num_of_times_survey_concluded ? "success" : ""
                                            }`}
                                    />
                                    {errors.num_of_times_survey_concluded && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_times_survey_concluded}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_km_times"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate per No. of times Survey to be Conducted
                                    </label>
                                    <input
                                        id="rate_per_km_times"
                                        type="number"
                                        min={0}
                                        name="rate_per_km_times"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate per No. of times Survey to be Conducted"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_km_times}
                                        className={`form-input ${errors.rate_per_km_times ? "error" : inputState.rate_per_km_times ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_km_times && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_km_times}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const AddContingencies = ({ id, getContingencies }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        description: "",
        unit: "",
        ammount: "",
        project: id

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["description", "unit", "ammount"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqcontingencies/`,
                    formData
                );

                if (res.status === 200) {
                    await getContingencies();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Contingencies" className="upload-svg flex-row" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Contingencies
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Contingencies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="description"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Description
                                    </label>
                                    <input
                                        id="description"
                                        type="text"
                                        name="description"
                                        maxLength={300}
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                        value={formData.description}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.description
                                            ? "error"
                                            : inputState.description
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.description && (
                                        <span className="error-message font-size-text ">
                                            {errors.description}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="unit"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Units
                                    </label>
                                    <input
                                        id="unit"
                                        type="text"
                                        name="unit"

                                        placeholder="Units"
                                        onChange={handleInputChange}
                                        value={formData.unit}
                                        className={`form-input ${errors.unit ? "error" : inputState.unit ? "success" : ""
                                            }`}
                                    />
                                    {errors.unit && (
                                        <span className="error-message font-size-text ">
                                            {errors.unit}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="ammount"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Amount
                                    </label>
                                    <input
                                        id="ammount"
                                        type="number"
                                        min={0}
                                        name="ammount"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Amount"
                                        onChange={handleInputChange}
                                        value={formData.ammount}
                                        className={`form-input ${errors.ammount ? "error" : inputState.ammount ? "success" : ""
                                            }`}
                                    />
                                    {errors.ammount && (
                                        <span className="error-message font-size-text ">
                                            {errors.ammount}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const AddOfficeFurnitureHeading = ({ id, getOfficeFurnitureheading }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        BOQoffice_furnitur_equipment: "",
        project: id,

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["BOQoffice_furnitur_equipment"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqheadingtable/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeFurnitureheading();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Add Office Furniture Heading Description" className="upload-svg flex-row" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {" "} Description
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Office Furniture Heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="boqheadingtable"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Heading Description
                                    </label>
                                    <textarea
                                        id="BOQoffice_furnitur_equipment"
                                        name="BOQoffice_furnitur_equipment"
                                        type="text"
                      
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                        value={formData.BOQoffice_furnitur_equipment}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.boqheadingtable
                                            ? "error"
                                            : inputState.boqheadingtable
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.BOQoffice_furnitur_equipment && (
                                        <span className="error-message font-size-text ">
                                            {errors.BOQoffice_furnitur_equipment}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ! ********************************* Update & Delete Button ****************************


const DeleteBreakdownOfCosts = ({ i, getBreakdownCost }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqemployeeupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getBreakdownCost();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Key Professional" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Key Professional</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete "{i.designation_name}"</h1>
                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ************************************************************************
// const UpdateSubprofessionalStaff = ({ i, getBreakdownCost }) => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => {
//         setShow(true);
//         setFormData(i);
//     };

//     const [formData, setFormData] = useState({
//         vehicle_description: "",
//         during_construction_period: "",
//         during_dlp_OM_period: "",
//         rate: "",
//         project: projectId2,

//         // sub_company: sessionStorage.getItem('company_id')
//     });

//     // !  ************** Validation start **************  ! //

//     const [errors, setErrors] = useState({});
//     const [inputState, setInputState] = useState({});

//     const validateForm = () => {
//         const newErrors = {};
//         const requiredFields = [
//             "vehicle_description",
//             "during_construction_period",
//             "during_dlp_OM_period",
//             "rate",
//         ];
//         requiredFields.forEach((field) => {
//             if (!formData[field]) {
//                 newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
//                     } is required !`;
//             }
//         });

//         setErrors(newErrors);

//         return Object.keys(newErrors).length === 0;
//     };

//     // ?  ************** Validation End **************  ! //

//     //! **************************** post API ***********************!//
//     const [loading, setLoading] = useState(false); //loading logic

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (validateForm()) {
//             setLoading(true); //loading logic
//             const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

//             try {
//                 let res = await axios.put(
//                     `${BASE_URL}/siteproject/boqtransportupdate/${i.id}/`,
//                     formData
//                 );

//                 if (res.status === 200) {
//                     await getBreakdownCost();
//                     setShow(false);
//                 } else {
//                     alert(res);
//                 }
//             } catch (err) {
//                 //toast Logic
//                 if (err.response) {
//                     toast.dismiss(loadingToastId);
//                     const errorData = err.response.data;

//                     if (typeof errorData.error === "string") {
//                         // Single error message
//                         toast.error(`Error: ${errorData.error}`);
//                     } else if (typeof errorData === "object" && errorData !== null) {
//                         // Multiple error messages
//                         Object.entries(errorData).forEach(([field, messages]) => {
//                             messages.forEach((message) =>
//                                 toast.error(`"${field}": ${message}`)
//                             );
//                         });
//                     } else {
//                         toast.error("Error:- Failed to Process!");
//                     }
//                 } else {
//                     toast.error("Error processing your request.");
//                 }
//             } finally {
//                 setLoading(false); //loading logic
//                 toast.dismiss(loadingToastId);
//             }
//         }
//     };

//     //! **************************** post API End ***********************!//

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;

//         setInputState({
//             ...inputState,
//             [name]: value.trim() ? "green" : "",
//         });

//         setFormData({
//             ...formData,
//             [name]: value,
//         });
//     };

//     return (
//         <>
//             <button
//                 title="Update Item"
//                 className="model-edit-button"
//                 onClick={handleShow}
//             >
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="18"
//                     height="18"
//                     viewBox="0 0 18 18"
//                     fill="none"
//                 >
//                     <path
//                         d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
//                         fill="#145650"
//                     />
//                 </svg>
//             </button>

//             <Modal
//                 show={show}
//                 onHide={handleClose}
//                 dialogClassName="request-leave width-40vw"
//             >
//                 <Modal.Body>
//                     <div className="parent-div">
//                         <div className="bdy-div">
//                             {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

//                             <form>
//                                 <div className="flex-column">
//                                     <label
//                                         htmlFor="vehicle_description"
//                                         className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                     >
//                                         Description of Vehicles
//                                     </label>
//                                     <input
//                                         id="vehicle_description"
//                                         type="vehicle_description"
//                                         name="vehicle_description"
//                                         
//                                         placeholder="Vehicle description"
//                                         onChange={handleInputChange}
//                                         value={formData.vehicle_description}
//                                         className={`form-input ${errors.vehicle_description
//                                             ? "error"
//                                             : inputState.vehicle_description
//                                                 ? "success"
//                                                 : ""
//                                             }`}
//                                     />
//                                     {errors.vehicle_description && (
//                                         <span className="error-message font-size-text ">
//                                             {errors.vehicle_description}
//                                         </span>
//                                     )}
//                                 </div>

//                                 <div className="flex-column">
//                                     <label
//                                         htmlFor="during_construction_period"
//                                         className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                     >
//                                         Duration Construction Period{" "}
//                                         <span className="required">*</span>
//                                     </label>
//                                     <input
//                                         id="during_construction_period"
//                                         type="number"
//                                         name="during_construction_period"
//                                         
//                                         placeholder="Construction Period"
//                                         onChange={handleInputChange}
//                                         value={formData.during_construction_period}
//                                         className={`form-input ${errors.during_construction_period
//                                             ? "error"
//                                             : inputState.during_construction_period
//                                                 ? "success"
//                                                 : ""
//                                             }`}
//                                     />
//                                     {errors.during_construction_period && (
//                                         <span className="error-message font-size-text ">
//                                             {errors.during_construction_period}
//                                         </span>
//                                     )}
//                                 </div>

//                                 <div className="flex-column">
//                                     <label
//                                         htmlFor="during_dlp_OM_period"
//                                         className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                     >
//                                         During DLP <span className="required">*</span>{" "}
//                                     </label>
//                                     <input
//                                         id="during_dlp_OM_period"
//                                         type="number"
//                                         min={0}
//                                         name="during_dlp_OM_period"
//                                         
//                                         placeholder="Number of months"
//                                         onChange={handleInputChange}
//                                         value={formData.during_dlp_OM_period}
//                                         className={`form-input ${errors.during_dlp_OM_period
//                                             ? "error"
//                                             : inputState.during_dlp_OM_period
//                                                 ? "success"
//                                                 : ""
//                                             }`}
//                                     />
//                                     {errors.during_dlp_OM_period && (
//                                         <span className="error-message font-size-text ">
//                                             {errors.during_dlp_OM_period}
//                                         </span>
//                                     )}
//                                 </div>

//                                 <div className="flex-column">
//                                     <label
//                                         htmlFor="rate"
//                                         className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                     >
//                                         Rates
//                                     </label>
//                                     <input
//                                         id="rate"
//                                         type="number"
//                                         min={0}
//                                         name="rate"
//                                         
//                                         placeholder="Rate"
//                                         onChange={handleInputChange}
//                                         value={formData.rate}
//                                         className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
//                                             }`}
//                                     />
//                                     {errors.rate && (
//                                         <span className="error-message font-size-text ">
//                                             {errors.rate}
//                                         </span>
//                                     )}
//                                 </div>
//                                 {/* 
//                                 <div className="flex-column">
//                                     <label htmlFor="amount" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
//                                         Amount (Rs.)
//                                     </label>
//                                     <input
//                                         id="amount"
//                                         type="number"
//                                         min={0}
//                                         name="amount"
//                                         
//                                         placeholder="Amount"
//                                         onChange={handleInputChange}
//                                         value={formData.name}
//                                         className={`form-input ${errors.amount ? "error" : inputState.amount ? "success" : ""
//                                             }`}
//                                     />
//                                     {errors.amount && (
//                                         <span className="error-message font-size-text ">{errors.amount}</span>
//                                     )}
//                                 </div> */}
//                             </form>
//                             <div className="button-models">
//                                 <button
//                                     className="model-button-cancel model-button   font-weight500   "
//                                     onClick={handleClose}
//                                 >
//                                     Cancel
//                                 </button>
//                                 <button
//                                     type="submit"
//                                     className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
//                                     onClick={handleSubmit}
//                                     disabled={loading}
//                                 >
//                                     Update
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                     <ToastContainer
//                         position="top-center"
//                         autoClose={1000}
//                         hideProgressBar={false}
//                         newestOnTop={true}
//                         closeOnClick
//                         rtl={false}
//                         pauseOnFocusLoss
//                         draggable
//                         pauseOnHover
//                     />
//                 </Modal.Body>
//             </Modal>
//         </>
//     );
// };

const DeleteSubprofessionalStaff = ({ i, getBreakdownCost }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqemployeeupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getBreakdownCost();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Sub Professional Staff" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Sub Professional Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500 font-size-heading">Are You Sure You Want to Delete "{i.designation_name}"</h1>
                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ************************************************************************
// const UpdateSupportStaff = ({ i, getBreakdownCost }) => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => {
//         setShow(true);
//         setFormData(i);
//     };

//     const [formData, setFormData] = useState({
//         vehicle_description: "",
//         during_construction_period: "",
//         during_dlp_OM_period: "",
//         rate: "",
//         project: projectId2,

//         // sub_company: sessionStorage.getItem('company_id')
//     });

//     // !  ************** Validation start **************  ! //

//     const [errors, setErrors] = useState({});
//     const [inputState, setInputState] = useState({});

//     const validateForm = () => {
//         const newErrors = {};
//         const requiredFields = [
//             "vehicle_description",
//             "during_construction_period",
//             "during_dlp_OM_period",
//             "rate",
//         ];
//         requiredFields.forEach((field) => {
//             if (!formData[field]) {
//                 newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
//                     } is required !`;
//             }
//         });

//         setErrors(newErrors);

//         return Object.keys(newErrors).length === 0;
//     };

//     // ?  ************** Validation End **************  ! //

//     //! **************************** post API ***********************!//
//     const [loading, setLoading] = useState(false); //loading logic

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (validateForm()) {
//             setLoading(true); //loading logic
//             const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

//             try {
//                 let res = await axios.put(
//                     `${BASE_URL}/siteproject/boqtransportupdate/${i.id}/`,
//                     formData
//                 );

//                 if (res.status === 200) {
//                     await getBreakdownCost();
//                     setShow(false);
//                 } else {
//                     alert(res);
//                 }
//             } catch (err) {
//                 //toast Logic
//                 if (err.response) {
//                     toast.dismiss(loadingToastId);
//                     const errorData = err.response.data;

//                     if (typeof errorData.error === "string") {
//                         // Single error message
//                         toast.error(`Error: ${errorData.error}`);
//                     } else if (typeof errorData === "object" && errorData !== null) {
//                         // Multiple error messages
//                         Object.entries(errorData).forEach(([field, messages]) => {
//                             messages.forEach((message) =>
//                                 toast.error(`"${field}": ${message}`)
//                             );
//                         });
//                     } else {
//                         toast.error("Error:- Failed to Process!");
//                     }
//                 } else {
//                     toast.error("Error processing your request.");
//                 }
//             } finally {
//                 setLoading(false); //loading logic
//                 toast.dismiss(loadingToastId);
//             }
//         }
//     };

//     //! **************************** post API End ***********************!//

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;

//         setInputState({
//             ...inputState,
//             [name]: value.trim() ? "green" : "",
//         });

//         setFormData({
//             ...formData,
//             [name]: value,
//         });
//     };

//     return (
//         <>
//             <button
//                 title="Update Item"
//                 className="model-edit-button"
//                 onClick={handleShow}
//             >
//                 <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="18"
//                     height="18"
//                     viewBox="0 0 18 18"
//                     fill="none"
//                 >
//                     <path
//                         d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
//                         fill="#145650"
//                     />
//                 </svg>
//             </button>

//             <Modal
//                 show={show}
//                 onHide={handleClose}
//                 dialogClassName="request-leave width-40vw"
//             >
//                 <Modal.Body>
//                     <div className="parent-div">
//                         <div className="bdy-div">
//                             {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

//                             <form>
//                                 <div className="flex-column">
//                                     <label
//                                         htmlFor="vehicle_description"
//                                         className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                     >
//                                         Description of Vehicles
//                                     </label>
//                                     <input
//                                         id="vehicle_description"
//                                         type="vehicle_description"
//                                         name="vehicle_description"
//                                         
//                                         placeholder="Vehicle description"
//                                         onChange={handleInputChange}
//                                         value={formData.vehicle_description}
//                                         className={`form-input ${errors.vehicle_description
//                                             ? "error"
//                                             : inputState.vehicle_description
//                                                 ? "success"
//                                                 : ""
//                                             }`}
//                                     />
//                                     {errors.vehicle_description && (
//                                         <span className="error-message font-size-text ">
//                                             {errors.vehicle_description}
//                                         </span>
//                                     )}
//                                 </div>

//                                 <div className="flex-column">
//                                     <label
//                                         htmlFor="during_construction_period"
//                                         className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                     >
//                                         Duration Construction Period{" "}
//                                         <span className="required">*</span>
//                                     </label>
//                                     <input
//                                         id="during_construction_period"
//                                         type="number"
//                                         name="during_construction_period"
//                                         
//                                         placeholder="Construction Period"
//                                         onChange={handleInputChange}
//                                         value={formData.during_construction_period}
//                                         className={`form-input ${errors.during_construction_period
//                                             ? "error"
//                                             : inputState.during_construction_period
//                                                 ? "success"
//                                                 : ""
//                                             }`}
//                                     />
//                                     {errors.during_construction_period && (
//                                         <span className="error-message font-size-text ">
//                                             {errors.during_construction_period}
//                                         </span>
//                                     )}
//                                 </div>

//                                 <div className="flex-column">
//                                     <label
//                                         htmlFor="during_dlp_OM_period"
//                                         className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                     >
//                                         During DLP <span className="required">*</span>{" "}
//                                     </label>
//                                     <input
//                                         id="during_dlp_OM_period"
//                                         type="number"
//                                         min={0}
//                                         name="during_dlp_OM_period"
//                                         
//                                         placeholder="Number of months"
//                                         onChange={handleInputChange}
//                                         value={formData.during_dlp_OM_period}
//                                         className={`form-input ${errors.during_dlp_OM_period
//                                             ? "error"
//                                             : inputState.during_dlp_OM_period
//                                                 ? "success"
//                                                 : ""
//                                             }`}
//                                     />
//                                     {errors.during_dlp_OM_period && (
//                                         <span className="error-message font-size-text ">
//                                             {errors.during_dlp_OM_period}
//                                         </span>
//                                     )}
//                                 </div>

//                                 <div className="flex-column">
//                                     <label
//                                         htmlFor="rate"
//                                         className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                     >
//                                         Rates
//                                     </label>
//                                     <input
//                                         id="rate"
//                                         type="number"
//                                         min={0}
//                                         name="rate"
//                                         
//                                         placeholder="Rate"
//                                         onChange={handleInputChange}
//                                         value={formData.rate}
//                                         className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
//                                             }`}
//                                     />
//                                     {errors.rate && (
//                                         <span className="error-message font-size-text ">
//                                             {errors.rate}
//                                         </span>
//                                     )}
//                                 </div>
//                                 {/* 
//                                 <div className="flex-column">
//                                     <label htmlFor="amount" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
//                                         Amount (Rs.)
//                                     </label>
//                                     <input
//                                         id="amount"
//                                         type="number"
//                                         min={0}
//                                         name="amount"
//                                         
//                                         placeholder="Amount"
//                                         onChange={handleInputChange}
//                                         value={formData.name}
//                                         className={`form-input ${errors.amount ? "error" : inputState.amount ? "success" : ""
//                                             }`}
//                                     />
//                                     {errors.amount && (
//                                         <span className="error-message font-size-text ">{errors.amount}</span>
//                                     )}
//                                 </div> */}
//                             </form>
//                             <div className="button-models">
//                                 <button
//                                     className="model-button-cancel model-button   font-weight500   "
//                                     onClick={handleClose}
//                                 >
//                                     Cancel
//                                 </button>
//                                 <button
//                                     type="submit"
//                                     className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
//                                     onClick={handleSubmit}
//                                     disabled={loading}
//                                 >
//                                     Update
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                     <ToastContainer
//                         position="top-center"
//                         autoClose={1000}
//                         hideProgressBar={false}
//                         newestOnTop={true}
//                         closeOnClick
//                         rtl={false}
//                         pauseOnFocusLoss
//                         draggable
//                         pauseOnHover
//                     />
//                 </Modal.Body>
//             </Modal>
//         </>
//     );
// };

const DeleteSupportStaff = ({ i, getBreakdownCost }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqemployeeupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getBreakdownCost();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Support Staff" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />

            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Support Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete "{i.designation_name}"</h1>
                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ************************************************************************

const AddTransportation = ({ id, getTransportation }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        vehicle_description: "",
        during_construction_period: "",
        during_dlp_OM_period: "",
        rate: "",
        project: id,

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "vehicle_description",
            "during_construction_period",
            "during_dlp_OM_period",
            "rate",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqtransport/`,
                    formData
                );

                if (res.status === 200) {
                    await getTransportation();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Transportation" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Transportation
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Transportaion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="vehicle_description"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Description of Vehicles<span className="required">*</span>
                                    </label>
                                    <input
                                        id="vehicle_description"
                                        type="text"
                                        name="vehicle_description"
                                        maxLength={300}
                                        placeholder="Vehicle Description"
                                        onChange={handleInputChange}
                                        value={formData.vehicle_description}
                                        className={`form-input   font-weight400  font-size-subheading ${errors.vehicle_description
                                            ? "error"
                                            : inputState.vehicle_description
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.vehicle_description && (
                                        <span className="error-message font-size-text ">
                                            {errors.vehicle_description}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="during_construction_period"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Duration Construction Period{" "}
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        id="during_construction_period"
                                        type="number"
                                        name="during_construction_period"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Construction Period"
                                        onChange={handleInputChange}
                                        value={formData.during_construction_period}
                                        className={`form-input ${errors.during_construction_period
                                            ? "error"
                                            : inputState.during_construction_period
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.during_construction_period && (
                                        <span className="error-message font-size-text ">
                                            {errors.during_construction_period}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="during_dlp_OM_period"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        During DLP <span className="required">*</span>{" "}
                                    </label>
                                    <input
                                        id="during_dlp_OM_period"
                                        type="number"
                                        min={0}
                                        name="during_dlp_OM_period"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Number of Months"
                                        onChange={handleInputChange}
                                        value={formData.during_dlp_OM_period}
                                        className={`form-input ${errors.during_dlp_OM_period
                                            ? "error"
                                            : inputState.during_dlp_OM_period
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.during_dlp_OM_period && (
                                        <span className="error-message font-size-text ">
                                            {errors.during_dlp_OM_period}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rates<span className="required">*</span>
                                    </label>
                                    <input
                                        id="rate"
                                        type="number"
                                        min={0}
                                        name="rate"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate"
                                        onChange={handleInputChange}
                                        value={formData.rate}
                                        className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate}
                                        </span>
                                    )}
                                </div>

                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};



const UpdateTransportation = ({ i, getTransportation }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        vehicle_description: "",
        during_construction_period: "",
        during_dlp_OM_period: "",
        rate: "",
        project: "",

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "vehicle_description",
            "during_construction_period",
            "during_dlp_OM_period",
            "rate",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqtransportupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getTransportation();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Vehicle"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >

                <Modal.Header closeButton>
                    <Modal.Title>Update Vehicle {i.vehicle_description}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">


                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="vehicle_description"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Description of Vehicles<span className="required">*</span>
                                    </label>
                                    <input
                                        id="vehicle_description"
                                        type="Description"
                                        name="vehicle_description"
                                        maxLength={300}
                                        placeholder="Vehicle description"
                                        onChange={handleInputChange}
                                        value={formData.vehicle_description}
                                        className={`form-input   font-weight400  font-size-subheading ${errors.vehicle_description
                                            ? "error"
                                            : inputState.vehicle_description
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.vehicle_description && (
                                        <span className="error-message font-size-text ">
                                            {errors.vehicle_description}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="during_construction_period"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Duration Construction Period
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        id="during_construction_period"
                                        type="number"
                                        name="during_construction_period"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Construction Period"
                                        onChange={handleInputChange}
                                        value={formData.during_construction_period}
                                        className={`form-input ${errors.during_construction_period
                                            ? "error"
                                            : inputState.during_construction_period
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.during_construction_period && (
                                        <span className="error-message font-size-text ">
                                            {errors.during_construction_period}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="during_dlp_OM_period"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        During DLP <span className="required">*</span>
                                    </label>
                                    <input
                                        id="during_dlp_OM_period"
                                        type="number"
                                        min={0}
                                        name="during_dlp_OM_period"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Number of months"
                                        onChange={handleInputChange}
                                        value={formData.during_dlp_OM_period}
                                        className={`form-input ${errors.during_dlp_OM_period
                                            ? "error"
                                            : inputState.during_dlp_OM_period
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.during_dlp_OM_period && (
                                        <span className="error-message font-size-text ">
                                            {errors.during_dlp_OM_period}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rates<span className="required">*</span>
                                    </label>
                                    <input
                                        id="rate"
                                        type="number"
                                        min={0}
                                        name="rate"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate"
                                        onChange={handleInputChange}
                                        value={formData.rate}
                                        className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate}
                                        </span>
                                    )}
                                </div>

                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteITransportation = ({ i, getTransportation }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqtransportupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getTransportation();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Transportation" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Transportation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete "{i.vehicle_description}"</h1>

                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// *************************************************************************


// const AddEmployeeBOQ = ({ id, getEmployee }) => {

//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => {
//         getEmployeeData();
//         setShow(true);
//     }

//     const [formData, setFormData] = useState({
//         empcode:"",
//         professional_type: "",
//         rate: "",
//         MM_construction_period: "",
//         MM_OM_period: "",
//         project: id,
//         eot: "",

//     });



//     const [errors, setErrors] = useState({});
//     const [inputState, setInputState] = useState({});
//     const [employeeList, setEmployeeList] = useState([]);
//     const validateForm = () => {
//         const newErrors = {};
//         const requiredFields = [
//             "empcode",
//             "MM_construction_period",
//             "MM_OM_period",
//             "rate",
//         ];
//         requiredFields.forEach((field) => {
//             if (!formData[field]) {
//                 newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
//                     } is required !`;
//             }
//         });

//         setErrors(newErrors);

//         return Object.keys(newErrors).length === 0;
//     };

//     const [loading, setLoading] = useState(false); //loading logic
//     const getEmployeeData = async () => {
//         try {
//             const res = await axios.get(
//                 `${BASE_URL}/siteproject/employeebyfilterss/${id}/null/null/null/null/`);
//             setEmployeeList(res.data);
//         } catch (err) { }
//     };



//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (validateForm()) {
//             setLoading(true); //loading logic
//             const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

//             try {
//                 let res = await axios.post(
//                     `${BASE_URL}/siteproject/boqemployee/`,
//                     formData
//                 );

//                 if (res.status === 200) {
//                     await getEmployee();
//                     setShow(false);
//                     window.location.reload();
//                 } else {
//                     alert(res);
//                 }
//             } catch (err) {
//                 //toast Logic
//                 handleErrorToast(err);
//             } finally {
//                 setLoading(false); //loading logic
//                 toast.dismiss(loadingToastId);
//             }
//         }
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;

//         setInputState({
//             ...inputState,
//             [name]: value.trim() ? "green" : "",
//         });

//         // For empcode, ensure it's handled as an array
//         if (name === "empcode") {
//             setFormData({
//                 ...formData,
//                 [name]: [value], // Wrap the selected value in an array
//             });
//         } else {
//             setFormData({
//                 ...formData,
//                 [name]: value,
//             });
//         }
//     };



//     return (
//         <>
//             <button title="Transportation" className="upload-svg flex-row" onClick={handleShow}>
//                 <AddwithWhiteCircle />
//                 {" "} Employee
//             </button>
//             <Modal
//                 show={show}
//                 onHide={handleClose}
//                 dialogClassName="request-leave width-40vw"
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>Add Employee BOQ</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="parent-div">
//                         <div className="bdy-div">
//                             <div title="Employee" className="flex-column form-group-selectt">
//                                 <label htmlFor="empcode" className="form-labels  font-weight500    font-size-subheading">
//                                     Employee<span className="required">*</span>
//                                 </label>
//                                 <br />
//                                 <select
//                                     id="empcode"
//                                     name="empcode"
//                                     placeholder="empcode"
//                                     value={formData.empcode[0] || "null"} // Access the first element of the array or set "null"
//                                     onChange={handleInputChange}
//                                     className={`form-input form-group-selection ${errors.empcode ? "error" : inputState.empcode ? "success" : ""}`}
//                                 >
//                                     <option value="null">Select an Employee</option>
//                                     {employeeList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
//                                         <option key={index} value={i.emp_code}>{i.emp_code} {i.name}</option>
//                                     ))}
//                                 </select>

//                                 <div className="form-group-selection-arrow">
//                                     <svg
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         width="20"
//                                         height="25"
//                                         fill="currentColor"
//                                         className="bi bi-caret-down-fill"
//                                         viewBox="0 0 16 16"
//                                     >
//                                         <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
//                                     </svg>
//                                 </div>
//                                 {errors.empcode && <span className="error-message font-size-text ">{errors.empcode}</span>}
//                             </div>

//                             <div className="flex-column">
//                                 <label
//                                     htmlFor="MM_construction_period"
//                                     className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                 >
//                                     Duration Construction Period{" "}
//                                     <span className="required">*</span>
//                                 </label>
//                                 <input
//                                     id="MM_construction_period"
//                                     type="number"
//                                     name="MM_construction_period"
//                                     onWheel={(e) => e.target.blur()}

//                                     placeholder="Construction Period"
//                                     onChange={handleInputChange}
//                                     value={formData.MM_construction_period}
//                                     className={`form-input ${errors.MM_construction_period
//                                         ? "error"
//                                         : inputState.MM_construction_period
//                                             ? "success"
//                                             : ""
//                                         }`}
//                                 />
//                                 {errors.MM_construction_period && (
//                                     <span className="error-message font-size-text ">
//                                         {errors.MM_construction_period}
//                                     </span>
//                                 )}
//                             </div>
//                             <div className="flex-column">
//                                 <label
//                                     htmlFor="MM_OM_period"
//                                     className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                 >
//                                     Duration OM Period{" "}
//                                     <span className="required">*</span>
//                                 </label>
//                                 <input
//                                     id="MM_OM_period"
//                                     type="number"
//                                     name="MM_OM_period"
//                                     onWheel={(e) => e.target.blur()}

//                                     placeholder="OM Period"
//                                     onChange={handleInputChange}
//                                     value={formData.MM_OM_period}
//                                     className={`form-input ${errors.MM_OM_period
//                                         ? "error"
//                                         : inputState.MM_OM_period
//                                             ? "success"
//                                             : ""
//                                         }`}
//                                 />
//                                 {errors.MM_OM_period && (
//                                     <span className="error-message font-size-text ">
//                                         {errors.MM_OM_period}
//                                     </span>
//                                 )}
//                             </div>
//                             <div className="flex-column">
//                                 <label
//                                     htmlFor="rate"
//                                     className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
//                                 >
//                                     Rates<span className="required">*</span>
//                                 </label>
//                                 <input
//                                     id="rate"
//                                     type="number"
//                                     min={0}
//                                     name="rate"
//                                     onWheel={(e) => e.target.blur()}

//                                     placeholder="Rate"
//                                     onChange={handleInputChange}
//                                     value={formData.rate}
//                                     className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
//                                         }`}
//                                 />
//                                 {errors.rate && (
//                                     <span className="error-message font-size-text ">
//                                         {errors.rate}
//                                     </span>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="button-models">
//                         <button
//                             className="model-button-cancel model-button   font-weight500   "
//                             onClick={handleClose}
//                         >
//                             Cancel
//                         </button>
//                         <button
//                             type="submit"
//                             className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
//                             onClick={handleSubmit}
//                             disabled={loading}
//                         >
//                             Add
//                         </button>
//                     </div>
//                     <ToastContainer
//                         position="top-center"
//                         autoClose={1000}
//                         hideProgressBar={false}
//                         newestOnTop={true}
//                         closeOnClick
//                         rtl={false}
//                         pauseOnFocusLoss
//                         draggable
//                         pauseOnHover
//                     />
//                 </Modal.Body>
//             </Modal>

//         </>
//     )
// }

const AddEmployeeBOQ = ({ id, getEmployee }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setFormData({
            empcode: [],
            designation: "",
            professional_type: "",
            rate: "",
            MM_construction_period: "",
            MM_OM_period: "",
            project: id,
            eot: "",
        })
    }
    const handleShow = () => {
        getEmployeeData();
        setShow(true);
    };

    const [formData, setFormData] = useState({
        empcode: [],
        designation: "",
        professional_type: "",
        rate: "",
        MM_construction_period: "",
        MM_OM_period: "",
        project: id,
        eot: "",
    });

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const [employeeList, setEmployeeList] = useState([]);

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "empcode",
            "MM_construction_period",
            "MM_OM_period",
            "rate",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); // loading logic
    const getEmployeeData = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeebyfilterss/${id}/null/null/null/null/`
            );
            setEmployeeList(res.data);
        } catch (err) {
            console.error("Failed to fetch employee data", err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); // loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); // toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqemployee/`,
                    formData
                );

                if (res.status === 200) {
                    await getEmployee();
                    toast.dismiss(loadingToastId);
                    handleClose();
                    toast.success(" Employee BOQ Added successfully!");


                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err); // toast Logic
            } finally {
                setLoading(false); // loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        if (name === "empcode") {
            // Find selected employee details
            const selectedEmployee = employeeList.find(emp => emp.emp_code === value);

            setFormData({
                ...formData,
                empcode: value ? [value] : [], // Store empcode as an array
                designation: selectedEmployee?.designation || "",
                professional_type: selectedEmployee?.professional_type || "",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    return (
        <>
            <button title="Transportation" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle /> Employee
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Employee BOQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div title="Employee" className="flex-column form-group-selectt">
                                <label htmlFor="empcode" className="form-labels font-weight500 font-size-subheading">
                                    Employee<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="empcode"
                                    name="empcode"
                                    placeholder="empcode"
                                    value={formData.empcode[0] || "null"} // Access the first element or set "null"
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.empcode ? "error" : inputState.empcode ? "success" : ""}`}
                                >
                                    <option value="null">Select an Employee</option>
                                    {employeeList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.emp_code}>
                                            {i.emp_code} {i.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>
                                {errors.empcode && <span className="error-message font-size-text">{errors.empcode}</span>}
                            </div>


                            <div className="flex-column">
                                <label
                                    htmlFor="MM_construction_period"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Duration Construction Period{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    id="MM_construction_period"
                                    type="number"
                                    name="MM_construction_period"
                                    onWheel={(e) => e.target.blur()}

                                    placeholder="Construction Period"
                                    onChange={handleInputChange}
                                    value={formData.MM_construction_period}
                                    className={`form-input ${errors.MM_construction_period
                                        ? "error"
                                        : inputState.MM_construction_period
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.MM_construction_period && (
                                    <span className="error-message font-size-text ">
                                        {errors.MM_construction_period}
                                    </span>
                                )}
                            </div>
                            <div className="flex-column">
                                <label
                                    htmlFor="MM_OM_period"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Duration OM Period{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    id="MM_OM_period"
                                    type="number"
                                    name="MM_OM_period"
                                    onWheel={(e) => e.target.blur()}

                                    placeholder="OM Period"
                                    onChange={handleInputChange}
                                    value={formData.MM_OM_period}
                                    className={`form-input ${errors.MM_OM_period
                                        ? "error"
                                        : inputState.MM_OM_period
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.MM_OM_period && (
                                    <span className="error-message font-size-text ">
                                        {errors.MM_OM_period}
                                    </span>
                                )}
                            </div>
                            <div className="flex-column">
                                <label
                                    htmlFor="rate"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Rates<span className="required">*</span>
                                </label>
                                <input
                                    id="rate"
                                    type="number"
                                    min={0}
                                    name="rate"
                                    onWheel={(e) => e.target.blur()}

                                    placeholder="Rate"
                                    onChange={handleInputChange}
                                    value={formData.rate}
                                    className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
                                        }`}
                                />
                                {errors.rate && (
                                    <span className="error-message font-size-text ">
                                        {errors.rate}
                                    </span>
                                )}
                            </div>

                            {/* Other input fields */}
                            {/* Construction Period, OM Period, and Rate inputs */}
                        </div>
                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Add
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

const UpdateEmployeeBOQ = ({ i, id, getEmployee }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => {
        getEmployeeData();
        setShow(true);
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        empcode: [],
        designation: "",
        professional_type: "",
        rate: "",
        MM_construction_period: "",
        MM_OM_period: "",
        project: id,
        eot: "",
    });

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const [employeeList, setEmployeeList] = useState([]);

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "empcode",
            "MM_construction_period",
            "MM_OM_period",
            "rate",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [loading, setLoading] = useState(false); // loading logic
    const getEmployeeData = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeebyfilterss/${id}/null/null/null/null/`
            );
            setEmployeeList(res.data);
        } catch (err) {
            console.error("Failed to fetch employee data", err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); // loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); // toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqemployeeupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getEmployee();
                    toast.dismiss(loadingToastId);
                    handleClose();
                    toast.success(" Employee BOQ Updated successfully!");

                    // window.location.reload();

                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err); // toast Logic
            } finally {
                setLoading(false); // loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        if (name === "empcode") {
            // Find selected employee details
            const selectedEmployee = employeeList.find(emp => emp.emp_code === value);

            setFormData({
                ...formData,
                empcode: value ? [value] : [], // Store empcode as an array
                designation: selectedEmployee?.designation || "",
                professional_type: selectedEmployee?.professional_type || "",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    return (
        <>
            <button title="Employee BOQ" className="model-edit-button" onClick={handleShow}>
                <UpdatePencil />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Employee BOQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="parent-div">
                            <div className="bdy-div">
                                <div title="Employee" className="flex-column form-group-selectt">
                                    <label htmlFor="empcode" className="form-labels font-weight500 font-size-subheading">
                                        Employee<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="empcode"
                                        name="empcode"
                                        placeholder="empcode"
                                        value={formData.empcode[0] || "null"} // Access the first element or set "null"
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.empcode ? "error" : inputState.empcode ? "success" : ""}`}
                                    >
                                        <option value="null">Select an Employee</option>
                                        {employeeList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                            <option key={index} value={i.emp_code}>
                                                {i.emp_code} {i.name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    {errors.empcode && <span className="error-message font-size-text">{errors.empcode}</span>}
                                </div>


                                <div className="flex-column">
                                    <label
                                        htmlFor="MM_construction_period"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Duration Construction Period{" "}
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        id="MM_construction_period"
                                        type="number"
                                        name="MM_construction_period"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Construction Period"
                                        onChange={handleInputChange}
                                        value={formData.MM_construction_period}
                                        className={`form-input ${errors.MM_construction_period
                                            ? "error"
                                            : inputState.MM_construction_period
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.MM_construction_period && (
                                        <span className="error-message font-size-text ">
                                            {errors.MM_construction_period}
                                        </span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label
                                        htmlFor="MM_OM_period"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Duration OM Period{" "}
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        id="MM_OM_period"
                                        type="number"
                                        name="MM_OM_period"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="OM Period"
                                        onChange={handleInputChange}
                                        value={formData.MM_OM_period}
                                        className={`form-input ${errors.MM_OM_period
                                            ? "error"
                                            : inputState.MM_OM_period
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.MM_OM_period && (
                                        <span className="error-message font-size-text ">
                                            {errors.MM_OM_period}
                                        </span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label
                                        htmlFor="rate"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rates<span className="required">*</span>
                                    </label>
                                    <input
                                        id="rate"
                                        type="number"
                                        min={0}
                                        name="rate"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate"
                                        onChange={handleInputChange}
                                        value={formData.rate}
                                        className={`form-input ${errors.rate ? "error" : inputState.rate ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate}
                                        </span>
                                    )}
                                </div>

                                {/* Other input fields */}
                                {/* Construction Period, OM Period, and Rate inputs */}
                            </div>
                        </div>
                        <div className="button-models">
                            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="model-button font-weight500 model-button-submit font-weight500 font-size-heading"
                                disabled={loading}
                            >
                                Update
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

const DeleteEmployeeBOQ = ({ i, getEmployee }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqemployeeupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getEmployee();
                toast.dismiss(loadingToastId);
                handleClose();
                toast.success(" Employee BOQ Deleted successfully!");
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Transportation" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Employee BOQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete Employee BOQ of "{i.employee_name_details[0].name}"</h1>

                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};





const AddDutyTravel = ({ id, getDutyTravel }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        trips: "",
        num_of_min_trip: "",
        rate_per_trip: "",
        project: id,

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["trips", "num_of_min_trip", "rate_per_trip"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqtravel/`,
                    formData
                );

                if (res.status === 200) {
                    await getDutyTravel();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Duty Travel" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Duty Travel
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Duty Travel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">


                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="trips"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Trips<span className="required">*</span>
                                    </label>
                                    <input
                                        id="trips"
                                        type="text"
                                        name="trips"

                                        placeholder="Trips"
                                        onChange={handleInputChange}
                                        value={formData.trips}
                                        className={`form-input ${errors.trips
                                            ? "error"
                                            : inputState.trips
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.trips && (
                                        <span className="error-message font-size-text ">
                                            {errors.trips}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_min_trip"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Number of Minimum Trips<span className="required">*</span>
                                    </label>
                                    <input
                                        id="num_of_min_trip"
                                        type="number"
                                        name="num_of_min_trip"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Number of Minimum Trips"
                                        onChange={handleInputChange}
                                        value={formData.num_of_min_trip}
                                        className={`form-input ${errors.num_of_min_trip ? "error" : inputState.num_of_min_trip ? "success" : ""
                                            }`}
                                    />
                                    {errors.num_of_min_trip && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_min_trip}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_trip"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate per Trip (Rs.)<span className="required">*</span>
                                    </label>
                                    <input
                                        id="rate_per_trip"
                                        type="number"
                                        min={0}
                                        name="rate_per_trip"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate per Trip"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_trip}
                                        className={`form-input ${errors.rate_per_trip ? "error" : inputState.rate_per_trip ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_trip && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_trip}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};


const UpdateDutyTravel = ({ i, getDutyTravel }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        trips: "",
        num_of_min_trip: "",
        rate_per_trip: "",
        project: "",

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["trips", "num_of_min_trip", "rate_per_trip"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqtravelupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getDutyTravel();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Duty Travel"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Duty Travel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="trips"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Trips<span className="required">*</span>
                                    </label>
                                    <input
                                        id="trips"
                                        type="trips"
                                        name="trips"

                                        placeholder="Trips"
                                        onChange={handleInputChange}
                                        value={formData.trips}
                                        className={`form-input ${errors.trips
                                            ? "error"
                                            : inputState.trips
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.trips && (
                                        <span className="error-message font-size-text ">
                                            {errors.trips}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_min_trip"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Number of minimum Trips<span className="required">*</span>
                                    </label>
                                    <input
                                        id="num_of_min_trip"
                                        type="num_of_min_trip"
                                        name="num_of_min_trip"

                                        placeholder="Number of minimum Trips"
                                        onChange={handleInputChange}
                                        value={formData.num_of_min_trip}
                                        className={`form-input ${errors.num_of_min_trip ? "error" : inputState.num_of_min_trip ? "success" : ""
                                            }`}
                                    />
                                    {errors.num_of_min_trip && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_min_trip}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_trip"
                                        className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    >
                                        Rate per Trip (Rs.)<span className="required">*</span>
                                    </label>
                                    <input
                                        id="rate_per_trip"
                                        type="number"
                                        min={0}
                                        name="rate_per_trip"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder=" Rate per Trip"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_trip}
                                        className={`form-input ${errors.rate_per_trip ? "error" : inputState.rate_per_trip ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate}
                                        </span>
                                    )}
                                </div>

                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteIDutyTravel = ({ i, getDutyTravel }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqtravelupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getDutyTravel();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Duty Travel" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Duty travels</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete "{i.trips}"</h1>


                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ****************************************************************************


const AddOfficeRent = ({ id, getOfficeRent }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true) };

    const [formData, setFormData] = useState({
        num_of_months: "",
        rate_per_month: "",
        project: id,

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["num_of_months", "rate_per_month"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqofficerent/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeRent();
                    setShow(false);

                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Office Rent" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Office Rent
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Office Rent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_months"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of Months<span className="required">*</span>
                                    </label>
                                    <input
                                        id="num_of_months"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        name="num_of_months"
                                        placeholder="No. of Months"
                                        onChange={handleInputChange}
                                        value={formData.num_of_months}
                                        className={`form-input ${errors.num_of_months
                                            ? "error"
                                            : inputState.num_of_months
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.num_of_months && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_months}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_month"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate / Month (Rs.)<span className="required">*</span>
                                    </label>
                                    <input
                                        id="rate_per_month"
                                        type="number"
                                        name="rate_per_month"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_month}
                                        className={`form-input ${errors.rate_per_month ? "error" : inputState.rate_per_month ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_month && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_month}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};


const UpdateOfficeRent = ({ i, getOfficeRent }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        num_of_months: "",
        rate_per_month: "",
        project: "",

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["num_of_months", "rate_per_month"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqofficerentupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeRent();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Office Rent"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>


            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Office Rent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_months"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of Months<span className="required">*</span>
                                    </label>
                                    <input
                                        id="num_of_months"
                                        type="number"
                                        name="num_of_months"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Months"
                                        onChange={handleInputChange}
                                        value={formData.num_of_months}
                                        className={`form-input ${errors.num_of_months
                                            ? "error"
                                            : inputState.num_of_months
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.num_of_months && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_months}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_month"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate / Month (Rs.)<span className="required">*</span>
                                    </label>
                                    <input
                                        id="rate_per_month"
                                        type="number"
                                        name="rate_per_month"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate / Month"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_month}
                                        className={`form-input ${errors.rate_per_month ? "error" : inputState.rate_per_month ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_month && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_month}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteOfficeRent = ({ i, getOfficeRent }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqofficerentupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getOfficeRent();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Office Rent" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Office Rent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete for "{i.num_of_months}" Months </h1>

                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ****************************************************************************

const AddOfficeSupplies = ({ id, getOfficeSupplies }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        item: "",
        months: "",
        monthly_rate: "",
        project: id,

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item", "months", "monthly_rate"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.post(
                    `${BASE_URL}/siteproject/boqofficesupplies/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeSupplies();
                    setShow(false);
                    window.location.reload();
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button title="Office Supplies" className="upload-svg flex-row" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Office Supplies
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Office Supplies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="item"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Item<span className="required">*</span>
                                    </label>
                                    <input
                                        id="item"
                                        type="text"
                                        name="item"

                                        placeholder="Item Name"
                                        onChange={handleInputChange}
                                        value={formData.item}
                                        className={`form-input ${errors.item
                                            ? "error"
                                            : inputState.item
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.item && (
                                        <span className="error-message font-size-text ">
                                            {errors.item}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="months"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Months<span className="required">*</span>
                                    </label>
                                    <input
                                        id="months"
                                        type="number"
                                        min={0}
                                        name="months"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Months"
                                        onChange={handleInputChange}
                                        value={formData.months}
                                        className={`form-input ${errors.months ? "error" : inputState.months ? "success" : ""
                                            }`}
                                    />
                                    {errors.months && (
                                        <span className="error-message font-size-text ">
                                            {errors.months}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="monthly_rate"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Monthly Rate<span className="required">*</span>
                                    </label>
                                    <input
                                        id="monthly_rate"
                                        type="number"
                                        min={0}
                                        name="monthly_rate"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Monthly Rate"
                                        onChange={handleInputChange}
                                        value={formData.monthly_rate}
                                        className={`form-input ${errors.monthly_rate ? "error" : inputState.monthly_rate ? "success" : ""
                                            }`}
                                    />
                                    {errors.monthly_rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.monthly_rate}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};



const UpdateOfficeSupplies = ({ i, getOfficeSupplies }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        item: "",
        months: "",
        monthly_rate: "",
        project: "",
        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["item", "months", "monthly_rate"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqofficesuppliesupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeSupplies();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Office Supplies"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>


            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Office Supplies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="item"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Item<span className="required">*</span>
                                    </label>
                                    <input
                                        id="item"
                                        type="text"
                                        name="item"

                                        placeholder="Item Name"
                                        onChange={handleInputChange}
                                        value={formData.item}
                                        className={`form-input ${errors.item
                                            ? "error"
                                            : inputState.item
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.item && (
                                        <span className="error-message font-size-text ">
                                            {errors.item}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="months"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Months<span className="required">*</span>
                                    </label>
                                    <input
                                        id="months"
                                        type="number"
                                        name="months"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Months"
                                        onChange={handleInputChange}
                                        value={formData.months}
                                        className={`form-input ${errors.months ? "error" : inputState.months ? "success" : ""
                                            }`}
                                    />
                                    {errors.months && (
                                        <span className="error-message font-size-text ">
                                            {errors.months}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="monthly_rate"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Monthly Rate<span className="required">*</span>
                                    </label>
                                    <input
                                        id="monthly_rate"
                                        type="number"
                                        name="monthly_rate"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Monthly Rate"
                                        onChange={handleInputChange}
                                        value={formData.monthly_rate}
                                        className={`form-input ${errors.monthly_rate ? "error" : inputState.monthly_rate ? "success" : ""
                                            }`}
                                    />
                                    {errors.monthly_rate && (
                                        <span className="error-message font-size-text ">
                                            {errors.monthly_rate}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteOfficeSupplies = ({ i, getOfficeSupplies }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqofficesuppliesupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getOfficeSupplies();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Office Supplies" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Office Supplies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete Item "{i.item}"</h1>
                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ****************************************************************************

const UpdateOfficeFurniture = ({ i, getOfficeFurniture }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        num_of_months: "",
        rate_per_month: "",
        project: "",

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["num_of_months", "rate_per_month"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqofficefurnitureandequipmentupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeFurniture();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Office Furniture"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>


            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Office Furniture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_months"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of month
                                    </label>
                                    <input
                                        id="num_of_months"
                                        type="number"
                                        name="num_of_months"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Month"
                                        onChange={handleInputChange}
                                        value={formData.num_of_months}
                                        className={`form-input ${errors.num_of_months
                                            ? "error"
                                            : inputState.num_of_months
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.num_of_months && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_months}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_month"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate per Month
                                    </label>
                                    <input
                                        id="rate_per_month"
                                        type="number"
                                        name="rate_per_month"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate per Month"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_month}
                                        className={`form-input ${errors.rate_per_month ? "error" : inputState.rate_per_month ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_month && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_month}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteOfficeFurniture = ({ i, getOfficeFurniture }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqofficefurnitureandequipmentupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getOfficeFurniture();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Office Furniture" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Office Furniture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete for "{i.num_of_months}" Months </h1>
                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ****************************************************************************

const UpdateReportsPrinting = ({ i, getReportsPrinting }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        description: "",
        num_of_reports: "",
        num_of_copies_per_report: "",
        rate_per_copy: "",
        project: "",

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["description", "num_of_reports", "num_of_copies_per_report", "rate_per_copy"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqreportsanddocprintupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getReportsPrinting();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Report & Doc"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>


            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Report & Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="description"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Description
                                    </label>
                                    <input
                                        id="description"
                                        type="text"
                                        name="description"
                                        maxLength={300}
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                        value={formData.description}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.description
                                            ? "error"
                                            : inputState.description
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.description && (
                                        <span className="error-message font-size-text ">
                                            {errors.description}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_reports"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of Reports
                                    </label>
                                    <input
                                        id="num_of_reports"
                                        type="number"
                                        name="num_of_reports"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Reports"
                                        onChange={handleInputChange}
                                        value={formData.num_of_reports}
                                        className={`form-input ${errors.num_of_reports ? "error" : inputState.num_of_reports ? "success" : ""
                                            }`}
                                    />
                                    {errors.num_of_reports && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_reports}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_copies_per_report"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of Copies per Report
                                    </label>
                                    <input
                                        id="num_of_copies_per_report"
                                        type="number"
                                        min={0}
                                        name="num_of_copies_per_report"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of Copies per Report"
                                        onChange={handleInputChange}
                                        value={formData.num_of_copies_per_report}
                                        className={`form-input ${errors.num_of_copies_per_report ? "error" : inputState.num_of_copies_per_report ? "success" : ""
                                            }`}
                                    />
                                    {errors.num_of_copies_per_report && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_copies_per_report}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_copy"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate per Copy
                                    </label>
                                    <input
                                        id="rate_per_copy"
                                        type="number"
                                        min={0}
                                        name="rate_per_copy"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate per Copy"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_copy}
                                        className={`form-input ${errors.rate_per_copy
                                            ? "error"
                                            : inputState.rate_per_copy
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.rate_per_copy && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_copy}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteReportsPrinting = ({ i, getReportsPrinting }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqreportsanddocprintupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getReportsPrinting();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Report & Doc" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Report & Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete "{i.description}"</h1>
                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ****************************************************************************

const UpdateRoadSurveyEquipment = ({ i, getRoadSurveyEquipment }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        KM: "",
        description: "",
        num_of_times_survey_concluded: "",
        rate_per_km_times: "",
        project: ""

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["description", "num_of_times_survey_concluded", "rate_per_km_times"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqroadsurveyequipmentupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getRoadSurveyEquipment();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Road Survey"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>


            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Road Survey</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="description"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Description
                                    </label>
                                    <input
                                        id="description"
                                        type="text"
                                        name="description"
                                        maxLength={300}
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                        value={formData.description}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.description
                                            ? "error"
                                            : inputState.description
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.description && (
                                        <span className="error-message font-size-text ">
                                            {errors.description}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="KM"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        KM
                                    </label>
                                    <input
                                        id="KM"
                                        type="number"
                                        min={0}
                                        name="KM"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="KM"
                                        onChange={handleInputChange}
                                        value={formData.KM}
                                        className={`form-input ${errors.KM ? "error" : inputState.KM ? "success" : ""
                                            }`}
                                    />
                                    {errors.KM && (
                                        <span className="error-message font-size-text ">
                                            {errors.KM}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="num_of_times_survey_concluded"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        No. of time survey concluded
                                    </label>
                                    <input
                                        id="num_of_times_survey_concluded"
                                        type="number"
                                        min={0}
                                        name="num_of_times_survey_concluded"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="No. of times Survey Concluded"
                                        onChange={handleInputChange}
                                        value={formData.num_of_times_survey_concluded}
                                        className={`form-input ${errors.num_of_times_survey_concluded ? "error" : inputState.num_of_times_survey_concluded ? "success" : ""
                                            }`}
                                    />
                                    {errors.num_of_times_survey_concluded && (
                                        <span className="error-message font-size-text ">
                                            {errors.num_of_times_survey_concluded}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="rate_per_km_times"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Rate per No. of times Survey to be Conducted
                                    </label>
                                    <input
                                        id="rate_per_km_times"
                                        type="number"
                                        min={0}
                                        name="rate_per_km_times"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Rate per No. of times Survey to be Conducted"
                                        onChange={handleInputChange}
                                        value={formData.rate_per_km_times}
                                        className={`form-input ${errors.rate_per_km_times ? "error" : inputState.rate_per_km_times ? "success" : ""
                                            }`}
                                    />
                                    {errors.rate_per_km_times && (
                                        <span className="error-message font-size-text ">
                                            {errors.rate_per_km_times}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteRoadSurveyEquipment = ({ i, getRoadSurveyEquipment }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqroadsurveyequipmentupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getRoadSurveyEquipment();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Road Survey" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Road Survey</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete "{i.description}"</h1>
                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ****************************************************************************

const UpdateContingenciest = ({ i, getContingencies }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        description: "",
        unit: "",
        ammount: "",
        project: ""

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["description", "unit", "ammount"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqcontingenciesupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getContingencies();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Contingencies"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>


            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Contingencies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="description"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Description
                                    </label>
                                    <input
                                        id="description"
                                        type="text"
                                        name="description"
                                        maxLength={300}
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                        value={formData.description}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.description
                                            ? "error"
                                            : inputState.description
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.description && (
                                        <span className="error-message font-size-text ">
                                            {errors.description}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="unit"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Units
                                    </label>
                                    <input
                                        id="unit"
                                        type="text"
                                        name="unit"

                                        placeholder="Units"
                                        onChange={handleInputChange}
                                        value={formData.unit}
                                        className={`form-input ${errors.unit ? "error" : inputState.unit ? "success" : ""
                                            }`}
                                    />
                                    {errors.unit && (
                                        <span className="error-message font-size-text ">
                                            {errors.unit}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label
                                        htmlFor="ammount"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Amount
                                    </label>
                                    <input
                                        id="ammount"
                                        type="number"
                                        min={0}
                                        name="ammount"
                                        onWheel={(e) => e.target.blur()}

                                        placeholder="Amount"
                                        onChange={handleInputChange}
                                        value={formData.ammount}
                                        className={`form-input ${errors.ammount ? "error" : inputState.ammount ? "success" : ""
                                            }`}
                                    />
                                    {errors.ammount && (
                                        <span className="error-message font-size-text ">
                                            {errors.ammount}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteUpdateContingenciest = ({ i, getContingencies }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/boqcontingenciesupdate/${i.id}/`
            );

            if (res.status === 200) {
                await getContingencies();
                setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {

            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };
    return (
        <>
            <button title="Delete Contingencies" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Contingencies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="del-button">
                        <div>
                            <h1 className="task-Tab-heading font-weight500    font-size-heading">Are You Sure You Want to Delete "{i.description}"</h1>
                        </div>
                    </div>
                    <br />
                    <div className="button-models">
                        <button className="model-button-cancel model-button   font-weight500    " onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            onClick={handleFormSubmitDelete}
                            className="model-button   font-weight500    model-button-delete"
                        >
                            Delete
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};

// ****************************************************************************

const UpdateAddOfficeFurnitureHeading = ({ i, getOfficeFurnitureheading }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setFormData(i);
    };

    const [formData, setFormData] = useState({
        BOQoffice_furnitur_equipment: "",
        project: ""

        // sub_company: sessionStorage.getItem('company_id')
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["BOQoffice_furnitur_equipment"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    //! **************************** post API ***********************!//
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(
                    `${BASE_URL}/siteproject/boqheadingtableupdate/${i.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getOfficeFurnitureheading();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <button
                title="Update Office Furniture Heading"
                className="model-edit-button"
                onClick={handleShow}
            >
                <UpdatePencil />
            </button>


            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Office Furniture Heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Add Item</h6> */}

                            <form>
                                <div className="flex-column">
                                    <label
                                        htmlFor="BOQoffice_furnitur_equipment"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Heading Description
                                    </label>
                                    <textarea
                                        id="BOQoffice_furnitur_equipment"
                                        type="text"
                                        name="BOQoffice_furnitur_equipment"
                                        // maxLength={300}
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                        value={formData.BOQoffice_furnitur_equipment}
                                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.BOQoffice_furnitur_equipment
                                            ? "error"
                                            : inputState.BOQoffice_furnitur_equipment
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.BOQoffice_furnitur_equipment && (
                                        <span className="error-message font-size-text ">
                                            {errors.BOQoffice_furnitur_equipment}
                                        </span>
                                    )}
                                </div>
                            </form>
                            <div className="button-models">
                                <button
                                    className="model-button-cancel model-button   font-weight500   "
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit  font-weight500    font-size-heading"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};


export {
    AddBreakdownOfCosts,
    AddSubprofessionalStaff,
    AddSupportStaff,
    AddTransportation,
    AddDutyTravel,
    AddOfficeRent,
    AddOfficeSupplies,
    AddOfficeFurniture,
    AddReportsPrinting,
    AddRoadSurveyEquipment,
    AddContingencies,
    UpdateBreakdownOfCosts,
    DeleteBreakdownOfCosts,
    // UpdateSubprofessionalStaff,
    DeleteSubprofessionalStaff,
    // UpdateSupportStaff,
    DeleteSupportStaff,
    UpdateTransportation,
    DeleteITransportation,
    UpdateDutyTravel,
    UpdateEmployeeBOQ,
    DeleteEmployeeBOQ,
    AddEmployeeBOQ,
    DeleteIDutyTravel,
    UpdateOfficeRent,
    DeleteOfficeRent,
    UpdateOfficeSupplies,
    DeleteOfficeSupplies,
    UpdateOfficeFurniture,
    DeleteOfficeFurniture,
    UpdateReportsPrinting,
    DeleteReportsPrinting,
    UpdateRoadSurveyEquipment,
    DeleteRoadSurveyEquipment,
    UpdateContingenciest,
    DeleteUpdateContingenciest,
    AddOfficeFurnitureHeading,
    UpdateAddOfficeFurnitureHeading
};  
