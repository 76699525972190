import React, { useEffect, useState } from 'react'
import { handleErrorToast, sortProjects } from '../../CustomFunctions';
import axios from 'axios';
import { BASE_URL } from '../../../config/axios';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Dashboardnavbar from '../../../layout/Dashboardnavbar';
import { datearray, formattedDateLong } from '../../Date';
import { AddwithWhiteCircle, CircularGrid, UpdatePencil } from '../../AllSvg';
import usePermission from '../../../config/permissions';
import { AddDocuments, DocumentModel } from '../ProjectDetailsCrudButtons';
// !  ************** Vehicle Model Components Start **************  ! //

const AddVehicleModel = ({ projectId, getVehicle }) => {

  const [show, setShow] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const { siteRole } = usePermission();

  const handleShow = () => {
    setShow(true);
    getProjects();
  }

  const initialFormData = {
    project: projectId ? projectId : "",
    name: "",
    vehicle_num: "",
    fixed_km: "",
    fixed_bill_amt: "",
    extra_amt_above_fix_km: "",

    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
    km_per_lit: "",
    fuel_price: ""
  };

  // const handleClose = () => {
  //   setShow(false);
  //   setFormData({
  //     project: projectId ? projectId : "",
  //     name: "",
  //     vehicle_num: "",
  //     fixed_km: "",
  //     fixed_bill_amt: "",
  //     extra_amt_above_fix_km: "",

  //     bank_name: "",
  //     account_holder_name: "",
  //     account_num: "",
  //     ifsc_code: "",
  //     pan_card: "",
  //     gst_num: "",
  //     address: "",
  //     mobile: "",
  //   })
  // }

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(initialFormData);
    // setProject(initialProject);

  }


  const getProjects = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  };



  const [formData, setFormData] = useState({
    project: projectId ? projectId : "",
    name: "",
    vehicle_num: "",
    fixed_km: "",
    fixed_bill_amt: "",
    extra_amt_above_fix_km: "",

    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
    km_per_lit: "",
    fuel_price: ""
  });


  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "project",
      "vehicle_num",
      "fixed_km",
      "fixed_bill_amt",
      "bank_name",
      "account_holder_name",
      "account_num",
      "ifsc_code",
      "pan_card",
      "mobile",
      "extra_amt_above_fix_km"
    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let ress = await axios.post(
          `${BASE_URL}/siteproject/vehicle/`,
          formData
        );

        if (ress.status === 200) {
          await getVehicle();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Add Vechile Model" className="upload-svg" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "}Vehicle
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Add Vehicle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="form-flex-wrap">


              {siteRole === "SiteManagement" ? "" : <>
                <div title="Site Projects" className="flex-column form-group-selectt">
                  <label htmlFor="project" className="form-labels font-weight500 font-size-subheading">
                    Project<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="project"
                    name="project"
                    value={formData.project}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                  >
                    <option value="">Select a Project</option>
                    {projectList?.map((i, index) => (
                      <option key={index} value={i.id}>
                        {i.site_prcode} - {i.project_short_name}
                      </option>
                    ))
                    }
                  </select>

                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.project && (
                      <span className="error-message font-size-text">{errors.project}</span>
                    )}
                  </div>
                </div></>}

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Vehicle Name<span className="required">*</span></label>
                <input
                  type="text"
                  name="name"
                  placeholder="Vehicle Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={`form-input ${errors.name ? 'is-invalid' : ''}`}
                />
                {errors.name && <div className="error-message font-size-text">{errors.name}</div>}
              </div>
              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Vehicle Number<span className="required">*</span></label>
                <input
                  type="text"
                  name="vehicle_num"
                  placeholder="Vehicle Number"
                  value={formData.vehicle_num}
                  onChange={handleInputChange}
                  className={`form-input ${errors.vehicle_num ? 'is-invalid' : ''}`}
                />
                {errors.vehicle_num && <div className="error-message font-size-text">{errors.vehicle_num}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Fixed Kilometer<span className="required">*</span></label>
                <input
                  type="number"
                  name="fixed_km"
                  placeholder="Fixed Kilometer"
                  min={0}
                  value={formData.fixed_km}
                  onChange={handleInputChange}
                  className={`form-input ${errors.fixed_km ? 'is-invalid' : ''}`}
                />
                {errors.fixed_km && <div className="error-message font-size-text">{errors.fixed_km}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Kilometer per Liter</label>
                <input
                  type="number"
                  name="km_per_lit"
                  placeholder="Kilometer per Liter"
                  min={0}
                  value={formData.km_per_lit}
                  onChange={handleInputChange}
                  className={`form-input ${errors.km_per_lit ? 'is-invalid' : ''}`}
                />
                {errors.km_per_lit && <div className="error-message font-size-text">{errors.km_per_lit}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Fuel Price</label>
                <input
                  type="number"
                  name="fuel_price"
                  placeholder="Fuel Price"
                  min={0}
                  value={formData.fuel_price}
                  onChange={handleInputChange}
                  className={`form-input ${errors.fuel_price ? 'is-invalid' : ''}`}
                />
                {errors.fuel_price && <div className="error-message font-size-text">{errors.fuel_price}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Fixed Bill Amount<span className="required">*</span></label>
                <input
                  type="number"
                  min={0}
                  name="fixed_bill_amt"
                  placeholder="Fixed Bill Amount"
                  value={formData.fixed_bill_amt}
                  onChange={handleInputChange}
                  className={`form-input ${errors.fixed_bill_amt ? 'is-invalid' : ''}`}
                />
                {errors.fixed_bill_amt && <div className="error-message font-size-text">{errors.fixed_bill_amt}</div>}
              </div>
              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Extra Amount Per KM After Fixed KM<span className="required">*</span></label>
                <input
                  type="number"
                  min={0}
                  name="extra_amt_above_fix_km"
                  placeholder="Extra Amount Per KM After Fixed KM"
                  value={formData.extra_amt_above_fix_km}
                  onChange={handleInputChange}
                  className={`form-input ${errors.extra_amt_above_fix_km ? 'is-invalid' : ''}`}
                />
                {errors.extra_amt_above_fix_km && <div className="error-message font-size-text">{errors.extra_amt_above_fix_km}</div>}
              </div>


              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Bank Name<span className="required">*</span></label>
                <input
                  type="text"
                  name="bank_name"
                  placeholder="Bank Name"
                  value={formData.bank_name}
                  onChange={handleInputChange}
                  className={`form-input ${errors.bank_name ? 'is-invalid' : ''}`}
                />
                {errors.bank_name && <div className="error-message font-size-text">{errors.bank_name}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Account Holder Name <span className="required">*</span></label>
                <input
                  type="text"
                  name="account_holder_name"
                  placeholder="Account Holder Name"
                  value={formData.account_holder_name}
                  onChange={handleInputChange}
                  className={`form-input ${errors.account_holder_name ? 'is-invalid' : ''}`}
                />
                {errors.account_holder_name && <div className="error-message font-size-text">{errors.account_holder_name}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Account Number<span className="required">*</span></label>
                <input
                  type="number"
                  min={0}
                  name="account_num"
                  placeholder="Account Number"
                  value={formData.account_num}
                  onChange={handleInputChange}
                  className={`form-input ${errors.account_num ? 'is-invalid' : ''}`}
                />
                {errors.account_num && <div className="error-message font-size-text">{errors.account_num}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">Mobile<span className="required">*</span></label>
                <input
                  type="number"
                  name="mobile"
                  min={0}
                  placeholder="Mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  className={`form-input ${errors.mobile ? 'is-invalid' : ''}`}
                />
                {errors.mobile && <div className="error-message font-size-text">{errors.mobile}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">IFSC Code<span className="required">*</span></label>
                <input
                  type="text"
                  name="ifsc_code"
                  placeholder="IFSC Code"
                  value={formData.ifsc_code}
                  onChange={handleInputChange}
                  className={`form-input ${errors.ifsc_code ? 'is-invalid' : ''}`}
                />
                {errors.ifsc_code && <div className="error-message font-size-text">{errors.ifsc_code}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">PAN Card<span className="required">*</span></label>
                <input
                  type="text"
                  name="pan_card"
                  placeholder="PAN Card"
                  value={formData.pan_card}
                  onChange={handleInputChange}
                  className={`form-input ${errors.pan_card ? 'is-invalid' : ''}`}
                />
                {errors.pan_card && <div className="error-message font-size-text">{errors.pan_card}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500 font-size-subheading">GST Number</label>
                <input
                  type="text"
                  name="gst_num"
                  placeholder="GST Number"
                  value={formData.gst_num}
                  onChange={handleInputChange}
                  className={`form-input ${errors.gst_num ? 'is-invalid' : ''}`}
                />
                {errors.gst_num && <div className="error-message font-size-text">{errors.gst_num}</div>}
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 font-size-subheading">
                  Address
                </label>
                <textarea
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleInputChange}
                  rows="50"
                  cols="50"
                  className={`form-textarea textarea  ${errors.address ? 'is-invalid' : ''}`}
                  style={{ height: "50vh" }}
                />
                {errors.address && <div className="error-message font-size-text">{errors.address}</div>}
              </div>



            </div>



            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                {loading ? 'Adding...' : 'Add'}
              </button>
            </div>


          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

const UpdateVehicle = ({ i, projectId, getVehicle }) => {
  const [projectList, setProjectList] = useState([]);
  const [show, setShow] = useState(false);
  const { siteRole } = usePermission();


  const handleShow = () => {
    getProjects();
    setShow(true);
    setFormData(i);
  }
  const getProjects = async () => {

    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);
    } catch (err) {
      handleErrorToast(err)
    } finally {

    }
  };



  const [formData, setFormData] = useState({
    project: projectId ? projectId : "",
    name: "",
    vehicle_num: "",
    fixed_km: "",
    fixed_bill_amt: "",
    extra_amt_above_fix_km: "",

    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
    km_per_lit: "",
    fuel_price: ""
  });


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});


  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "project",
      "vehicle_num",
      "fixed_km",
      "fixed_bill_amt",
      "extra_amt_above_fix_km"
    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // ?  ************** Validation End **************  ! //


  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.put(
          `${BASE_URL}/siteproject/vehicle/${i.id}/`,
          formData
        );

        if (ress.status === 200) {
          await getVehicle();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(i);
  }


  return (


    <div>
      <>
        <button title='Update Vehicle Details' className='model-edit-button' onClick={handleShow}>
          <UpdatePencil />
        </button>

        <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
          <Modal.Header closeButton>
            <Modal.Title>Update Vehicle Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <form className="register-form">
              <div className="form-flex-wrap">


                {siteRole === "SiteManagement" ? "" : <>
                  <div title="Site Projects" className="flex-column form-group-selectt">
                    <label htmlFor="project" className="form-labels font-weight500 font-size-subheading">
                      Project<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      id="project"
                      name="project"
                      value={formData.project}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                    >
                      <option value="">Select a Project</option>
                      {projectList?.map((i, index) => (
                        <option key={index} value={i.id}>
                          {i.site_prcode} - {i.project_short_name}
                        </option>
                      ))
                      }
                    </select>

                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    <div>
                      {errors.project && (
                        <span className="error-message font-size-text">{errors.project}</span>
                      )}
                    </div>
                  </div></>}

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Vehicle Name<span className="required">*</span></label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Vehicle Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.name ? 'is-invalid' : ''}`}
                  />
                  {errors.name && <div className="error-message font-size-text">{errors.name}</div>}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Vehicle Number<span className="required">*</span></label>
                  <input
                    type="text"
                    name="vehicle_num"
                    placeholder="Vehicle Number"
                    value={formData.vehicle_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.vehicle_num ? 'is-invalid' : ''}`}
                  />
                  {errors.vehicle_num && <div className="error-message font-size-text">{errors.vehicle_num}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Fixed Kilometer<span className="required">*</span></label>
                  <input
                    type="number"
                    name="fixed_km"
                    placeholder="Fixed Kilometer"
                    min={0}
                    value={formData.fixed_km}
                    onChange={handleInputChange}
                    className={`form-input ${errors.fixed_km ? 'is-invalid' : ''}`}
                  />
                  {errors.fixed_km && <div className="error-message font-size-text">{errors.fixed_km}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Kilometer per Liter</label>
                  <input
                    type="number"
                    name="km_per_lit"
                    placeholder="Kilometer per Liter"
                    min={0}
                    value={formData.km_per_lit}
                    onChange={handleInputChange}
                    className={`form-input ${errors.km_per_lit ? 'is-invalid' : ''}`}
                  />
                  {errors.km_per_lit && <div className="error-message font-size-text">{errors.km_per_lit}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Fuel Price</label>
                  <input
                    type="number"
                    name=" fuel_price"
                    placeholder="Fuel Price"
                    min={0}
                    value={formData.fuel_price}
                    onChange={handleInputChange}
                    className={`form-input ${errors.fuel_price ? 'is-invalid' : ''}`}
                  />
                  {errors.fuel_price && <div className="error-message font-size-text">{errors.fuel_price}</div>}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Fixed Bill Amount<span className="required">*</span></label>
                  <input
                    type="number"
                    min={0}
                    name="fixed_bill_amt"
                    placeholder="Fixed Bill Amount"
                    value={formData.fixed_bill_amt}
                    onChange={handleInputChange}
                    className={`form-input ${errors.fixed_bill_amt ? 'is-invalid' : ''}`}
                  />
                  {errors.fixed_bill_amt && <div className="error-message font-size-text">{errors.fixed_bill_amt}</div>}
                </div>
                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Extra Amount Per KM After Fixed KM<span className="required">*</span></label>
                  <input
                    type="number"
                    min={0}
                    name="extra_amt_above_fix_km"
                    placeholder="Extra Amount Per KM After Fixed KM"
                    value={formData.extra_amt_above_fix_km}
                    onChange={handleInputChange}
                    className={`form-input ${errors.extra_amt_above_fix_km ? 'is-invalid' : ''}`}
                  />
                  {errors.extra_amt_above_fix_km && <div className="error-message font-size-text">{errors.extra_amt_above_fix_km}</div>}
                </div>


                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Bank Name<span className="required">*</span></label>
                  <input
                    type="text"
                    name="bank_name"
                    placeholder="Bank Name"
                    value={formData.bank_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.bank_name ? 'is-invalid' : ''}`}
                  />
                  {errors.bank_name && <div className="error-message font-size-text">{errors.bank_name}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Account Holder Name <span className="required">*</span></label>
                  <input
                    type="text"
                    name="account_holder_name"
                    placeholder="Account Holder Name"
                    value={formData.account_holder_name}
                    onChange={handleInputChange}
                    className={`form-input ${errors.account_holder_name ? 'is-invalid' : ''}`}
                  />
                  {errors.account_holder_name && <div className="error-message font-size-text">{errors.account_holder_name}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Account Number<span className="required">*</span></label>
                  <input
                    type="number"
                    min={0}
                    name="account_num"
                    placeholder="Account Number"
                    value={formData.account_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.account_num ? 'is-invalid' : ''}`}
                  />
                  {errors.account_num && <div className="error-message font-size-text">{errors.account_num}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">Mobile<span className="required">*</span></label>
                  <input
                    type="number"
                    name="mobile"
                    min={0}
                    placeholder="Mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    className={`form-input ${errors.mobile ? 'is-invalid' : ''}`}
                  />
                  {errors.mobile && <div className="error-message font-size-text">{errors.mobile}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">IFSC Code<span className="required">*</span></label>
                  <input
                    type="text"
                    name="ifsc_code"
                    placeholder="IFSC Code"
                    value={formData.ifsc_code}
                    onChange={handleInputChange}
                    className={`form-input ${errors.ifsc_code ? 'is-invalid' : ''}`}
                  />
                  {errors.ifsc_code && <div className="error-message font-size-text">{errors.ifsc_code}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">PAN Card<span className="required">*</span></label>
                  <input
                    type="text"
                    name="pan_card"
                    placeholder="PAN Card"
                    value={formData.pan_card}
                    onChange={handleInputChange}
                    className={`form-input ${errors.pan_card ? 'is-invalid' : ''}`}
                  />
                  {errors.pan_card && <div className="error-message font-size-text">{errors.pan_card}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels  font-weight500 font-size-subheading">GST Number<span className="required">*</span></label>
                  <input
                    type="text"
                    name="gst_num"
                    placeholder="GST Number"
                    value={formData.gst_num}
                    onChange={handleInputChange}
                    className={`form-input ${errors.gst_num ? 'is-invalid' : ''}`}
                  />
                  {errors.gst_num && <div className="error-message font-size-text">{errors.gst_num}</div>}
                </div>

                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    Address<span className="required">*</span>
                  </label>
                  <textarea
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleInputChange}
                    rows="50"
                    cols="50"
                    className={`form-textarea width-10vw ${errors.address ? 'is-invalid' : ''}`}
                  />
                  {errors.address && <div className="error-message font-size-text">{errors.address}</div>}
                </div>

              </div>
            </form>


            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                {loading ? 'Updating...' : 'Update'}
              </button>
            </div>

          </Modal.Body>
        </Modal>
      </>

    </div>


  )
}

const DeleteVehicle = ({ i, getVehicle }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);

  };
  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/vehicle/${i.id}/`);

      if (res.status === 200) {
        await getVehicle();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      console.log("Error")
      console.log(err)
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Vehicle Model Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Vehicle Model "{i.name}"

            <div className="button-models">
              <button className="model-button-cancel  model-button font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
}

// !  ************** Vehicle Model Components End**************  ! //


// !  ************** Vehicle Log Components Start **************  ! //

const AddVehicleLogDetails = ({ getVehicleLogDetail, projectid }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [show, setShow] = useState(false);
  const { siteRole } = usePermission();

  const initialProject = "null";
  const [setProject] = useState(initialProject);
  const [projectList, setProjectList] = useState([]);

  const handleShow = () => {

    setShow(true);
  }


  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(initialFormData);
    // setProject(initialProject);

  }



  const [formData, setFormData] = useState({
    vehicle: "",
    date: "",
    from_time: "10:00:00",
    to_time: "17:30:00",
    commencement_of_trip: "",
    conclusion_of_trip: "",
    person_using_conveyance: "",
    purpose_and_place: "",
    project: projectid ? projectid : null,
  });

  const getProjects = async () => {
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      handleErrorToast(err);
    } finally {

    }
  };

  const getEmployee = async () => {

    try {
      const emp = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${formData.project ? formData.project : "null"}/null/`);
      setEmployeeList(emp.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {

    }
  }
  const getVehicle = async () => {
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapi/${formData.project ? formData.project : "null"}/`);
      setVehicleList(veh.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {
    }
  }
  const initialFormData = {
    vehicle: "",
    date: "",
    from_time: "10:00:00",
    to_time: "17:30:00",
    commencement_of_trip: "",
    conclusion_of_trip: "",
    person_using_conveyance: "",
    purpose_and_place: "",
    project: projectid ? projectid : null,
  };


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle",
      "date",
      "from_time",
      "to_time",
      "commencement_of_trip",
      "conclusion_of_trip",
      "purpose_and_place",
      "person_using_conveyance",


    ]

    if (!formData.to_time) {
      newErrors.to_time = "To time is required.";
    } else if (formData.to_time <= formData.from_time) {
      newErrors.to_time = "To time must be later than From time.";
    }

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }

  // ?  ************** Validation End **************  ! //


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));

    // Validation logic
    if (name === 'conclusion_of_trip') {
      if (Number(value) <= Number(formData.commencement_of_trip)) {
        setErrors(prevState => ({
          ...prevState,
          conclusion_of_trip: 'Conclusion of Trip must be greater than Commencement of Trip',
        }));
      } else {
        setErrors(prevState => ({
          ...prevState,
          conclusion_of_trip: '',
        }));
      }
    }

    if (name === 'commencement_of_trip') {
      setErrors(prevState => ({
        ...prevState,
        conclusion_of_trip: '',
      }));
    }
  };


  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.post(
          `${BASE_URL}/siteproject/vehiclelogdetail/
`, formData
        );

        if (ress.status === 200) {
          await getVehicleLogDetail();
          handleClose();
          // setFormData("");
        } else {
          alert(ress);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  useEffect(() => {
    getProjects();
    if (formData.project !== "null") {
      getVehicle();
      getEmployee();
    }
    else {
      setVehicleList([]);
      setEmployeeList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project]);

  const generateTimeOptions = (startTime = "00:00") => {
    const times = [];
    let current = new Date(`2024-01-01T${startTime}:00`);
    const end = new Date(`2024-01-01T23:59:00`);

    while (current <= end) {
      times.push(current.toTimeString().slice(0, 5)); // Get HH:MM format
      current.setMinutes(current.getMinutes() + 30); // Increment by 30 minutes
    }
    return times;
  };

  const timeOptions = generateTimeOptions(formData.from_time || "00:00");


  return (



    <>
      <button title="Add Vechile Log Details" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {" "} Vehicle Log Details
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Vehicle Log Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form >

                {siteRole === 'SiteManagement' ? "" : <>
                  <div title="Project" className="flex-column form-group-selectt">
                    <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                      Project<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="project"
                      name="project"
                      placeholder="project"
                      value={formData.project}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                    >

                      <option value="null">Select a Project</option>
                      {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                        <option key={index} value={i.id}>{i.project_short_name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                  </div>
                </>
                }
                <div title="Vehicle" className="flex-column form-group-selectt">
                  <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                    {siteRole === "SiteManagement" ? ("Vehicle") : ("Vehicle (Select Project First)")} <span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="vehicle"
                    name="vehicle"
                    placeholder="vehicle"
                    value={formData.vehicle}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                  >

                    <option value="">Select a vehicle</option>
                    {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                </div>

                <div title="person_using_conveyance" className="flex-column form-group-selectt">
                  <label htmlFor="person_using_conveyance" className="form-labels  font-weight500    font-size-subheading">
                    {siteRole === "SiteManagement" ? ("Person Using Conveyance") : ("Person Using Conveyance (Select Project First)")} <span className="required">*</span>

                  </label>
                  <br />
                  <select
                    type="text"
                    id="person_using_conveyance"
                    name="person_using_conveyance"
                    placeholder="Person Using Conveyance"
                    value={formData.person_using_conveyance}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.person_using_conveyance ? "error" : inputState.person_using_conveyance ? "success" : ""}`}
                  >

                    <option value="">Select an Employee</option>
                    {employeeList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.emp_code}  {i.name}-{i.designation_name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.person_using_conveyance && <span className="error-message font-size-text ">{errors.person_using_conveyance}</span>}
                </div>

                <div className="flex-column">
                  <label htmlFor='date' className="form-labels  font-weight500 font-size-subheading">Date<span className="required">*</span></label>
                  <input
                    type="date"
                    name="date"
                    placeholder="Date"
                    value={formData.date}
                    onChange={handleInputChange}
                    className={`form-input ${errors.date ? 'is-invalid' : ''}`}
                  />
                  {errors.date && <div className="error-message font-size-text">{errors.date}</div>}
                </div>
                <div className='flex-row width-35vw justify-between'>
                  <div className="flex-column ">
                    <label htmlFor='from_time' className="form-labels  font-weight500 font-size-subheading">From Time<span className="required">*</span></label>
                    <input
                      id='from_time'
                      type="time"
                      name="from_time"
                      placeholder="Time"
                      value={formData.from_time}
                      onChange={handleInputChange}
                      className={`form-input form-input-time ${errors.from_time ? 'is-invalid' : ''}`}
                    />
                    {errors.from_time && <div className="error-message font-size-text">{errors.from_time}</div>}
                  </div>
                  <div className="flex-column">
                    <label htmlFor='to_time' className="form-labels   font-weight500 font-size-subheading">To Time<span className="required">*</span></label>
                    <input
                      id='to_time'
                      type="time"
                      name="to_time"
                      placeholder="Time"
                      value={formData.to_time}
                      min={formData.from_time}
                      onChange={handleInputChange}
                      className={`form-input form-input-time ${errors.to_time ? 'is-invalid' : ''}`}
                    />
                    {errors.to_time && <div className="error-message  font-size-text">{errors.to_time}</div>}
                  </div>
                </div>



                <div className="flex-column">
                  <label htmlFor='commencement_of_trip' className="form-labels  font-weight500 font-size-subheading">Commencement of Trip (Initial Reading) <span className="required">*</span></label>
                  <input
                    id='commencement_of_trip'
                    type="number"
                    min={0}
                    name="commencement_of_trip"
                    placeholder="Commencement of Trip"
                    value={formData.commencement_of_trip}
                    onChange={handleInputChange}
                    className={`form-input ${errors.commencement_of_trip ? 'is-invalid' : ''}`}
                  />
                  {errors.commencement_of_trip && <div className="error-message font-size-text">{errors.commencement_of_trip}</div>}
                </div>
                {formData.commencement_of_trip &&
                  <div className="flex-column">
                    <label htmlFor='conclusion_of_trip' className="form-labels  font-weight500 font-size-subheading">Conclusion of Trip (Final Reading)<span className="required">*</span></label>
                    <input
                      id='conclusion_of_trip'
                      type="number"
                      min={formData.commencement_of_trip}
                      name="conclusion_of_trip"
                      placeholder={formData.commencement_of_trip}
                      value={formData.conclusion_of_trip}
                      onChange={handleInputChange}
                      className={`form-input ${errors.conclusion_of_trip ? 'is-invalid' : ''}`}
                    />
                    {errors.conclusion_of_trip && <div className="error-message font-size-text">{errors.conclusion_of_trip}</div>}
                  </div>
                }

                <div className="flex-column">
                  <label htmlFor='purpose_and_place' className="form-labels  font-weight500 font-size-subheading">Purpose And Place<span className="required">*</span></label>
                  <input
                    id='purpose_and_place'
                    type="text"
                    name="purpose_and_place"
                    placeholder="Purpose And Place"
                    value={formData.purpose_and_place}
                    onChange={handleInputChange}
                    className={`form-input ${errors.purpose_and_place ? 'is-invalid' : ''}`}
                  />
                  {errors.purpose_and_place && <div className="error-message font-size-text">{errors.purpose_and_place}</div>}
                </div>
                <div className="button-models">
                  <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                    Cancel
                  </button>
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>




  )
}

const UpdateVehicleLogDetail = ({ getVehicleLogDetail, i }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [show, setShow] = useState(false);
  const [project, setProject] = useState("null");
  const [projectList, setProjectList] = useState([]);

  const handleShow = () => {
    getEmployee();
    setShow(true);
    getVehicle();
    setFormData(i);
    getProjects();
  }
  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(i);
    setProject(i);
  }


  const getProjects = async () => {
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      console.log("err2")
      console.log(err)
      handleErrorToast(err);
    } finally {

    }
  };

  const getEmployee = async () => {

    try {
      const emp = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${i.project_id}/null/`);
      setEmployeeList(emp.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {

    }
  }
  const getVehicle = async () => {

    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapibyproj/${i.project_id}`);
      setVehicleList(veh.data);

    } catch (err) {
      console.log("err1")
      console.log(err)
      handleErrorToast(err);

    } finally {

    }
  }


  const [formData, setFormData] = useState({
    vehicle: "",
    date: "",
    from_time: "",
    to_time: "",
    commencement_of_trip: "",
    conclusion_of_trip: "",
    person_using_conveyance: "",
    purpose_and_place: "",
  });

  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle",
      "date",
      "from_time",
      "to_time",
      "commencement_of_trip",
      "conclusion_of_trip",
      "purpose_and_place",
      "person_using_conveyance",

    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.put(
          `${BASE_URL}/siteproject/vehiclelogdetail/${i.id}/
`, formData
        );

        if (ress.status === 200) {
          await getVehicleLogDetail();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  // useEffect(() => {
  //   getProjects();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);



  useEffect(() => {

    if (project !== "null") {
      getVehicle();
    }
    else {
      setVehicleList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);



  return (


    <div>
      <>
        <button title='Update Vehicle Log Details' className='model-edit-button' onClick={handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
              fill="#145650"
            />
          </svg>
        </button>

        <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
          <Modal.Header closeButton>
            <Modal.Title>Update Vehicle Log Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <div className="parent-div">
              <div className="bdy-div">
                <form >
                  <div title="Vehicle" className="flex-column form-group-selectt">
                    <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                      Vehicle<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="vehicle"
                      name="vehicle"
                      placeholder="vehicle"
                      value={formData.vehicle}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                    >

                      <option value="">Select a vehicle</option>
                      {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                        <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                  </div>
                  <div title="person_using_conveyance" className="flex-column form-group-selectt">
                    <label htmlFor="person_using_conveyance" className="form-labels  font-weight500    font-size-subheading">
                      Person Using Conveyance<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="person_using_conveyance"
                      name="person_using_conveyance"
                      placeholder="person_using_conveyance"
                      value={formData.person_using_conveyance}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.person_using_conveyance ? "error" : inputState.person_using_conveyance ? "success" : ""}`}
                    >

                      <option value="">Select an Employee</option>
                      {employeeList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                        <option key={index} value={i.id}>{i.emp_code}  {i.name}-{i.designation_name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.person_using_conveyance && <span className="error-message font-size-text ">{errors.person_using_conveyance}</span>}
                  </div>

                  <div className="flex-column">
                    <label htmlFor='date' className="form-labels  font-weight500 font-size-subheading">Date<span className="required">*</span></label>
                    <input
                      type="date"
                      name="date"
                      placeholder="Date"
                      value={formData.date}
                      onChange={handleInputChange}
                      className={`form-input ${errors.date ? 'is-invalid' : ''}`}
                    />
                    {errors.date && <div className="error-message font-size-text">{errors.date}</div>}
                  </div>
                  <div className='flex-row width-35vw justify-between'>
                    <div className="flex-column">
                      <label htmlFor='from_time' className="form-labels  font-weight500 font-size-subheading">From Time<span className="required">*</span></label>
                      <input
                        id='from_time'
                        type="time"
                        name="from_time"
                        placeholder="Time"
                        value={formData.from_time}
                        onChange={handleInputChange}
                        className={`form-input form-input-time${errors.from_time ? 'is-invalid' : ''}`}
                      />
                      {errors.from_time && <div className="error-message font-size-text">{errors.from_time}</div>}
                    </div>
                    <div className="flex-column">
                      <label htmlFor='to_time' className="form-labels  font-weight500 font-size-subheading">To Time<span className="required">*</span></label>
                      <input
                        id='to_time'
                        type="time"
                        name="to_time"
                        placeholder="Time"
                        value={formData.to_time}
                        onChange={handleInputChange}
                        className={`form-input form-input-time${errors.to_time ? 'is-invalid' : ''}`}
                      />
                      {errors.to_time && <div className="error-message font-size-text">{errors.to_time}</div>}
                    </div>
                  </div>



                  <div className="flex-column">
                    <label htmlFor='commencement_of_trip' className="form-labels  font-weight500 font-size-subheading">Commencement of Trip (Initial Reading) <span className="required">*</span></label>
                    <input
                      id='commencement_of_trip'
                      type="number"
                      min={0}
                      name="commencement_of_trip"
                      placeholder="Commencement of Trip"
                      value={formData.commencement_of_trip}
                      onChange={handleInputChange}
                      className={`form-input ${errors.commencement_of_trip ? 'is-invalid' : ''}`}
                    />
                    {errors.commencement_of_trip && <div className="error-message font-size-text">{errors.commencement_of_trip}</div>}
                  </div>

                  <div className="flex-column">
                    <label htmlFor='conclusion_of_trip' className="form-labels  font-weight500 font-size-subheading">Conclusion of Trip (Final Reading)<span className="required">*</span></label>
                    <input
                      id='conclusion_of_trip'
                      type="number"
                      min={0}
                      name="conclusion_of_trip"
                      placeholder="Conclusion of Trip"
                      value={formData.conclusion_of_trip}
                      onChange={handleInputChange}
                      className={`form-input ${errors.conclusion_of_trip ? 'is-invalid' : ''}`}
                    />
                    {errors.conclusion_of_trip && <div className="error-message font-size-text">{errors.conclusion_of_trip}</div>}
                  </div>

                  <div className="flex-column">
                    <label htmlFor='purpose_and_place' className="form-labels  font-weight500 font-size-subheading">Purpose And Place<span className="required">*</span></label>
                    <input
                      id='purpose_and_place'
                      type="text"
                      name="purpose_and_place"
                      placeholder="Purpose And Place"
                      value={formData.purpose_and_place}
                      onChange={handleInputChange}
                      className={`form-input ${errors.purpose_and_place ? 'is-invalid' : ''}`}
                    />
                    {errors.purpose_and_place && <div className="error-message font-size-text">{errors.purpose_and_place}</div>}
                  </div>
                  <div className="button-models">
                    <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                      Cancel
                    </button>
                    <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                      {loading ? 'Updating...' : 'Update'}
                    </button>
                  </div>
                </form>
              </div></div>
          </Modal.Body>
        </Modal>
      </>

    </div>


  )
}

const DeleteVehicleLogDetail = ({ getVehicleLogDetail, i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);

  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/vehiclelogdetail/${i.id}/`);

      if (res.status === 200) {
        await getVehicleLogDetail();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      console.log("Error")
      console.log(err)
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Vehicle Log Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Vehicle Log Detail of  "{i.personusingconveyance}"

            <div className="button-models">
              <button className="model-button model-button-cancel   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
}
// !  ************** Vehicle Log Components End **************  ! //

//?  ********* Vehicle Billing Details Components Start******** ?//

const AddVehicleBillingDetails = ({ getVehicleBillingDetail, projectid }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleOwnerList, setVehicleOwnerList] = useState([]);
  const [show, setShow] = useState(false);


  const [setBuffering] = useState(false); //buffering logic
  const initialProject = "null";
  const [setProject] = useState(initialProject);
  const [projectList, setProjectList] = useState([]);

  const handleShow = () => {
    // getVehicle();
    getVehicleOwner();
    setShow(true);
  }
  const role = sessionStorage.getItem('role');


  const [formData, setFormData] = useState({
    vehicle_owner_details: "",
    vehicle: "",
    month: "",
    year: "",
    extra_amount: "",
    final_total_amount: "",
    less_tds: "",
    payable_amount: "",
    project: projectid ? projectid : null,

  });


  const getProjects = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  const getVehicleOwner = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const emp = await axios.get(`${BASE_URL}/siteproject/vehicleownerandbankdetail/`);
      setVehicleOwnerList(emp.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  }
  const getVehicle = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapi/${formData.project ? formData.project : "null"}`);
      setVehicleList(veh.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  }

  const initialFormData = {
    vehicle_owner_details: "",
    vehicle: "",
    month: "",
    year: "",
    extra_amount: "",
    final_total_amount: "",
    less_tds: "",
    payable_amount: "",
    project: projectid ? projectid : null,

  };



  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(initialFormData);

  }


  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle_owner_details",
      "vehicle",
      "month",
      "year",
      "extra_amount",
      "final_total_amount",
      "less_tds",
      "payable_amount",


    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.post(
          `${BASE_URL}/siteproject/vehiclebillingdetails/
`, formData
        );

        if (ress.status === 200) {
          await getVehicleBillingDetail();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  useEffect(() => {
    getProjects();
    if (formData.project !== "null") {
      getVehicle();
    }
    else {
      setVehicleList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project]);

  return (



    <>
      <button title="Add Vechile Billing Details" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {" "} Vehicle Billing Details
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Vehicle Billing Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form>
                {
                  role === "SiteManagement" ? "" : <>
                    <div title="Project" className="flex-column form-group-selectt">
                      <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                        Project<span className="required">*</span>
                      </label>
                      <br />
                      <select
                        type="text"
                        id="project"
                        name="project"
                        placeholder="project"
                        value={formData.project}
                        onChange={handleInputChange}
                        className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                      >

                        <option value="null">Select a Project</option>
                        {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                          <option key={index} value={i.id}>{i.project_short_name}</option>
                        ))}
                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                      {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                    </div>
                  </>
                }
                <div title="Vehicle" className="flex-column form-group-selectt">
                  <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                    {role === "SiteManagement" ? ("Vehicle") : ("Vehicle (Select Project First)")} <span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="vehicle"
                    name="vehicle"
                    placeholder="vehicle"
                    value={formData.vehicle}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                  >

                    <option value="">Select a vehicle</option>
                    {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                </div>

                <div title="vehicle_owner_details" className="flex-column form-group-selectt">
                  <label htmlFor="vehicle_owner_details" className="form-labels  font-weight500    font-size-subheading">
                    Vehicle's Owner Detail<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="vehicle_owner_details"
                    name="vehicle_owner_details"
                    placeholder="Vehicle's Owner Detail"
                    value={formData.vehicle_owner_details}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.vehicle_owner_details ? "error" : inputState.vehicle_owner_details ? "success" : ""}`}
                  >

                    <option value="">Owner Detail</option>
                    {vehicleOwnerList?.sort((a, b) => a.account_holder_name.localeCompare(b.account_holder_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}> {i.account_holder_name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.vehicle_owner_details && <span className="error-message font-size-text ">{errors.vehicle_owner_details}</span>}
                </div>
                <div title="Month" className="flex-column form-group-selectt">
                  <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="month"
                    name="month"
                    placeholder="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.month ? "error" : inputState.month ? "success" : ""}`}
                  >

                    <option value="">Select a month</option>
                    {monthOptions.map((month) => (
                      <option key={month.id} value={month.id}>{month.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.month && <span className="error-message font-size-text ">{errors.month}</span>}
                </div>
                <div title="Year" className="flex-column form-group-selectt">
                  <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="year"
                    name="year"
                    placeholder="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.year ? "error" : inputState.year ? "success" : ""}`}
                  >

                    <option value="">Select a Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.year && <span className="error-message font-size-text ">{errors.year}</span>}
                </div>
                <div className="flex-column">
                  <label htmlFor='extra_amount' className="form-labels  font-weight500 font-size-subheading">Extra Amount<span className="required">*</span></label>
                  <input
                    id='extra_amount'
                    type="number"
                    min={0}
                    name="extra_amount"
                    placeholder="Extra Amount"
                    value={formData.extra_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.extra_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.extra_amount && <div className="error-message font-size-text">{errors.extra_amount}</div>}
                </div>



                <div className="flex-column">
                  <label htmlFor='final_total_amount' className="form-labels  font-weight500 font-size-subheading">Final Total Amount <span className="required">*</span></label>
                  <input
                    id='final_total_amount'
                    type="number"
                    min={0}
                    name="final_total_amount"
                    placeholder="Final Total Amount"
                    value={formData.final_total_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.final_total_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.final_total_amount && <div className="error-message font-size-text">{errors.final_total_amount}</div>}
                </div>
                <div className="flex-column">
                  <label htmlFor='less_tds' className="form-labels  font-weight500 font-size-subheading">Less TDS <span className="required">*</span></label>
                  <input
                    id='less_tds'
                    type="number"
                    min={0}
                    name="less_tds"
                    placeholder="Less TDS"
                    value={formData.less_tds}
                    onChange={handleInputChange}
                    className={`form-input ${errors.less_tds ? 'is-invalid' : ''}`}
                  />
                  {errors.less_tds && <div className="error-message font-size-text">{errors.less_tds}</div>}
                </div>
                <div className="flex-column">
                  <label htmlFor='payable_amount' className="form-labels  font-weight500 font-size-subheading">Payable Amount <span className="required">*</span></label>
                  <input
                    id='payable_amount'
                    type="number"
                    min={0}
                    name="payable_amount"
                    placeholder="Payable Amount"
                    value={formData.payable_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.payable_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.payable_amount && <div className="error-message font-size-text">{errors.payable_amount}</div>}
                </div>
                <div className="button-models">
                  <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                    Cancel
                  </button>
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>




  )
}


const UpdateVehicleBillingDetail = ({ getVehicleBillingDetail, i }) => {
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleOwnerList, setVehicleOwnerList] = useState([]);
  const [show, setShow] = useState(false);
  // const [setBuffering] = useState(false); //buffering logic

  const handleShow = () => {
    getVehicle();
    getVehicleOwner();
    setShow(true);
    setFormData(i)
  }

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);
    setFormData(i);
    // setProject(i); 
  }


  const getVehicleOwner = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const emp = await axios.get(`${BASE_URL}/siteproject/filterbyprojvehicle/${i.project_id}`);
      setVehicleOwnerList(emp.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      // setBuffering(false);// End Buffering
    }
  }
  const getVehicle = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapibyproj/${i.project_id}`);
      setVehicleList(veh.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      // setBuffering(false);// End Buffering
    }
  }

  const [formData, setFormData] = useState({
    vehicle_owner_details: "",
    vehicle: "",
    month: "",
    year: "",
    extra_amount: "",
    final_total_amount: "",
    less_tds: "",
    payable_amount: "",
  });

  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle_owner_details",
      "vehicle",
      "month",
      "year",
      "extra_amount",
      "final_total_amount",
      "less_tds",
      "payable_amount",

    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let ress = await axios.put(
          `${BASE_URL}/siteproject/vehiclebillingdetails/${i.id}/`, formData);

        if (ress.status === 200) {
          await getVehicleBillingDetail();
          handleClose();
        } else {
          alert(ress);
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (


    <div>
      <>
        <button title='Update Billing Details' className='model-edit-button' onClick={handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
              fill="#145650"
            />
          </svg>
        </button>

        <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
          <Modal.Header closeButton>
            <Modal.Title>Update Vehicle Billing Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <div className="parent-div">
              <div className="bdy-div">
                <form>

                  <div title="Vehicle" className="flex-column form-group-selectt">
                    <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                      Vehicle<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="vehicle"
                      name="vehicle"
                      placeholder="vehicle"
                      value={formData.vehicle}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                    >

                      <option value="">Select a vehicle</option>
                      {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                        <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                  </div>

                  <div title="vehicle_owner_details" className="flex-column form-group-selectt">
                    <label htmlFor="vehicle_owner_details" className="form-labels  font-weight500    font-size-subheading">
                      Vehicle's Owner Detail<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="vehicle_owner_details"
                      name="vehicle_owner_details"
                      placeholder="Vehicle's Owner Detail"
                      value={formData.vehicle_owner_details}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.vehicle_owner_details ? "error" : inputState.vehicle_owner_details ? "success" : ""}`}
                    >

                      <option value="">Owner Detail</option>
                      {vehicleOwnerList?.sort((a, b) => a.account_holder_name.localeCompare(b.account_holder_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                        <option key={index} value={i.id}> {i.account_holder_name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.vehicle_owner_details && <span className="error-message font-size-text ">{errors.vehicle_owner_details}</span>}
                  </div>
                  <div title="Month" className="flex-column form-group-selectt">
                    <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                      Month<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="month"
                      name="month"
                      placeholder="month"
                      value={formData.month}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.month ? "error" : inputState.month ? "success" : ""}`}
                    >

                      <option value="">Select a month</option>
                      {monthOptions.map((month) => (
                        <option key={month.id} value={month.id}>{month.name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.month && <span className="error-message font-size-text ">{errors.month}</span>}
                  </div>
                  <div title="Year" className="flex-column form-group-selectt">
                    <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                      Year<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="year"
                      name="year"
                      placeholder="year"
                      value={formData.year}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.year ? "error" : inputState.year ? "success" : ""}`}
                    >

                      <option value="">Select a Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {errors.year && <span className="error-message font-size-text ">{errors.year}</span>}
                  </div>
                  <div className="flex-column">
                    <label htmlFor='extra_amount' className="form-labels  font-weight500 font-size-subheading">Extra Amount<span className="required">*</span></label>
                    <input
                      id='extra_amount'
                      type="number"
                      min={0}
                      name="extra_amount"
                      placeholder="Extra Amount"
                      value={formData.extra_amount}
                      onChange={handleInputChange}
                      className={`form-input ${errors.extra_amount ? 'is-invalid' : ''}`}
                    />
                    {errors.extra_amount && <div className="error-message font-size-text">{errors.extra_amount}</div>}
                  </div>



                  <div className="flex-column">
                    <label htmlFor='final_total_amount' className="form-labels  font-weight500 font-size-subheading">Final Total Amount <span className="required">*</span></label>
                    <input
                      id='final_total_amount'
                      type="number"
                      min={0}
                      name="final_total_amount"
                      placeholder="Final Total Amount"
                      value={formData.final_total_amount}
                      onChange={handleInputChange}
                      className={`form-input ${errors.final_total_amount ? 'is-invalid' : ''}`}
                    />
                    {errors.final_total_amount && <div className="error-message font-size-text">{errors.final_total_amount}</div>}
                  </div>
                  <div className="flex-column">
                    <label htmlFor='less_tds' className="form-labels  font-weight500 font-size-subheading">Less TDS <span className="required">*</span></label>
                    <input
                      id='less_tds'
                      type="number"
                      min={0}
                      name="less_tds"
                      placeholder="Less TDS"
                      value={formData.less_tds}
                      onChange={handleInputChange}
                      className={`form-input ${errors.less_tds ? 'is-invalid' : ''}`}
                    />
                    {errors.less_tds && <div className="error-message font-size-text">{errors.less_tds}</div>}
                  </div>
                  <div className="flex-column">
                    <label htmlFor='payable_amount' className="form-labels  font-weight500 font-size-subheading">Payable Amount <span className="required">*</span></label>
                    <input
                      id='payable_amount'
                      type="number"
                      min={0}
                      name="payable_amount"
                      placeholder="Payable Amount"
                      value={formData.payable_amount}
                      onChange={handleInputChange}
                      className={`form-input ${errors.payable_amount ? 'is-invalid' : ''}`}
                    />
                    {errors.payable_amount && <div className="error-message font-size-text">{errors.payable_amount}</div>}
                  </div>
                  <div className="button-models">
                    <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                      Cancel
                    </button>
                    <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                      {loading ? 'Updating...' : 'Update'}
                    </button>
                  </div>
                </form>
              </div></div>
          </Modal.Body>
        </Modal>
      </>

    </div>


  )
}

const DeleteVehicleBillingDetail = ({ getVehicleBillingDetail, i }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);

  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/vehiclebillingdetails/${i.id}/`);

      if (res.status === 200) {
        await getVehicleBillingDetail();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      console.log("Error")
      console.log(err)
      //toast Logic
      handleErrorToast(err);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Vehicle Billing Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Vehicle Billing Details of "{i.vehicleownerdetails}"

            <div className="button-models">
              <button className="model-button model-button-cancel   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
}


// !  ********* Vehicle Billing Details Components End******** ?//


//?  ********* Vehicle Billing Details Components Start******** ?//


const GenerateforVehicle = ({vehicleList }) => {

  const [show, setShow] = useState(false);


  const handleShow = () => {
    setShow(true);
  }

  const handleClose = () => {
    // getVehicle();
    setShow(false);
  }




  const [formData, setFormData] = useState({
    month: datearray[1],
    year: datearray[0],
    vehicle: "",
  });









  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicle",
      "month",
      "year",

    ]

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //


  const [generateAllVehicleloading, setGenerateAllVehicleLoading] = useState(false); //loading logic
  const handleGenerateAllVechicle = async (e) => {
    e.preventDefault();

    if (validateForm()) {



      setGenerateAllVehicleLoading(true); //loading logic

      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let ress = await axios.post(
          `${BASE_URL}/siteproject/generate-vehicle-bill-by-all-vehicle/${datearray[1]}/${datearray[0]}/`
        );

        if (ress.status === 200) {
          // await getVehicleBillingDetail();

        } else {
          alert(ress);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setGenerateAllVehicleLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }

    }
  };



  return (
    <>
      <button title="Add Vechile Billing Details" className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleShow}>
      Vehicle To Generate Bill
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Generate Bill of </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form>



                <div title="Month" className="flex-column form-group-selectt">
                  <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="month"
                    name="month"
                    placeholder="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input-date form-group-selection ${errors.month ? "error" : inputState.month ? "success" : ""}`}
                  >

                    <option value="">Select a month</option>
                    {monthOptions.map((month) => (
                      <option key={month.id} value={month.id}>{month.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>

                </div>

                <div title="Year" className="flex-column form-group-selectt">
                  <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="year"
                    name="year"
                    placeholder="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input-date form-group-selection ${errors.year ? "error" : inputState.year ? "success" : ""}`}
                  >

                    <option value="">Select a Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>

                </div>




                <div title="Vehicle" className="flex-column form-group-selectt">
                  <label htmlFor="vehicle" className="form-labels  font-weight500    font-size-subheading">
                    Vehicle<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="vehicle"
                    name="vehicle"
                    placeholder="vehicle"
                    value={formData.vehicle}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.vehicle ? "error" : inputState.vehicle ? "success" : ""}`}
                  >

                    <option value="">Select a vehicle</option>
                    {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.vehicle_num} - {i.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.vehicle && <span className="error-message font-size-text ">{errors.vehicle}</span>}
                </div>



                <div className="button-models">
                  <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                    Cancel
                  </button>

                  <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleGenerateAllVechicle} disabled={generateAllVehicleloading}>
                    {generateAllVehicleloading ? 'Generating...' : 'Generate For All Vehicle'}
                  </button>

                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>




  )
}


const GenerateVehicleBillingDetails = ({ getVehicleBillingDetail, projectid, vehicleList, projectList }) => {

  const [show, setShow] = useState(false);


  const handleShow = () => {
    // getVehicle();
    setShow(true);
  }

  const handleClose = () => {
    // getVehicle();
    setShow(false);
  }




  const [formData, setFormData] = useState({
    month: datearray[1],
    year: datearray[0],
  });









  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  // !  ************** Validation start **************  ! //




  const handleInputChange = (e) => {
    const { name, value } = e.target;



    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [generateAllProjectloading, setGenerateAllProjectLoading] = useState(false); //loading logic
  const handleGenerateAllProject = async (e) => {
    e.preventDefault();


    setGenerateAllProjectLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



    try {
      let ress = await axios.post(
        `${BASE_URL}/siteproject/generate-vehicle-bill-by-all-project/${datearray[1]}/${datearray[0]}/`
      );

      if (ress.status === 200) {
        await getVehicleBillingDetail();

      } else {
        alert(ress);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setGenerateAllProjectLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      toast.dismiss(loadingToastId);
    }
  };





  const [generateAllVehicleloading, setGenerateAllVehicleLoading] = useState(false); //loading logic
  const handleGenerateAllVechicle = async (e) => {
    e.preventDefault();

    setGenerateAllVehicleLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let ress = await axios.post(
        `${BASE_URL}/siteproject/generate-vehicle-bill-by-all-vehicle/${datearray[1]}/${datearray[0]}/`
      );

      if (ress.status === 200) {
        await getVehicleBillingDetail();

      } else {
        alert(ress);
      }
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setGenerateAllVehicleLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      toast.dismiss(loadingToastId);
    }
  };



  return (
    <>
      <button title="Add Vechile Billing Details" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {" "} Generate Bills
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Generate Bills</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form>



                <div title="Month" className="flex-column form-group-selectt">
                  <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="month"
                    name="month"
                    placeholder="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input-date form-group-selection `}
                  >

                    <option value="">Select a month</option>
                    {monthOptions.map((month) => (
                      <option key={month.id} value={month.id}>{month.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>

                </div>

                <div title="Year" className="flex-column form-group-selectt">
                  <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    type="text"
                    id="year"
                    name="year"
                    placeholder="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input-date form-group-selection`}
                  >

                    <option value="">Select a Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>

                </div>


                <div>
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleGenerateAllProject} disabled={generateAllProjectloading} >
                    {generateAllProjectloading ? 'Generating...' : 'Generate For All Project'}
                  </button>

                </div>

                <div>
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" onClick={handleGenerateAllVechicle} disabled={generateAllVehicleloading} >
                    {generateAllVehicleloading ? 'Generating...' : 'Generate For All Vehicle'}
                  </button>

                </div>
{/* 
                <div>
                <GenerateforVehicle vehicleList={vehicleList} />
                
                </div> */}



                <div className="button-models">
                  <button className="model-button-cancel model-button font-weight500" onClick={(e) => handleClose(e)}>
                    Cancel
                  </button>

                </div>
              </form>
            </div></div>
        </Modal.Body>
      </Modal>
    </>




  )
}


// !  ********* Vehicle Billing Details Components End******** ?//






const VehicleModelTable = () => {

  const [vehicleModel, setVehiclemodel] = useState([]);
  const [siteVehicle, setSiteVehicle] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSearch, setSearch] = useState("");
  const { projectID, siteRole } = usePermission();
  const [selectedProject, setSelectedProject] = useState(projectID ? projectID : null);
  const [projectList, setProjectList] = useState([]);

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const navigate = useNavigate();

  const ViewVechile = (vehicle) => {
    navigate(`/${urlParts[3]}/Vechiclelogbook/${vehicle}/`
    );
  };

  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);


    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };


  const getVehicle = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/vehicle/`);
      const ress = await axios.get(`${BASE_URL}/siteproject/vehiclefilterapibyproj/${selectedProject}/`);
      setVehiclemodel(res.data);
      setSiteVehicle(ress.data);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  }

  useEffect(() => {
    getVehicle();
    getProjects();
  }, [selectedProject])

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, selectedSearch, vehicleModel]);




  const applyFilters = () => {
    let filteredData = vehicleModel;
    if (selectedSearch !== "") {
      const lowercaseSelectedName = selectedSearch.toLowerCase();
      filteredData = filteredData.filter(project => {
        const name = project.name.toString().toLowerCase().includes(lowercaseSelectedName);
        const num = project.vehicle_num.toString().toLowerCase().includes(lowercaseSelectedName);
        return name || num;
      });
    }
    if (selectedProject) {
      filteredData = filteredData.filter(project => project.project === selectedProject);
    }

    setFilteredData(filteredData);
  };

  return (
    <div>

      <div className="attendance-heading  font-weight500    font-size-heading"> </div>

      {siteRole === "SiteManagement" ? ("") : (
        <div className="attendance-subcontainer margin">
          <div className="field-cont width-100vw justify-between">
            <div title='Search Filter' className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#707070"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#707070 "
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              <input className="attendance-input-field width-25vw"
                placeholder='Search Vehicle Number / Vehicle Name'
                type="text"
                value={selectedSearch}
                onChange={(e) => setSearch(e.target.value)} />
              <hr className="field-cont-hr" />

              <div title="Project Filter " className="field-cont-div ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clip-path="url(#clip0_650_3324)">
                    <path
                      d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                      fill="#707070"
                    />
                    <path
                      d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                      fill="#707070"
                    />
                    <path
                      d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                      fill="#707070"
                    />
                    <path
                      d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                      fill="#707070"
                    />
                    <path
                      d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                      fill="#707070"
                    />
                    <path
                      d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                      fill="#707070"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_650_3324">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <select

                  value={selectedProject}
                  onChange={(e) => setSelectedProject(e.target.value)}
                  className={`attendance-input-field width-15vw`}
                >

                  <option value="">Select Project</option>
                  {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                  ))}
                </select>
                <hr className="field-cont-hr" />

              </div>
            </div>

            <div>
              <AddVehicleModel getVehicle={getVehicle} />
            </div>
          </div>
        </div>
      )}


      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="table-css-white-background overflow-hidden flex-column">
        {siteRole === "SiteManagement" ? (
          <div className='flex-row justify-end'>
            <AddVehicleModel projectId={projectID} getVehicle={getVehicle} />

          </div>
        ) : ("")
        }
        <div className='table-box height-73vh '>
          <table className="table-css width-100vw">
            <thead className="table-heading">
              <tr className="custom-table-head-tr ">
                <th className="align-left">S.No.</th>
                <th className="align-center">Project Name</th>
                <th className="align-center">Vehicle Name</th>
                <th className="align-center">Vehicle Number</th>
                <th className="align-center">Fixed Kilometer</th>
                <th className="align-center">Fixed Bill Amount</th>
                <th className="align-center">Extra Amount Per KM After Fixed KM</th>
                {/* <th className='align-center'>Bank Details</th> */}
                {/* {siteRole === "Admin" ? (
                  <th className="align-center">Document</th>
                ) : ("")} */}
                <th className="align-center width-5vw">Document </th>

                <th className="align-center width-5vw">Action </th>
                {siteRole === "Admin" ? (
                  <th className="align-center">View Vehiclelog</th>
                ) : ("")}

              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (

              <tbody>
                {(siteRole === "SiteManagement" ? siteVehicle : filteredData).map((vehicle, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{monthIndex + 1}</td>
                      <td className="align-center">{vehicle.project_short_name}</td>
                      <td className="align-center">{vehicle.name}</td>
                      <td className="align-center">{vehicle.vehicle_num}</td>
                      <td className="align-center">{vehicle.fixed_km}</td>
                      <td className="align-center">{vehicle.fixed_bill_amt}</td>
                      <td className="align-center">{vehicle.extra_amt_above_fix_km}</td>

                      <td className='align-center'><DocumentModel siteVehicle={siteVehicle} vehicle={vehicle.id} getDocument={getVehicle} /></td>
                      {/* {siteRole === "Admin" ? (
                        <td className="align-center">
                          <Vehicledoc vehicle={vehicle} getVehicle={getVehicle} />
                        </td>) : ("")} */}

                      {/* <td className="align-center">{vehicle.</td> */}


                      <td className="justify-center  flex-row action-padding">
                        <div className='flex-row'>
                          <UpdateVehicle i={vehicle} getVehicle={getVehicle} />
                          {siteRole === "Account" ? "" :
                            <>
                              <DeleteVehicle i={vehicle} getVehicle={getVehicle} />
                            </>

                          }
                        </div>
                      </td>
                      {siteRole === "Admin" ? (
                        <td className="align-center">
                          <button onClick={() => ViewVechile(vehicle.id)} title="View Vehicle Log">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 30 30" fill="none">
                              <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                              <path
                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                fill="#2576BC"
                              />
                            </svg>
                          </button>
                        </td>) : ("")}

                    </tr>
                  </React.Fragment>
                ))}
              </tbody>

            )}
          </table>
        </div>
      </div>

    </div>
  )
}



const VehicleLogDetails = () => {
  const { siteRole, projectID } = usePermission();
  const [VehicleLogDetails, setVehicleLogDetails] = useState([]);
  const [siteLogDetails, setSiteLogDetails] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [vehicle, setVehicle] = useState("null");
  const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);


  const endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

  const [project, setProject] = useState(projectID ? projectID : "null");

  const [fromdate, setFromDate] = useState(startDate.toISOString().split('T')[0]);
  const [todate, setToDate] = useState(endDate.toISOString().split('T')[0]);
  const [fromTime, setFromTime] = useState("null");
  const [toTime, setToTime] = useState("null");
  const [projectList, setProjectList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  console.log(project);
  const getStartEndOfMonth = () => {
    return { start: startDate, end: endDate };
  };

  const getPreviousAndCurrentDate = () => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return { from: yesterday, to: today };
  };

  useEffect(() => {
    if ((project === null || project === "null") && (vehicle === 'null' || vehicle === null)) {
      const { from, to } = getPreviousAndCurrentDate();
      setFromDate(from.toISOString().split('T')[0]);
      setToDate(to.toISOString().split('T')[0]);
    } else {

      const { start, end } = getStartEndOfMonth();
      setFromDate(start.toISOString().split('T')[0]);
      setToDate(end.toISOString().split('T')[0]);

    }
  }, [project, vehicle]);

  const getVehicle = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehicle-short/`);
      setVehicleList(veh.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  }


  const getProjects = async () => {
    setBuffering(true); // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);


      const projectIDs = sortedProjects.map(project => project.id);
      console.log("Project IDs:", projectIDs); // You can use this array as needed

    } catch (err) {
      console.log("Error:", err);
      handleErrorToast(err);
    } finally {
      setBuffering(false); // End Buffering
    }
  };

  const getVehicleLogDetail = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/vehiclelogdetailbyallfilter/${vehicle}/${project}/${fromdate}/${todate}/${fromTime}/${toTime}/`);
      setVehicleLogDetails(response.data);
    }
    catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  }
  useEffect(() => {
    getProjects();
    getVehicle();
    getVehicleLogDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle, project, fromdate, todate, fromTime, toTime]);


  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {siteRole === "SiteManagement" ? ("") : (
        <div className="attendance-subcontainer margin">
          <div className="field-cont width-100vw justify-between">
            <div title=' Filter' className="field-cont-div">

              <div title="Vehicle Filter" className="field-cont-div ">
                <CircularGrid />
                <select
                  id="vehicle"
                  name="vehicle"
                  placeholder="vehicle"
                  value={vehicle}
                  onChange={(e) => setVehicle(e.target.value)}
                  className={`attendance-input-field width-10vw`}
                >

                  <option value="null">Select Vehicle</option>
                  {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.name}-{i.vehicle_num}</option>
                  ))}
                </select>


              </div>
              <hr className="field-cont-hr" />
              <div title="ProjectFilter" className="field-cont-div ">
                <CircularGrid />
                <select
                  id="project"
                  name="project"
                  placeholder="Project"
                  value={project}
                  onChange={(e) => setProject(e.target.value)}
                  className={`attendance-input-field width-10vw`}
                >

                  <option value="null">Select Project</option>
                  {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.project_short_name}</option>
                  ))}
                </select>

              </div>
              <hr className="field-cont-hr" />
              <div title='From Date' className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />

              </div>
              <hr className="field-cont-hr" />
              <div title='To Date' className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="To Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
              <div title='From Time' className="date-uppercase ">
                <input
                  className="attendance-input-field  date-field date-field-filter"
                  placeholder="From Time"
                  type="time"
                  value={fromTime}
                  onChange={(e) => setFromTime(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
              <div title='To time' className="date-uppercase ">
                <input
                  className="attendance-input-field    date-field date-field-filter"
                  placeholder="To time"
                  type="time"
                  value={toTime}
                  onChange={(e) => setToTime(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div>
              <AddVehicleLogDetails getVehicleLogDetail={getVehicleLogDetail} />
            </div>
          </div>
        </div>
      )}
      <div className="table-css-white-background overflow-hidden flex-column">
        {siteRole === "SiteManagement" ? (
          <div className='flex-row justify-end'>
            <AddVehicleLogDetails projectid={projectID} getVehicleLogDetail={getVehicleLogDetail} />
          </div>
        ) : ("")
        }
        <div className='table-box height-73vh'>
          <table className="table-css width-100vw">
            <thead className="table-heading">
              <tr className="custom-table-head-tr ">
                <th className='align-left'>S.No.</th>
                <th className='align-leftt'>Vehicle Name</th>
                <th className='align-center'>Date</th>
                <th className='align-center'>From Time</th>
                <th className='align-center'>To Time</th>
                <th className='align-center'>Commencement of Trip</th>
                <th className='align-center'>Conclusion of Trip</th>
                <th className='align-center'>Person Using Conveyance</th>
                <th className='align-center'>Purpose And Place</th>
                <th className='align-center'>Action</th>
              </tr>
            </thead>
            {buffer ? (
              <div className='spinner'></div>
            ) : (
              <tbody>
                {VehicleLogDetails.map((vehicle, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className='custom-table-head-td'>
                      <td className="align-left">{monthIndex + 1}</td>
                      <td className="align-leftt">{vehicle.vehicle_name}</td>
                      <td className="align-center">{formattedDateLong(vehicle.date)}</td>
                      <td className="align-center">{vehicle.from_time}</td>
                      <td className="align-center">{vehicle.to_time}</td>
                      <td className="align-center">{vehicle.commencement_of_trip}</td>
                      <td className="align-center">{vehicle.conclusion_of_trip}</td>
                      <td className="align-center">{vehicle.personusingconveyance}</td>
                      <td className="align-center">{vehicle.purpose_and_place}</td>

                      <td className="justify-center  action-padding flex-row">
                        <div className='flex-row'>
                          <UpdateVehicleLogDetail i={vehicle} getVehicleLogDetail={getVehicleLogDetail} />
                          {siteRole === "Account" ? "" :
                            <DeleteVehicleLogDetail i={vehicle} getVehicleLogDetail={getVehicleLogDetail} />
                          }
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  )

}


const VehicleBillingDetails = () => {
  const [vehicleBillingDetails, setVehicleBillingDetails] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [project, setProject] = useState("null");
  const [search, setSearch] = useState("")
  const [projectList, setProjectList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [vehicle, setVehicle] = useState("null");
  const [vehicleList, setVehicleList] = useState([]);


  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;

  const [filterMonth, setFilterMonth] = useState(`${month}`);
  const [filterYear, setFilterYear] = useState(`${year}`);
  const { projectID, siteRole } = usePermission();
  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getVehicleBillingDetail = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/filter-vehicle-billing/${project}/${vehicle}/${filterMonth}/${filterYear}/`);
      setVehicleBillingDetails(response.data);

    }
    catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  }
  useEffect(() => {
    getVehicleBillingDetail();
    getProjects();
    getVehicle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const getMonthName = (monthNumber) => {
    const month = monthOptions.find(m => m.id.toString() === monthNumber);
    return month ? month.name : '';
  };

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);



  const getVehicle = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const veh = await axios.get(`${BASE_URL}/siteproject/vehicle-short/`);
      setVehicleList(veh.data);

    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  }





  const role = sessionStorage.getItem('role');


  console.log(vehicleBillingDetails)

  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {role === "SiteManagement" ? ("") : (

        <div className="attendance-subcontainer margin">
          <div className="field-cont width-100vw justify-between">
            <div className="flex-row">
              <div title='Month Filter' className="field-cont-div">
                <CircularGrid />

                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={filterMonth}
                  onChange={(e) => setFilterMonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>

                <hr className="field-cont-hr" />
              </div>

              <div title='Year Filter' className="field-cont-div">
                <CircularGrid />

                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={filterYear}
                  onChange={(e) => setFilterYear(e.target.value)}
                >
                  <option value="">Select Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>

                <hr className="field-cont-hr" />
              </div>

              <div title="Project Filter " className="field-cont-div ">
                <CircularGrid />
                <select
                  id="project"
                  name="project"
                  placeholder="Project"
                  value={project}
                  onChange={(e) => setProject(e.target.value)}
                  className={`attendance-input-field width-15vw`}
                >

                  <option value="null">Select a Project</option>
                  {projectList?.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.site_prcode}-{i.project_short_name}
                    </option>
                  ))}
                </select>
                <hr className="field-cont-hr" />

              </div>

              <div title="Vehicle Filter" className="field-cont-div ">
                <CircularGrid />
                <select
                  id="vehicle"
                  name="vehicle"
                  placeholder="vehicle"
                  value={vehicle}
                  onChange={(e) => setVehicle(e.target.value)}
                  className={`attendance-input-field width-10vw`}
                >

                  <option value="null">Select Vehicle</option>
                  {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.name}-{i.vehicle_num}</option>
                  ))}
                </select>


              </div>
            </div>

            <div>
              <AddVehicleBillingDetails getVehicleBillingDetail={getVehicleBillingDetail} />
              <GenerateVehicleBillingDetails getVehicleBillingDetail={getVehicleBillingDetail} vehicleList={vehicleList} projectList={projectList} />

            </div>
          </div>
        </div>
      )}
      <div className="table-css-white-background overflow-hidden flex-column">
        {/* {role === "SiteManagement" ? (
          <div className='flex-row justify-end'>
            <AddVehicleBillingDetails projectid={projectID} getVehicleBillingDetail={getVehicleBillingDetail} />
          </div>
        ) : ("")
        }

        */}
        <div className='table-box height-73vh'>
          <table className="table-css width-100vw">
            <thead className="table-heading">
              <tr className="custom-table-head-tr ">
                <th className='align-left'>S.No.</th>
                <th className='align-center'>Vehicle No</th>
                <th className='align-center'>Vehicle Name</th>

                <th className='align-center'>Fixed KM</th>
                <th className='align-center'>Extra KM</th>
                <th className='align-center'>Total KM</th>



                <th className='align-center'>Action</th>
              </tr>




            </thead>
            {buffer ? (
              <div className='spinner'></div>
            ) : (
              <tbody>
                {vehicleBillingDetails.map((vehicle, monthIndex) => (
                  <React.Fragment key={monthIndex}>
                    <tr className='custom-table-head-td'>
                      <td className="align-left">{monthIndex + 1}</td>
                      <td className="align-center">{vehicle.vehicle.vehicle_num}</td>
                      <td className="align-center">{vehicle.vehicle.name}</td>

                      <td className="align-center">{vehicle.fixed_km}</td>
                      <td className="align-center">{vehicle.final_total_amount}</td>
                      <td className="align-center">{vehicle.less_tds}</td>
                      <td className="align-center">{vehicle.payable_amount}</td>

                      <td className=" justify-center action-padding flex-row ">
                        <div className='flex-row'>
                          <UpdateVehicleBillingDetail i={vehicle} getVehicleBillingDetail={getVehicleBillingDetail} />
                          {siteRole === "Account" ? "" :
                            <DeleteVehicleBillingDetail i={vehicle} getVehicleBillingDetail={getVehicleBillingDetail} />
                          }
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  )

}


const VehicleManagement = () => {


  const [toggleState, setToggleState] = useState(1);
  const { siteRole } = usePermission();
  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return "Vehicle Model";
      case 2:
        return "Vehicle Log Details";
      case 3:
        return "Vehicle Billing Details";
      default:
        return "Vehicle Model";
    }
  };
  return (
    <div>
      <div>

        <Dashboardnavbar name={getSelectedTitle()} url="Vehicle Monitoring" />
      </div>
      <div className="bloc-tabss wfm-marginn">
        <button
          className={
            toggleState === 1
              ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
          }
          onClick={() => setToggleState(1)}
        >
          Vehicle Model
        </button>

        <button
          className={
            toggleState === 2
              ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs "
              : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
          }
          onClick={() => setToggleState(2)}
        >
          Vehicle Log Detail
        </button>
        {siteRole === "SiteManagement" ? "" :
          <button
            className={
              toggleState === 3
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading  "
            }
            onClick={() => setToggleState(3)}
          >
            Vehicle Billing Details
          </button>}


      </div>
      <div className="content-tabs">
        {toggleState === 1 ? <VehicleModelTable /> : null}
        {toggleState === 2 ? <VehicleLogDetails /> : null}
        {toggleState === 3 ? <VehicleBillingDetails /> : null}

      </div>
    </div>
  )

}

export { VehicleManagement, AddVehicleModel, AddVehicleBillingDetails, AddVehicleLogDetails }