import React, { useEffect, useState, useRef } from "react";
import usePermission from "../../config/permissions";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { toast, ToastContainer } from "react-toastify";


import { formattedDateLong } from "../Date";
import {
  AddProjectDetails,


} from "./ProjectDetailsCrudButtons";

import { useNavigate } from "react-router-dom";
import { ProjectProgressBar } from "./SiteProjectManagementComponents";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { formatRoundoff, formatRoundoffCrores, handleErrorToast, sortProjects } from "../CustomFunctions";
import { CircularGrid, DeleteDustbin, Eye, Search, SearchSmall, Sort } from "../AllSvg";
import { Modal } from "react-bootstrap";



const DeleteProject = ({ i, fetchContractorProjects }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/project/${i.id}/`);

      if (res.status === 200) {
        await fetchContractorProjects();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete Project" className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Project {i.project_short_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Project {i.project_short_name}
            <div className="button-models">
              <button className="model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button font-weight500  model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};



const SiteProjectTable = () => {
  const { siteRole } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(null);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [selectedCC, setSelectedCC] = useState('contractor');
  const [sorting, setSorting] = useState("asc_pr");

  // Fetch sub-companies when the component mounts
  useEffect(() => {
    const fetchSubCompanies = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
        setSiteSubCompanyList(response.data);
      } catch (error) {
        console.error('Error fetching sub-companies:', error);
      }
    };

    fetchSubCompanies();
  }, []);

  // Event handlers
  const handleCompanyChange = (e) => setToggleCompany(e.target.value);
  const handleSortChange = (e) => setSorting(e.target.value);
  const handleCCChange = (e) => setSelectedCC(e.target.value);
  const handleNameChange = (e) => setSelectedName(e.target.value);


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dashboardnavbar name="All Projects" url="Projects" />

      <div className="content-tabs">
        <div>
          <div className="attendance-subcont">
            <div className="field-cont">
              <div title="Company Filter" className="field-cont-div">
                <CircularGrid />
                <select
                  onChange={handleCompanyChange}
                  value={toggleCompany || 'null'}
                  className="attendance-input-field width-15vw date-field"
                >
                  <option value="null">Select Sub Company</option>
                  {siteSubCompanyList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.title}
                    </option>
                  ))}
                </select>
                <hr className="field-cont-hr" />
              </div>

              <div title="Sort Filter" className="field-cont-div">
                <Sort />
                <select
                  className="attendance-input-field width-15vw   date-field"
                  type="text"
                  value={sorting}
                  onChange={handleSortChange}
                >
                  <option value="asc_pr">Asc. Pr Code</option>
                  <option value="desc_pr">Desc. Pr Code</option>
                  <option value="asc_short_name">Asc. Project Name</option>
                  <option value="desc_short_name">Desc. Project Name</option>
                </select>
                <hr className="field-cont-hr" />
              </div>


              <div title="CC Filter" className="field-cont-div">
                <CircularGrid />
                <select
                  className="attendance-input-field width-10vw date-field"
                  type="text"
                  value={selectedCC}
                  onChange={handleCCChange}
                >
                  <option value="contractor">Contractor</option>
                  <option value="consultant">Consultant</option>
                </select>
                <hr className="field-cont-hr" />
              </div>

              <div title="Search Filter" className="field-cont-div">
                <SearchSmall />
                <input
                  className="attendance-input-field width-15vw"
                  placeholder=" Code / Project Name"
                  type="text"
                  value={selectedName}
                  onChange={handleNameChange}
                />
              </div>
            </div>
            {siteRole === "Hr" || siteRole === "Account" ? "" :
              <div className="btn-cont">
                <AddProjectDetails />
              </div>
            }
          </div>
        </div>

        {/* Conditionally render the views based on selectedCC */}
        {selectedCC === 'contractor' ? (
          <ProjectContractorView
            subCompany={toggleCompany}
            searchName={selectedName}
            searchSort={sorting}
          />
        ) : (
          <ProjectConsultantView
            subCompany={toggleCompany}
            searchName={selectedName}
            searchSort={sorting}
          />
        )}
      </div>
    </>
  );
};




const ProjectContractorView = ({ subCompany, searchName, searchSort }) => {



  const [projectsList, setProjectsList] = useState([]);
  const [buffer, setBuffering] = useState(true);
  const navigate = useNavigate();
  const { siteRole } = usePermission();
  // Function to fetch projects for contractors
  const fetchContractorProjects = async () => {
    setBuffering(true);
    try {
      // const response = await axios.get(`${BASE_URL}/siteproject/projectdashboard/contractor/`);
      const response = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' || sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
          `${BASE_URL}/siteproject/projectdashboard/contractor/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : `${BASE_URL}/siteproject/projectdashboard/contractor/`
      );

      let filteredProjects = response.data;

      // Apply sub-company filter
      if (subCompany && subCompany !== 'null') {
        filteredProjects = filteredProjects.filter(
          (project) => project.sub_company === subCompany
        );
      }


      if (searchName) {
        const lowerCaseName = searchName.toLowerCase();
        filteredProjects = filteredProjects.filter(
          (project) =>
            project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
            project.project_name.toLowerCase().includes(lowerCaseName) ||
            project.project_short_name.toLowerCase().includes(lowerCaseName)
        );
      }

      switch (searchSort) {
        case "asc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode");
          break;
        case "desc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode").reverse();
          break;
        case "asc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            a.project_short_name.localeCompare(b.project_short_name)
          );
          break;
        case "desc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            b.project_short_name.localeCompare(a.project_short_name)
          );
          break;
        default:
          break;
      }

      setProjectsList(filteredProjects);
    } catch (error) {
      console.error('Error fetching contractor projects:', error);
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    fetchContractorProjects();
  }, [subCompany, searchName, searchSort]); // Refetch projects when filters change

  const tableRef = useRef(null);

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const toggleshow = "Contractor";

  const ViewAllProjectDetails = (project) => {
    navigate(`/${urlParts[3]}/ProjectAllDetails/`, {
      state: { project, toggleshow },
    });
  };











  return (
    <>


      <div className=" table-css-white-background height-73vh table-box" style={{paddingTop:"0px"}}>
        <table ref={tableRef} className="font-size-label table-css">
          <thead className="table-heading">
            <tr className="custom-table-head-th">
              <th className="align-leftt font-size-text">Project</th>
              <th className="align-center font-size-text">Progress(%)</th>
              <th className="align-center font-size-text">Project Details</th>
              {siteRole === "Hr" ? "" :
                <th className="align-center font-size-text">Billing details</th>
              }
              <th className="align-center font-size-text">Change of Scope</th>
              <th className="align-center font-size-text">Extension of Time</th>
              <th className="align-center width-5vw font-size-text" style={{ width: "5vw" }}>View Details</th>
              {siteRole === "Hr" || siteRole === "BD" || siteRole === "Account" ? "" :
                <th className="align-right width-5vw font-size-text">Delete</th>
              }

              {/* <th className="align-center width-5vw font-size-text">BOQ</th> */}

            </tr>
          </thead>
          {buffer ? <div className="spinner-z"></div> : null}
          <tbody>
            {projectsList?.sort((a, b) => {
              if (a.year === b.year) { return b.month - a.month; }
              return b.year - a.year;
            }).map((project) => {
              const progressData = [

                {
                  month: project?.latest_progress?.month,
                  Physical: project?.latest_progress?.physical_progress,
                  Financial: project?.latest_progress?.financial_progress,
                },

              ];
              return (
                <tr key={project.id} className="custom-table-head-td4">
                  <td style={{ width: "5vw" }} title={`Project Name: \n${project.project_name}`} className="align-left">
                    {project.site_prcode} : <br /> {project.project_short_name}
                  </td>
                  <td style={{ width: "5vw" }} className="align-center">
                    <div className="employee-card-image">
                      <ProjectProgressBar data={progressData} />
                      {/* <ProjectProgressBar data={project.progress[0]} /> */}
                    </div>
                  </td>

                  <td className="align-center">
                    {project.project_name ? (
                      <div className="employee-card-image">
                        <table
                          className="nested-table font-size-subheading"
                          // style={{ cursor: "pointer" }}
                          // onClick={() => ViewProjectDetails(project)}
                          title="Go to Project Contractor Details"
                        >
                          <tbody>
                            <tr>
                              <td>Contractor Award</td>
                              <td>
                                {project?.loa_award_date
                                  ? formattedDateLong(project?.loa_award_date)
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Agreement Date</td>
                              <td>
                                {project?.agreement_date
                                  ? formattedDateLong(project?.agreement_date)
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Schedule Construction Completion Date</td>
                              <td>
                                {project?.schedule_construction_completion_date
                                  ? formattedDateLong(
                                    project?.schedule_construction_completion_date
                                  )
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <td>Construction Cost (CR)</td>
                              <td>
                                {project?.construction_cost
                                  ? formatRoundoffCrores(
                                    project?.construction_cost
                                  )
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Contract Mode</td>
                              <td style={{ textTransform: "uppercase" }}>
                                {project?.contract_mode
                                  ? project?.contract_mode
                                  : "-"}
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="align-center">
                        <p>Project Details Unavailable.</p>
                      </div>
                    )}
                  </td>
                  {siteRole === "Hr" ? "" :
                    <td className="align-center">
                      {project?.billing_details.bill_recommended_total || project?.billing_details.bill_submitted_total
                        || project?.billing_details.bill_ld_total
                        || project?.billing_details.bill_withheld_total
                        || project?.billing_details.balancebills_submitted_total
                        || project?.billing_details.balancebills_recommended_total
                        || project?.billing_details.balancebills_ld_total
                        || project?.billing_details.balancebills_withheld_total
                        || project?.billing_details.balancebills_released_withheld_total
                        ? (
                          <div className="employee-card-image">
                            <table
                              className="nested-table font-size-subheading"
                              // style={{ cursor: "pointer" }}
                              // onClick={() => ViewProjectDetails(project)}
                              title="Go to Project Contractor Details"
                            >
                              <tbody>
                                <tr>
                                  <td>Total Bill Submitted Amount</td>
                                  <td>
                                    {project?.billing_details.bill_submitted_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.bill_submitted_total))}`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Bill Recommended</td>
                                  <td>
                                    {project?.billing_details.bill_recommended_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.bill_recommended_total))}`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Bill LD Amount</td>
                                  <td>
                                    {project?.billing_details.bill_ld_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.bill_ld_total))}`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Bill Withheld Amount</td>
                                  <td>
                                    {project?.billing_details.bill_withheld_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.bill_withheld_total))}`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Balance Bills Submitted Amount</td>
                                  <td>
                                    {project?.billing_details.balancebills_submitted_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.balancebills_submitted_total))}`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Balance Bills Recommended Amount</td>
                                  <td>
                                    {project?.billing_details.balancebills_recommended_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.balancebills_recommended_total))}`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Balance Bills LD Amount</td>
                                  <td>
                                    {project?.billing_details.balancebills_ld_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.balancebills_ld_total))}`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Balance Bills Withheld Amount</td>
                                  <td>
                                    {project?.billing_details.balancebills_withheld_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.balancebills_withheld_total))}`
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Balance Bills Released Amount</td>
                                  <td>
                                    {project?.billing_details.balancebills_released_withheld_total
                                      ? `Rs.${formatRoundoff(Number(project.billing_details.balancebills_released_withheld_total))}`
                                      : "-"}
                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="align-center ">
                            <p className="text-center">Billing Details Unavailable.</p>
                          </div>
                        )}
                    </td>
                  }




                  <td className="align-center">
                    {project.coss.contractor_coss?.length > 0 ? (
                      <div className="employee-card-image">
                        <table className="nested-table font-size-subheading">
                          <thead>
                            <tr>
                              <th>COS</th>
                              <th>Approved Date</th>
                              <th>Amount</th>

                            </tr>
                          </thead>
                          <tbody>
                            {project.coss.contractor_coss.map((cosDetails) => (
                              <tr>
                                <td>{cosDetails.cos_code}</td>
                                <td>
                                  {formattedDateLong(cosDetails.approval_date)}
                                </td>
                                <td>Rs. {formatRoundoff(Number(cosDetails.amount))}</td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div >
                        <p className="align-center">No Contractor COS Data Available.</p>
                      </div>
                    )}
                  </td>

                  <td className="align-center">
                    {project.eots.contractor_eots.length > 0 ? (
                      <div className="employee-card-image">
                        <table className="nested-table font-size-subheading">
                          <thead>
                            <tr>
                              <th>EOT</th>
                              <th>Approved Date</th>
                              <th>Duration</th>

                            </tr>
                          </thead>
                          <tbody>
                            {project.eots.contractor_eots.map((eotDetails) => (
                              <tr>
                                <td>{eotDetails.eot_code}</td>
                                <td>
                                  {formattedDateLong(eotDetails.approved_date)}
                                </td>
                                <td>{eotDetails.approved_days}</td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div >
                        <p className="align-center">No Contractor EOT Data Available.</p>
                      </div>
                    )}
                  </td>


                  <td className="align-center width-5vw" style={{ width: "5vw" }}>

                    <button
                      onClick={() => ViewAllProjectDetails(project)}
                      title="All Project Details"
                    >
                      <Eye />
                    </button>
                  </td>

                  {
                    siteRole === "Hr" || siteRole === "BD" || siteRole === "Account" ? "" :
                      <td className="align-center width-5vw" style={{ width: "5vw" }}>

                        <DeleteProject i={project} fetchContractorProjects={fetchContractorProjects} />
                      </td>
                  }






                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const ProjectConsultantView = ({ subCompany, searchName, searchSort }) => {


  const [projectsList, setProjectsList] = useState([]);
  const [buffer, setBuffering] = useState(true);
  const navigate = useNavigate();

  // Function to fetch projects for consultants
  const fetchConsultantProjects = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectdashboard/consultant/`);


      let filteredProjects = response.data;

      // Apply sub-company filter
      if (subCompany && subCompany !== 'null') {
        filteredProjects = filteredProjects.filter(
          (project) => project.sub_company === subCompany
        );
      }

      // Apply search name filter
      if (searchName) {
        const lowerCaseName = searchName.toLowerCase();
        filteredProjects = filteredProjects.filter(
          (project) =>
            project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
            project.project_name.toLowerCase().includes(lowerCaseName) ||
            project.project_short_name.toLowerCase().includes(lowerCaseName)
        );
      }

      switch (searchSort) {
        case "asc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode");
          break;
        case "desc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode").reverse();
          break;
        case "asc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            a.project_short_name.localeCompare(b.project_short_name)
          );
          break;
        case "desc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            b.project_short_name.localeCompare(a.project_short_name)
          );
          break;
        default:
          break;
      }

      // const sortingdata = sortProjects(filteredProjects)

      setProjectsList(filteredProjects);

    } catch (error) {
      console.error('Error fetching consultant projects:', error);
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    fetchConsultantProjects();
  }, [subCompany, searchName, searchSort]); // Refetch projects when filters change


  const [viewMode, setViewMode] = useState('inactive'); // employees or inactive
  const tableRef = useRef(null);


  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const toggleshow = "Consultant";

  const ViewAllProjectDetails = (project) => {
    navigate(`/${urlParts[3]}/ProjectAllDetails/`, {
      state: { project, toggleshow },
    });
  };

  // Helper function to split an array into two equal parts
  const splitArray = (array) => {
    const midIndex = Math.ceil(array.length / 2);
    return [array.slice(0, midIndex), array.slice(midIndex)];
  };

  const handleViewToggle = (mode) => {
    setViewMode(mode);
  };

  return (
    <>
      <div className="filter-showing">
        <div>Consultant details:</div>
        <div>
          Showing {projectsList.length} of {projectsList.length}
        </div>
      </div>

      {/* Toggle between Employees and Inactive Designations */}
      <div className="bloc-tabss2  wfm-margin" >
        <button
          className={viewMode === "inactive" ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}

          onClick={() => handleViewToggle('inactive')}
        >
          Vacant Positions
        </button>
        <button
          className={viewMode === "employees" ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => handleViewToggle('employees')}
        >
          View Employees
        </button>

      </div>

      <div className="table-css-white-background  table-box" style={{paddingTop:"0px",height:"63vh"}}>
        <table ref={tableRef} className="font-size-label table-css">
          <thead className="table-heading">
            <tr className="custom-table-head-th">
              <th className="align-left font-size-text">Project</th>
              <th className="align-center font-size-text">Project Details</th>
              <th className="align-center font-size-text">Key Professionals</th>
              <th className="align-center font-size-text">Sub Professionals</th>
              <th className="align-center font-size-text">Support Staff</th>
              <th className="width-5vw align-center font-size-text" style={{ width: "5vw" }}>View Details</th>
              <th className="align-right width-5vw font-size-text">Delete</th>

            </tr>
          </thead>
          {buffer ? <div className="spinner-z"></div> : null}
          <tbody>
            {projectsList?.map((project) => (
              <tr key={project.id} className="custom-table-head-td4">
                {/* Project Name and Short Description */}
                <td style={{ width: "5vw" }} title={`${project.project_name}`} className="align-left">
                  {project.site_prcode} : {project.project_short_name}
                </td>

                {/* Project Details */}
                <td className="align-center">
                  {project.project_name ? (
                    <div className="employee-card-image">
                      <table className="nested-table font-size-subheading">
                        <tbody>
                          <tr>
                            <td>Consultant LOA</td>
                            <td>{project?.loa_award_date ? formattedDateLong(project?.loa_award_date) : "-"}</td>
                          </tr>
                          <tr>
                            <td>Completion Date</td>
                            <td>{project?.project_completion_date ? formattedDateLong(project?.project_completion_date) : "-"}</td>
                          </tr>
                          <tr>
                            <td>Consultancy Fees</td>
                            <td>{project?.consultancy_fees || "-"}</td>
                          </tr>
                          <tr>
                            <td>Contract Mode</td>
                            <td>{project?.contract_mode || "-"}</td>
                          </tr>
                          <tr>
                            <td>Lead, JV, Association</td>
                            <td>{project.lead}, {project.jv}, {project.association}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="align-center ">
                      <p className="text-center">Project Details Unavailable</p>
                    </div>
                  )}
                </td>

                {/* Key Professionals */}
                <td className="align-center">
                  {viewMode === 'employees' && project.employees?.key_professionals?.length > 0 ? (
                    <div className="employee-card-image">
                      <table className="nested-table font-size-subheading">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {project.employees?.key_professionals?.map((keyProfessional) => (
                            <tr key={keyProfessional.id}>
                              <td>{keyProfessional.name}</td>
                              <td>{keyProfessional.designation}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.key_professionals?.length > 0 ? (
                    <div className="employee-card-image">
                      <table className="nested-table font-size-subheading">
                        <thead>
                          <tr>
                            <th>Designation</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {project.inactive_or_no_employee_designations.key_professionals.map((designation, index) => (
                            <tr key={index}>
                              <td>{designation.designation}</td>
                              <td>{designation.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="align-center ">
                      <p className="text-center">No Key Professional Data Available</p>
                    </div>
                  )}
                </td>

                {/* Sub Professionals */}
                <td className="align-center">
                  {viewMode === 'employees' && project.employees.sub_professionals?.length > 0 ? (
                    <div className="employee-card-image">
                      {splitArray(project.employees.sub_professionals).map((split, index) => (
                        <div key={index} className="split-table">
                          <table className="nested-table font-size-subheading">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Designation</th>
                              </tr>
                            </thead>
                            <tbody>
                              {split.map((subProfessional) => (
                                <tr key={subProfessional.id}>
                                  <td>{subProfessional.name}</td>
                                  <td>{subProfessional.designation}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </div>
                  ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.sub_professionals?.length > 0 ? (
                    <div className="employee-card-image">
                      <table className="nested-table font-size-subheading">
                        <thead>
                          <tr>
                            <th>Designation</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {project.inactive_or_no_employee_designations.sub_professionals.map((designation, index) => (
                            <tr key={index}>
                              <td>{designation.designation}</td>
                              <td>{designation.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="align-center ">
                      <p className="text-center">No Sub Professional Data Available</p>
                    </div>
                  )}
                </td>

                {/* Support Staff */}
                <td className="align-center">
                  {viewMode === 'employees' && project.employees.support_staff?.length > 0 ? (
                    <div className="employee-card-image">
                      <table className="nested-table font-size-subheading">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {project.employees.support_staff.map((supportStaff) => (
                            <tr key={supportStaff.id}>
                              <td>{supportStaff.name}</td>
                              <td>{supportStaff.designation}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.support_staff?.length > 0 ? (
                    <div className="employee-card-image">
                      <table className="nested-table font-size-subheading">
                        <thead>
                          <tr>
                            <th>Designation</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {project.inactive_or_no_employee_designations.support_staff.map((designation, index) => (
                            <tr key={index}>
                              <td>{designation.designation}</td>
                              <td>{designation.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="align-center ">
                      <p className="text-center">No Support Staff Data Available</p>
                    </div>
                  )}
                </td>

                {/* View Details Button */}
                <td className="width-5vw align-center" style={{ width: "5vw" }}>
                  <button onClick={() => ViewAllProjectDetails(project)} title="All Project Details">
                    <Eye />
                  </button>
                </td>



                <td className="align-center width-5vw" style={{ width: "5vw" }}>

                  <DeleteProject i={project} fetchContractorProjects={fetchConsultantProjects} />
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};




export {

  SiteProjectTable,
};
