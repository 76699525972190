import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";
import { formattedDate } from "../../Date";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import usePermission from "../../../config/permissions";

const ExpenseAdd = ({ getExpansee }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setImprestExist(null)
    setShow(false)
  };
  const handleShow = () => {
    getAllempdata()
    setShow(true)
  };
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();



  const [formData, setFormData] = useState({
    name: "",
    imprest: "",
    date: formattedDate,
    empcode: sessionStorage.getItem("emp_code"),
    project: sessionStorage.getItem('sitemanagement_project'),
    // project_name: "",
    transportation: 0,
    accomodation: 0,
    fooding: 0,
    other_name: "",
    other: 0,
    other_details: [
      // {
      //   other_name: "",
      //   amount: 0,
      //   approved_other: 0,
      //   remaining_other: 0
      // },
    ],
    no_of_days: "",
    person: "",
    remarks: "",
    imprest_open: false,

  });

  const [imprestExist, setImprestExist] = useState(null);

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      imprestExist ? "imprest" : "project_name",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */


  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      person: formData?.multipleperson?.length,
    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        const url = imprestExist
          ? `${BASE_URL}/siteproject/expense/`
          : `${BASE_URL}/siteproject/createImprestAndExpense/`;

        const data = imprestExist ? formData : updatedFormData;
        let res = await axios.post(url, data);

        if (res.status === 200) {
          if (imprestExist) {
            try {
              let imp = await axios.put(`${BASE_URL}/siteproject/imprest/${formData.imprest}/`, {
                imprest_open: false
              });
              if (imp.status === 200) {
                await getExpansee();
                await getAllempdata();
                setFormData({
                  name: "",
                  imprest: "",
                  date: formattedDate,
                  empcode: sessionStorage.getItem("emp_code")
                });
                setShow(false);
              } else {
                console.log("Imprest Closing Error");
                alert("Error closing imprest: " + imp.statusText);
              }
            } catch (error) {
              console.error('Error updating imprest:', error);
              alert("Error closing imprest: " + error.message);
            }
          } else {
            await getExpansee();
            await getAllempdata();
            setFormData({
              name: "",
              imprest: "",
              date: formattedDate,
              empcode: sessionStorage.getItem("emp_code")
            });
            // setShow(false);
            handleClose();
          }
        } else {
          console.log("Expense Creation Error");
          // alert("Error creating expense: " + res.statusText);
        }

      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  useEffect(() => {
    getAllempdata();
  }, [imprestExist]);
  const [allemployeeImprest, setAllemployeeImprest] = useState([]);


  const getAllempdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/imprestbyproject/${sessionStorage.getItem("sitemanagement_project")}/`);
      setAllemployeeImprest(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    }
  };



  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Expense Name
                </label>
                <input
                  id="name"
                  type="name"
                  name="name"
                  placeholder="Expense Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column justify-center">
                <div className="jd-timeline">
                  <div className="leave-radio-button-inner-div">
                    <input
                      onClick={(e) => setImprestExist(true)}
                      name="imprest_exist"
                      type="radio"
                      // value={true}
                      className="age-input"
                    />
                    <label className="age-label font-weight500 font-size-heading">Existing Imprest</label></div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      onClick={(e) => setImprestExist(false)}
                      name="imprest_exist"
                      type="radio"
                      // value={false}
                      className="age-input"
                    />
                    <label className="age-label font-weight500 font-size-heading">No Imprest</label>
                  </div>
                </div>
              </div>


              {imprestExist === null ?
                null :
                imprestExist !== false ?
                  <>
                    <div className="flex-column form-group-select">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Against Imprest<span className="required">*</span>
                      </label>
                      <br />
                      <select
                        name="imprest"
                        onChange={handleInputChange}
                        value={formData.imprest}
                        className={`form-input form-group-selection form-input-background ${errors.imprest ? "error" : inputState.imprest ? "success" : ""
                          }`}>
                        <option value="">Select imprest</option>
                        {allemployeeImprest
                          .filter((imprest) => imprest.imprest_open === true)
                          .map((e, index) => (<option value={e.id}>{e.imprest_name}</option>))
                        }

                        {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          class="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                    </div>
                    <br />
                    {errors.imprest && (
                      <span className="error-message font-size-text ">{errors.imprest}</span>
                    )}

                  </>
                  :
                  <>

                    <div className="flex-column">
                      <label htmlFor="date" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                        Date<span className="required">*</span>
                      </label>
                      <input
                        id="date"
                        type="date"
                        name="date"
                        onChange={handleInputChange}
                        value={formData.date}
                        className={`form-input ${errors.date ? "error" : inputState.date ? "success" : ""
                          }`}
                      />
                      {errors.date && (
                        <span className="error-message font-size-text ">{errors.date}</span>
                      )}
                    </div>
                    <div className="flex-column">
                      <label
                        htmlFor="project_name"
                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                      >
                        Project Code & Name
                      </label>
                      <input
                        id="project_name"
                        type="text"
                        name="project_name"
                        placeholder="Project Code & Name"
                        onChange={handleInputChange}
                        value={formData.project_name}
                        className={`form-input ${errors.project_name
                          ? "error"
                          : inputState.project_name
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.project_name && (
                        <span className="error-message font-size-text ">{errors.project_name}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label
                        htmlFor="no_of_days"
                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                      >
                        Number of days
                      </label>
                      <input
                        id="no_of_days"
                        type="number"
                        min={0}
                        placeholder="Days"
                        name="no_of_days"
                        onWheel={(e) => e.target.blur()}
                        onChange={handleInputChange}
                        value={formData.no_of_days}
                        className={`form-input ${errors.no_of_days
                          ? "error"
                          : inputState.no_of_days
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.no_of_days && (
                        <span className="error-message font-size-text ">{errors.no_of_days}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                        Number's of Person (Auto)
                      </label>
                      <input
                        id="person"
                        type="number"
                        name="person"
                        // onChange={handleInputChange}
                        readOnly
                        value={formData.multipleperson?.length}
                        className={`form-input ${errors.person ? "error" : inputState.person ? "success" : ""
                          }`}
                      />
                      {errors.person && (
                        <span className="error-message font-size-text ">{errors.person}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                        Remarks
                      </label>
                      <textarea
                        id="remarks"
                        type="text"
                        name="remarks"
                        placeholder="Your feedback"
                        onChange={handleInputChange}
                        // maxLength={200}
                        value={formData.remarks}
                        className={`form-input-textarea   font-weight400  font-size-subheading ${errors.remarks
                          ? "error"
                          : inputState.remarks
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.remarks && (
                        <span className="error-message font-size-text ">{errors.remarks}</span>
                      )}
                    </div>
                  </>
              }




              <div className="button-models">
                <button className="model-button   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export { ExpenseAdd };