import React, { useState } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';
import { SiteDesignationTable, SiteSubcompanyTable, SiteEmployeeDetails } from './WorkforceTable';
import usePermission from '../../config/permissions';
import { Attendance, GetAttendance, OfficeRent } from '../Attendance';
const Wfm = () => {


    const { siteRole } = usePermission();

    const [toggleState, setToggleState] = useState(1);
    const getSelectedTitle = () => {
        switch (toggleState) {

            case 1:
                return 'Site Employees';
            case 2:
                return 'Actual Attendance';
            case 3:
                return 'BOQ Attendance';
            case 4:
                return 'Office Rent';
            case 5:
                return 'Site Designation'
            case 6:
                return 'Site Sub Companies';
            default:
                return 'Site Employees';
        }
    };


    return (
        <>
            <Dashboardnavbar name={getSelectedTitle()} url="Employee Management" />
            {siteRole === "SiteManagement" ? <>
                <button
                    className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                    onClick={() => setToggleState(1)}
                >
                    Site Employees
                </button>
                <button
                    className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                    onClick={() => setToggleState(4)}
                >
                    Office Rent
                </button>
            </>
                :
                <div >
                    <div className="bloc-tabss2  wfm-margin">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                            onClick={() => setToggleState(1)}
                        >
                            Site Employees
                        </button>
                        {
                            siteRole === "BD" ? "" :
                                <>
                                    <button
                                        className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                                        onClick={() => setToggleState(2)}
                                    >
                                        Actual Attendance
                                    </button>


                                    <button
                                        className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                                        onClick={() => setToggleState(3)}
                                    >
                                        BOQ Attendance
                                    </button>
                                </>
                        }

                        <button
                            className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                            onClick={() => setToggleState(4)}
                        >
                            Office Rent
                        </button>

                        <button
                            className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                            onClick={() => setToggleState(5)}
                        >
                            Site Designation
                        </button>
                        <button
                            className={toggleState === 6 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                            onClick={() => setToggleState(6)}
                        >
                            Site Sub Companies
                        </button>



                    </div>
                </div>}
            <div className="content-tabs">
                {toggleState === 1 ? <SiteEmployeeDetails /> : null}
                {toggleState === 2 ? <Attendance /> : null}
                {toggleState === 3 ? <GetAttendance /> : null}
                {toggleState === 5 ? <SiteDesignationTable /> : null}
                {toggleState === 4 ? <OfficeRent /> : null}
                {toggleState === 6 ? <SiteSubcompanyTable /> : null}

            </div>
        </>
    );
};





export default Wfm;
