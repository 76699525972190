
const formatDate = (dateString,) => {

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date = new Date(dateString);

  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: userTimeZone,
  };

  return date.toLocaleString('en-US', options);
};


const formatTime = (dateString) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = new Date(dateString);

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: userTimeZone
  };

  return date.toLocaleString('en-US', options);
};



const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;
const day = today.getDate();
const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
const formattedTime = today.toTimeString().split(" ")[0];

var datearray = formattedDate.split("-");

const formatDateDDMMYYYY = (date) =>

  new Date(date).toLocaleString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });

  const formatDateDD_MM_YYYY = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
    const year = d.getFullYear();
    
    return `${day}-${month}-${year}`;
  }

const formattedDateLong = (date) =>

  new Date(date).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

const formattedDateNoYear = (date) =>

  new Date(date).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
  });

export { formatDate, formattedTime, formattedDate, datearray, formatTime, formatDateDDMMYYYY, formatDateDD_MM_YYYY, formattedDateLong, formattedDateNoYear }