import React from "react";
import { ToastContainer, toast } from 'react-toastify';

import { FRONTEND_URL, IMAGE_URL } from "../config/axios";
import Applymodels from "../pages/Applymodels";
import { useNavigate } from "react-router-dom";

const Dashboardnavbar = ({ name, url }) => {
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const ViewProfile=()=>{
    navigate(`/${urlParts[3]}/userdetail/`);
  }
  return (
    <div className="navbar-section-main">
      <div className="navbar-margin-left">
        <div className="navbar-subheading   font-weight500    font-size-text">Pages / {url}</div>
        <div className="navbar-heading  font-weight700 ">{name}</div>
      </div>
      <div className="navbar-subsection navbar-margin-right">
        <div className="applymodel">
          <Applymodels />
        </div>
        <div className="navbar-subsection-b">
          <div className="navbar-search">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input placeholder="Search" className="navbar-input font-weight400 font-size-text " />
            </div>
          </div>

          <div className="navbar-logo-mobile">
            <button
              title="logout"
              className="navbar-svg"
              onClick={() => {
                toast.info('Loging Out...');
                sessionStorage.clear();
                window.location = FRONTEND_URL;
              }}>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                  fill="#A3AED0"
                />
                <path
                  fillRule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                  fill="#A3AED0"
                />
              </svg>
            </button>
            <div>
              <button onClick={ViewProfile}>
              <img src={`${IMAGE_URL}${sessionStorage.getItem("profilepic")}`} alt="image" className="navbar-user-login" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover /> */}
    </div>
  );
};

export default Dashboardnavbar;