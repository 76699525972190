import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import { handleErrorToast } from "../CustomFunctions";
import { AddwithWhiteCircle, DeleteDustbin, SearchSmall, UpdatePencil } from "../AllSvg";
import usePermission from "../../config/permissions";


const RunPayRoll = ({ month, year }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    try {
      let res = await axios.post(
        `${BASE_URL}/siteproject/salaryslipallpost/${year}/${month}/`
      );

      if (res.status === 200) {
        handleClose();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false); //loading logic
    }
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[month - 1];

  return (
    <>
      <button
        className="model-view-button model-add"
        title="Run Pay Roll"
        onClick={handleShow}
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="payroll"
        >
          <g>
            <path d="M72.36,492.088a13.5,13.5,0,0,0,14.3-1.8l32.079-26.753,38.644,31.19.093.072a13.438,13.438,0,0,0,16.669-.039l.095-.076,38.2-31.17,38.2,31.17.094.076a13.438,13.438,0,0,0,16.668.039l.092-.072,38.64-31.191,32.072,26.753h0a13.608,13.608,0,0,0,14.371,1.8A12.526,12.526,0,0,0,360,480.775V428.44l21.848,18.291a13.246,13.246,0,0,0,8.516,3.074,12.576,12.576,0,0,0,5.517-1.275A12.248,12.248,0,0,0,403,437.217V384.883l22.127,18.291a13.623,13.623,0,0,0,8.656,3.074,13.839,13.839,0,0,0,5.807-1.275A12.519,12.519,0,0,0,447,393.66V49.843C447,30.264,429.723,14,408.542,14H190.573C169.392,14,152,30.264,152,49.843V58h-4.984C125.834,58,109,73.821,109,93.4V101h-5.542C82.277,101,65,117.378,65,136.958V480.775A12.473,12.473,0,0,0,72.36,492.088ZM168,49.843C168,39.086,178.214,30,190.573,30H408.542C420.9,30,431,39.086,431,49.843V387.034l-28-22.986V93.4C403,73.821,386.166,58,364.984,58H168ZM125,93.4c0-10.757,9.657-19.4,22.016-19.4H364.984C377.343,74,387,82.643,387,93.4V430.592L360,407.6V136.958C360,117.378,342.608,101,321.427,101H125ZM81,136.958C81,126.2,91.1,117,103.458,117H321.427C333.786,117,344,126.2,344,136.958V474.149l-29.193-24.285c-.033-.027-.105-.054-.138-.081a13.441,13.441,0,0,0-16.791-.125c-.031.025-.072.049-.1.073l-38.665,31.207L220.9,449.76l-.1-.077a13.428,13.428,0,0,0-16.716,0l-.095.077-38.21,31.178-38.66-31.207-.092-.073a13.411,13.411,0,0,0-16.771.125c-.033.027-.088.054-.121.081L81,474.149Z"></path>
            <path d="M149.586 404H275.3a8 8 0 0 0 0-16H149.586a8 8 0 0 0 0 16zM212.443 314.73a28 28 0 0 1-27.967-27.966 8 8 0 0 0-16 0A44.038 44.038 0 0 0 202 329.467V344a8 8 0 0 0 16 0V330.367a43.961 43.961 0 0 0-5.557-87.57 27.964 27.964 0 0 1-2.621-55.806c.06 0 .118.009.178.009a7.989 7.989 0 0 0 1.236-.1c.4-.017.8-.031 1.207-.031a28 28 0 0 1 27.966 27.967 8 8 0 1 0 16 0A44.023 44.023 0 0 0 218 171.228V159a8 8 0 0 0-16 0v13.128A43.963 43.963 0 0 0 212.443 258.8a27.967 27.967 0 0 1 0 55.933z"></path>
          </g>
        </svg>
      </button>

      <Modal show={show} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h3 className="task-Tab-heading  font-weight500    font-size-heading">
                Run Pay Rolls for "{monthName}-{year}"
              </h3>
              <p style={{ textAlign: "center" }}>
                Confirm Running Pay Roll for the year: {year}, month:{" "}
                {monthName}
              </p>

              <div className="button-models">
                <button
                  className="model-button  font-weight500   "
                  onClick={handleClose}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button  font-weight500    model-button-leave font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};


// ! ***************  SalaryStructureHeading Crud Models Start ***************

const SalaryStructureHeadingAdd = ({ getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
    sub_company: ""
  });

  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "sub_company",
    ];


    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    // // Add more validation for numerical fields as needed
    // if (formData.epf_criteria && isNaN(formData.epf_criteria)) {
    //   newErrors.epf_criteria = 'EPF criteria must be a valid number!';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/siteproject/salaryheading/`, formData);
        if (res.status === 200) {
          await getSalaryStructureHeadings();
          setShow(false);
          // toast.success('Salary Policy created successfully!');
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="upload-svg" title="Add Salary Heading" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "} Salary Heading
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Add Salary Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Site Designation Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              <div className="flex-column form-group-selectt">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company<span className="required">*</span>
                </label>
                <br />
                <select
                  id="sub_company"
                  name="sub_company"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                >
                  <option value="">Select Site Company  </option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">{errors.sub_company}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const SalaryStructureHeadingUpdate = ({ i, getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
    console.log(SUB_COMPANIES)
  };

  const [formData, setFormData] = useState({
    title: "",
    sub_company: ""
  });
  console.log("formData")
  console.log(formData)
  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "sub_company",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.put(`${BASE_URL}/siteproject/salaryheading/${i.id}/`, formData);
        if (res.status === 200) {
          await getSalaryStructureHeadings();
          setShow(false);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Salary Heading" onClick={handleShow}>
        <UpdatePencil />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Update Salary Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">


              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Site Designation Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              <div className="flex-column form-group-selectt">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company<span className="required">*</span>
                </label>
                <br />
                <select
                  id="sub_company"
                  name="sub_company"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                >
                  <option value="">Select Site Company  </option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">{errors.sub_company}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const SalaryStructureHeadingDelete = ({ i, getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/salaryheading/${i.id}/`);

      if (res.status === 200) {
        await getSalaryStructureHeadings();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Salary Heading" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Deduction Policy : {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Salary Deduction Policy : "{i.title}"
            <div className="button-models">
              <button className="model-button model-button-cancel  font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SalaryStructureHeading Crud Models End ***************



// ! ***************  SalaryDeductionPolicy Crud Models Start ***************

const SalaryDeductionPolicyAdd = ({ getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: '',
    epf: false,
    epf_criteria: '',
    epf_employee: false,
    epf_employer: false,
    epf_employee_rate: '',
    epf_employer_rate: '',
    esi: false,
    esi_criteria: '',
    esi_employee: false,
    esi_employer: false,
    esi_employee_rate: '',
    esi_employer_rate: '',
    sub_company: '',
  });

  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'name',
      'epf_criteria',
      'epf_employee_rate',
      'epf_employer_rate',
      'esi_criteria',
      'esi_employee_rate',
      'esi_employer_rate',
      'sub_company',
    ];


    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    // // Add more validation for numerical fields as needed
    // if (formData.epf_criteria && isNaN(formData.epf_criteria)) {
    //   newErrors.epf_criteria = 'EPF criteria must be a valid number!';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/siteproject/salarypolicy/`, formData);
        if (res.status === 200) {
          await getSalaryDeductionPolicyList();
          setShow(false);
          // toast.success('Salary Policy created successfully!');
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="upload-svg" title="Add Salary Deduction Policy" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "} Salary Deduction Policy
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Add Salary Deduction Policy</Modal.Title></h1>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Salary Deduction Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company<span className="required">*</span>
                </label>
                <select
                  id="sub_company"
                  name="sub_company"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                >
                  <option value=''>Select Sub Company</option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                </select>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">{errors.sub_company}</span>
                )}
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF
                </label>
                <input
                  type="checkbox"
                  name="epf"
                  onChange={handleInputChange}
                  checked={formData.epf}
                  className={`inputform-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF Criteria
                </label>
                <input
                  id="epf_criteria"
                  name="epf_criteria"
                  value={formData.epf_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.epf_criteria ? "error" : inputState.epf_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.epf_criteria && (
                  <span className="error-message font-size-text ">{errors.epf_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employee"
                      onChange={handleInputChange}
                      checked={formData.epf_employee}
                      className={`inputform-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="epf_employee_rate"
                        name="epf_employee_rate"
                        value={formData.epf_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.epf_employee_rate && (
                        <span className="error-message font-size-text ">{errors.epf_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employer"
                      checked={formData.epf_employer}
                      onChange={handleInputChange}
                      className={`inputform-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="epf_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer  Rate
                    </label>
                    <input
                      id="epf_employer_rate"
                      name="epf_employer_rate"
                      value={formData.epf_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.epf_employer_rate && (
                      <span className="error-message font-size-text ">{errors.epf_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI
                </label>
                <input
                  type="checkbox"
                  name="esi"
                  onChange={handleInputChange}
                  checked={formData.esi}
                  className={`inputform-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI Criteria
                </label>
                <input
                  id="esi_criteria"
                  name="esi_criteria"
                  value={formData.esi_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.esi_criteria ? "error" : inputState.esi_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.esi_criteria && (
                  <span className="error-message font-size-text ">{errors.esi_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employee"
                      onChange={handleInputChange}
                      checked={formData.esi_employee}
                      className={`inputform-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="esi_employee_rate"
                        name="esi_employee_rate"
                        value={formData.esi_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.esi_employee_rate && (
                        <span className="error-message font-size-text ">{errors.esi_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employer"
                      onChange={handleInputChange}
                      checked={formData.esi_employer}
                      className={`inputform-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="esi_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer Rate
                    </label>
                    <input
                      id="esi_employer_rate"
                      name="esi_employer_rate"
                      value={formData.esi_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.esi_employer_rate && (
                      <span className="error-message font-size-text ">{errors.esi_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>


              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const SalaryDeductionPolicyUpdate = ({ i, getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
    console.log(SUB_COMPANIES)
  };

  const [formData, setFormData] = useState({
    name: '',
    epf: false,
    epf_criteria: '',
    epf_employee: false,
    epf_employer: false,
    epf_employee_rate: '',
    epf_employer_rate: '',
    esi: false,
    esi_criteria: '',
    esi_employee: false,
    esi_employer: false,
    esi_employee_rate: '',
    esi_employer_rate: '',
    sub_company: '',
  });
  console.log("formData")
  console.log(formData)
  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'name',
      'epf_criteria',
      'epf_employee_rate',
      'epf_employer_rate',
      'esi_criteria',
      'esi_employee_rate',
      'esi_employer_rate',
      'sub_company',
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/salarypolicy/${i.id}/`,
          formData
        );
        if (res.status === 200) {
          await getSalaryDeductionPolicyList();
          setShow(false);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Salary Deduction Policy" onClick={handleShow}>
        <UpdatePencil />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Salary Deduction Policy: <br />{i.name}</Modal.Title></h1>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Salary Deduction Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company<span className="required">*</span>
                </label>
                <select
                  id="sub_company"
                  name="sub_company"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                >
                  <option value=''>Select Sub Company</option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                </select>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">{errors.sub_company}</span>
                )}
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF
                </label>
                <input
                  type="checkbox"
                  name="epf"
                  onChange={handleInputChange}
                  checked={formData.epf}
                  className={`inputform-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF Criteria
                </label>
                <input
                  id="epf_criteria"
                  name="epf_criteria"
                  value={formData.epf_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.epf_criteria ? "error" : inputState.epf_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.epf_criteria && (
                  <span className="error-message font-size-text ">{errors.epf_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employee"
                      onChange={handleInputChange}
                      checked={formData.epf_employee}
                      className={`inputform-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="epf_employee_rate"
                        name="epf_employee_rate"
                        value={formData.epf_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.epf_employee_rate && (
                        <span className="error-message font-size-text ">{errors.epf_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employer"
                      checked={formData.epf_employer}
                      onChange={handleInputChange}
                      className={`inputform-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="epf_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer  Rate
                    </label>
                    <input
                      id="epf_employer_rate"
                      name="epf_employer_rate"
                      value={formData.epf_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.epf_employer_rate && (
                      <span className="error-message font-size-text ">{errors.epf_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI
                </label>
                <input
                  type="checkbox"
                  name="esi"
                  onChange={handleInputChange}
                  checked={formData.esi}
                  className={`inputform-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI Criteria
                </label>
                <input
                  id="esi_criteria"
                  name="esi_criteria"
                  value={formData.esi_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.esi_criteria ? "error" : inputState.esi_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.esi_criteria && (
                  <span className="error-message font-size-text ">{errors.esi_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employee"
                      onChange={handleInputChange}
                      checked={formData.esi_employee}
                      className={`inputform-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="esi_employee_rate"
                        name="esi_employee_rate"
                        value={formData.esi_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.esi_employee_rate && (
                        <span className="error-message font-size-text ">{errors.esi_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employer"
                      onChange={handleInputChange}
                      checked={formData.esi_employer}
                      className={`inputform-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="esi_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer Rate
                    </label>
                    <input
                      id="esi_employer_rate"
                      name="esi_employer_rate"
                      value={formData.esi_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.esi_employer_rate && (
                      <span className="error-message font-size-text ">{errors.esi_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const SalaryDeductionPolicyDelete = ({ i, getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/salarypolicy/${i.id}/`);

      if (res.status === 200) {
        await getSalaryDeductionPolicyList();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Salary Deduction Policy" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Salary Deduction Policy : {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Salary Deduction Policy : {i.name}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SalaryDeductionPolicy Crud Models End ***************



export {
  RunPayRoll,

  SalaryStructureHeadingAdd,
  SalaryStructureHeadingUpdate,
  SalaryStructureHeadingDelete,

  SalaryDeductionPolicyAdd,
  SalaryDeductionPolicyUpdate,
  SalaryDeductionPolicyDelete,
};
