import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../config/axios';
import * as XLSX from 'xlsx';
import { Modal } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { formattedDateLong } from './Date';
import { formatCurrencyIndian, getMonthName, handleErrorToast, sortProjects } from './CustomFunctions';
import { AddwithWhiteCircle, CircularGrid, DeleteDustbin, DropdownArrow, Location, UpdatePencil } from './AllSvg';
import { DeleteSiteEmployee, UpdateSiteEmployee } from './Wfm/WorkforceCrudButton';
import usePermission from '../config/permissions';

const UploadAttendance = ({ getVehicleBillingDetail }) => {

  const [show, setShow] = useState(false);


  const [buffer, setBuffering] = useState(false); //buffering logic
  const initialProject = "null";
  const [project, setProject] = useState(initialProject);
  const [projectList, setProjectList] = useState([]);

  const handleShow = () => {
    // getVehicle();

    setShow(true);
  }

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);

  }


  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

  // ? *********** Handle File UPload ***********


  const [fileData, setFileData] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the sheet with attendance data is named "Attendance"
        const worksheet = workbook.Sheets['Attendance'];
        const rawData = XLSX.utils.sheet_to_json(worksheet);

        // Transform rawData into the required format for uploading
        const transformedData = transformAttendanceData(rawData);
        setJsonData(transformedData);

        console.log(transformedData)
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const transformAttendanceData = (rawData) => {
    let transformedData = [];

    rawData.forEach((entry) => {
      const emp_code = entry.emp_code;


      // Loop through days of the month (assuming 1 to 31)
      for (let day = 1; day <= 31; day++) {
        if (entry[day]) {
          transformedData.push({
            emp_code: emp_code,
            date: `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
            status: entry[day],  // 'P', 'A', 'L', etc.
            in_time: null,  // Add if available in the data
            out_time: null  // Add if available in the data
          });
        }
      }
    });

    return transformedData;
  };

  const handleSubmit = async () => {
    if (!jsonData) {
      alert("Please upload an Excel file first!");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/siteproject/attendancepostbulk/`, { data: jsonData });
      alert('Attendance uploaded successfully!');
    } catch (error) {
      console.error('Error uploading attendance:', error);
      alert('Error uploading attendance.');
    }
  };


  return (


    <div>
      <>
        <button title='Add Vehicle Billing Details' className='models-button model-add float-right' style={{ margin: 0 }} onClick={handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_1415_3177)">
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                fill="#2576BC"
              />
            </g>
            <defs>
              <clipPath id="clip0_1415_3177">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>

        <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
          <Modal.Header closeButton>
            <Modal.Title>Upload Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <div className="parent-div">
              <div className="bdy-div">
                <form>
                  {/*   
                    <div title="Project" className="flex-column form-group-selectt">
                      <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                        Project<span className="required">*</span>
                      </label>
                      <br />
                      <select
                        type="text"
                        id="project"
                        name="project"
                        placeholder="project"
                        value={project}
                        onChange={(e) => setProject(e.target.value)}
                        className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                      >
  
                        <option value="null">Select a Project</option>
                        {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                          <option key={index} value={i.id}>{i.project_short_name}</option>
                        ))}
                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                      {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                    </div> */}


                  <div title="Month" className="flex-column form-group-selectt">
                    <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                      Month<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="month"
                      name="month"
                      placeholder="month"
                      value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}
                      className={`form-input form-group-selection}`}
                    >

                      <option value="">Select a month</option>
                      {monthOptions.map((month) => (
                        <option key={month.id} value={month.id}>{month.name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>

                  </div>
                  <div title="Year" className="flex-column form-group-selectt">
                    <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                      Year<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="year"
                      name="year"
                      placeholder="year"
                      value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}
                      className={`form-input form-group-selection }`}
                    >

                      <option value="">Select a Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>

                  </div>


                  <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />

                  <button onClick={handleSubmit}>Upload</button>

                </form>
              </div></div>
          </Modal.Body>
        </Modal>
      </>

    </div>


  )
}




const Attendanceee = () => {
  const [VehicleBillingDetails, setVehicleBillingDetails] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [project, setProject] = useState("null");
  const [search, setSearch] = useState("")
  const [projectList, setProjectList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getVehicleBillingDetail = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/filterbyprojvehiclebilling/${project}/`);
      setVehicleBillingDetails(response.data);
    }
    catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  }
  useEffect(() => {
    getVehicleBillingDetail();
    getProjects();
  }, [project]);

  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const getMonthName = (monthNumber) => {
    const month = monthOptions.find(m => m.id.toString() === monthNumber);
    return month ? month.name : '';
  };

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);



  useEffect(() => {
    applyFilters();
  }, [search, filterMonth, filterYear, VehicleBillingDetails])


  const applyFilters = () => {
    let filteredData = VehicleBillingDetails;
    if (search !== "") {
      const lowercaseSelectedName = search.toLowerCase();
      filteredData = filteredData.filter(project => {
        const name = project.vehicle_name.toString().toLowerCase().includes(lowercaseSelectedName);
        const owner_name = project.vehicleownerdetails.toString().toLowerCase().includes(lowercaseSelectedName);
        return name || owner_name;
      });
    }
    if (filterMonth) {
      filteredData = filteredData.filter(project => project.month === filterMonth);

    }
    if (filterYear) {
      filteredData = filteredData.filter(project => project.year === filterYear);

    }






    setFilteredData(filteredData);
  };
  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-subcontainer margin">
        <div className="field-cont width-100vw justify-between">
          <div className="flex-row">
            <div title='Month Filter' className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={filterMonth}
                onChange={(e) => setFilterMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div title='Year Filter' className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div title="Project Filter " className="field-cont-div ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <select
                id="project"
                name="project"
                placeholder="Project"
                value={project}
                onChange={(e) => setProject(e.target.value)}
                className={`attendance-input-field width-15vw`}
              >

                <option value="null">Select Project</option>
                {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                  <option key={index} value={i.id}>{i.project_short_name}</option>
                ))}
              </select>
              <hr className="field-cont-hr" />

            </div>

            <div title='Search Filter' className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#707070"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#707070 "
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              <input className="attendance-input-field"
                placeholder='Search Name'
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)} />
              <hr className="field-cont-hr" />
            </div>
          </div>

          <div>
            <UploadAttendance getVehicleBillingDetail={getVehicleBillingDetail} />

          </div>
        </div>
      </div>

    </div>
  )

}


const AddSiteAttendence = ({ getAttendance, i }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);


  const [formData, setFormData] = useState({
    employee: "",
    total_days: "",
    man_months: "",
    month: "",
    year: "",
  })
  const handleShow = () => {
    setShow(true);
    getEmployee();
  }
  const handleClose = () => {
    setShow(false);
    setFormData({
      employee: "",
      total_days: "",
      man_months: "",
      month: "",
      year: "",
    })
  }

  const getEmployee = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const ress = await axios.get(`${BASE_URL}/siteproject/employee/`);

      setEmployeeData(ress.data)


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  }


  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateAttendenceForm = () => {
    const newErrors = {};
    const requiredFields = [

      "total_days",
      "man_months",
      "month",
      "year",

    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePost = async (e) => {
    e.preventDefault();
    if (validateAttendenceForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {

        const res = await axios.post(`${BASE_URL}/siteproject/attendancesites/`, formData);

        if (res.status === 200) {
          toast.success("Attendance Added Successfully");
          await getAttendance();
          handleClose();
        } else {
          toast.error(`Error: ${res.statusText}`);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const years = Array.from({ length: 20 }, (v, i) => new Date().getFullYear() - i);

  return (
    <>
      <button title="Add Site Attendance" className="upload-svg" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "} Add Site Attendance
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handlePost}>
                <div title="Employees List" className="flex-column form-group-selectt">
                  <label htmlFor="employee" className="form-labels  font-weight500    font-size-subheading">
                    Employee<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="employee"
                    name="employee"
                    placeholder="employee"
                    value={formData.employee}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.employee ? "error" : inputState.employee ? "success" : ""}`}
                  >
                    <option value="">Select an Employee</option>
                    {employeeData?.sort((a, b) => a.emp_code.localeCompare(b.emp_code, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.emp_code}-{i.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  {errors.employee && <span className="error-message font-size-text ">{errors.employee}</span>}
                </div>
                <div title="Total Days" className="flex-column">
                  <label htmlFor="total_days" className="form-labels  font-weight500    font-size-subheading">
                    Total Days<span className="required">*</span>
                  </label>
                  <input
                    maxLength={20}
                    type="text"
                    id="total_days"
                    name="total_days"
                    placeholder="Total Days"
                    onChange={handleInputChange}
                    value={formData.total_days}
                    className={`form-input ${errors.total_days
                      ? "error"
                      : inputState.total_days
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.total_days && (
                    <span className="error-message font-size-text ">{errors.total_days}</span>
                  )}
                </div>
                <div title="Man Months" className="flex-column">
                  <label htmlFor="man_months" className="form-labels  font-weight500    font-size-subheading">
                    Man Months<span className="required">*</span>
                  </label>
                  <input
                    maxLength={20}
                    type="text"
                    id="man_months"
                    name="man_months"
                    placeholder="Man Months"
                    onChange={handleInputChange}
                    value={formData.man_months}
                    className={`form-input ${errors.man_months
                      ? "error"
                      : inputState.man_months
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.man_months && (
                    <span className="error-message font-size-text ">{errors.man_months}</span>
                  )}
                </div>
                <div title="Month" className="flex-column form-group-selectt">
                  <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="month"
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.month ? "error" : inputState.month ? "success" : ""}`}
                  >
                    <option value="">Select a Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </select>
                  <DropdownArrow />
                  <div>
                    {errors.month && (
                      <span className="error-message font-size-text ">{errors.month}</span>
                    )}
                  </div>
                </div>
                <div title="Year" className="flex-column form-group-selectt">
                  <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="year"
                    name="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.year ? "error" : inputState.year ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Year</option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <DropdownArrow />
                  <div>
                    {errors.year && (
                      <span className="error-message font-size-text ">{errors.year}</span>
                    )}
                  </div>
                </div>



                <div className="button-models">
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" type="submit" disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const AddOfficeRent = ({ getRent, siteProjectList }) => {
  const [show, setShow] = useState(false);
  const { siteRole, projectID } = usePermission();

  const handleClose = () => {
    setFormData({
      project: "",
      rent_amt: "",
      agreement_duration: "",
      agreement_date: "",
      owner_name: "",
      agreement_upload: "",
      bank_name: "",
      account_holder_name: "",
      account_num: "",
      ifsc_code: "",
      pan_card: "",
      gst_num: "",
      address: "",
      mobile: "",
    })
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
    getproject();
  }


  const [fileNames, setFileNames] = useState("");

  const [formData, setFormData] = useState({
    project: projectID ? projectID : "",
    rent_amt: "",
    agreement_duration: "",
    agreement_date: "",
    owner_name: "",
    agreement_upload: "",
    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["project", "rent_amt", "agreement_date", "owner_name", "agreement_upload", "bank_name", "account_holder_name", "account_num", "ifsc_code", "pan_card", "gst_num", "address", "mobile"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const [projectList, setProjectList] = useState([]);

  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/project/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("project", formData.project);
      formDataa.append("rent_amt", formData.rent_amt);
      formDataa.append("agreement_duration", formData.agreement_duration);
      formDataa.append("agreement_date", formData.agreement_date);
      formDataa.append("owner_name", formData.owner_name);
      formDataa.append("agreement_upload", formData.agreement_upload);
      formDataa.append("bank_name", formData.bank_name);
      formDataa.append("account_holder_name", formData.account_holder_name);
      formDataa.append("account_num", formData.account_num);
      formDataa.append("ifsc_code", formData.ifsc_code);
      formDataa.append("pan_card", formData.pan_card);
      formDataa.append("gst_num", formData.gst_num);
      formDataa.append("address", formData.address);
      formDataa.append("mobile", formData.mobile);

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.agreement_upload;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });
      formDataa.append("agreement_upload", customFile);

      try {
        let res = await axios.post(
          `${BASE_URL}/siteproject/officerent/`,
          formDataa,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          await getRent();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("Office rent  added successfully!");

        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add data!");
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const validFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];

      if (validFileTypes.includes(file.type)) {
        setFormData({
          ...formData,
          [name]: file,
        });
        setErrors({
          ...errors,
          [name]: "", // Clear any previous errors for this field
        });
        setFileNames(file.name); // Store the selected file name
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF or DOC file.",
        });
        setFileNames(""); // Reset file name if the selected file is invalid
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
      setFileNames(""); // Reset file name if no file is selected
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
    setFileNames(""); // Clear the file name when the file is cleared
  };

  return (
    <>
      <button title="Add OfficeRent" className="upload-svg" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {" "}Office Rent
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title> Add Office Rent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading font-weight500    font-size-heading">Circular</h6> */}

              <form className="register-form">

                <div className="form-flex-wrap">

                  {siteRole === "SiteManagement" ? <></> :

                    <div title="Site Projects" className="flex-column form-group-selectt">
                      <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                        Site Projects<span className="required">*</span>
                      </label>
                      <br />
                      <select
                        id="project"
                        name="project"
                        value={formData.project}
                        onChange={handleInputChange}
                        className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                          }`}
                      >
                        <option value="">Select a Project</option>
                        {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                          <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                        </>))
                        }
                      </select>
                      {/* {formData.project !== "" ? "" :  */}
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          class="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                      {/* } */}
                      <div>
                        {errors.project && (
                          <span className="error-message font-size-text ">{errors.project}</span>
                        )}
                      </div>
                    </div>
                  }
                  <div title="Rent Amount" className="flex-column">
                    <label htmlFor="rent_amt" className="form-labels  font-weight500    font-size-subheading">
                      Rent Amount<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="rent_amt"
                      name="rent_amt"
                      placeholder="Rent Amount."
                      onChange={handleInputChange}
                      value={formData.rent_amt}
                      className={`form-input ${errors.rent_amt
                        ? "error"
                        : inputState.rent_amt
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.rent_amt && (
                      <span className="error-message font-size-text ">{errors.rent_amt}</span>
                    )}
                  </div>

                  <div title="Agreement Duration" className="flex-column">
                    <label htmlFor="agreement_duration" className="form-labels  font-weight500    font-size-subheading">
                      Agreement Duration<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="agreement_duration"
                      name="agreement_duration"
                      placeholder="Agreement Duration."
                      onChange={handleInputChange}
                      value={formData.agreement_duration}
                      className={`form-input ${errors.agreement_duration
                        ? "error"
                        : inputState.agreement_duration
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.agreement_duration && (
                      <span className="error-message font-size-text ">{errors.agreement_duration}</span>
                    )}
                  </div>

                  <div title="Agreement Date" className="flex-column">
                    <label htmlFor="agreement_date" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                      Agreement Date
                    </label>
                    <input
                      id="agreement_date"
                      type="date"
                      name="agreement_date"
                      placeholder="date"
                      onChange={handleInputChange}
                      value={formData.agreement_date}
                      className={`form-input ${errors.agreement_date ? "error" : inputState.agreement_date ? "success" : ""
                        }`}
                    />
                    {errors.agreement_date && (
                      <span className="error-message font-size-text ">{errors.agreement_date}</span>
                    )}
                  </div>

                  <div title="Owner name" className="flex-column">
                    <label htmlFor="owner_name" className="form-labels  font-weight500    font-size-subheading">
                      Owner Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      min={0}
                      maxLength={10}
                      id="owner_name"
                      name="owner_name"
                      placeholder="Owner name."
                      onChange={handleInputChange}
                      value={formData.owner_name}
                      className={`form-input ${errors.owner_name
                        ? "error"
                        : inputState.owner_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.owner_name && (
                      <span className="error-message font-size-text ">{errors.owner_name}</span>
                    )}
                  </div>



                  <div title="Bank name" className="flex-column">
                    <label htmlFor="bank_name" className="form-labels  font-weight500    font-size-subheading">
                      Bank Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="bank_name"
                      name="bank_name"
                      placeholder="Bank name."
                      onChange={handleInputChange}
                      value={formData.bank_name}
                      className={`form-input ${errors.bank_name
                        ? "error"
                        : inputState.bank_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.bank_name && (
                      <span className="error-message font-size-text ">{errors.bank_name}</span>
                    )}
                  </div>

                  <div title="Account holder name" className="flex-column">
                    <label htmlFor="account_holder_name" className="form-labels  font-weight500    font-size-subheading">
                      Account Holder Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="account_holder_name"
                      name="account_holder_name"
                      placeholder="Account holder name."
                      onChange={handleInputChange}
                      value={formData.account_holder_name}
                      className={`form-input ${errors.account_holder_name
                        ? "error"
                        : inputState.account_holder_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_holder_name && (
                      <span className="error-message font-size-text ">{errors.account_holder_name}</span>
                    )}
                  </div>

                  <div title="Account number" className="flex-column">
                    <label htmlFor="account_num" className="form-labels  font-weight500    font-size-subheading">
                      Account Number<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="account_num"
                      name="account_num"
                      placeholder="Account number."
                      onChange={handleInputChange}
                      value={formData.account_num}
                      className={`form-input ${errors.account_num
                        ? "error"
                        : inputState.account_num
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_num && (
                      <span className="error-message font-size-text ">{errors.account_num}</span>
                    )}
                  </div>

                  <div title="Ifsc code" className="flex-column">
                    <label htmlFor="ifsc_code" className="form-labels  font-weight500    font-size-subheading">
                      Ifsc Code<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="ifsc_code"
                      name="ifsc_code"
                      placeholder="Ifsc Code."
                      onChange={handleInputChange}
                      value={formData.ifsc_code}
                      className={`form-input ${errors.ifsc_code
                        ? "error"
                        : inputState.ifsc_code
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.ifsc_code && (
                      <span className="error-message font-size-text ">{errors.ifsc_code}</span>
                    )}
                  </div>


                  <div title="Pan Card" className="flex-column">
                    <label htmlFor="pan_card" className="form-labels  font-weight500    font-size-subheading">
                      PAN Card<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="pan_card"
                      name="pan_card"
                      placeholder="PAN Card."
                      onChange={handleInputChange}
                      value={formData.pan_card}
                      className={`form-input ${errors.pan_card
                        ? "error"
                        : inputState.pan_card
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.pan_card && (
                      <span className="error-message font-size-text ">{errors.pan_card}</span>
                    )}
                  </div>

                  <div title="GSt Num" className="flex-column">
                    <label htmlFor="gst_num" className="form-labels  font-weight500    font-size-subheading">
                      GST Number<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="gst_num"
                      name="gst_num"
                      placeholder="GST Number."
                      onChange={handleInputChange}
                      value={formData.gst_num}
                      className={`form-input ${errors.gst_num
                        ? "error"
                        : inputState.gst_num
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.gst_num && (
                      <span className="error-message font-size-text ">{errors.gst_num}</span>
                    )}
                  </div>

                  <div title="Phone number" className="flex-column">
                    <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                      Phone Number<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="mobile"
                      name="mobile"
                      placeholder="Phone number."
                      onChange={handleInputChange}
                      value={formData.mobile}
                      className={`form-input ${errors.mobile
                        ? "error"
                        : inputState.mobile
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.mobile && (
                      <span className="error-message font-size-text ">{errors.mobile}</span>
                    )}
                  </div>

                  <div title="Address" className="flex-column">
                    <label htmlFor="address" className="form-labels  font-weight500    font-size-subheading">
                      Address<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Address."
                      onChange={handleInputChange}
                      value={formData.address}
                      className={`form-input ${errors.address
                        ? "error"
                        : inputState.address
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.address && (
                      <span className="error-message font-size-text ">{errors.address}</span>
                    )}
                  </div>
                  <div className='upload-button-field'>
                    <div title="Agreement upload" className="flex-column">
                      <label
                        htmlFor="agreement_upload"
                        className="form-labels  font-weight500    font-size-subheading"
                      >
                        Agreement Upload
                      </label>
                      <div className='flex-row  justify-between'>
                        <div>

                          <label
                            htmlFor='agreement_upload'
                            className={` svg-icon  ${errors.agreement_upload ? 'error' : inputState.agreement_upload ? 'success' : ''}`}
                          >
                            <svg
                              className="svg-field form-input"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                stroke="#707070"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </label>
                          <input
                            id="agreement_upload"
                            type="file"
                            name="agreement_upload"
                            accept=".pdf, .doc, .docx"
                            onChange={handleFileChange}
                            className=""
                            style={{ display: 'none', position: 'relative' }}

                          />
                        </div>
                        <div className=''>
                          {fileNames && (
                            <p className="file-name">{fileNames}</p> // Display the selected file name
                          )}
                        </div>
                        <div>
                          {formData.agreement_upload && (
                            <button
                              className="file-clear clear-button"
                              onClick={() => handleFileClear("agreement_upload")}
                            >
                              Clear
                            </button>
                          )}
                        </div>

                      </div>
                      {errors.agreement_upload && (
                        <span className="error-message font-size-text">
                          {errors.agreement_upload}
                        </span>
                      )}
                    </div>
                  </div>

                </div>
              </form>
              <div className="button-models">
                <button className="model-button font-weight500 model-button-cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};



const DeleteOfficeRent = ({ i, getRent }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/officerentupdate/${i.id}/`);

      if (res.status === 200) {
        await getRent();
        setShow(false);
        toast.dismiss(loadingToastId);
        toast.success("Data deleted successfully!");
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Office Rent "{i.account_holder_name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete {i.account_holder_name}?
            <div className="button-models">
              <button className="model-button   font-weight500   model-button-cancel " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};


const UpdateOfficeRent = ({ getRent, projectId, siteProjectList, i }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {

    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
    getproject();
    setFormData(i);
  }



  const [formData, setFormData] = useState({
    project: "",
    rent_amt: "",
    agreement_duration: "",
    agreement_date: "",
    owner_name: "",
    agreement_upload: i.agreement_upload,
    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["project", "rent_amt", "agreement_date", "owner_name", "bank_name", "account_holder_name", "account_num", "ifsc_code", "pan_card", "gst_num", "address", "mobile"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const [projectList, setProjectList] = useState([]);

  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/project/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("project", formData.project);
      formDataa.append("rent_amt", formData.rent_amt);
      formDataa.append("agreement_duration", formData.agreement_duration);
      formDataa.append("agreement_date", formData.agreement_date);
      formDataa.append("owner_name", formData.owner_name);
      formDataa.append("agreement_upload", formData.agreement_upload);
      formDataa.append("bank_name", formData.bank_name);
      formDataa.append("account_holder_name", formData.account_holder_name);
      formDataa.append("account_num", formData.account_num);
      formDataa.append("ifsc_code", formData.ifsc_code);
      formDataa.append("pan_card", formData.pan_card);
      formDataa.append("gst_num", formData.gst_num);
      formDataa.append("address", formData.address);
      formDataa.append("mobile", formData.mobile);

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.agreement_upload;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });
      formDataa.append("agreement_upload", customFile);

      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/officerentupdate/${i.id}/`,
          formDataa,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          await getRent();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("Office Rent Updated Successfully!");

        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to update office rent!");
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const validFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];

      if (validFileTypes.includes(file.type)) {
        setFormData({
          ...formData,
          [name]: file,
        });
        setErrors({
          ...errors,
          [name]: "", // Clear any previous errors for this field
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF or DOC file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };


  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <button title="Update Office Rent" className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title> Update Office Rent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading font-weight500    font-size-heading">Circular</h6> */}

              <form className="register-form">

                <div className="form-flex-wrap">


                  <div title="Site Projects" className="flex-column form-group-selectt">
                    <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                      Site Projects<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      id="project"
                      name="project"
                      value={formData.project}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                        }`}
                    >
                      <option value="">Select a Project</option>
                      {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                        <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                      </>))
                      }
                    </select>
                    {/* {formData.project !== "" ? "" :  */}
                    <div className="form-group-selection-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="25"
                        fill="currentColor"
                        class="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    {/* } */}
                    <div>
                      {errors.project && (
                        <span className="error-message font-size-text ">{errors.project}</span>
                      )}
                    </div>
                  </div>

                  <div title="Rent Amount" className="flex-column">
                    <label htmlFor="rent_amt" className="form-labels  font-weight500    font-size-subheading">
                      Rent Amount<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="rent_amt"
                      name="rent_amt"
                      placeholder="Rent Amount."
                      onChange={handleInputChange}
                      value={formData.rent_amt}
                      className={`form-input ${errors.rent_amt
                        ? "error"
                        : inputState.rent_amt
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.rent_amt && (
                      <span className="error-message font-size-text ">{errors.rent_amt}</span>
                    )}
                  </div>

                  {/* <div title="Agreement Duration" className="flex-column">
                    <label htmlFor="agreement_duration" className="form-labels  font-weight500    font-size-subheading">
                      Agreement Duration<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="agreement_duration"
                      name="agreement_duration"
                      placeholder="Agreement Duration."
                      onChange={handleInputChange}
                      value={formData.agreement_duration}
                      className={`form-input ${errors.agreement_duration
                        ? "error"
                        : inputState.agreement_duration
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.agreement_duration && (
                      <span className="error-message font-size-text ">{errors.agreement_duration}</span>
                    )}
                  </div> */}

                  <div title="Agreement Date" className="flex-column">
                    <label htmlFor="agreement_date" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                      Agreement Date
                    </label>
                    <input
                      id="agreement_date"
                      type="date"
                      name="agreement_date"
                      placeholder="date"
                      onChange={handleInputChange}
                      value={formData.agreement_date}
                      className={`form-input ${errors.agreement_date ? "error" : inputState.agreement_date ? "success" : ""
                        }`}
                    />
                    {errors.agreement_date && (
                      <span className="error-message font-size-text ">{errors.agreement_date}</span>
                    )}
                  </div>

                  <div title="Owner name" className="flex-column">
                    <label htmlFor="owner_name" className="form-labels  font-weight500    font-size-subheading">
                      Owner Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      min={0}
                      maxLength={10}
                      id="owner_name"
                      name="owner_name"
                      placeholder="Owner name."
                      onChange={handleInputChange}
                      value={formData.owner_name}
                      className={`form-input ${errors.owner_name
                        ? "error"
                        : inputState.owner_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.owner_name && (
                      <span className="error-message font-size-text ">{errors.owner_name}</span>
                    )}
                  </div>



                  <div title="Bank name" className="flex-column">
                    <label htmlFor="bank_name" className="form-labels  font-weight500    font-size-subheading">
                      Bank Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="bank_name"
                      name="bank_name"
                      placeholder="Bank name."
                      onChange={handleInputChange}
                      value={formData.bank_name}
                      className={`form-input ${errors.bank_name
                        ? "error"
                        : inputState.bank_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.bank_name && (
                      <span className="error-message font-size-text ">{errors.bank_name}</span>
                    )}
                  </div>

                  <div title="Account holder name" className="flex-column">
                    <label htmlFor="account_holder_name" className="form-labels  font-weight500    font-size-subheading">
                      Account Holder Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="account_holder_name"
                      name="account_holder_name"
                      placeholder="Account holder name."
                      onChange={handleInputChange}
                      value={formData.account_holder_name}
                      className={`form-input ${errors.account_holder_name
                        ? "error"
                        : inputState.account_holder_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_holder_name && (
                      <span className="error-message font-size-text ">{errors.account_holder_name}</span>
                    )}
                  </div>

                  <div title="Account number" className="flex-column">
                    <label htmlFor="account_num" className="form-labels  font-weight500    font-size-subheading">
                      Account Number<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="account_num"
                      name="account_num"
                      placeholder="Account number."
                      onChange={handleInputChange}
                      value={formData.account_num}
                      className={`form-input ${errors.account_num
                        ? "error"
                        : inputState.account_num
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_num && (
                      <span className="error-message font-size-text ">{errors.account_num}</span>
                    )}
                  </div>

                  <div title="Ifsc code" className="flex-column">
                    <label htmlFor="ifsc_code" className="form-labels  font-weight500    font-size-subheading">
                      Ifsc Code<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="ifsc_code"
                      name="ifsc_code"
                      placeholder="Ifsc Code."
                      onChange={handleInputChange}
                      value={formData.ifsc_code}
                      className={`form-input ${errors.ifsc_code
                        ? "error"
                        : inputState.ifsc_code
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.ifsc_code && (
                      <span className="error-message font-size-text ">{errors.ifsc_code}</span>
                    )}
                  </div>


                  <div title="Pan Card" className="flex-column">
                    <label htmlFor="pan_card" className="form-labels  font-weight500    font-size-subheading">
                      PAN Card<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="pan_card"
                      name="pan_card"
                      placeholder="PAN Card."
                      onChange={handleInputChange}
                      value={formData.pan_card}
                      className={`form-input ${errors.pan_card
                        ? "error"
                        : inputState.pan_card
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.pan_card && (
                      <span className="error-message font-size-text ">{errors.pan_card}</span>
                    )}
                  </div>

                  <div title="GSt Num" className="flex-column">
                    <label htmlFor="gst_num" className="form-labels  font-weight500    font-size-subheading">
                      GST Number<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="gst_num"
                      name="gst_num"
                      placeholder="GST Number."
                      onChange={handleInputChange}
                      value={formData.gst_num}
                      className={`form-input ${errors.gst_num
                        ? "error"
                        : inputState.gst_num
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.gst_num && (
                      <span className="error-message font-size-text ">{errors.gst_num}</span>
                    )}
                  </div>

                  <div title="Phone number" className="flex-column">
                    <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                      Phone Number<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="mobile"
                      name="mobile"
                      placeholder="Phone number."
                      onChange={handleInputChange}
                      value={formData.mobile}
                      className={`form-input ${errors.mobile
                        ? "error"
                        : inputState.mobile
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.mobile && (
                      <span className="error-message font-size-text ">{errors.mobile}</span>
                    )}
                  </div>

                  <div title="Address" className="flex-column">
                    <label htmlFor="address" className="form-labels  font-weight500    font-size-subheading">
                      Address<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Address."
                      onChange={handleInputChange}
                      value={formData.address}
                      className={`form-input ${errors.address
                        ? "error"
                        : inputState.address
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.address && (
                      <span className="error-message font-size-text ">{errors.address}</span>
                    )}
                  </div>


                </div>
              </form>
              <div className="button-models">
                <button className="model-button font-weight500 model-button-cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};


const GetAttendance = () => {
  const [project, setProject] = useState("null");
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [projectList, setProjectList] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [siteAttendanceList, setSiteAttendanceList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getAttendance = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/attendancesites/`);
      setSiteAttendanceList(res.data);

    } catch (err) {
      handleErrorToast(err);

    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/null/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    getAttendance();
    getproject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const getEmployee=()=>{
  //   try{
  //     const response=await axios.get(`/siteproject/employee/`);
  //   }
  // }
  const applyFilters = () => {
    let filteredData = siteAttendanceList;

    // Filter by name or code
    if (selectedName.trim() !== "") {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.employee_name
          ? employee.employee_name.toLowerCase().includes(lowercaseSelectedName)
          : false;
        const empCodeMatch = employee.emp_code
          ? employee.emp_code.toString().includes(selectedName)
          : false;

        return nameMatch || empCodeMatch;
      });
    }

    // Filter by selected project
    if (project && project !== "null") {
      filteredData = filteredData.filter((employee) => employee.project_id === parseInt(project));
    }

    // Filter by selected month and year
    if (selectMonth) {
      filteredData = filteredData.filter((employee) => {
        return parseInt(employee.month) === parseInt(selectMonth);
      });
    }

    if (yearr) {
      filteredData = filteredData.filter((employee) => {
        return parseInt(employee.year) === parseInt(yearr);
      });
    }

    // Set the filtered data
    setFilteredData(filteredData);
  };


  // Update the useEffect to call applyFilters whenever selectedName or siteAttendanceList changes
  useEffect(() => {
    applyFilters();
  }, [selectedName, siteAttendanceList, project]);
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const [selectMonth, setSelectedMonth] = useState(`${month}`);
  const day = today.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[selectMonth - 1];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);
  const [yearr, setYearr] = useState(`${year}`);

  useEffect(() => {
    getAttendance();
    getproject();
  }, [selectMonth, yearr, monthName]);

  return (
    <>
      <div >
        <div className="attendance-subcont">

          <div className="field-cont">
            <div className="field-cont-div">
              <CircularGrid />
              <select
                id="project"
                name="project"
                value={project}
                onChange={(e) => setProject(e.target.value)}  // Ensure correct project ID is set
                className="attendance-input-field width-15vw"
              >
                <option value="">Select a Project</option>  {/* Default option */}
                {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true }))
                  .map((i, index) => (
                    <option value={i.id} key={index}> {/* Correctly use i.id for project ID */}
                      {i.site_prcode} - {i.project_short_name}
                    </option>
                  ))}
              </select>
              <hr className="field-cont-hr" />
              <div className="field-cont justify-end">
              </div>
              <div className="field-cont-div">
                <CircularGrid />
                <input
                  className="attendance-input-field width-10vw  "
                  placeholder="Name or Code"
                  type="text"
                  value={selectedName}
                  onChange={(e) => setSelectedName(e.target.value)}
                />
                <hr className="field-cont-hr" />
              </div>
              <div className="field-cont-div">
                <input
                  type="number"
                  maxLength={4}
                  value={yearr}
                  onChange={(e) => setYearr(e.target.value)}
                  className="attendance-input-field width-10vw   date-field"
                />
                <hr className="field-cont-hr" />
              </div>
              <div className="field-cont-div">
                <CircularGrid />
                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={selectMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {/* <option value="">All</option> */}
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <hr className="field-cont-hr" />
              </div>
            </div>
          </div>
          <div className="field-cont">
            <AddSiteAttendence getAttendance={getAttendance} />
            <AddBulkboqAttendance getAttendance={getAttendance} />
          </div>
        </div>
      </div>
      <div className="table-css-white-background">
        <div>
          {/* <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">BOQ Attendance</h2>
          <div className="field-cont">


            <AddSiteAttendence getAttendance={getAttendance} />
            <AddBulkboqAttendance getAttendance={getAttendance} />
          </div>
        </div> */}
          {/* <div >
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div> */}
        </div>

        <div className="form-flex-wrap table-box height-73vh">
          <table className="table-css">
            <thead className='table-heading'>
              <tr className="custom-table-head-tr">
                <th className="align-left">ID</th>
                <th className="align-center">Employee Name</th>
                <th className="align-center">Project Name</th>
                <th className="align-center">Month</th>
                <th className="align-center">Year</th>
                <th className="align-center">Total Days</th>
                <th className="align-center">Man Months</th>

                <th className="align-right width-5vw">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.employee_name}</td>
                    <td className="align-center">{i.project_short_name}</td>
                    <td className="align-center">{i.month}</td>
                    <td className="align-center">{i.year}</td>
                    <td className="align-center">{i.total_days}</td>
                    <td className="align-center">{i.man_months}</td>
                    <td className="align-center">
                      <DeleteboqAttandance getAttendance={getAttendance} i={i} />
                    </td>



                    {/* <td className="align-right flex-row ">
                    <UpdateSiteEmployee
                      i={i}
                      getSiteEmployee={getSiteEmployee}
                      siteProjectList={siteProjectList}
                      siteDepartmentList={siteDepartmentList}
                      siteSubCompanyList={siteSubCompanyList}
                      projectId={projectId}
                    />
                    <DeleteSiteEmployee
                      i={i}
                      getSiteEmployee={getSiteEmployee}

                    />
                  </td> */}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>


      </div>
    </>
  )

}


const OfficeRent = (i) => {
  const [project, setProject] = useState("null");
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [subCompanyList, setSubCompanyList] = useState([]);
  const [subCompany, setSubCompany] = useState("null")
  const [subbuffer, setSubBuffering] = useState(true); //buffering logic
  const [projectList, setProjectList] = useState([]);
  const { projectID } = usePermission();
  const [selectedName, setName] = useState("");
  const [selectedFromDate, setFromDate] = useState('null');
  const [selectedToDate, setToDate] = useState('null');
  const [siteAttendanceList, setSiteAttendanceList] = useState([]);




  useEffect(() => {
    getSubCompany();
    getRent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID, project]);
  useEffect(() => {
    getproject();
  }, [subCompany])

  const getRent = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/officerentbyproj/${projectID ? projectID : project}/`);
      setSiteAttendanceList(res.data);

    } catch (err) {
      handleErrorToast(err);

    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${subCompany}/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }
  const getSubCompany = async () => {
    setSubBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompanyList(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setSubBuffering(false);// End Buffering
    }
  };
  const filterByOwnerNameAndDate = () => {
    return siteAttendanceList.filter((item) => {
      const matchesName = selectedName
        ? item.owner_name.toLowerCase().includes(selectedName.toLowerCase())
        : true;

      const itemDate = Date.parse(item.agreement_date);
      const fromDate = selectedFromDate ? Date.parse(selectedFromDate) : null;
      const toDate = selectedToDate ? Date.parse(selectedToDate) : null;

      const matchesFromDate = fromDate ? itemDate >= fromDate : true;
      const matchesToDate = toDate ? itemDate <= toDate : true;

      return matchesName && matchesFromDate && matchesToDate;
    });
  };

  return (
    <>


      <div >
        <div className="attendance-subcont">

          <div className="field-cont">
            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-15vw   "
                type="text"
                value={subCompany}
                onChange={(e) => setSubCompany(e.target.value)}
              >
                <option value="">Select Sub Company</option>
                {subCompanyList.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>


            <div className="field-cont-div">
              <CircularGrid />

              <select
                id="project"
                name="project"
                value={project}
                onChange={(e) => setProject(e.target.value)}
                className="attendance-input-field width-15vw"
              >
                <option value="">Select a Project</option>
                {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                  <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                </>))
                }
              </select>
              <hr className="field-cont-hr" />
              <div className="field-cont justify-end">
              </div>

            </div>
            <div className="field-cont-div">
              <CircularGrid />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Owner Name"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>
            <div title="From Date Filter" className="field-cont-div">
              <input
                className="attendance-input-field width-10vw   date-field"
                placeholder="From Date"
                type="date"
                value={selectedFromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>

            <div title="To Date Filter" className="field-cont-div ">
              <input
                className="attendance-input-field width-10vw   date-field"
                placeholder="To Date"
                type="date"
                value={selectedToDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <hr className="field-cont-hr" />
            </div>
          </div>

          <AddOfficeRent getRent={getRent} />
        </div>
      </div>
      <div className="form-flex-wrap" style={{ overflow: "scroll" }}>
        <div className='table-box height-73vh'>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">S.no.</th>
                <th className="align-center">Project</th>
                <th className="align-center">Rent Amount</th>
                <th className="align-center">Agreement Date</th>
                <th className="align-center">Agreement Duration <br />(in Month)</th>
                <th className="align-center">Owner Name</th>
                <th className="align-center">View Agreement</th>
                <th className="align-center">Bank Name</th>
                <th className="align-center">Account Holder Name</th>
                <th className="align-center">Account number</th>
                <th className="align-center">IFSC Code</th>
                <th className="align-center">PAN Number</th>
                <th className="align-center">GST Number</th>
                <th className="align-center">Address</th>
                <th className="align-center">Mobile no.</th>
                <th className="align-center width-5vw">Action</th>
              </tr>
            </thead>
            <tbody>
              {filterByOwnerNameAndDate().map((i, index) => (
                <tr key={index} className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.project}</td>
                  <td className="align-center">Rs.{formatCurrencyIndian(i.rent_amt)}</td>
                  <td className="align-center">{i.agreement_date}</td>
                  <td className="align-center">{i.agreement_duration}</td>
                  <td className="align-center">{i.owner_name}</td>
                  <td className="align-center ">
                    <a href={i.agreement_upload} target="blank" className="document-download-button  width-5vw  width-5vw">
                      View
                    </a>
                  </td>
                  <td className="align-center">{i.bank_name}</td>
                  <td className="align-center">{i.account_holder_name}</td>
                  <td className="align-center">{i.account_num}</td>
                  <td className="align-center">{i.ifsc_code}</td>
                  <td className="align-center">{i.pan_card}</td>
                  <td className="align-center">{i.gst_num}</td>
                  <td className="align-center">{i.address}</td>
                  <td className="align-center">{i.mobile}</td>
                  <td className="align-center flex-row " style={{ height: "12vh" }}>
                    <UpdateOfficeRent i={i} getRent={getRent} />
                    <DeleteOfficeRent i={i} getRent={getRent} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>)

}








const UploadAttendanceBulk2 = ({ id, getAttendanceData }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);



  const [fileName, setFileName] = useState("");
  const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
  const [docErrors, setDocErrors] = useState({});
  const [docInputState, setDocInputState] = useState({});

  const [jsonData, setJsonData] = useState(null);


  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const [monthh, setMonthh] = useState(`${month}`);
  const day = today.getDate();

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  const bulktableRef = useRef(null);
  const handleDownload = () => {
    const table = bulktableRef.current;
    const wb = XLSX.utils.table_to_book(table, { sheet: "Attendance Sheet" });
    const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

    const blob = new Blob([s2ab(wbout)], {
      type: "application/vnd.ms-excel",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Attendance Sheet Format.xls";
    a.click();
    window.URL.revokeObjectURL(url);
  };


  // Utility function to convert binary string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };


  const handleClose = () => {
    setFileName("")
    setExcelJson(null)
    setDocErrors({})
    setShow(false)
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);

        const workbook = XLSX.read(data, { type: 'array' });

        console.log(workbook)

        // Assuming the sheet with attendance data is named "Attendance"
        const worksheet = workbook.Sheets['Attendance Sheet'];

        const rawData = XLSX.utils.sheet_to_json(worksheet);
        console.log(rawData)
        // Transform rawData into the required format for uploading
        const transformedData = transformAttendanceData(rawData);
        setJsonData(transformedData);
        console.log("demo")

        console.log(transformedData)
      };
      reader.readAsArrayBuffer(file);
      setFileName(file.name);
    }
  };

  const transformAttendanceData = (rawData) => {
    let transformedData = [];

    rawData.forEach((entry) => {
      const emp_code = entry.emp_code;


      // Loop through days of the month (assuming 1 to 31)
      for (let day = 1; day <= 31; day++) {
        if (entry[day]) {
          transformedData.push({
            emp_code: emp_code,
            date: `${yearr}-${monthh.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
            status: entry[day],  // 'P', 'A', 'L', etc.
            in_time: null,  // Add if available in the data
            out_time: null  // Add if available in the data
          });
        }
      }
    });

    return transformedData;
  };

  console.log(jsonData)

  const handleSubmit = async () => {
    if (!jsonData) {
      alert("Please upload an Excel file first!");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/siteproject/attendancepostbulk/`, { data: jsonData });
      alert('Attendance uploaded successfully!');
    } catch (error) {
      console.error('Error uploading attendance:', error);
      alert('Error uploading attendance.');
    }
  };

  // Error handling function for API errors
  const handleError = (err, loadingToastId) => {
    toast.dismiss(loadingToastId);
    handleErrorToast(err);
  };





  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // /attandance/employattandancessheet/222/2/2023/

  // const [monthName, setMonthName] = useState(`${month}`);

  const [yearr, setYearr] = useState(`${year}`);


  const [project, setProject] = useState("null");

  useEffect(() => {
    getAllattendancedata();
  }, [monthh, yearr]);


  const [allattendance, setAllattendance] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic




  // ?  ************** Validation End **************  ! //
  const [projectList, setProjectList] = useState([]);

  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/null/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  let getAllattendancedata = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/monthlyattendnaceall/${monthh}/${yearr}/${project}/`
      );

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: '', numericPart: 0 };
        }
      };

      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } = getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } = getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setAllattendance(sortedList);
      // setAllattendance(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);

    } finally {
      setBuffering(false); //buffering logic
    }
  };

  const refreshdata = async () => {
    setBuffering(true); //buffering logic
    await getAllattendancedata();
    setBuffering(false); //buffering logic
  };

  const getAllDatesOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dates = [];
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      dates.push({ date: formattedDate });
    }

    return dates;
  };

  const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
    ...date,
    index,
  }));

  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

  // *********** Filter Logic ***********




  useEffect(() => {
    getproject()
  }, [])



  useEffect(() => {
    getAllattendancedata()
  }, [project, yearr, monthh])



  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  const tableRef = useRef(null);





  return (
    <>
      <button
        title="Upload"
        className="model-button font-weight500 model-button-print"
        onClick={handleShow}
        style={{ margin: "2px" }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
          <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
          </g>
        </svg>
      </button>

      <Modal show={show} dialogClassName="ourcompany-model">
        <Modal.Header className="task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Upload Bulk Attendance Data in format:</Modal.Title>

          <button onClick={handleDownload} className="model-button-download model-button font-weight500" style={{ marginLeft: "35vw" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
              <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
              </g>
            </svg>
            {"  "} Format
          </button>
        </Modal.Header>
        <Modal.Body>



          <form className="register-form">
            <div className="form-flex-wrap">

              <div title="Project" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Project<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="project"
                  name="project"
                  placeholder="project"
                  value={project}
                  onChange={(e) => setProject(e.target.value)}
                  className='form-input form-group-selection'
                >

                  <option value="null">Select a Project</option>
                  {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.project_short_name}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>

              </div>


              <div title="Month" className="flex-column form-group-selectt">
                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                  Month<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="month"
                  name="month"
                  placeholder="month"
                  value={monthh} onChange={(e) => setMonthh(e.target.value)}
                  className={`form-input-date width-15vw`}
                >

                  <option value="">Select a month</option>
                  {monthOptions.map((month) => (
                    <option key={month.id} value={month.id}>{month.name}</option>
                  ))}
                </select>


              </div>

              <div title="Year" className="flex-column form-group-selectt ">
                <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                  Year<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="year"
                  name="year"
                  placeholder="year"
                  value={yearr} onChange={(e) => setYearr(e.target.value)}
                  className={`form-input-date width-10vw`}
                >

                  <option value="">Select a Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>





          <div className="table-css-white-background" style={{ display: "none" }}>
            <table
              ref={bulktableRef}
              id="my-table"
              style={{
                borderColor: "#323232",
                borderRadius: "5px",
                padding: "0px 5px",
                borderWidth: "1px",
                // marginLeft: "10px",
                // zoom: "70%",
              }}
            >
              <thead

              >

                <tr>
                  <th className="summary-excel-tab br-5">emp_code</th>
                  <th className="summary-excel-tab br-5">Name</th>
                  <th className="summary-excel-tab br-5">Joining Date</th>


                  <th className="summary-excel-tab br-5"> Designation Name</th>




                  {datees.map((date) => (
                    <th className="summary-excel-tab br-5" key={date.date}>
                      {date.date}
                    </th>
                  ))}



                </tr>
              </thead>

              {
                // buffer ? <div className="spinner"></div> :
                <tbody>
                  {allattendance
                    .sort((a, b) => a.emp_code - b.emp_code)
                    .map((data, index) => {


                      const inactivename = data.employee_status === "inactive" ? "#ff1212" : "black";


                      return (
                        <>
                          <tr
                            style={{
                              backgroundColor:
                                index === selectedIndex ? "yellow" : "white",
                              color: inactivename,
                            }}
                            onClick={() => handleClick(index)}
                          >
                            <td className="summary-excel-tab br-5">{data.emp_code}</td>
                            <td className="summary-excel-tab br-5">{data.name}</td>
                            <td className="summary-excel-tab br-5">{data.joining_date}</td>
                            <td className="summary-excel-tab br-5">{data.desigantion_name}</td>

                            {dates.map((date) => {
                              // Find the attendance record for the given date
                              const attendanceForDate = data.attendances.find(
                                (attendance) => attendance.date === date.date
                              );

                              const attendanceStatus = attendanceForDate
                                ? attendanceForDate.status === "P"
                                  ? "present"
                                  : attendanceForDate.status === "A"
                                    ? "absent"
                                    : attendanceForDate.status === "L"
                                      ? "leave"
                                      : attendanceForDate.status === "W"
                                        ? "weeklyoff"
                                        : attendanceForDate.status === "H"
                                          ? "holiday"
                                          : attendanceForDate.status.slice(0, 2)
                                : "-";


                              // Define the variables for In Time, Out Time, and Status
                              const inTime = attendanceForDate?.in_time ?? "-";
                              const outTime = attendanceForDate?.out_time ?? "-";
                              const attstat = attendanceForDate?.status ?? "-";

                              return (
                                <td
                                  key={date.date}
                                  style={{
                                    backgroundColor: index === selectedIndex ? "yellow" : "",
                                    color: index === selectedIndex ? "black" : "",
                                  }}
                                  data-status={attendanceStatus}
                                  className="behavior summary-excel-tab"
                                  title={`Date: ${date.date}\nIn Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\n`}
                                >
                                  {attstat}
                                </td>
                              );
                            })}



                            {/* <td
                          className="summary-excel-tab br-5"
                          style={{ backgroundColor: bgtotalpaiddays }}
                        >
                          {data.total}
                        </td> */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              }
            </table>

          </div>



          <div className="flex-column field-cont-div">
            <label htmlFor="document_file" className="form-labels font-weight500 font-size-heading">
              Upload File
            </label>
            <label
              htmlFor="document_file"
              className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                }`}
            >
              <svg
                className="pdf-input"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                  stroke="#707070"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </label>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              id="document_file"
              name="document_file"
              className="file-inputs align-center"
              style={{ display: "none", position: "relative" }} />
            {fileName && <p className="file-name align-center">{fileName}</p>}
            <br />
            {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
          </div>

          <div className="button-models">
            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
              Cancel
            </button>
            <button className="model-button-download model-button font-weight500" onClick={handleSubmit}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                  <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                </g>
              </svg>
              {"  "} Upload
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};






const AddBulkboqAttendance = ({ getAttendance, i }) => {

  const [show, setShow] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [employeeList, setEmployeeData] = useState([]);
  const [project, setProject] = useState("null");
  const [jsonData, setJsonData] = useState([]);
  const [fileName, setFileName] = useState("");
  const today = new Date();
  const [docErrors, setDocErrors] = useState({});
  const [docInputState, setDocInputState] = useState({});
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const [monthh, setMonthh] = useState(`${month}`);
  const [yearr, setYearr] = useState(`${year}`);
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
  const [formData, setFormData] = useState([
    {

    }
  ])


  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];

  const handleShow = () => {
    setShow(true);
    getProject();
  }
  const handleClose = () => {
    setShow(false);
    setProject("null");
    setMonthh(month);
    setYearr(year);
    setFileName("");
  }



  const getProject = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/project/`);
      setProjectList(res.data);

    } catch (err) {
      handleErrorToast(err);
    }
  }
  const getEmployee = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${project}/null/`);
      setEmployeeData(res.data);

    } catch (err) {

    }
  }
  useEffect(() => {
    getEmployee();
  }, [project]);

  const empIds = employeeList.map((item) => item.id);


  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const bulktableRef = useRef(null);
  const handleDownload = () => {
    const table = bulktableRef.current;
    const wb = XLSX.utils.table_to_book(table, { sheet: "BOQ Attendance Sheet" });
    const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

    const blob = new Blob([s2ab(wbout)], {
      type: "application/vnd.ms-excel",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "BOQ Attendance Sheet Format.xls";
    a.click();
    window.URL.revokeObjectURL(url);
  };



  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Get the first sheet name dynamically, if needed
        const sheetName = workbook.SheetNames[0];
        console.log(`Sheet name: ${sheetName}`);

        // Access the sheet dynamically or by name, if known
        const worksheet = workbook.Sheets[sheetName];
        if (!worksheet) {
          console.error("Worksheet not found. Please check the sheet name.");
          return;
        }

        // Convert worksheet to JSON
        const rawData = XLSX.utils.sheet_to_json(worksheet);
        console.log("Raw data from sheet:", rawData);

        // Transform rawData as needed
        const transformedData = rawData.map(row => ({
          emp_code: row['emp_code'],
          name: row['Name'],
          total_days: row['Days'],
          man_months: row['Man Months'],
          month: row['Month'],
          year: row['Year'],
        }));

        const combinedData = transformedData.map((item, index) => ({
          ...item,
          employee: empIds[index] || null,  // Ensure each row gets a unique emp_id from empIds array
        }));
        // Update state with transformed data
        setJsonData(combinedData);
        setFileName(file.name);

      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        alert("There was an error reading the file.");
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait...");

    try {
      const res = await axios.post(`${BASE_URL}/siteproject/attendancsitebulk/`, jsonData
      );
      if (res.status === 201) {
        await getAttendance();

        handleClose();
        toast.dismiss(loadingToastId);
        toast.success("BOQ Attendance added successfully!");
      } else {
        toast.dismiss(loadingToastId);
        toast.error("Failed to add Data!");
      }

    } catch (err) {
      handleErrorToast(err);
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title='Add Bulk BOQ Attendance'
        onClick={handleShow}
        className='upload-svg'
      >
        <AddwithWhiteCircle />{" "}BOQAttendance
      </button>

      <Modal show={show} dialogClassName='ourcompany-model'>
        <Modal.Header className="task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Upload Bulk Attendance Data in format:</Modal.Title>
          {project === "null" ? <></> :
            <button onClick={handleDownload} className="model-button-download model-button font-weight500" style={{ marginLeft: "35vw" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                  <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                </g>
              </svg>
              {"  "} Format
            </button>
          }
        </Modal.Header>
        <Modal.Body>
          <form className='register-form'>
            <div className="form-flex-wrap">
              <div title="Project" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Project<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="project"
                  name="project"
                  placeholder="project"
                  value={project}
                  onChange={(e) => setProject(e.target.value)}
                  className='form-input form-group-selection'
                >

                  <option value="null">Select a Project</option>
                  {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.project_short_name}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
              </div>

              <div title="Month" className="flex-column form-group-selectt">
                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                  Month<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="month"
                  name="month"
                  placeholder="month"
                  value={monthh} onChange={(e) => setMonthh(e.target.value)}
                  className={`form-input-date width-15vw`}
                >

                  <option value="">Select a month</option>
                  {monthOptions.map((month) => (
                    <option key={month.id} value={month.id}>{month.name}</option>
                  ))}
                </select>


              </div>

              <div title="Year" className="flex-column form-group-selectt ">
                <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                  Year<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="year"
                  name="year"
                  placeholder="year"
                  value={yearr} onChange={(e) => setYearr(e.target.value)}
                  className={`form-input-date width-10vw`}
                >

                  <option value="">Select a Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>

          <div className="table-css-white-background" style={{ display: "none" }}>
            <table
              ref={bulktableRef}
              id="my-table"
              style={{
                borderColor: "#323232",
                borderRadius: "5px",
                padding: "0px 5px",
                borderWidth: "1px",
                // marginLeft: "10px",
                // zoom: "70%",
              }}
            >
              <thead

              >

                <tr>
                  <th className="summary-excel-tab br-5">emp_code</th>
                  <th className="summary-excel-tab br-5">Name</th>
                  <th className="summary-excel-tab br-5">Month</th>
                  <th className="summary-excel-tab br-5">Year</th>
                  <th className="summary-excel-tab br-5">Days</th>
                  <th className="summary-excel-tab br-5">Man Months</th>






                  {/* {datees.map((date) => (
                    <th className="summary-excel-tab br-5" key={date.date}>
                      {date.date}
                    </th>
                  ))} */}



                </tr>
              </thead>

              {
                // buffer ? <div className="spinner"></div> :
                <tbody>
                  {employeeList
                    .sort((a, b) => a.emp_code - b.emp_code)
                    .map((data, index) => {


                      const inactivename = data.employee_status === "inactive" ? "#ff1212" : "black";


                      return (
                        <>
                          <tr
                          // style={{
                          //   backgroundColor:
                          //     index === selectedIndex ? "yellow" : "white",
                          //   color: inactivename,
                          // }}
                          // onClick={() => handleClick(index)}
                          >
                            <td className="summary-excel-tab br-5">{data.emp_code}</td>
                            <td className="summary-excel-tab br-5">{data.name}</td>
                            <td className="summary-excel-tab br-5 text-center">{monthh}</td>
                            <td className="summary-excel-tab br-5 text-center">{yearr}</td>
                            <td className="summary-excel-tab br-5 text-center">{"-"}</td>
                            <td className="summary-excel-tab br-5 text-center">{"-"}</td>


                            {/* {employeeList.map((date) => {
                              // Find the attendance record for the given date
                              const attendanceForDate = data.attendances.find(
                                (attendance) => attendance.date === date.date
                              );

                              const attendanceStatus = attendanceForDate
                                ? attendanceForDate.status === "P"
                                  ? "present"
                                  : attendanceForDate.status === "A"
                                    ? "absent"
                                    : attendanceForDate.status === "L"
                                      ? "leave"
                                      : attendanceForDate.status === "W"
                                        ? "weeklyoff"
                                        : attendanceForDate.status === "H"
                                          ? "holiday"
                                          : attendanceForDate.status.slice(0, 2)
                                : "-";


                              // Define the variables for In Time, Out Time, and Status
                              const inTime = attendanceForDate?.in_time ?? "-";
                              const outTime = attendanceForDate?.out_time ?? "-";
                              const attstat = attendanceForDate?.status ?? "-";

                              return (
                                <td
                                  // key={date.date}
                                  // style={{
                                  //   backgroundColor: index === selectedIndex ? "yellow" : "",
                                  //   color: index === selectedIndex ? "black" : "",
                                  // }}
                                  // data-status={attendanceStatus}
                                  // className="behavior summary-excel-tab"
                                  // title={`Date: ${date.date}\nIn Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\n`}
                                >
                                  {attstat}
                                </td>
                              );
                            })} */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              }
            </table>

          </div>
          <div className="flex-column field-cont-div">
            <label htmlFor="document_file" className="form-labels font-weight500 font-size-heading">
              Upload File
            </label>
            <label
              htmlFor="document_file"
              className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                }`}
            >
              <svg
                className="pdf-input"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                  stroke="#707070"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </label>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              id="document_file"
              name="document_file"
              className="file-inputs align-center"
              style={{ display: "none", position: "relative" }} />
            {fileName && <p className="file-name align-center">{fileName}</p>}
            <br />
            {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
          </div>
          <div className="button-models">
            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
              Cancel
            </button>
            <button className="model-button-download model-button font-weight500" onClick={handleSubmit}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                  <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                </g>
              </svg>
              {"  "} Upload
            </button>
          </div>

        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </>
  )
}

const DeleteboqAttandance = ({ i, getAttendance }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/attendancesitesupdate/${i.id}/`
      );

      if (res.status === 200) {
        await getAttendance();
        handleClose();
        toast.dismiss(loadingToastId);
        toast.success("Data deleted successfully!");
      } else {
        toast.dismiss(loadingToastId);
        toast.error("Failed to delete data!");
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Boq Attendance"
        className="model-delete-button"
        onClick={handleShow}
      >
        <DeleteDustbin />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete BOQ Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete attandance data of {i.employee_name}
            <div className="button-models">
              <button
                className="model-button   font-weight500    "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const Attendance = () => {

  // console.log(indexRow)

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const [monthh, setMonthh] = useState(`${month}`);
  const day = today.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[monthh - 1];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // /attandance/employattandancessheet/222/2/2023/

  // const [monthName, setMonthName] = useState(`${month}`);

  const [yearr, setYearr] = useState(`${year}`);


  const [project, setProject] = useState("null");

  useEffect(() => {
    getSubCompany();
    getAllattendancedata();
  }, [monthh, yearr, monthName]);

  const [SubCompany, setSubCompany] = useState([]);
  const [allattendance, setAllattendance] = useState([]);
  const [subbuffer, setSubBuffering] = useState(true); //buffering logic
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getSubCompany = async () => {
    setSubBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setSubBuffering(false);// End Buffering
    }
  };



  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const [projectList, setProjectList] = useState([]);

  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/null/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  let getAllattendancedata = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/monthlyattendnaceall/${monthh}/${yearr}/${project}/`
      );

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: '', numericPart: 0 };
        }
      };

      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } = getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } = getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setAllattendance(sortedList);
      // setAllattendance(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);

    } finally {
      setBuffering(false); //buffering logic
    }
  };

  const refreshdata = async () => {
    setBuffering(true); //buffering logic
    await getAllattendancedata();
    setBuffering(false); //buffering logic
  };

  const getAllDatesOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dates = [];
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      dates.push({ date: formattedDate });
    }

    return dates;
  };

  const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
    ...date,
    index,
  }));

  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

  // *********** Filter Logic ***********
  const [filteredEmployee, setFilteredEmployee] = useState([]);

  // const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );


  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;

    if (selectedStatus !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.employee_status === selectedStatus
      );
    }

    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code
          .toString()
          .includes(selectedName);
        return nameMatch || empCodeMatch;
      });
    }

    if (selectedDep) {
      const lowercaseSelectedName = selectedDep.toLowerCase();

      filteredData = filteredData.filter((employee) => {
        const depMatch = employee.department_name
          .toLowerCase()
          .includes(lowercaseSelectedName);
        // const rhMatch = employee.rh_name.toLowerCase().includes(lowercaseSelectedName);
        const rhMatch = employee.rh_name
          ? employee.rh_name.toLowerCase().includes(lowercaseSelectedName)
          : false;
        return depMatch || rhMatch;
      });
    }



    setFilteredEmployee(filteredData);
  };

  useEffect(() => {
    getproject()
  }, [])

  useEffect(() => {
    applyFilters();

  }, [
    selectedName,
    selectedStatus,
    // selectedDep,
    allattendance,
  ]);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  const tableRef = useRef(null);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">

          <div className="field-cont">
            <div className="field-cont-div">
              <CircularGrid />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">Sub Company</option>
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.title}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>


            <div className="field-cont-div">
              <CircularGrid />

              <select
                id="project"
                name="project"
                value={project}
                onChange={(e) => setProject(e.target.value)}
                className="attendance-input-field date-field"
              >
                <option value="">Select a Project</option>
                {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                  <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                </>))
                }
              </select>
              <hr className="field-cont-hr" />
            </div>





            <div className="field-cont-div">
              <input
                type="number"
                maxLength={4}
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
                className="attendance-input-field width-10vw   date-field"
              />
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Name or Code"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Dep or Rep Head"
                type="text"
                value={selectedDep}
                onChange={(e) => setDep(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Location />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>

                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              <hr className="field-cont-hr" />
            </div>


            {/* <div className="btn-cont">
                </div> */}
            <div className="btn-cont">
              {/* <button className='attendance-btn' onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 11 12" fill="none">
                  <circle cx="5" cy="5" r="4.3" stroke="#FFFFFF" stroke-width="1.4" />
                  <line x1="10.0101" y1="11" x2="8" y2="8.98995" stroke="#FFFFFF" stroke-width="1.4" stroke-linecap="round" />
                </svg>Search</button> */}
              <DownloadTableExcel
                filename={
                  selectedDivision === ""
                    ? `${monthName}-AttendanceSheet`
                    : `${monthName}-AttendanceSheet-${selectedDivision}`
                }
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <button className=" model-button-print">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="22"
                    id="download"
                    fill="#fff"
                  >
                    <g
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                    </g>
                  </svg>
                </button>
              </DownloadTableExcel>

              <UploadAttendance />


              <UploadAttendanceBulk2 />
            </div>
          </div>
        </div>
      </div>

      <div className="custom-table-width">
        <div className='flex-row justify-between'>

          <div className='table-heading'>
            <div className="repo-heading font-weight500    font-size-heading">
              Attendance ( {filteredEmployee.length} of {allattendance.length})
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="loading"><g><path fill="#414042" d="M29.89 15.81a2.51 2.51 0 1 0-5 .45 9.65 9.65 0 0 1-1.68 6.34 10.24 10.24 0 0 1-5.74 4 10.71 10.71 0 0 1-7.38-.7 11.44 11.44 0 0 1-5.48-5.62A12.07 12.07 0 0 0 9.46 27 12.58 12.58 0 0 0 17.9 29a13.31 13.31 0 0 0 8.18-4 14 14 0 0 0 3.81-8.75v-.08A2.29 2.29 0 0 0 29.89 15.81zM7.11 15.74A9.65 9.65 0 0 1 8.79 9.4a10.24 10.24 0 0 1 5.74-4 10.71 10.71 0 0 1 7.38.7 11.44 11.44 0 0 1 5.48 5.62A12.07 12.07 0 0 0 22.54 5 12.58 12.58 0 0 0 14.1 3 13.31 13.31 0 0 0 5.92 7a14 14 0 0 0-3.81 8.75v.08a2.29 2.29 0 0 0 0 .37 2.51 2.51 0 1 0 5-.45z"></path></g></svg> */}
            </div>
          </div>
          <div className="table-heading-flex ">


            {buffer || subbuffer ?
              <div className="spinner-vsmall"></div> :
              <div
                className="refresh-button"
                onClick={refreshdata}
                disabled={buffer} //buffering logic
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="refresh-icon"
                  fill="#fff"
                  viewBox="0 0 24 24"
                  id="refresh"
                >
                  <path d="M21 21a1 1 0 0 1-1-1V16H16a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5A1 1 0 0 1 21 21zM8 10H3A1 1 0 0 1 2 9V4A1 1 0 0 1 4 4V8H8a1 1 0 0 1 0 2z"></path>
                  <path d="M12 22a10 10 0 0 1-9.94-8.89 1 1 0 0 1 2-.22 8 8 0 0 0 15.5 1.78 1 1 0 1 1 1.88.67A10 10 0 0 1 12 22zM20.94 12a1 1 0 0 1-1-.89A8 8 0 0 0 4.46 9.33a1 1 0 1 1-1.88-.67 10 10 0 0 1 19.37 2.22 1 1 0 0 1-.88 1.1z"></path>
                </svg>
              </div>}

            {/* <div>
    
              </div> */}
          </div>
        </div>
        <div className='paddingTop-0px' style={{ height: "60vh" }}>

          <div className='table-box  '>
            <table
              ref={tableRef}
              id="my-table"
              style={{
                borderColor: "#323232",
                borderRadius: "5px",
                padding: "0px 5px",
                borderWidth: "1px",
                // marginLeft: "10px",
                // zoom: "70%",
              }}
            >
              <thead className='table-heading '>

                <tr>
                  <th rowSpan="2" className="summary-excel-tab br-5">Emp</th>
                  <th rowSpan="2" className="summary-excel-tab br-5">Name</th>
                  <th rowSpan="2" className="summary-excel-tab br-5">Joining Date</th>


                  <th rowSpan="2" className="summary-excel-tab br-5"> Designation Name</th>
                  <th colSpan={datees.length} className="summary-excel-tab br-5 align-center">Attendance</th>



                  <th colSpan="5" className="summary-excel-tab br-5">Attendance Stats</th>
                </tr>

                <tr>

                  {datees.map((date) => (
                    <th className="summary-excel-tab br-5" key={date.date}>
                      {date.date}
                    </th>
                  ))}

                  <th className="summary-excel-tab br-5">P</th>
                  <th className="summary-excel-tab br-5">A</th>
                  <th className="summary-excel-tab br-5">L</th>
                  <th className="summary-excel-tab br-5">H</th>
                  <th className="summary-excel-tab br-5">W</th>

                </tr>
              </thead>

              {
                // buffer ? <div className="spinner"></div> :
                <tbody>
                  {filteredEmployee
                    .sort((a, b) => a.emp_code - b.emp_code)
                    .map((data, index) => {
                      const background = data.late === 0 ? "white" : "#f9935f";

                      const inactivename = data.employee_status === "inactive" ? "#ff1212" : "black";
                      const present = data.present === 0 ? "white" : "#a3fc92";
                      const bgabsent = data.absent === 0 ? "white" : "#fccab1";
                      const holiday = data.holiday === 0 ? "white" : "#83B8E6CC";
                      const weeklyoff = data.weeklyoff === 0 ? "white" : "#83B8E6CC";
                      const late = data.late === 0 ? "white" : "#ffea72";
                      const leave = data.leave === 0 ? "white" : "#a3fc92";

                      return (
                        <>
                          <tr
                            style={{
                              backgroundColor:
                                index === selectedIndex ? "yellow" : "white",
                              color: inactivename,
                            }}
                            onClick={() => handleClick(index)}
                          >
                            <td className="summary-excel-tab br-5">{data.emp_code}</td>
                            <td className="summary-excel-tab br-5">{data.name}</td>
                            <td className="summary-excel-tab br-5">{data.joining_date}</td>
                            <td className="summary-excel-tab br-5">{data.desigantion_name}</td>

                            {dates.map((date) => {
                              // Find the attendance record for the given date
                              const attendanceForDate = data.attendances.find(
                                (attendance) => attendance.date === date.date
                              );

                              const attendanceStatus = attendanceForDate
                                ? attendanceForDate.status === "P"
                                  ? "present"
                                  : attendanceForDate.status === "A"
                                    ? "absent"
                                    : attendanceForDate.status === "L"
                                      ? "leave"
                                      : attendanceForDate.status === "W"
                                        ? "weeklyoff"
                                        : attendanceForDate.status === "H"
                                          ? "holiday"
                                          : attendanceForDate.status.slice(0, 2)
                                : "-";


                              // Define the variables for In Time, Out Time, and Status
                              const inTime = attendanceForDate?.in_time ?? "-";
                              const outTime = attendanceForDate?.out_time ?? "-";
                              const attstat = attendanceForDate?.status ?? "-";

                              return (
                                <td
                                  key={date.date}
                                  style={{
                                    backgroundColor: index === selectedIndex ? "yellow" : "",
                                    color: index === selectedIndex ? "black" : "",
                                  }}
                                  data-status={attendanceStatus}
                                  className="behavior summary-excel-tab"
                                  title={`Date: ${date.date}\nIn Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\n`}
                                >
                                  {attstat}
                                </td>
                              );
                            })}

                            <td
                              className="summary-excel-tab br-5"
                              style={{ backgroundColor: present }}
                            >
                              {data.present}
                            </td>


                            <td
                              className="summary-excel-tab br-5"
                              style={{ backgroundColor: bgabsent }}
                            >
                              {data.absent}
                            </td>
                            <td
                              className="summary-excel-tab br-5"
                              style={{ backgroundColor: holiday }}
                            >
                              {data.holiday}
                            </td>
                            <td
                              className="summary-excel-tab br-5"
                              style={{ backgroundColor: weeklyoff }}
                            >
                              {data.weeklyoff}
                            </td>
                            <td
                              className="summary-excel-tab br-5"
                              style={{ backgroundColor: leave }}
                            >
                              {data.leave}
                            </td>


                            {/* <td
                          className="summary-excel-tab br-5"
                          style={{ backgroundColor: bgtotalpaiddays }}
                        >
                          {data.total}
                        </td> */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              }
            </table>
            <div className="legend">
              <p>S = Sick</p>
              <p>L = Leave or Absent</p>
              <p>T = Travel / Tour</p>
              <p>HO = Head Office Input</p>
              <p>H =Holiday or Sunday</p>
            </div>
          </div>
        </div>





      </div>
    </>
  );
};


export { Attendance, DeleteboqAttandance, AddBulkboqAttendance, AddSiteAttendence, GetAttendance, OfficeRent, UpdateOfficeRent }
