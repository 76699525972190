import React, { useEffect, useState } from "react";
import usePermission from "../../config/permissions";
import axios from "axios";
import { BASE_URL, role } from "../../config/axios";
import { ToastContainer } from "react-toastify";

import Modal from "react-bootstrap/Modal";



import {

    AddSiteDepartment,
    AddSiteSubcompanys,
    DeleteSiteDepartment,

    DeleteSiteSubcompany,
    UpdateSiteDepartment,

    UpdateSiteSubcompanyupdate,


    AddSiteEmployee, UploadSiteEmployee
    , EmployeeDetailCard


} from "./WorkforceCrudButton"

import { handleErrorToast, sortProjects } from "../CustomFunctions";
import { CircularGrid, Location, Search, SearchSmall } from "../AllSvg";
import { useLocation } from "react-router-dom";
import { AddSiteAttendence } from "../Attendance";




const SiteDesignationTable = () => {
    const [siteDepartmentList, setSiteDepartmentList] = useState([]);
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { PERMISSION_AUTHORITY } = usePermission();
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

    const getSiteDepartment = async () => {
        setBuffering(true); // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSiteSubCompanyList(sub.data);

            // const res = await axios.get(`${BASE_URL}/siteproject/designation/`);
            const res = await axios.get(
                `${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`
            );
            setSiteDepartmentList(res.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getSiteDepartment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleCompany]);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                    Site Designation
                </div>
                <div className="field-cont">
                    {PERMISSION_AUTHORITY ? (
                        <div className="table-searchh dropdown-container field-cont-div">
                            <select
                                onChange={(e) => setToggleCompany(e.target.value)}
                                value={toggleCompany}
                                className="dropdown font-size-subheading  "
                            >
                                <option value="null">Select Sub Company</option>
                                {siteSubCompanyList.map((company) => (
                                    <option value={company.id}>{company.title}</option>
                                ))}
                                ;
                            </select>
                        </div>
                    ) : null}
                    <div className="table-search field-cont-div">
                        <div>
                            <SearchSmall />

                        </div>
                        <div>
                            <input
                                placeholder="Search Designation"
                                className="navbar-input font-weight400  font-size-text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <AddSiteDepartment
                        getSiteDepartment={getSiteDepartment}
                        siteDepartmentList={siteDepartmentList}
                        siteSubCompanyList={siteSubCompanyList}
                    />
                </div>
            </div>

            <div className="table-box height-73vh">
            <table className="table-css">
                <thead className="table-heading">
                    <tr className="custom-table-head-tr">
                        <th className="align-left">Sr.no</th>
                        <th className="align-leftt">Designation</th>
                        <th className="align-center">Sub Company</th>
                        <th className="align-center">Employee Count</th>
                        <th className="align-center">Employee View</th>
                        <th className="align-center width-5vw">Action</th>
                    </tr>
                </thead>
                {buffer ? (
                    <div className="spinner"></div>
                ) : (
                    <tbody>
                        {siteDepartmentList
                            ?.filter((e) =>
                                e.title.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) =>
                                a.title.localeCompare(b.title, "en", {
                                    ignorePunctuation: true,
                                })
                            )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-leftt">{i.title}</td>
                                        <td className="align-center">{i.sub_company_name}</td>

                                        <td className="align-center">{i?.employee_count}</td>
                                        {/* <td className="align-center">View </td> */}
                                        <td className="align-center">
                                            <EmployeebySiteDesignationTable designation={i} />{" "}
                                        </td>
                                        <td className="align-center flex-row width-5vw">
                                            <UpdateSiteDepartment
                                                i={i}
                                                getSiteDepartment={getSiteDepartment}
                                                siteDepartmentList={siteDepartmentList}
                                                siteSubCompanyList={siteSubCompanyList}

                                            />
                                            {PERMISSION_AUTHORITY ? (
                                                <DeleteSiteDepartment
                                                    i={i}
                                                    getSiteDepartment={getSiteDepartment}
                                                />
                                            ) : null}


                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                )}
            </table>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

const EmployeebySiteDesignationTable = ({ designation }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getSiteDepartmentEmployees(designation.id);
    };

    const [departmentEmployees, setDepartmentEmployees] = useState([]);

    const getSiteDepartmentEmployees = async (designation) => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeedetailsbydesignation/${designation}/active/`
            );
            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });
            setDepartmentEmployees(sortedList);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };


    return (
        <>
            <button
                className=""
                title="View Employees"
                style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
                onClick={handleShow}
            >
                View
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <>
                        {/* <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"></div>
              </div> */}
                        <div className="table-css-white-background">
                            <div className="table-heading-flex">
                                <div className="repo-heading font-weight500    font-size-heading">
                                    Employees with : <br />
                                    {designation.title}
                                </div>
                                <div className="flex-row">
                                    <div className="table-searchh">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11"
                                                height="12"
                                                viewBox="0 0 11 12"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.3"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                />
                                                <line
                                                    x1="10.0101"
                                                    y1="11"
                                                    x2="8"
                                                    y2="8.98995"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <div>
                                            <input
                                                placeholder="Search"
                                                className="navbar-inputt font-weight400  font-size-text"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table-css">
                                <thead>
                                    <tr className="custom-table-head-tr">
                                        <th className="align-left">ID</th>
                                        <th className="align-center">Emp Code</th>
                                        <th className="align-center"> Name </th>
                                    </tr>
                                </thead>
                                {buffer ? (
                                    <div className="spinner"></div> //buffering logic
                                ) : (
                                    <tbody>
                                        {departmentEmployees
                                            ?.filter(
                                                (e) =>
                                                    e.name
                                                        .toLowerCase()
                                                        .includes(searchQuery.toLowerCase()) ||
                                                    e.emp_code
                                                        .toString()
                                                        .includes(searchQuery.toLowerCase())
                                            )
                                            .sort((a, b) => a.emp_code - b.emp_code)
                                            .map((i, index) => (
                                                <React.Fragment key={index}>
                                                    {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                                                    <tr className="custom-table-head-td">
                                                        <td className="align-left">{index + 1}</td>
                                                        <td className="align-center">{i.emp_code}</td>
                                                        <td className="align-center">{i.name}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const SiteSubcompanyTable = () => {
    const { PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [subCompanyList, setSubCompanyList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const getSiteSubcompanies = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                // `${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/${toggleCompany}/active/`
                `${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/null/active/`
                // `${BASE_URL}/siteproject/subcompany`
            );
            setSubCompanyList(res.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getSiteSubcompanies();
    }, []);

    return (
        <div className="table-css-white-background">
            <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">
                    Sub Company
                </div>
                <div className="flex-row">
                    <div className="table-searchh">
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="12"
                                viewBox="0 0 11 12"
                                fill="none"
                            >
                                <circle
                                    cx="5"
                                    cy="5"
                                    r="4.3"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                />
                                <line
                                    x1="10.0101"
                                    y1="11"
                                    x2="8"
                                    y2="8.98995"
                                    stroke="#2B3674"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        <div>
                            <input
                                placeholder="Search Sub Company"
                                className="navbar-inputt font-weight400  font-size-text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    {PERMISSION_AUTHORITY ? (
                        <AddSiteSubcompanys getSiteSubcompanies={getSiteSubcompanies} />
                    ) : null}
                </div>
            </div>
            <div className="table-box height-73vh">
            <table className="table-css">
                <thead className="table-heading">
                    <tr className="custom-table-head-tr">
                        <th className="align-left">Sr.no</th>
                        <th className="align-center">Company Name</th>
                        <th className="align-center">Employee Count</th>
                        <th className="align-center">Employees</th>
                        <th className="align-right width-5vw">Action</th>
                    </tr>
                </thead>
                {buffer ? (
                    <div className="spinner"></div> //buffering logic
                ) : (
                    <tbody>
                        {subCompanyList
                            ?.filter((e) =>
                                e.title.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) =>
                                a.title.localeCompare(b.title, "en", {
                                    ignorePunctuation: true,
                                })
                            )
                            .map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">
                                        <td className="align-left">{index + 1}</td>
                                        <td className="align-center">{i.title}</td>
                                        <td className="align-center">{i.employee_count}</td>
                                        <td className="align-center">
                                            <EmployeebySiteSubCompanyTable subcompany={i} />{" "}
                                        </td>
                                        <td className="align-right flex-row ">
                                            <UpdateSiteSubcompanyupdate
                                                i={i}
                                                getSiteSubcompanies={getSiteSubcompanies}
                                            />

                                            {PERMISSION_AUTHORITY ? (
                                                <DeleteSiteSubcompany
                                                    i={i}
                                                    getSiteSubcompanies={getSiteSubcompanies}
                                                />
                                            ) : null}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                    </tbody>
                )}
            </table>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

const EmployeebySiteSubCompanyTable = ({ subcompany }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getSubCompanyEmployees(subcompany.id);
    };

    const [subcompanyEmployees, setSubCompanyEmployees] = useState([]);

    const getSubCompanyEmployees = async (subcompanyId) => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeedetailsbysubcompany/${subcompanyId}/active/`
            );
            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });
            setSubCompanyEmployees(sortedList);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // useEffect(() => {
    //   getSubCompanyEmployees();
    // }, []);


    return (
        <>
            <button
                className=""
                title="View Employees"
                style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
                onClick={handleShow}
            >
                View
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Body>
                    <>
                        {/* <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"></div>
              </div> */}
                        <div className="table-css-white-background">
                            <div className="table-heading-flex">
                                <div className="repo-heading font-weight500    font-size-heading">
                                    Employees in : <br />
                                    {subcompany.title}
                                </div>
                                <div className="flex-row">
                                    <div className="table-searchh">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11"
                                                height="12"
                                                viewBox="0 0 11 12"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.3"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                />
                                                <line
                                                    x1="10.0101"
                                                    y1="11"
                                                    x2="8"
                                                    y2="8.98995"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <div>
                                            <input
                                                placeholder="Search"
                                                className="navbar-inputt font-weight400  font-size-text"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table-css">
                                <thead>
                                    <tr className="custom-table-head-tr">
                                        <th className="align-left">ID</th>
                                        <th className="align-center">Emp Code</th>
                                        <th className="align-center"> Name </th>
                                        <th className="align-center"> Designation </th>
                                    </tr>
                                </thead>
                                {buffer ? (
                                    <div className="spinner"></div> //buffering logic
                                ) : (
                                    <tbody>
                                        {subcompanyEmployees
                                            ?.filter(
                                                (e) =>
                                                    e.emp_code.includes(searchQuery.toLowerCase()) ||
                                                    e.name
                                                        .toLowerCase()
                                                        .includes(searchQuery.toLowerCase())
                                            )
                                            .sort((a, b) => a.emp_code - b.emp_code)
                                            .map((i, index) => (
                                                <React.Fragment key={index}>
                                                    {/* <tr className="tr-border-bottom">
                            <td colSpan="6"></td>
                          </tr> */}
                                                    <tr className="custom-table-head-td">
                                                        <td className="align-left">{index + 1}</td>
                                                        <td className="align-center">{i.emp_code}</td>
                                                        <td className="align-center">{i.name}</td>
                                                        <td className="align-center">
                                                            {i.designation_name}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};



const SiteEmployeeDetails = () => {
    const { projectID, companyId, siteRole } = usePermission();
    const [project, setProject] = useState(projectID ? projectID : null);
    const location = useLocation();

    const [projectList, setProjectList] = useState([]);
    const [subCompany, setSubCompany] = useState(companyId ? companyId : null);
    const [subcompanyList, setSubCompanyList] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);

    const [profession, setProfession] = useState("null");
    const [designation, setDesignation] = useState(null);
    const [designationList, setDesignationList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("null");
    const [buffer, setBuffering] = useState(false); //buffering logic
    const projectIdd = projectID ? projectID : location.state?.project?.id;

    const getSubCompany = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompanyList(response.data);
        } catch (error) {
            alert(error);
        }
    }

    const getproject = async () => {
        try {
            // const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${subCompany}`);
            const response = await axios.get(
                sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
                    `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                    : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
                        `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                        : `${BASE_URL}/siteproject/projectfilterbysubcompany/${subCompany}/`
            );
            const sortedList = sortProjects(response.data, "site_prcode");
            // setProjectList(response.data)
            setProjectList(sortedList)

        } catch (error) {
            alert(error);
        }
    }

    const getDesignation = async () => {
        try {
            const responsee = await axios.get(`${BASE_URL}/siteproject/designationfilterbysubcompany/${subCompany}`)
            setDesignationList(responsee.data);
        } catch (error) {

        }
    }

    useEffect(() => {

        getSubCompany();



    }, []);

    useEffect(() => {


        if (projectID === null) {
            getproject();
        }
        getproject();

        getDesignation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subCompany])

    const getEmployee = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const ress = await axios.get(`${BASE_URL}/siteproject/employeebyfilterss/${project}/${profession}/${selectedStatus}/${subCompany}/${designation}/`);

            setEmployeeData(ress.data)


        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    }

    useEffect(() => {
        getEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, profession, selectedStatus, subCompany, designation]);

    // const currentUrl = window.location.href;
    // const urlParts = currentUrl.split("/");


    // const navigate = useNavigate()
    // const Viewdetails = (emp_code, user_detail) => {
    //   navigate(`/${urlParts[3]}/userdetailscrud/${emp_code}/${user_detail}/`)
    // }
    return (
        <div>
            <div className="attendance-subcont">
                <div className="field-cont">

                    <div className="field-cont-div">
                        <Location />
                        <select className="attendance-input-field  date-field" type="text"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="null">All</option>

                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>



                        </select>
                        <hr className="field-cont-hr" />
                    </div>


                    {siteRole === "SiteManagement" ? "" :
                        <div title="Company Filter" className="field-cont-div">
                            <CircularGrid />
                            <select
                                onChange={(e) => setSubCompany(e.target.value)}
                                value={subCompany}
                                className="attendance-input-field width-15vw   date-field"
                            // className="dropdown font-size-subheading  "
                            >
                                <option value="null">Select Sub Company</option>
                                {subcompanyList.map((company) => (
                                    <option value={company.id}>{company.title}</option>
                                ))}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>

                    }
                    {siteRole === "SiteManagement" ? "" :
                        <div title="Project" className="field-cont-div">
                            <CircularGrid />

                            <select
                                onChange={(e) => setProject(e.target.value)}
                                value={project}
                                className="attendance-input-field width-20vw   date-field"
                            >
                                <option value="null">Select Project</option>
                                {projectList.map((project) => (
                                    <option value={project.id}>{project.site_prcode}-{project.project_short_name}</option>
                                ))}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>
                    }


                    <div className="field-cont-div">
                        <CircularGrid />


                        <select
                            className="attendance-input-field width-10vw   date-field"
                            type="text"
                            value={profession}
                            onChange={(e) => setProfession(e.target.value)}
                        >
                            <option value="null"> Professional </option>
                            <option value="Key Professional">Key Professional</option>
                            <option value="Sub Professional">Sub Professional</option>
                            <option value="Support Staff">Support Staff</option>
                        </select>
                        <hr className="field-cont-hr" />
                    </div>

                    <div title="Designation" className="field-cont-div">
                        <CircularGrid />

                        <select
                            onChange={(e) => setDesignation(e.target.value)}
                            value={designation}
                            className="attendance-input-field width-20vw   date-field"
                        >
                            <option value="null">Select Designation</option>
                            {designationList.map((designation) => (
                                <option value={designation.id}>{designation.title}</option>
                            ))}
                            ;
                        </select>

                        <hr className="field-cont-hr" />
                    </div>
                </div>
                <div className="btn-cont">
                    <AddSiteEmployee
                        projectId={project}
                        getSiteEmployee={getEmployee}
                        siteProjectList={projectList}
                        siteDepartmentList={designationList}
                        siteSubCompanyList={subcompanyList}
                        companyId={subCompany}
                    />
                    <UploadSiteEmployee />

                </div>
            </div>
            <div className="height-73vh">
                {
                    buffer ? <div className="spinner"></div> :
                        <EmployeeDetailCard
                            data={employeeData}
                            getSiteEmployee={getEmployee}
                            siteDepartmentList={designationList}
                            siteProjectList={projectList}
                            siteSubCompanyList={subcompanyList} />}
            </div>
        </div>
    )

}







export {
    SiteDesignationTable,
    SiteSubcompanyTable,

    SiteEmployeeDetails,

};
