import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../config/axios';
import { Modal } from 'react-bootstrap';
import { sortProjects } from '../CustomFunctions';
import { formattedDateLong } from '../Date';
import { UpdateTrackRecordDetails } from '../SiteManagement/ProjectDetailsCrudButtons';

const ProjectByOurRoleModel = ({ project_number, our_role }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getProjectData()
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [projectdata, setProjectData] = useState([]);

  const getProjectData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/project-by-our-role/${our_role}/`);

      const sortedProjects = sortProjects(res.data, 'site_prcode')

      setProjectData(sortedProjects);
    } catch (err) { }
  };





  return (
    <>
      <div onClick={handleShow}>
        {project_number}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4.3"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                    />
                    <line
                      x1="10.0101"
                      y1="11"
                      x2="8"
                      y2="8.98995"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <input
                    placeholder="Search by Project Name/ Authority Engineer"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>



          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-left font-size-text font-weightbold'>Sno.</th>
                <th className='align-center font-size-text font-weightbold'>Project Code</th>
                <th className='align-leftt font-size-text font-weightbold'>Project Name</th>
                <th className='align-center font-size-text font-weightbold'>Authority Engineer</th>

              </tr>
            </thead>
            <tbody>
              {projectdata.filter((e) =>
                String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.authority_engineer_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                      <td className='align-center font-size-text font-weight400'>{project.site_prcode}</td>
                      <td className='align-leftt font-size-text font-weight400'>{project.project_short_name}</td>

                      <td className='align-center font-size-text font-weight400'>{project.authority_engineer_name}</td>


                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};



const ProjectByWrokTypeModel = ({ project_number, work_type }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getProjectData()
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [projectdata, setProjectData] = useState([]);

  const getProjectData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/project-by-work-type/${work_type}/`);

      const sortedProjects = sortProjects(res.data, 'site_prcode')

      setProjectData(sortedProjects);
    } catch (err) { }
  };





  return (
    <>
      <div onClick={handleShow}>
        {project_number}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4.3"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                    />
                    <line
                      x1="10.0101"
                      y1="11"
                      x2="8"
                      y2="8.98995"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <input
                    placeholder="Search by Project Name/ Authority Engineer"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>



          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-left font-size-text font-weightbold'>Sno.</th>
                <th className='align-center font-size-text font-weightbold'>Project Code</th>
                <th className='align-leftt font-size-text font-weightbold'>Project Name</th>
                <th className='align-center font-size-text font-weightbold'>Authority Engineer</th>

              </tr>
            </thead>
            <tbody>
              {projectdata.filter((e) =>
                String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.authority_engineer_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                      <td className='align-center font-size-text font-weight400'>{project.site_prcode}</td>
                      <td className='align-leftt font-size-text font-weight400'>{project.project_short_name}</td>

                      <td className='align-center font-size-text font-weight400'>{project.authority_engineer_name}</td>


                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};

const EmployeebyTypeModel = ({ count, professional_type }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getEmployeeData()
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [employeedata, setEmployeeData] = useState([]);

  const getEmployeeData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/employee-by-type/${professional_type}/`);
      setEmployeeData(res.data);
    } catch (err) { }
  };





  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title >
            Employee Details

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4.3"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                    />
                    <line
                      x1="10.0101"
                      y1="11"
                      x2="8"
                      y2="8.98995"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <input
                    placeholder="Search by Name/ Desigantion/ Project Name"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-left font-size-text font-weightbold'>Sno.</th>
                <th className='align-leftt font-size-text font-weightbold'>Name</th>


                <th className='align-center font-size-text font-weightbold'>Designation Name</th>


                {professional_type === "null" ? <th className='align-centre font-size-text font-weightbold'>Professional Type</th> : ""}



                <th className='align-center font-size-text font-weightbold'>Project Code</th>

                <th className='align-center font-size-text font-weightbold'>Project Short Name</th>



              </tr>
            </thead>
            <tbody>
              {employeedata.filter((e) =>
                e.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.designation_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                      <td className='align-leftt font-size-text font-weight400'>{project.name}</td>
                      <td className='align-center font-size-text font-weight400'>{project.designation_name}</td>

                      {professional_type === "null" ? <td className='align-center font-size-text font-weight400'>{project.professional_type}</td> : ""}


                      <td className='align-center font-size-text font-weight400'>{project.site_project}</td>

                      <td className='align-center font-size-text font-weight400'>{project.project_short_name}</td>



                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};


const VacantEmployeebyTypeModel = ({ count, professional_type }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getEmployeeData()
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [employeedata, setEmployeeData] = useState([]);

  const getEmployeeData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/vacant-employee-by-type/${professional_type}/`);



      const sortedProjects = sortProjects(res.data, 'site_prcode')

      setEmployeeData(sortedProjects);

    } catch (err) { }
  };


  console.log(employeedata)




  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title >
            Vacant Position Details

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4.3"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                    />
                    <line
                      x1="10.0101"
                      y1="11"
                      x2="8"
                      y2="8.98995"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <input
                    placeholder="Search by Desigantion/ Code/Project Name"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-left font-size-text font-weightbold'>Sno.</th>



                <th className='align-leftt font-size-text font-weightbold'>Designation Name</th>


                {professional_type === "null" ? <th className='align-centre font-size-text font-weightbold'>Professional Type</th> : ""}



                <th className='align-center font-size-text font-weightbold'>Project Code</th>

                <th className='align-center font-size-text font-weightbold'>Project Short Name</th>



              </tr>
            </thead>
            <tbody>
              {employeedata.filter((e) => String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.designation_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>

                      <td className='align-leftt font-size-text font-weight400'>{project.designation_name}</td>

                      {professional_type === "null" ? <td className='align-center font-size-text font-weight400'>{project.professional_type}</td> : ""}


                      <td className='align-center font-size-text font-weight400'>{project.site_prcode}</td>

                      <td className='align-center font-size-text font-weight400'>{project.project_short_name}</td>



                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};



const EmployeeDesignationWiseModel = ({ count, projectdata }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };






  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width60vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4.3"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                    />
                    <line
                      x1="10.0101"
                      y1="11"
                      x2="8"
                      y2="8.98995"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <input
                    placeholder="Search by Project Name/ Authority Engineer"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>



          <table className='custom-table'>
            <thead className='heading'>
              <tr className='custom-table-head-tr global-table-tr'>
                <th className='align-left font-size-text font-weightbold'>Sno.</th>
                <th className='align-center font-size-text font-weightbold'>Project Code</th>
                <th className='align-leftt font-size-text font-weightbold'>Project Name</th>

              </tr>
            </thead>
            <tbody>
              {projectdata.filter((e) =>
                String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.authority_engineer_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((project, index) => (
                <>
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className='custom-table-head-td global-table-td' key={index}>
                      <td className='align-center font-size-text font-weight400'>{index + 1}</td>
                      <td className='align-center font-size-text font-weight400'>{project.site_prcode}</td>
                      <td className='align-leftt font-size-text font-weight400'>{project.project_short_name}</td>



                    </tr>
                  </React.Fragment>
                </>
              ))}
            </tbody>
          </table>

        </Modal.Body>
      </Modal>
    </>
  );
};



const LetterbyStatusModel = ({ count, letter_status }) => {
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
  };
  const handleShow = () => {
    setshow(true);
    getEmployeeData()
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const [letterdata, setLetterData] = useState([]);

  const getEmployeeData = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/lettery-by-status/${letter_status}/`);



      const sortedProjects = sortProjects(res.data, 'site_prcode')

      setLetterData(sortedProjects);

    } catch (err) { }
  };






  return (
    <>
      <div onClick={handleShow}>
        {count}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="model-width90vw"
      >
        <Modal.Header closeButton>
          <Modal.Title >
            Vacant Position Details

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="table-heading-flex">
            <div></div>
            <div className="flex-row">
              <div className="table-searchh">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4.3"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                    />
                    <line
                      x1="10.0101"
                      y1="11"
                      x2="8"
                      y2="8.98995"
                      stroke="#2B3674"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div>
                  <input
                    placeholder="Search by Site Code/Project Name/ Number"
                    className="navbar-inputt font-weight400  font-size-text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>


          <table className="table-css">
            <thead>
              <tr className='custom-table-head-tr'>

                <th className='align-leftt'>Project</th>
                <th className='align-leftt'>No</th>
                <th className='align-center'>Exchange</th>
                <th className='align-center'>Date</th>
                <th className='align-center'>Subject</th>
                <th className='align-center'>From /To</th>
                <th className='align-center'>Type</th>
                <th className='align-center'>Associated Letter</th>
                <th className='align-center'>Status</th>

                <th className='align-right width-5vw'>View/Edit</th>

              </tr>
            </thead>

            <tbody>
              {letterdata.filter((e) => String(e.site_prcode).toLowerCase().includes(searchQuery.toLowerCase()) ||

String(e.project_short_name).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.letter_num).toLowerCase().includes(searchQuery.toLowerCase()) ||
                String(e.subject).toLowerCase().includes(searchQuery.toLowerCase())
              ).map((i, index) => (
                <tr className="custom-table-head-td">
                  <td className='align-leftt'>{i?.site_prcode}-{i.project_short_name}</td>
                  <td className='align-leftt form-text-trasformation-uppercase'>{i.letter_num}</td>
                  <td className='align-center form-text-trasformation-uppercase'>{i.letterExchange}</td>
                  <td className='align-center'>{formattedDateLong(i.letter_date)}</td>
                  <td className='align-center'>{i.subject}</td>
                  <td className='align-center form-text-trasformation-uppercase'>{i.letter_from}/ {i.letter_to}</td>
              
                  <td className='align-center form-text-trasformation-uppercase'>{i.lettertype}</td>
                  <td className='align-left'> {i.associated_letters_get.length > 0
                    ? i.associated_letters_get[0].letter_num
                    : "No associated letters"}</td>
                  {/* <td className='align-center'>{i.letter_date ? formattedDateLong(i.letter_date) : "---"}</td> */}
                  <td className='align-center form-text-trasformation-uppercase'>{i.status}</td>

                  <td className='align-center'>
                              <UpdateTrackRecordDetails
                                i={i}
                   
                                projectId={i.project}
                                letterType={i.lettertype}
                                workperformedby={i.workperformedby}
                              // associatedletters={i.associated_letters_get[0].letter_num }
                              />

                            </td>

                </tr>
              ))}
            </tbody>
          </table>


        </Modal.Body>
      </Modal>
    </>
  );
};






export { ProjectByOurRoleModel, EmployeebyTypeModel, VacantEmployeebyTypeModel, EmployeeDesignationWiseModel, LetterbyStatusModel }
